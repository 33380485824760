import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ERadioButtonMargin,
  ERadioButtonScale,
  ScwMatButtonGroupAlignment,
  ScwMatButtonGroupLabelPosition,
  ScwMatRadioGroupOption,
  ScwMatRadioGroupRule,
} from './scw-mat-radio-group.model';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ScwMatInputRule } from '../scw-mat-input/scw-mat-input.model';

@Component({
  selector: 'scw-mat-radio-group',
  templateUrl: './scw-mat-radio-group.component.html',
  styleUrls: ['./scw-mat-radio-group.component.scss'],
})
export class ScwMatRadioGroupComponent {
  @Input() inputModel: any;
  @Input() isValid: boolean = false;
  @Input() options: ScwMatRadioGroupOption[];
  @Input() alignment: ScwMatButtonGroupAlignment = 'horizontal';
  @Input() labelPosition: ScwMatButtonGroupLabelPosition = 'after';
  @Input() hasErrors: boolean = false;
  @Input() errorText: string;
  @Input() rules: ScwMatRadioGroupRule[] = [];
  @Input() groupLabel: string = '';
  @Input() customMargin: ERadioButtonMargin;
  @Input() customScale: ERadioButtonScale;

  @Output() inputModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isAnyError: boolean = false;

  constructor(private translate: TranslateService) {}

  public reset(): void {
    this.inputModel = null;
    this.clearErrorMessage();
  }

  private isValidEqualizer(isValid: boolean): void {
    this.isValid = isValid;
    this.isValidChange.emit(this.isValid);
  }

  private showErrorMessage(message: string): void {
    this.isValidEqualizer(false);
    this.isAnyError = true;
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  public clearErrorMessage(): void {
    this.isValidEqualizer(true);
    this.isAnyError = false;
    this.hasErrors = false;
    this.errorText = null;
  }

  private requiredRule(rule: ScwMatInputRule): void {
    if (_.isNil(this.inputModel)) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.required'));
    }
  }

  private customRule(rule: ScwMatInputRule): void {
    if (this.inputModel && rule.custom && !rule.validator(this.inputModel)) {
      this.showErrorMessage(rule.message);
    }
  }

  private checkRules(): void {
    if (this.rules.length === 0) {
      this.isValidEqualizer(true);
      return;
    }

    this.isAnyError = false;

    for (const rule of this.rules) {
      if (this.isAnyError) {
        return;
      }

      if ('required' in rule) {
        this.requiredRule(rule);
      }

      if ('custom' in rule) {
        this.customRule(rule);
      }
    }

    if (this.isAnyError) {
      return;
    }

    this.clearErrorMessage();
  }

  public onNgModelChange(): void {
    this.inputModelChange.emit(this.inputModel);
    this.checkRules();
  }
}
