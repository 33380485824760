import { Injectable } from '@angular/core';
import {
  IElasticRequestBody,
  IActivityHistoryRequestParameter,
} from '../../store/reports/root-cause-analysis-elastic/root-cause-analysis-elastic.model';
import moment from 'moment';
import {mysqlDateFormat, mysqlTimestampFormat} from '../helper/date';
import { ActivityTypes } from '../model/enum/activity-types';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../store/oee.reducer';
import { CheckInTableElasticParams } from '../../view/reports/check-in-logs-elastic/check-in-logs-elastic.model';
import {
  ElasticReduceBy,
  ElasticStackChartGroupBy
} from '../../view/reports/root-cause-analysis-elastic/charts/charts.model';

@Injectable({
  providedIn: 'root',
})
export class ElasticHelperService {

  constructor(public store: Store<oeeAppReducer.OeeAppState>) {}

  public getElasticQueryBodyOfActivityHistory(
    params: IActivityHistoryRequestParameter,
  ): IElasticRequestBody {
    const startDate = moment(params.dateRange.startDate).format(params.isBusinessDate ?mysqlDateFormat : mysqlTimestampFormat);
    const endDate = moment(params.dateRange.endDate).format(params.isBusinessDate ?mysqlDateFormat : mysqlTimestampFormat);
    return {
      search: {
        ...(params.isBusinessDate ? {
          shiftDay: {
            timeZone: params.userTimezone,
            gte: startDate,
            lte: endDate,
          },
        } : {
          start: {
            timeZone: params.userTimezone,
            gt: startDate,
          },
          end: {
            timeZone: params.userTimezone,
            lte: endDate,
          },
        }
        ),
        activity: {
          type: [ActivityTypes.DOWN_TIME, ActivityTypes.DOWN_TIME_PLANNED, ActivityTypes.IDLE_TIME],
          ...(params.activityIds !== -1 && { ids: params.activityIds }),
          ...(params.searchTexts?.selectedActivityName && { names: [params.searchTexts.selectedActivityName] })
        },
        ...(params.searchTexts?.selectedEquipmentName && {
          equipment: {
            names: [params.searchTexts.selectedEquipmentName]
          }
        }),
        ...(params.searchTexts?.selectedTaskName && {
          task: {
            titles: Array.isArray(params.searchTexts.selectedTaskName)
              ? params.searchTexts.selectedTaskName
              : [params.searchTexts.selectedTaskName],
          },
        }),
        ...(params.siteIds !== -1 && { site: { ids: params.siteIds } }),
        ...(params.lineIds !== -1 && { line: { ids: params.lineIds } }),
        ...(params.productIds !== -1 && { workOrderSchedule: { product: { ids: params.productIds } } }),
        ...(params.rootCauseGroupIds !== -1 && { task: { rootCauseGroup: { ids: params.rootCauseGroupIds } } }),
        ...(params.shiftIds !== -1 && { shift: { ids: params.shiftIds } }),
      },
    };
  }

  public getElasticQueryBodyOfStackChart(
    params: IActivityHistoryRequestParameter,
  ): IElasticRequestBody {
    const startDate = moment(params.dateRange.startDate).format(params.isBusinessDate ?mysqlDateFormat : mysqlTimestampFormat);
    const endDate = moment(params.dateRange.endDate).format(params.isBusinessDate ?mysqlDateFormat : mysqlTimestampFormat);
    return {
      search: {
        ...(params.isBusinessDate ? {
          shiftDay: {
            timeZone: params.userTimezone,
            gte: startDate,
            lte: endDate,
          },
        } : {
          start: {
            timeZone: params.userTimezone,
            gt: startDate,
          },
          end: {
            timeZone: params.userTimezone,
            lte: endDate,
          },
        }
        ),
        activity: {
          type: [ActivityTypes.DOWN_TIME, ActivityTypes.DOWN_TIME_PLANNED, ActivityTypes.IDLE_TIME],
          ...(params.activityIds !== -1 && { ids: params.activityIds }),
          ...(params.searchTexts?.selectedActivityName && { names: [params.searchTexts.selectedActivityName] })
        },
        ...(params.searchTexts?.selectedEquipmentName && {
          equipment: {
            names: [params.searchTexts.selectedEquipmentName]
          }
        }),
        ...(params.searchTexts?.selectedTaskName && {
          task: {
            titles: Array.isArray(params.searchTexts.selectedTaskName)
              ? params.searchTexts.selectedTaskName
              : [params.searchTexts.selectedTaskName],
          },
        }),
        ...(params.siteIds !== -1 && { site: { ids: params.siteIds } }),
        ...(params.lineIds !== -1 && { line: { ids: params.lineIds } }),
        ...(params.productIds !== -1 && { workOrderSchedule: { product: { ids: params.productIds } } }),
        ...(params.rootCauseGroupIds !== -1 && { task: { rootCauseGroup: { ids: params.rootCauseGroupIds } } }),
        ...(params.shiftIds !== -1 && { shift: { ids: params.shiftIds } }),
      },
      aggregation: {
        ...(!params.isComparisonMode && ({join: {fields: [ElasticReduceBy.ACTIVITY_TYPE]}})),
        groupBy: params.isBusinessDate ? ElasticStackChartGroupBy.SHIFT_DAY : ElasticStackChartGroupBy.START,
        ...(params.groupBy !== (params.isBusinessDate ? ElasticStackChartGroupBy.SHIFT_DAY : ElasticStackChartGroupBy.START) && { groupBy: params.groupBy }),
        ...((params.groupBy === (params.isBusinessDate ? ElasticStackChartGroupBy.SHIFT_DAY : ElasticStackChartGroupBy.START))
          && {
            attributes: {
              dateHistogram: {
                field: params.isBusinessDate ? ElasticStackChartGroupBy.SHIFT_DAY : ElasticStackChartGroupBy.START,
                interval: params.interval,
              },
            },
          }),
        ...(params.isComparisonMode && !params.field && ({
          join: {
            fields: params.isBusinessDate ? [ElasticStackChartGroupBy.SHIFT_DAY] : [ElasticStackChartGroupBy.START],
            attributes: {
              dateHistogram: {
                field: params.isBusinessDate ? ElasticStackChartGroupBy.SHIFT_DAY : ElasticStackChartGroupBy.START,
                interval: params.interval,
              },
            },
          },
        })),
        ...(params.isComparisonMode && params.field && ({
          join: {
            fields: [params.field],
          },
        })),
        ...((params.groupBy === 'activity') &&
          {
            join: {
              fields: [ElasticReduceBy.ACTIVITY_TYPE, 'equipment', 'task'],
            },
            include: {
              fields: ['taskTarget'],
            },
          }
        ),
        ...((params.groupBy === 'equipment') &&
          {
            join: {
              fields: [ElasticReduceBy.ACTIVITY_TYPE, 'activity', 'task'],
            },
          }
        ),
        ...((params.groupBy === 'task') &&
          {
            join: {
              fields: [ElasticReduceBy.ACTIVITY_TYPE, 'activity', 'equipment'],
            },
          }
        ),
        ...((params.isComparisonMode && !params.field)
          && {
            attributes: {
              dateHistogram: {
                field: params.isBusinessDate ? ElasticStackChartGroupBy.SHIFT_DAY : ElasticStackChartGroupBy.START,
                interval: params.interval ?? ElasticStackChartGroupBy.WEEK,
              },
            },
          }),
      },
    };
  }

  public getElasticComparisonQueryBodyOfStackChart(
    params: IActivityHistoryRequestParameter,
  ): IElasticRequestBody {
    const startDate = moment(params.dateRange.startDate).format(params.isBusinessDate ?mysqlDateFormat : mysqlTimestampFormat);
    const endDate = moment(params.dateRange.endDate).format(params.isBusinessDate ?mysqlDateFormat : mysqlTimestampFormat);
    return {
      search: {
        ...(params.isBusinessDate ? {
          shiftDay: {
            timeZone: params.userTimezone,
            gte: startDate,
            lte: endDate,
          },
        } : {
          start: {
            timeZone: params.userTimezone,
            gt: startDate,
          },
          end: {
            timeZone: params.userTimezone,
            lte: endDate,
          },
        }
        ),
        activity: {
          type: [ActivityTypes.DOWN_TIME, ActivityTypes.DOWN_TIME_PLANNED, ActivityTypes.IDLE_TIME],
          ...(params.activityIds !== -1 && { ids: params.activityIds }),
        },
        ...(params.siteIds !== -1 && { site: { ids: params.siteIds } }),
        ...(params.lineIds !== -1 && { line: { ids: params.lineIds } }),
        ...(params.productIds !== -1 && { workOrderSchedule: { product: { ids: params.productIds } } }),
        ...(params.rootCauseGroupIds !== -1 && { task: { rootCauseGroup: { ids: params.rootCauseGroupIds } } }),
        ...(params.shiftIds !== -1 && { shift: { ids: params.shiftIds } }),
      },
      aggregation: {
        groupBy: params.comparisonGroup?.toLowerCase(),
        ...(params.groupBy === ElasticStackChartGroupBy.START && {
          attributes: {
            dateHistogram: {
              field: ElasticStackChartGroupBy.START,
              interval: params.interval,
            },
          },
        }),
        join: {
          fields: [ElasticReduceBy.ACTIVITY_TYPE, 'activity', 'equipment', 'task'],
        },
      },
    };
  }

  public getElasticQueryBodyOfCheckIns(params: CheckInTableElasticParams, timezone: string): IElasticRequestBody {
    const startDate = moment(params.startDate).format(mysqlDateFormat);
    const endDate = moment(params.endDate).format(mysqlDateFormat);

    return {
      user: {
        checkIn: {
          checkInTime: {
            gt: startDate,
            timeZone: timezone,
          },
          checkOutTime: {
            lt: endDate,
            timeZone: timezone,
          },
        },
      },
      sourceType: {
        ids: [params.sourceTypeId],
      },
      ...(params.destinationTypeIds &&
        params.destinationTypeIds !== -1 && { destinationType: { ids: params.destinationTypeIds } }),
      ...(params.destinationObjectIds &&
        params.destinationObjectIds !== -1 && { destinationObject: { ids: params.destinationObjectIds } }),
      ...(params.sourceObjectIds && params.sourceObjectIds !== -1 && { sourceObject: { ids: params.sourceObjectIds } }),
      ...(params.siteIds && params.siteIds !== -1 && { site: { ids: params.siteIds } }),
      ...(params.shiftIds && params.shiftIds !== -1 && { shift: { ids: params.shiftIds } }),
    };
  }
}
