import { Action } from '@ngrx/store';
import {
  OeeWaterfallAnalysisOeeParametersInterface,
  OeeWaterfallAnalysisRootCauseAnalysisParametersInterface,
  TargetDatumInterface,
  TargetQueryParametersInterface,
} from './oee-waterfall-analysis.model';
import { IOeeCalculationResultFormatted } from '../../../shared/model/interface/generic-api-response.model';
import { IRootCauseAnalysisChartNode } from '../root-cause-analysis/root-cause-analysis.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { HttpErrorResponse } from '@angular/common/http';

export const OEE_WATERFALL_ANALYSIS_OEE_DATA_LOADING = '[OEE_WATERFALL_ANALYSIS] OEE DATA LOADING';
export const OEE_WATERFALL_ANALYSIS_OEE_DATA_LOADED = '[OEE_WATERFALL_ANALYSIS] OEE DATA LOADED';
export const OEE_WATERFALL_ANALYSIS_ROOT_CAUSE_ANALYSIS_DATA_LOADING =
  '[OEE_WATERFALL_ANALYSIS] ROOT CAUSE ANALYSIS DATA LOADING';
export const OEE_WATERFALL_ANALYSIS_ROOT_CAUSE_ANALYSIS_DATA_LOADED =
  '[OEE_WATERFALL_ANALYSIS] ROOT CAUSE ANALYSIS DATA LOADED';
export const OEE_WATERFALL_ANALYSIS_TARGETS_DATA_LOADED = '[OEE_WATERFALL_ANALYSIS] TARGETS DATA LOADING';
export const OEE_WATERFALL_ANALYSIS_TARGETS_DATA_LOADING = '[OEE_WATERFALL_ANALYSIS] TARGETS DATA LOADED';
export const OEE_WATERFALL_ANALYSIS_FETCH_ERROR = '[OEE_WATERFALL_ANALYSIS] OEE WATERFALL ANALYSIS FETCH ERROR';

export class OeeDataLoading implements Action {
  readonly type = OEE_WATERFALL_ANALYSIS_OEE_DATA_LOADING;

  constructor(public params: OeeWaterfallAnalysisOeeParametersInterface) {}
}

export class OeeDataLoaded implements Action {
  readonly type = OEE_WATERFALL_ANALYSIS_OEE_DATA_LOADED;

  constructor(public payload: IOeeCalculationResultFormatted) {}
}

export class RootCauseAnalysisDataLoading implements Action {
  readonly type = OEE_WATERFALL_ANALYSIS_ROOT_CAUSE_ANALYSIS_DATA_LOADING;

  constructor(public params: OeeWaterfallAnalysisRootCauseAnalysisParametersInterface) {}
}

export class RootCauseAnalysisDataLoaded implements Action {
  readonly type = OEE_WATERFALL_ANALYSIS_ROOT_CAUSE_ANALYSIS_DATA_LOADED;

  constructor(public payload: IRootCauseAnalysisChartNode[]) {}
}

export class TargetsDataLoading implements Action {
  readonly type = OEE_WATERFALL_ANALYSIS_TARGETS_DATA_LOADING;

  constructor(public queryParameters: TargetQueryParametersInterface) {}
}

export class TargetsDataLoaded implements Action {
  readonly type = OEE_WATERFALL_ANALYSIS_TARGETS_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<TargetDatumInterface>) {}
}

export class FetchError implements Action {
  readonly type = OEE_WATERFALL_ANALYSIS_FETCH_ERROR;

  constructor(public params: HttpErrorResponse) {}
}

export type OeeWaterfallAnalysisActions =
  | OeeDataLoading
  | OeeDataLoaded
  | RootCauseAnalysisDataLoading
  | RootCauseAnalysisDataLoaded
  | TargetsDataLoading
  | TargetsDataLoaded
  | FetchError;
