import { IMicrostopsAnalysisState } from './microstops-analysis.model';
import * as Actions from './microstops-analysis.actions';

export const microstopsAnalysisInitialState: IMicrostopsAnalysisState = {
  getMicrostopsAnalysisData: false,
  getMicrostopsAnalysisDataCompleted: false,
  microstopsAnalysisData: null,
};

export function microstopsAnalysisReducer(
  state = microstopsAnalysisInitialState,
  action: Actions.MicrostopsAnalysisActions,
): IMicrostopsAnalysisState {
  switch (action.type) {
    case Actions.GET_MICROSTOPS_ANALYSIS_DATA:
      return {
        ...state,
        getMicrostopsAnalysisData: true,
        getMicrostopsAnalysisDataCompleted: false,
      };

    case Actions.GET_MICROSTOPS_ANALYSIS_DATA_COMPLETED:
      return {
        ...state,
        getMicrostopsAnalysisData: false,
        getMicrostopsAnalysisDataCompleted: true,
        microstopsAnalysisData: action.payload,
      };

    case Actions.FETCH_ERROR:
      return {
        ...state,
        getMicrostopsAnalysisData: false,
        getMicrostopsAnalysisDataCompleted: false,
      };

    default:
      return state;
  }
}
