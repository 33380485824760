<div class="page-config-container">
  <ng-template [ngIf]="large">
    <scw-mat-button [iconButton]="true" [type]="'standard'" (onClick)="showModal(config_modal)">
      <em class="fas fa-sliders-h"></em>
    </scw-mat-button>
  </ng-template>
  <ng-template [ngIf]="!large">
    <scw-mat-small-button [iconButton]="true" (onClick)="showModal(config_modal)">
      <em class="fas fa-sliders-h"></em>
    </scw-mat-small-button>
  </ng-template>
</div>
<ng-template #config_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'general.modal.pageConfiguration.header' | translate }}
    </h3>
    <button id="close-button" type="button" class="close" onfocus="blur()" aria-label="Close" (click)="onCloseClick()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div class="col-12">
        <div class="row">
          <div *ngFor="let section of sectionOrder">
            <div *ngIf="section.type === pageConfigurationTypes.HEADER_GROUP">
              <ng-container *ngTemplateOutlet="headerGroupSection"></ng-container>
            </div>
            <div *ngIf="section.type === pageConfigurationTypes.BUTTON_GROUP">
              <ng-container *ngTemplateOutlet="buttonGroupSection"></ng-container>
            </div>
            <div *ngIf="section.type === pageConfigurationTypes.WIDGET">
              <ng-container *ngTemplateOutlet="widgetSection"></ng-container>
            </div>
            <div *ngIf="section.type === pageConfigurationTypes.TABLE">
              <ng-container *ngTemplateOutlet="tableSection"></ng-container>
            </div>
            <div *ngIf="section.type === pageConfigurationTypes.CHART">
              <ng-container *ngTemplateOutlet="chartSection"></ng-container>
            </div>
            <div *ngIf="section.type === pageConfigurationTypes.CUSTOM">
              <ng-container *ngTemplateOutlet="customSection; context: { index: section.index }"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button (onClick)="apply(true)" [type]="'standard'" data-automation-id="page-config-set-as-default-btn">
        {{ 'general.setAsDefault' | translate }}
      </scw-mat-button>
      <scw-mat-button (onClick)="apply(false)" data-automation-id="page-config-apply-btn">
        {{ 'general.apply' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
<ng-template #unsaved_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'general.modal.pageConfiguration.header' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-section">
      <div>
        <em class="fas fa-exclamation-circle modal-info-icon"></em>
      </div>
      <h6 class="scw-header">
        {{ 'pageConfiguration.unsavedChanges.label' | translate }}
      </h6>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-left dont-apply-button">
      <scw-mat-button (onClick)="dontApply()" [type]="'danger'">
        {{ 'general.dontApply' | translate }}
      </scw-mat-button>
    </div>
    <div class="buttons-right">
      <scw-mat-button (onClick)="modal.dismiss()" [type]="'standard'">
        {{ 'general.cancel' | translate }}
      </scw-mat-button>
      <scw-mat-button (onClick)="apply(false)">
        {{ 'general.apply' | translate }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>
<ng-template let-column="column" #groupItem>
  <div class="page_configuration_item-left">
    <scw-mat-checkbox
      [id]="'table-config-checkbox-' + column.value"
      [(inputModel)]="column.selected"
      [disabled]="column.disabled"
      (onChange)="changeCheckBoxWithGroupLogic($event)"
    >
    </scw-mat-checkbox>
    <div class="page_configuration_item-left-title">{{ column.name }}</div>
  </div>
</ng-template>

<ng-template #headerGroupSection>
  <div class="row" *ngIf="hasGroupedHeaders">
    <ng-container *ngFor="let group of configurationGroups">
      <ng-template [ngIf]="group.isVisible">
        <div class="col-12">
          <h6 class="scw-header">
            <em [ngClass]="group.icon"></em>
            {{ group.name }}
          </h6>
        </div>
        <ng-template [ngIf]="group.description">
          <div class="col-12">
            <div class="label-text">
              {{ group.description }}
            </div>
          </div>
        </ng-template>
        <div class="col-12">
          <div
            cdkDropList
            class="datatable-list"
            cdkDropListOrientation="vertical"
            (cdkDropListDropped)="drop($event, group.values)"
            [cdkDropListEnterPredicate]="cdkDropListEnterPredicateColumn"
            *ngIf="group.values.length"
          >
            <ng-container *ngFor="let column of group.values">
              <ng-template [ngIf]="column.sortable">
                <div
                  cdkDrag
                  class="datatable-box"
                  [class.is-sortable]="column.sortable"
                  [id]="column.value"
                  [cdkDragData]="column"
                >
                  <ng-container *ngTemplateOutlet="groupItem; context: { column: column }"></ng-container>
                  <em class="fas fa-arrows-alt-v page_configuration_item-drag_icon"></em>
                </div>
              </ng-template>
              <ng-template [ngIf]="!column.sortable">
                <div class="datatable-box" [id]="column.value">
                  <ng-container *ngTemplateOutlet="groupItem; context: { column: column }"></ng-container>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
<ng-template
  #buttonGroupSection
>
  <div class="row" *ngIf="addDashboardModeSwitch">
    <div class="col-12 m-b-25">
      <scw-mat-button-group
        *ngIf="addDashboardModeSwitch"
        [buttons]="dashboardModeButtons"
        (inputModelChange)="pageModeChange($event)"
        [inputModel]="mode"
        data-automation-id="mode-btn"
      ></scw-mat-button-group>
      </div>
  </div>
</ng-template>
<ng-template #widgetSection>
  <div class="row" *ngIf="tabs[0].widget?.length">
    <div class="col-12">
      <h6 class="scw-header">
        <em class="fas fa-grip-horizontal"></em>
        {{ 'pageConfiguration.modal.widgetConfiguration' | translate }}
      </h6>
    </div>
    <div class="col-12">
      <div class="label-text">
        {{ widgetLabelTranslation }}
      </div>
    </div>
    <div class="col-12">
      <ng-template [ngIf]="isSortable">
        <div
          cdkDropList
          class="datatable-list"
          (cdkDropListDropped)="drop($event, tabs[0].widget)"
          cdkDropListOrientation="vertical"
        >
          <ng-container *ngFor="let item of tabs[0].widget; index as rowIndex">
            <div class="datatable-box" [id]="item.name" cdkDrag>
              <div class="page_configuration_item-left">
                <scw-mat-checkbox
                  [id]="'widget-config-checkbox-' + item.name"
                  [(inputModel)]="item.selected"
                  [disabled]="
                    !isWidgetLimitEnabled ? item.disabled : selectedWidgetCount >= widgetLimit && !item.selected
                  "
                  (onChange)="changeCheckBox($event, sectionTypes.Widget)"
                >
                </scw-mat-checkbox>
                <div class="page_configuration_item-left-title">{{ item.title }}</div>
              </div>
              <em class="fas fa-arrows-alt-v page_configuration_item-drag_icon"></em>
            </div>
          </ng-container>
        </div>
      </ng-template>
      <ng-template [ngIf]="!isSortable">
        <div class="datatable-list">
          <ng-container *ngFor="let item of tabs[0].widget; index as rowIndex">
            <div class="datatable-box" [id]="item.name">
              <div class="page_configuration_item-left">
                <scw-mat-checkbox
                  [id]="'table-config-checkbox-' + item.name"
                  [(inputModel)]="item.selected"
                  [disabled]="item.disabled"
                  (onChange)="changeCheckBox($event, sectionTypes.Widget)"
                >
                </scw-mat-checkbox>
                <div class="page_configuration_item-left-title">{{ item.title }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
<ng-template #tableSection>
  <div class="row" *ngIf="tabs[0].table.length">
    <div class="col-12 d-flex flex-column my-4 gap-3" *ngIf="tableConfigHasRow">
      <ng-container *ngFor="let item of tabs[0].table">
        <div *ngIf="item?.isTableRow">
          <div class="page_configuration_item-left">
            <scw-mat-checkbox
              [id]="'table-config-checkbox-' + item.name"
              [(inputModel)]="item.selected"
              [disabled]="item.disabled"
              (onChange)="changeCheckBox($event, sectionTypes.Table)"
            >
            </scw-mat-checkbox>
            <div class="page_configuration_item-left-title">{{ item.title }}</div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-12">
      <h6 class="scw-header">
        <em class="fas fa-table"></em>
        {{ 'pageConfiguration.modal.tableConfiguration' | translate }}
      </h6>
    </div>
    <div class="col-12">
      <div class="label-text">
        {{ tableLabelTranslation }}
      </div>
    </div>
    <div class="col-12">
      <ng-template [ngIf]="isSortable && !hasRoundupTable">
        <div
          cdkDropList
          class="datatable-list"
          (cdkDropListDropped)="drop($event, tabs[0].table)"
          cdkDropListOrientation="vertical"
          [cdkDropListEnterPredicate]="cdkDropListEnterPredicateColumn"
        >
          <ng-container *ngFor="let item of tabs[0].table; index as rowIndex">
            <ng-template [ngIf]="item.sortable === false && pageHeader.titleKey === 'work_orders'" [ngIfElse]="defaultPageConfiguration">
              <div class="datatable-box" [class.is-sortable]="isSortable" [id]="item.name" *ngIf="!item?.isTableRow">
                <div class="page_configuration_item-left">
                  <scw-mat-checkbox
                    [id]="'table-config-checkbox-' + item.name"
                    [(inputModel)]="item.selected"
                    [disabled]="item.disabled"
                    (onChange)="changeCheckBox($event, sectionTypes.Table)"
                  >
                  </scw-mat-checkbox>
                  <div class="page_configuration_item-left-title">{{ item.title }}</div>
                </div>
              </div>
            </ng-template>
            <ng-template #defaultPageConfiguration>
              <div
                class="datatable-box"
                [class.is-sortable]="isSortable"
                [id]="item.name"
                cdkDrag
                *ngIf="!item?.isTableRow"
                [cdkDragData]="item"
              >
                <div class="page_configuration_item-left">
                  <scw-mat-checkbox
                    [id]="'table-config-checkbox-' + item.name"
                    [(inputModel)]="item.selected"
                    [disabled]="item.disabled"
                    (onChange)="changeCheckBox($event, sectionTypes.Table)"
                  >
                  </scw-mat-checkbox>
                  <div class="page_configuration_item-left-title">{{ item.title }}</div>
                </div>
                <em class="fas fa-arrows-alt-v page_configuration_item-drag_icon"></em>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </ng-template>
      <ng-template [ngIf]="!isSortable && !hasRoundupTable">
        <div class="datatable-list">
          <ng-container *ngFor="let item of tabs[0].table; index as rowIndex">
            <div class="datatable-box" [id]="item.name">
              <div class="page_configuration_item-left">
                <scw-mat-checkbox
                  [id]="'table-config-checkbox-' + item.name"
                  [(inputModel)]="item.selected"
                  [disabled]="item.disabled"
                  (onChange)="changeCheckBox($event, sectionTypes.Table)"
                >
                </scw-mat-checkbox>
                <div class="page_configuration_item-left-title">{{ item.title }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
    <ng-template [ngIf]="hasRoundupTable">
      <div class="col-12">
        <div
          cdkDropList
          class="datatable-list"
          (cdkDropListDropped)="drop($event, tabs[0].table)"
          cdkDropListOrientation="vertical"
        >
          <ng-container
            *ngFor="let item of tabs[0].table | slice: 0:productionListViewHeaders.length; index as rowIndex"
          >
            <div class="datatable-box" [id]="item.name" cdkDrag>
              <div class="page_configuration_item-left">
                <scw-mat-checkbox
                  [id]="'table-config-checkbox-' + item.name"
                  [(inputModel)]="item.selected"
                  [disabled]="item.disabled"
                  (onChange)="changeCheckBox($event, sectionTypes.Table, 0, true)"
                >
                </scw-mat-checkbox>
                <div class="page_configuration_item-left-title">{{ item.title }}</div>
              </div>
              <em class="fas fa-arrows-alt-v page_configuration_item-drag_icon"></em>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="label-text">
          {{ pageHeader.titleKey === 'production_review' ? productionReviewInfoMessage : null }}
        </div>
      </div>
      <div class="col-12">
        <h6 class="scw-header">
          <em class="fas fa-table"></em>
          {{ 'pageConfiguration.modal.roundupTable' | translate }}
        </h6>
      </div>
      <div class="col-12">
        <div class="label-text">
          {{ roundupTableLabelTranslation }}
        </div>
      </div>
      <div class="col-12">
        <div class="datatable-list">
          <ng-container *ngFor="let item of tabs[0].table | slice: productionListViewHeaders.length; index as rowIndex">
            <div class="datatable-box" [id]="item.name">
              <div class="page_configuration_item-left">
                <scw-mat-checkbox
                  [id]="'table-config-checkbox-' + item.name"
                  [(inputModel)]="item.selected"
                  [disabled]="item.disabled"
                  (onChange)="changeCheckBox($event, sectionTypes.Table, 0, true)"
                >
                </scw-mat-checkbox>
                <div class="page_configuration_item-left-title">{{ item.title }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
<ng-template #chartSection >
  <div class="row" *ngIf="tabs[0].chart?.length">
    <div class="col-12">
      <h6 class="scw-header">
        <em class="fas fa-table"></em>
        {{ 'pageConfiguration.modal.chartConfiguration' | translate }}
      </h6>
    </div>
    <div class="col-12">
      <div class="label-text">
        {{ chartLabelTranslation }}
      </div>
    </div>
    <div class="col-12">
      <ng-template [ngIf]="isSortable">
        <div
          cdkDropList
          class="datatable-list"
          (cdkDropListDropped)="drop($event, tabs[0].chart)"
          cdkDropListOrientation="vertical"
        >
          <ng-container *ngFor="let item of tabs[0]?.chart; index as rowIndex">
            <div class="datatable-box" [id]="item.name" cdkDrag>
              <div class="page_configuration_item-left">
                <scw-mat-checkbox
                  [id]="'table-config-checkbox-' + item.name"
                  [(inputModel)]="item.selected"
                  [disabled]="item.disabled"
                  (onChange)="changeChartCheckBox($event)"
                >
                </scw-mat-checkbox>
                <div class="page_configuration_item-left-title">{{ item.title }}</div>
              </div>
              <em class="fas fa-arrows-alt-v page_configuration_item-drag_icon"></em>
            </div>
          </ng-container>
        </div>
      </ng-template>
      <ng-template [ngIf]="!isSortable">
        <div class="datatable-list">
          <ng-container *ngFor="let item of tabs[0]?.chart; index as rowIndex">
            <div class="datatable-box" [id]="item.name">
              <div class="page_configuration_item-left">
                <scw-mat-checkbox
                  [id]="'table-config-checkbox-' + item.name"
                  [(inputModel)]="item.selected"
                  [disabled]="item.disabled"
                  (onChange)="changeChartCheckBox($event)"
                >
                </scw-mat-checkbox>
                <div class="page_configuration_item-left-title">{{ item.title }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
<ng-template #customSection let-index="index">
  <div class="row" *ngFor="let item of tabs[index]?.custom; index as rowIndex">
    <div class="col-12"
         *ngIf="item.show !== false">
      <h6 class="scw-header">
        <em [class]="item.headerIconClass"></em>
        {{ item.header }}
      </h6>
    </div>
    <ng-template [ngIf]="item.label">
      <div class="col-12">
        <div class="label-text">
          {{ item.label }}
        </div>
      </div>
    </ng-template>
    <div *ngIf="item.show !== false " class="col-12">
      <ng-template [ngIf]="item.isRadio">
        <scw-mat-radio-group
          [options]="item.radioGroup"
          [(inputModel)]="item.defaultRadioButtonValue"
          (inputModelChange)="setSelectedRadio($event, rowIndex)"
          alignment="vertical"
        ></scw-mat-radio-group>
      </ng-template>
      <ng-template [ngIf]="!item.bordered">
        <ng-container *ngFor="let row of item.rows">
          <div class="page_configuration_item-left">
            <scw-mat-checkbox
              [id]="'table-config-checkbox-' + row.name"
              [(inputModel)]="row.selected"
              [disabled]="row.disabled"
              (onChange)="changeCheckBox($event, sectionTypes.Custom, rowIndex)"
            >
            </scw-mat-checkbox>
            <div class="page_configuration_item-left-title">{{ row.title }}</div>
          </div>
        </ng-container>
      </ng-template>
      <ng-template [ngIf]="item.bordered">
        <ng-template [ngIf]="item.sortable">
          <div
            cdkDropList
            class="datatable-list"
            (cdkDropListDropped)="drop($event, item.rows)"
            cdkDropListOrientation="vertical"
            [cdkDropListEnterPredicate]="cdkDropListEnterPredicateColumn"
          >
            <ng-container *ngFor="let row of item.rows">
              <div
                class="datatable-box"
                [class.is-sortable]="item.sortable"
                [id]="row.name"
                cdkDrag
                [cdkDragData]="item"
              >
                <div class="page_configuration_item-left">
                  <scw-mat-checkbox
                    [id]="'table-config-checkbox-' + row.name"
                    [(inputModel)]="row.selected"
                    [disabled]="row.disabled"
                    (onChange)="changeCheckBox($event, sectionTypes.Custom, rowIndex)"
                  >
                  </scw-mat-checkbox>
                  <div class="page_configuration_item-left-title">{{ row.title }}</div>
                </div>
                <em class="fas fa-arrows-alt-v page_configuration_item-drag_icon"></em>
              </div>
            </ng-container>
          </div>
        </ng-template>
        <ng-template [ngIf]="!item.sortable">
          <div class="datatable-list">
            <ng-container *ngFor="let row of item.rows">
              <div class="datatable-box" [id]="row.name">
                <div class="page_configuration_item-left">
                  <scw-mat-checkbox
                    [id]="'table-config-checkbox-' + row.name"
                    [(inputModel)]="row.selected"
                    [disabled]="row.disabled"
                    (onChange)="changeCheckBox($event, sectionTypes.Custom, rowIndex)"
                  >
                  </scw-mat-checkbox>
                  <div class="page_configuration_item-left-title">{{ row.title }}</div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ng-template>
    </div>
    <div class="col-12">
      <div class="label-text">
        {{ pageHeader.titleKey === 'production_review' ? productionReviewInfoMessage : null }}
      </div>
    </div>
  </div>
</ng-template>
