import { Action } from '@ngrx/store';
import { IMicrostopsAnalysisData, IMicrostopsAnalysisTableQuery } from './microstops-analysis.model';

export const GET_MICROSTOPS_ANALYSIS_DATA = '[MICROSTOPS ANALYSIS] GET MICROSTOPS ANALYSIS DATA';
export const GET_MICROSTOPS_ANALYSIS_DATA_COMPLETED = '[MICROSTOPS ANALYSIS] GET MICROSTOPS ANALYSIS DATA COMPLETED';
export const FETCH_ERROR = '[MICROSTOPS ANALYSIS] FETCH ERROR';

export class GetMicrostopsAnalysisData {
  readonly type = GET_MICROSTOPS_ANALYSIS_DATA;

  constructor(public payload: IMicrostopsAnalysisTableQuery) {}
}

export class GetMicrostopsAnalysisDataCompleted {
  readonly type = GET_MICROSTOPS_ANALYSIS_DATA_COMPLETED;

  constructor(public payload: IMicrostopsAnalysisData) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type MicrostopsAnalysisActions = GetMicrostopsAnalysisData | GetMicrostopsAnalysisDataCompleted | FetchError;
