import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OeeAppState } from '../../oee.reducer';
import { Store } from '@ngrx/store';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import {
  IOeeCalculationResultFormatted,
  ResponseInterface,
} from '../../../shared/model/interface/generic-api-response.model';
import {
  OeeWaterfallAnalysisOeeParametersInterface,
  OeeWaterfallAnalysisRootCauseAnalysisParametersInterface,
  TargetDatumInterface,
  TargetQueryParametersInterface,
} from './oee-waterfall-analysis.model';
import { Observable } from 'rxjs';
import { IRootCauseAnalysisChartNode } from '../root-cause-analysis/root-cause-analysis.model';
import moment from 'moment';
import { mysqlDateFormat } from '../../../shared/helper/date';

@Injectable({
  providedIn: 'root',
})
export class OeeWaterfallAnalysisService {
  private readonly routes = {
    oee: `${this.baseUrl}/oee-calculation/calculate-oee`,
    rootCauseAnalysis: `${this.baseUrl}/activity-histories/root-cause-analysis`,
    targets: `${this.baseUrl}/targets`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<OeeAppState>,
  ) {}
  public getOeeData(
    body: OeeWaterfallAnalysisOeeParametersInterface,
  ): Observable<GetManyResponseInterface<IOeeCalculationResultFormatted>> {
    return this.http.post<GetManyResponseInterface<IOeeCalculationResultFormatted>>(this.routes.oee, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getRootCauseAnalysisData(
    body: OeeWaterfallAnalysisRootCauseAnalysisParametersInterface,
  ): Observable<ResponseInterface<IRootCauseAnalysisChartNode[]>> {
    return this.http.post<ResponseInterface<IRootCauseAnalysisChartNode[]>>(`${this.routes.rootCauseAnalysis}`, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getTargetData(
    queryParams: TargetQueryParametersInterface,
  ): Observable<GetManyResponseInterface<TargetDatumInterface>> {
    const filter: { [key: string]: any } = {
      $and: [
        {
          target_type: {
            $eq: 'OEE',
          },
        },
        {
          $and: [
            {
              target_period: {
                $gte: moment(queryParams.start).startOf('month').format(mysqlDateFormat),
              },
            },
            {
              target_period: {
                $lt: moment(queryParams.end).add(1, 'month').startOf('month').format(mysqlDateFormat),
              },
            },
            {
              'line.site_id': {
                in: queryParams.siteIds,
              },
            },
          ],
        },
        queryParams.lineIds
          ? {
              line_id: {
                in: queryParams.lineIds,
              },
            }
          : {},
      ],
    };
    const httpParams: HttpParams = new HttpParams()
      .append('limit', 1000)
      .append('join', 'line||siteId')
      .append('s', JSON.stringify(filter));

    return this.http.get<GetManyResponseInterface<TargetDatumInterface>>(`${this.routes.targets}`, {
      params: httpParams,
    });
  }
}
