import { Action } from '@ngrx/store';
import {
  CheckInGetOneInterface,
  CheckInsResponseInterface,
  ICheckInUpdateMany,
  ICheckOutMany,
  IBulkCheckIn,
  IOngoingCheckIn,
  ICheckOut,
} from './check-in.model';
import { CheckInOngoingUpdateInterface, CheckInSignalrDataInterface } from '../../view/home/cico/cico.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { MergedArrayDataInterface } from '../../../constants.model';

export const CHECK_IN_HOME_DATA_LOADING = '[CHECK IN] Get check-in home data';
export const CHECK_IN_HOME_DATA_LOADED = '[CHECK IN] Get check-in home data complete';
export const CHECK_IN_LOG_DATA_LOADING = '[CHECK IN] Get check-in log data';
export const CHECK_IN_LOG_DATA_LOADED = '[CHECK IN] Get check-in log data complete';

export const CHECK_IN_DATA_LOADING = '[CHECK IN] Check-in data';
export const CHECK_IN_DATA_LOADED = '[CHECK IN] Check-in complete';

export const CHECK_IN_BULK_DATA_LOADING = '[CHECK IN] Check-in bulk data';
export const CHECK_IN_BULK_DATA_LOADED = '[CHECK IN] Check-in bulk complete';

export const CHECK_OUT_DATA_LOADING = '[CHECK IN] Check-out data';
export const CHECK_OUT_DATA_LOADED = '[CHECK IN] Check-out complete';

export const CHECK_OUT_UPDATE_ONGOING_LOADING = '[CHECK IN] Check in update ongoing data';
export const CHECK_OUT_UPDATE_ONGOING_LOADED = '[CHECK IN] Check in update ongoing complete';

export const CHECK_IN_HOME_DATA_FETCH_ERROR = '[CHECK IN] Get check-in log data fetch error';
export const CHECK_IN_LOG_DATA_FETCH_ERROR = '[CHECK IN] Get check-in log data fetch error';
export const CHECK_IN_OPERATION_FETCH_ERROR = '[CHECK IN] Check-in operation fetch error';

export const CHECK_IN_BULK_EDIT_CHECK_IN_LOADING = '[CHECK IN] Bulk edit ongoing check-in data';
export const CHECK_IN_BULK_EDIT_CHECK_IN_LOADED = '[CHECK IN] Bulk edit ongoing check-in complete';
export const CHECK_IN_BULK_CHECK_OUT_LOADING = '[CHECK IN] Bulk check-out data';
export const CHECK_IN_BULK_CHECK_OUT_LOADED = '[CHECK IN] Bulk check-out complete';
export const RESET_STORE = '[CHECK IN] Reset store';

export class CheckInHomeDataLoading implements Action {
  readonly type = CHECK_IN_HOME_DATA_LOADING;

  constructor(public lineId: number, public stationId?: number, public additionalData?: CheckInSignalrDataInterface) {}
}

export class CheckInHomeDataLoaded implements Action {
  readonly type = CHECK_IN_HOME_DATA_LOADED;

  constructor(public data: CheckInsResponseInterface, public additionalData?: CheckInSignalrDataInterface) {}
}

export class CheckInLogDataLoading implements Action {
  readonly type = CHECK_IN_LOG_DATA_LOADING;

  constructor(
    public lineId: number,
    public isLast24HoursSelected: boolean = false,
    public stationId?: number,
    public additionalData?: CheckInSignalrDataInterface,
  ) {}
}

export class CheckInLogDataLoaded implements Action {
  readonly type = CHECK_IN_LOG_DATA_LOADED;

  constructor(public data: CheckInsResponseInterface, public additionalData?: CheckInSignalrDataInterface) {}
}

export class CheckInLoading implements Action {
  readonly type = CHECK_IN_DATA_LOADING;

  constructor(public data: IOngoingCheckIn) {}
}

export class CheckInLoaded implements Action {
  readonly type = CHECK_IN_DATA_LOADED;

  constructor(public payload: BaseOneResponseInterface<CheckInGetOneInterface>) {}
}

export class CheckInBulkLoading implements Action {
  readonly type = CHECK_IN_BULK_DATA_LOADING;

  constructor(public data: IBulkCheckIn, public forMobileComponent: boolean = false) {}
}

export class CheckInBulkLoaded implements Action {
  readonly type = CHECK_IN_BULK_DATA_LOADED;

  constructor(public payload: BulkResponseDataInterface, public forMobileComponent?: boolean) {}
}

export class CheckOutLoading implements Action {
  readonly type = CHECK_OUT_DATA_LOADING;

  constructor(public id: any, public data: ICheckOut) {}
}

export class CheckOutLoaded implements Action {
  readonly type = CHECK_OUT_DATA_LOADED;

  constructor(public payload: BaseOneResponseInterface<CheckInGetOneInterface>) {}
}

export class CheckInHomeFetchError implements Action {
  readonly type = CHECK_IN_HOME_DATA_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class CheckInLogFetchError implements Action {
  readonly type = CHECK_IN_LOG_DATA_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class CheckInOperationFetchError implements Action {
  readonly type = CHECK_IN_OPERATION_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class CheckOutUpdateOngoingLoading implements Action {
  readonly type = CHECK_OUT_UPDATE_ONGOING_LOADING;

  constructor(public data: CheckInOngoingUpdateInterface, public id: number) {}
}

export class CheckOutUpdateOngoingLoaded implements Action {
  readonly type = CHECK_OUT_UPDATE_ONGOING_LOADED;

  constructor(public payload: BaseOneResponseInterface<CheckInGetOneInterface>) {}
}

export class BulkEditOngoingCheckInLoading implements Action {
  readonly type = CHECK_IN_BULK_EDIT_CHECK_IN_LOADING;

  constructor(public body: ICheckInUpdateMany) {}
}

export class BulkEditOngoingCheckInLoaded implements Action {
  readonly type = CHECK_IN_BULK_EDIT_CHECK_IN_LOADED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class BulkEditOngoingCheckOutLoading implements Action {
  readonly type = CHECK_IN_BULK_CHECK_OUT_LOADING;

  constructor(public body: ICheckOutMany) {}
}

export class BulkEditOngoingCheckOutLoaded implements Action {
  readonly type = CHECK_IN_BULK_CHECK_OUT_LOADED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class ResetStore implements Action {
  readonly type = RESET_STORE;
}

export type CheckInActions =
  | CheckInHomeDataLoading
  | CheckInHomeDataLoaded
  | CheckInLogDataLoading
  | CheckInLogDataLoaded
  | CheckInHomeFetchError
  | CheckInLogFetchError
  | CheckInLoading
  | CheckInLoaded
  | CheckInBulkLoading
  | CheckInBulkLoaded
  | CheckOutLoading
  | CheckOutLoaded
  | CheckOutUpdateOngoingLoading
  | CheckOutUpdateOngoingLoaded
  | CheckInOperationFetchError
  | BulkEditOngoingCheckInLoading
  | BulkEditOngoingCheckInLoaded
  | BulkEditOngoingCheckOutLoading
  | BulkEditOngoingCheckOutLoaded
  | ResetStore;
