import {
  ContainerItemConfig,
  DragHelper,
  EventModel,
  EventStore,
  GridColumnConfig,
  ResourceModel,
  ResourceStore,
  ResourceTimeRangeModel,
  ResourceTimeRangeStore,
} from '@bryntum/schedulerpro';
import * as _ from 'lodash';
import {
  WorkOrderInterface,
  WorkOrderScheduleInterface,
} from '../../../store/work-order-schedule/work-order-schedule.model';
import { ScenarioItemInterface } from '../../../store/scheduler/scheduler.model';
import { DurationUnit } from '../../line-availability/line-availability.model';
import { UserInterface } from '../../../store/settings/users/users.model';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import { IGridTitleTranslationSettings, UnplannedActivitiesInterface } from './gantt-view.component.model';

export enum ColorDropdownProps {
  DUE_DATES = 'dueDates',
  WORK_ORDER = 'workOrder',
  WARNING_STATUS = 'warningStatus',
  DEFAULT = 'default',
  READINESS = 'readiness',
}

export enum DisplayModeDropdownProps {
  ACTUAL = 'actual',
  DEPLOYED = 'deployed',
  DEFAULT = 'default',
  PLANNED = 'planned',
}

export interface EventDragValidatorInterface<T> {
  eventRecords: T[];
  context: any;
}

export interface SchedulerHomeGanttEventRecord extends EventModel {
  data: any;
}

export interface ContextValidControlInterface {
  isLineValid: boolean;
  isSiteValid: boolean;
  valid: boolean;
  isResourceAndDateValid: boolean;
  isScenarioSelected?: boolean;
  isHorizonValid?: boolean;
}

export interface EventTooltipDataInterface {
  eventRecord: GanttScenarioItem;
  tip: {
    tools: {
      [key: number]: {
        hidden: boolean;
      };
    };
  };
}

export interface ColorAssignmentsForIds {
  [id: number]: string;
}

export type ColorAssignmentInterface = {
  [key in TaskType]?: ColorAssignmentsForIds;
};

export enum TaskType {
  unplannedWorkOrder = 'unplannedWo',
  downTimeActivity = 'downTimeActivity',
  user = 'user',
  line = 'line',
}

export class BaseEventModel extends EventModel {
  public type?: TaskType;
  public workOrderSchedule?: WorkOrderScheduleInterface;

  static override get defaults() {
    return {
      durationUnit: DurationUnit.HOUR,
      draggable: true,
      isDraggable: true,
    };
  }
}

export class GanttScenarioItem extends BaseEventModel {
  activityId: number;
  warning?: boolean;

  constructor(data: object) {
    let name = _.get(data, 'workOrderSchedule.woNumber', null);
    if (name === null) {
      name = _.get(data, 'activity.name', null);
      _.set(data, 'type', TaskType.downTimeActivity);
      _.set(data, 'activityId', _.get(data, 'activity.activityId', null));
    } else {
      _.set(data, 'type', TaskType.unplannedWorkOrder);
      _.set(data, 'siteId', _.get(data, 'workOrderSchedule.siteId', null));
      _.set(data, 'workOrderScheduleId', _.get(data, 'workOrderSchedule.id', null));
    }
    _.set(data, 'name', name);
    super(data);
  }

  static override get fields() {
    return [
      { name: 'resourceId', dataSource: 'lineId' },
      { name: 'siteId', dataSource: 'siteId' },
      { name: 'type' },
      { name: 'workOrderSchedule' },
      { name: 'activity' },
      { name: 'activityId' },
      { name: 'warning' },
      { name: 'workOrderScheduleId' },
      { name: 'scenarioId' },
      { name: 'lineId' },
    ];
  }

  static get $$name() {
    return 'GanttScenarioItem';
  }
}

export class GanttScenarioItemStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: GanttScenarioItem,
    };
  }
}

export class LaborSchedulerItem extends BaseEventModel {
  constructor(data: object) {
    _.set(data, 'name', _.get(data, 'workOrderSchedule.woNumber', null));
    _.set(data, 'type', _.get(data, 'type', null));
    _.set(data, 'id', _.get(data, 'id', null));
    _.set(data, 'scenarioId', _.get(data, 'scenarioId', null));
    super(data);
  }

  static override get fields() {
    return [{ name: 'id' }, { name: 'name' }, { name: 'type' }, { name: 'scenarioId' }];
  }

  static get $$name() {
    return 'LaborSchedulerItem';
  }
}

export class LaborSchedulerItemStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: LaborSchedulerItem,
    };
  }
}

export class UnplannedWoEvent extends BaseEventModel {
  static get $$name() {
    return 'UnplannedWoEvent';
  }

  constructor(data: WorkOrderInterface) {
    _.set(data, 'type', TaskType.unplannedWorkOrder);
    _.set(data, 'name', data.woNumber);

    if (!_.has(data, 'duration')) {
      _.set(data, 'duration', Math.round((data.averageDuration / 60) * 100) / 100);
    }

    super(data);
  }

  static override get defaults() {
    return {
      reapplyFilterOnAdd: true,
      reapplyFilterOnUpdate: true,
      durationUnit: DurationUnit.HOUR,
      draggable: true,
      isDraggable: true,
    };
  }

  static override get fields() {
    return [
      { name: 'name', dataSource: 'name' },
      { name: 'woNumber', dataSource: 'woNumber' },
      { name: 'siteId', dataSource: 'siteId' },
      { name: 'product', dataSource: 'product' },
      { name: 'productId', dataSource: 'productId' },
      { name: 'woDueDate', dataSource: 'woDueDate' },
      { name: 'type' },
    ];
  }
}

export class UnplannedWoEventStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: UnplannedWoEvent,
    };
  }
}

export class UnplannedActivityEvent extends BaseEventModel {
  constructor(data: UnplannedActivitiesInterface) {
    _.set(data, 'type', TaskType.downTimeActivity);

    if (!_.has(data, 'duration')) {
      _.set(data, 'duration', 8);
    }

    super(data);
  }

  static override get fields() {
    return [
      { name: 'name', dataSource: 'name' },
      { name: 'activityType', dataSource: 'activityType' },
      { name: 'activitySubtype', dataSource: 'activitySubtype' },
      { name: 'activityPlannedType', dataSource: 'activityPlannedType' },
      { name: 'countdownTimer', dataSource: 'countdownTimer' },
      { name: 'active', dataSource: 'active' },
      { name: 'activityId', dataSource: 'id' },
      { name: 'type' },
    ];
  }

  static get $$name() {
    return 'UnplannedActivityEvent';
  }
}

export class UnplannedActivityEventStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: UnplannedActivityEvent,
    };
  }
}

export class LineResourceEvent extends ResourceModel {
  public siteId: number;

  static get $$name() {
    return 'LineResourceEvent';
  }

  static override get fields() {
    return [
      { name: 'id', dataSource: 'id' },
      { name: 'lineType', dataSource: 'lineType' },
      { name: 'lineTypeId', dataSource: 'lineTypeId' },
      { name: 'name', dataSource: 'name' },
      { name: 'activityIds', dataSource: 'activityIds' },
      { name: 'siteId', dataSource: 'siteId' },
      { name: 'schedulerShiftPlanId', dataSource: 'schedulerShiftPlanId' },
    ];
  }
}

export class LineResourceEventStore extends ResourceStore {
  static get defaultConfig() {
    return {
      modelClass: LineResourceEvent,
    };
  }
}

export class UserResourceEvent extends ResourceModel {
  static get $$name() {
    return 'UserResourceEvent';
  }

  static override get fields() {
    return [
      { name: 'id', dataSource: 'id' },
      { name: 'name', dataSource: 'name' },
    ];
  }
}

export class UserImageResourceEvent extends UserResourceEvent {
  public avatar?: string;
  public className?: string;
  public avatarPath?: string;
}

export class UserResourceEventStore extends ResourceStore {
  static get defaultConfig() {
    return {
      modelClass: UserResourceEvent,
    };
  }
}

export class LineResourceTimeRange extends ResourceTimeRangeModel {
  static get $$name() {
    return 'LineResourceTimeRange';
  }

  static override get fields() {
    return [
      { name: 'id', dataSource: 'id' },
      { name: 'resourceId', dataSource: 'resourceId' },
      { name: 'startDate', dataSource: 'startDate' },
      { name: 'endDate', dataSource: 'endDate' },
      { name: 'cls', dataSource: 'cls' },
    ];
  }
}

export class LineResourceTimeRangeStore extends ResourceTimeRangeStore {
  static get defaultConfig() {
    return {
      modelClass: LineResourceTimeRange,
    };
  }
}

export class UsersEvent extends BaseEventModel {
  constructor(data: UserInterface) {
    _.set(data, 'type', TaskType.user);

    if (!_.has(data, 'duration')) {
      _.set(data, 'duration', 8);
    }

    super(data);
  }

  static override get fields() {
    return [
      { name: 'id', dataSource: 'id' },
      { name: 'name', dataSource: 'name' },
    ];
  }

  static get $$name() {
    return 'UsersEvent';
  }
}

export class UsersEventStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: UsersEvent,
    };
  }
}

export class LinesEvent extends BaseEventModel {
  constructor(data: LineCRUDInterface) {
    _.set(data, 'type', TaskType.line);

    if (!_.has(data, 'duration')) {
      _.set(data, 'duration', 8);
    }

    super(data);
  }

  static override get fields() {
    return [
      { name: 'id', dataSource: 'id' },
      { name: 'name', dataSource: 'name' },
    ];
  }

  static get $$name() {
    return 'LinesEvent';
  }
}

export class LinesEventStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: LinesEvent,
    };
  }
}

export interface EventStoreUpdateInterface {
  source: EventStore;
  record: GanttScenarioItem;
  changes: any;
  type: string;
}

export interface GanttTreeResourceRowInterface {
  id: string;
  name: string;
  lineType: string;
  lineTypeId: number;
  siteId: number;
  schedulerShiftPlanId?: number;
  activityIds?: string;
  expanded: boolean;
  children?: GanttTreeResourceRowInterface[];
}

export interface GanttTreeResourceLineAsObjectInterface {
  id: string;
  name: string;
  expanded: boolean;
  lines?: {
    [lineId: string]: GanttTreeResourceRowInterface;
  };
  lineType: string;
  lineTypeId: number;
  siteId: number;
  activityIds?: string;
  cls?: string;
  isPasteEvent?: boolean;
}

export interface GanttTreeResourceDepartmentAsObjectInterface {
  [departmentId: string]: GanttTreeResourceLineAsObjectInterface;
}

export interface GanttTreeEventInterface extends ScenarioItemInterface {
  name: string;
  resourceId: string;
}

export enum GanttFilterResourceStoreActionEnum {
  COLLAPSE = 'collapse',
  EXPAND = 'expand',
}

export interface GanttEventTooltipFeatureInterface {
  hoverDelay: number;
  hideDelay: number;
  template: (data: EventTooltipDataInterface) => string;
}

export interface IGanttDependencyItem {
  id: string | number;
  sequenceNumber: number;
  jobId: number;
}

export interface EventRendererDataInterface {
  eventRecord: GanttScenarioItem;
  renderData: any;
}

export class Drag extends DragHelper {
  static get defaultConfig() {
    return {
      mode: 'translateXY',
      callOnFunctions: true,
      autoSizeClonedTarget: false,
      unifiedProxy: true,
      removeProxyAfterDrop: false,
      cloneTarget: true,
      dropTargetSelector: '.b-timeline-subgrid',
      targetSelector: '.b-grid-row:not(.b-group-row)',
    };
  }

  override createProxy(grabbedElement): HTMLElement {
    const { context, schedule, grid }: any = this,
      draggedAppointment = grid.getRecordFromElement(grabbedElement),
      durationInPixels = schedule.timeAxisViewModel.getDistanceForDuration(draggedAppointment?.durationMS),
      proxy: HTMLDivElement = document.createElement('div');
    proxy.style.cssText = '';

    proxy.style.width = `${durationInPixels}px`;
    proxy.style.height = `${schedule.rowHeight - 2 * schedule.resourceMargin}px`;

    proxy.classList.add('b-sch-event-wrap', 'b-sch-style-border', 'b-unassigned-class', 'b-sch-horizontal');
    proxy.innerHTML = draggedAppointment?.name;
    context.task = draggedAppointment?.data;

    return proxy;
  }
}

export class GridWidget {
  constructor(config: Partial<ContainerItemConfig>, gridTitleTranslationSettings?: IGridTitleTranslationSettings) {
    return GridWidget.initialConfig(config, gridTitleTranslationSettings);
  }

  static initialConfig(
    config: Partial<ContainerItemConfig>,
    gridTitleTranslationSettings?: IGridTitleTranslationSettings,
  ): Partial<ContainerItemConfig> {
    const defaultGridColumnConfig: Partial<GridColumnConfig> = {
      flex: 1,
      filterable: {
        filterField: {
          placeholder: gridTitleTranslationSettings?.searchTitle,
        },
      },
    };

    return {
      selectionMode: {
        cell: false,
      },
      // @ts-ignore
      type: 'grid',
      minHeight: '50%',
      appendTo: 'main',
      collapsible: true,
      features: {
        filterBar: {
          compactMode: false,
        },
        search: true,
        stripe: true,
        sort: 'name',
        cellEdit: false,
        cellMenu: false,
        headerMenu: false,
        rowCopyPaste: false,
      },
      columns: [
        {
          ...defaultGridColumnConfig,
          field: 'name',
          text: gridTitleTranslationSettings?.nameTitle,
          sortable(lhs, rhs) {
            return lhs.name.localeCompare(rhs.name);
          },
        },
        {
          ...defaultGridColumnConfig,
          field: 'productName',
          text: gridTitleTranslationSettings?.detailTitle,
          sortable(lhs, rhs) {
            return lhs.productName.localeCompare(rhs.productName);
          },
        },
        {
          text: gridTitleTranslationSettings?.durationTitle,
          flex: 1,
          align: 'center',
          field: 'fullDuration',
          renderer: ({ record }) => `${record.duration} h`,
          filterable: false,
        },
      ],
      rowHeight: 35,
      disableGridRowModelWarning: true,
      ...config,
    };
  }
}
