import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IEquipmentTaskResponse, IGetTaskQuery, ITaskEquipmentDetail } from './equipment-task.model';
import { Observable } from 'rxjs';
import { STATIC_MAX_LIMIT_OF_CRUD } from '../../../constants';
import { ResponseArrayInterface } from '../../shared/model/interface/generic-api-response.model';
import { ITreeNode } from '../settings/tree-nodes/tree-nodes.model';
import * as _ from 'lodash';
import {
  ENodeType,
  ETaskListMode,
  INodeGroup,
  ITaskTreeNode,
} from '../../shared/component/activity-buttons/task-selection/task-selection.model';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { User } from '../user/model';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../store/oee.reducer';

@Injectable({
  providedIn: 'root',
})
export class EquipmentTaskService {
  private readonly EQUIPMENT_TASK = {
    GET: {
      DATA_URL: `${this.baseUrl}/tasks`,
    },
  };

  constructor(
    private readonly translate: TranslateService,
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  private userLanguage$: string = '';

  public getEquipmentsAndTasks(
    lineId: number,
    activityId: number,
    statusId: number,
    searchText: string,
    taskTreeParentId?: number,
    ignoreHidden?: boolean,
  ): Observable<IEquipmentTaskResponse> {
    let params: HttpParams = new HttpParams();
    const conditions: {
      $and: object[];
    } = {
      $and: [],
    };

    const searchObject: IGetTaskQuery = {
      statusId: { $eq: statusId },
      activityId: { $eq: activityId },
      lineId: { $eq: lineId },
      ...(ignoreHidden ? { hideOnHomePage: { $eq: false } } : {}),
    };

    if (searchText === null) {
      searchObject.taskTreeParentId = taskTreeParentId ? { $eq: taskTreeParentId } : { $isnull: true };
    } else {
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          this.userLanguage$ = state.language;
        });
      const orFilter: object[] = [];
      orFilter.push({ title: { $cont: searchText } });
      orFilter.push({ 'equipment.equipmentName': { $cont: searchText } });
      conditions.$and.push({ $or: orFilter });
    }

    conditions.$and.push(searchObject);

    if (activityId !== null) {
      params = params
        .append('fields', 'id,title,titleTranslations,equipmentId,order,isCommentRequired,phaseId,meta,hideOnHomePage')
        .append('join', 'equipment||equipmentName')
        .append('join', 'equipment.equipmentAssignment')
        .append('s', JSON.stringify(conditions))
        .append('limit', String(STATIC_MAX_LIMIT_OF_CRUD));
    }

    return this.http.get<IEquipmentTaskResponse>(this.EQUIPMENT_TASK.GET.DATA_URL, { params });
  }

  public prepareNodeData(response: (IEquipmentTaskResponse | ResponseArrayInterface<ITreeNode>)[]): ITaskTreeNode[] {
    const tasks: ITaskEquipmentDetail[] = _.get(response, '0.data', []);
    const folders: ITreeNode[] = _.get(response, '1.data', []);
    const nodes: ITaskTreeNode[] = [];
    let equipments: ITaskTreeNode[] = [];

    for (const folder of folders) {
      nodes.push({
        id: folder.id,
        type: ENodeType.Folder,
        title: folder.name,
        icon: 'fas fa-folder',
        order: 0,
        isVirtualGroup: false,
        meta: folder.meta ?? null,
        isActive: folder.isActive,
      });
    }

    for (const task of tasks) {
      nodes.push({
        id: task.id,
        type: ENodeType.Task,
        title: task.title,
        subTitle: task?.equipment?.equipmentName,
        subIcon: 'fas fa-cube',
        order: task.order,
        icon: null,
        isVirtualGroup: false,
        hideOnHomePage: !!task.hideOnHomePage,
        taskInfo: {
          equipmentName: task?.equipment?.equipmentName,
          equipmentId: task?.equipment?.id,
          equipmentOrder: task?.equipment?.equipmentOrder,
          taskId: task.id,
          taskName: task.title,
          taskNameTranslations: task.titleTranslations,
          isCommentRequired: task.isCommentRequired,
          phaseId: task.phaseId,
          meta: task.meta,
        },
      });

      if (task.equipment) {
        equipments.push({
          id: task.equipment.id,
          type: ENodeType.Equipment,
          title: task.equipment.equipmentName,
          order: task.equipment.equipmentOrder,
          icon: 'fas fa-cube',
          isVirtualGroup: true,
        });
      }
    }

    equipments = _.uniqBy(equipments, 'id');

    return _.orderBy(nodes.concat(equipments), ['order'], ['asc']);
  }

  public static getNodeGroups(
    nodes: ITaskTreeNode[],
    listMode: ETaskListMode = ETaskListMode.EQUIPMENT_BASED,
    selectedNode?: ITaskTreeNode,
  ): INodeGroup[] {
    const groups: INodeGroup[] = [];
    const cloneNodes: ITaskTreeNode[] = _.cloneDeep(nodes);
    const folders: ITaskTreeNode[] = cloneNodes.filter((node: ITaskTreeNode) => node.type === ENodeType.Folder);
    const equipments: ITaskTreeNode[] = nodes.filter((node: ITaskTreeNode) => node.type === ENodeType.Equipment);
    const tasks: ITaskTreeNode[] = cloneNodes.filter((node: ITaskTreeNode) => node.type === ENodeType.Task);
    let basedGroups: INodeGroup[] = [];

    if (selectedNode) {
      const virtualGroup: INodeGroup | undefined = EquipmentTaskService.getVirtualNodeGroup(cloneNodes, selectedNode);
      return [virtualGroup];
    }

    if (folders.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.folders',
        nodes: folders,
      });
    }

    switch (listMode) {
      case ETaskListMode.EQUIPMENT_BASED:
        basedGroups = EquipmentTaskService.getEquipmentBasedGroups(tasks, equipments);
        break;

      case ETaskListMode.TASK_BASED:
        basedGroups = EquipmentTaskService.getTaskBasedGroups(tasks);
        break;
    }

    return groups.concat(basedGroups);
  }

  private static getEquipmentBasedGroups(tasks: ITaskTreeNode[], equipments: ITaskTreeNode[]): INodeGroup[] {
    const groups: INodeGroup[] = [];
    const tasksWithoutEquipment = tasks.filter((node: ITaskTreeNode) => !node?.taskInfo?.equipmentId);

    if (equipments.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.equipments',
        nodes: EquipmentTaskService.setVirtualGroupFlag(_.uniqBy(equipments, 'title')),
      });
    }

    if (tasksWithoutEquipment.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.tasks',
        nodes: tasksWithoutEquipment,
      });
    }

    return groups;
  }

  private static setVirtualGroupFlag(nodes: ITaskTreeNode[]): ITaskTreeNode[] {
    return nodes.map((item: ITaskTreeNode) => {
      return {
        ...item,
        icon: 'fas fa-layer-group',
        isVirtualGroup: true,
      };
    });
  }

  private static getTaskBasedGroups(tasks: ITaskTreeNode[]): INodeGroup[] {
    const groups: INodeGroup[] = [];
    const taskGroups = _.groupBy(tasks, 'title');
    const virtualGroups: ITaskTreeNode[] = [];
    let others: ITaskTreeNode[] = [];

    for (const group of Object.values(taskGroups)) {
      const taskGroup: ITaskTreeNode[] = group as ITaskTreeNode[];

      if (taskGroup.length > 1) {
        const item: ITaskTreeNode = _.head(taskGroup);
        item.isVirtualGroup = true;
        virtualGroups.push(item);
        continue;
      }

      others = others.concat(taskGroup);
    }

    if (virtualGroups.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.taskGroups',
        nodes: EquipmentTaskService.setVirtualGroupFlag(virtualGroups),
      });
    }

    if (others.length > 0) {
      groups.push({
        name: 'main.equipmentTaskSelection.tasks',
        nodes: others,
      });
    }

    return groups;
  }

  private static getVirtualNodeGroup(nodes: ITaskTreeNode[], selectedNode: ITaskTreeNode): INodeGroup | undefined {
    if (selectedNode.type === ENodeType.Equipment && nodes.length > 0) {
      return {
        nodes,
        name: 'main.equipmentTaskSelection.tasks',
      };
    }

    if (selectedNode.type === ENodeType.Task) {
      const selectedTasks = nodes.filter(
        (node: ITaskTreeNode) => node.type === ENodeType.Task && node.title === selectedNode.title,
      );

      if (selectedTasks.length > 0) {
        return {
          name: 'main.equipmentTaskSelection.tasks',
          nodes: selectedTasks,
        };
      }
    }

    return undefined;
  }
}
