import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { DropdownOptionInterface } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatFormInputInterface } from '../../../shared/component/scw-mat-ui/scw-mat-form/scw-mat-form.model';
import { IRulesOfForm } from '../../../shared/model/interface/generic.model';
import { IColorPaletteOption } from '../../../shared/service/color/color.model';

export enum TagsObjectTypes {
  User = 1,
  Equipment = 2,
  Comment = 3,
}

export interface TagsStateInterface {
  tagsData: GetManyResponseInterface<TagInterface>;
  tagsLoading: boolean;
  tagsLoaded: boolean;
  loadFailed: boolean;
}

export interface TagInterface extends TagCRUDRequestInterface {
  id: number;
  site?: { id: number; name: string };
  tagType: string;
}

export enum TagType {
  PhaseTargetExceeded = 'phase_target_exceeded',
}

export interface TagCRUDRequestInterface {
  id?: number;
  siteId: number;
  objectType: number;
  name: string;
  color: string;
}

export interface TagInputFormParameters {
  form: TagFormInterface;
  rules: IRulesOfForm<TagFormInterface>;
  isBulk: boolean;
}

export interface TagFormInterface {
  id?: ScwMatFormInputInterface<number>;
  siteId: ScwMatFormInputInterface<DropdownOptionInterface[]>;
  objectType: ScwMatFormInputInterface<DropdownOptionInterface[]>;
  name: ScwMatFormInputInterface<string>;
  color: ScwMatFormInputInterface<IColorPaletteOption[]>;
}

export interface TagFormDropdownData {
  sites: GetManyResponseInterface<DropdownOptionInterface>;
}

export interface ColorDropdownOptionInterface {
  id: number;
  backgroundColor: string;
}
