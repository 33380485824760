import { Inject, Injectable } from '@angular/core';
import { DatatableInterface } from '../../../../shared/service/datatable/datatable.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { FloorPlanInterface, LineInterface } from './floor-plans.model';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { ImageHelperService } from '../../../../shared/helper/image-helper.service';

@Injectable({
  providedIn: 'root',
})
export class FloorPlansService {
  private readonly routes = {
    floorPlans: `${this.api}/floor-plans`,
    bulkDelete: `${this.api}/floor-plans/bulk/delete`,
    image: `${this.api}/floor-plans/image`,
    lines: `${this.api}/lines`,
  };

  public tableQuery: DatatableInterface = {
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    orderDesc: true,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private api: string,
    private readonly imageHelperService: ImageHelperService,
  ) {}

  public getFloorPlans(params: HttpParams): Observable<ResponseArrayInterface<FloorPlanInterface>> {
    return this.http.get<ResponseArrayInterface<FloorPlanInterface>>(this.routes.floorPlans, { params });
  }

  public createFloorPlan(floorPlan: FloorPlanInterface): Observable<BaseOneResponseInterface<FloorPlanInterface>> {
    return this.http.post<BaseOneResponseInterface<FloorPlanInterface>>(this.routes.floorPlans, floorPlan);
  }

  public updateFloorPlan(floorPlanId:number, floorPlan: FloorPlanInterface): Observable<BaseOneResponseInterface<FloorPlanInterface>> {

    return this.http.patch<BaseOneResponseInterface<FloorPlanInterface>>(`${this.routes.floorPlans}/${floorPlanId}`, floorPlan);
  }

  public deleteFloorPlan(floorPlanId: number): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.floorPlans}/${floorPlanId}`);
  }

  public bulkDeleteFloorPlans(floorPlanIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(this.routes.bulkDelete, {
      body: { floorPlanIds },
    });
  }

  public getLines(siteId: number): Observable<GetManyResponseInterface<LineInterface>> {
    const httpParams: HttpParams = new HttpParams()
      .set('s', JSON.stringify({ siteId: { $eq: siteId } }))
      .append('fields', 'id,title,lineType')
      .append('limit', '1000');

    return this.http.get<GetManyResponseInterface<LineInterface>>(this.routes.lines, { params: httpParams });
  }

  public getFloorPlanImage(imagePath: string): Observable<BaseOneResponseInterface<string>> {
    return this.http.get<BaseOneResponseInterface<string>>(`${this.routes.image}/${imagePath}`);
  }

  public uploadFloorPlanImage(id: number, base64ImageContent: string): Observable<BaseCrudResponse> {
    return this.http.patch<BaseCrudResponse>(
      `${this.routes.image}/${id}`,
      this.imageHelperService.getImageUploadFormData(base64ImageContent),
    );
  }
}
