import { Moment } from 'moment';
import { InitialSelectedRangeTypes } from '../scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';

export interface DateRangeInterface {
  alwaysShowCalendars?: boolean;
  wide?: boolean;
  autoApply?: boolean;
  className?: string;
  closeOnAutoApply?: boolean;
  customRangeDirection?: boolean;
  customStyle?: CSSStyleDeclaration;
  disabled?: boolean;
  drops: DateRangeDropsTypes;
  emptyWeekRowClass?: string;
  firstDayOfNextMonthClass?: string;
  firstMonthDayClass?: string;
  id?: string;
  inputModel?: DateRangeReturnInterface;
  isCustomDate?: Function;
  isInvalidDate?: Function;
  keepCalendarOpeningWithRange?: boolean;
  lastDayOfPreviousMonthClass?: string;
  lastMonthDayClass?: string;
  linkedCalendars?: boolean;
  locale?: DateRangeLocaleInterface;
  lockStartDate?: boolean;
  maxDate?: Moment;
  minDate?: Moment;
  opens: DateRangeOpensTypes;
  placeholder?: string;
  ranges?: object;
  readonly?: boolean;
  showCancel?: boolean;
  showClearButton?: boolean;
  showCustomRangeLabel?: boolean;
  showISOWeekNumbers?: boolean;
  showRangeLabelOnInput?: boolean;
  showWeekNumbers?: boolean;
  singleDatePicker?: boolean;
  timePicker24Hour?: boolean;
  timePicker?: boolean;
  timePickerIncrement?: number;
  timePickerSeconds?: boolean;
  dateLimit?: number;
  isCustomWeekStart?: boolean;
  weekStartDay?: number;
  initialSelectedDate?: InitialSelectedRangeTypes;
}

export interface DateRangeLocaleInterface {
  applyLabel?: string;
  cancelLabel?: string;
  clearLabel?: string;
  customRangeLabel?: string;
  daysOfWeek?: string[];
  direction?: string;
  firstDay?: number;
  format?: string;
  monthNames?: string[];
  separator?: string;
  weekLabel?: string;
}

export interface DateRangeRangesDefaultInterface {
  today: [Moment, Moment];
  yesterday: [Moment, Moment];
  thisWeek: [Moment, Moment];
  lastWeek: [Moment, Moment];
  thisMonth: [Moment, Moment];
  lastMonth: [Moment, Moment];
  thisYear?: [Moment, Moment];
  lastYear?: [Moment, Moment];
}

export interface DateRangeReturnInterface {
  endDate: Moment;
  startDate: Moment;
}

export enum DateRangeOpensTypes {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum DateRangeDropsTypes {
  up = 'up',
  down = 'down',
}
