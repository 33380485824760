import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ResponseInterface } from '../../../shared/model/interface/generic-api-response.model';
import * as oeeAppReducer from '../../../store/oee.reducer';
import * as AppActions from '../../app/actions';
import * as AccountActions from './account.actions';
import { AccountSettingsInterface, LanguageInterface, SitesInterface } from './account.model';
import { AccountService } from './account.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class AccountEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: AccountService,
  ) {}


  getAccountSettings = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.GET_ACCOUNT_SETTINGS),
    switchMap(() => {
      this.store.dispatch(new AppActions.ShowLoader());

      return this.service.getAccountSettings().pipe(
        switchMap((response: ResponseInterface<AccountSettingsInterface>) => {
          return of(new AccountActions.GetAccountSettingsCompleted(response), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new AccountActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new AccountActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  getLanguages = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.GET_LANGUAGES),
    switchMap(() => {
      this.store.dispatch(new AppActions.ShowLoader());

      return this.service.getLanguages().pipe(
        switchMap((response: ResponseInterface<LanguageInterface[]>) => {
          return of(new AccountActions.GetLanguagesCompleted(response), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new AccountActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new AccountActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  getSites = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.GET_SITES),
    switchMap(() => {
      this.store.dispatch(new AppActions.ShowLoader());

      const params: HttpParams = new HttpParams().set('limit', 1000);

      return this.service.getSites(params).pipe(
        switchMap((response: ResponseInterface<SitesInterface[]>) => {
          return of(new AccountActions.GetSitesCompleted(response), new AppActions.HideLoader());
        }),
        catchError((error) => {
          return of(new AccountActions.FetchError(error), new AppActions.HideLoader());
        }),
      );
    }),
  ));


  editAccount = createEffect(() => this.actions$.pipe(
    ofType(AccountActions.EDIT_ACCOUNT_SETTINGS),
    switchMap((objectData: AccountActions.EditAccountSettings) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return this.service.editAccountSettings(objectData.payload).pipe(
        switchMap((response: ResponseInterface<AccountSettingsInterface>) => {
          const actions = [
            new AccountActions.EditAccountSettingsCompleted(response),
            new AppActions.GetCurrentUser(),
            ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
          ];

          return of(...actions);
        }),
        catchError((error) => {
          return of(new AccountActions.FetchError(error), new AppActions.HideLoader());
        }),
      );
    }),
  ));
}
