import { OeeAppState } from '../../../store/oee.reducer';

export interface IFilterMap {
  storeName: keyof OeeAppState;
  expectedProperty: string;
  filteredDataIds: (string | number)[] | number;
  filterPrefix: string;
  filterName: string;
  reference?: IReferenceChart;
  values?: string | string[];
}

export interface IFilterMapped {
  id: number;
  value: string;
}

export interface IFilter {
  dateRange: IDate;
}

export interface IDate {
  startDate: string;
  endDate: string;
}

export interface IChart extends IAbilities {
  id: ChartType;
  reference?: IReferenceChart;
}

export interface IReferenceChart {
  ids: ChartType[];
}

export interface IAbilities {
  page?: {
    orientation: Orientation;
    order: PageOrder;
    padding?: IPadding;
    hasFooter?: boolean;
    hasHeader?: boolean;
  };
  size?: ISize;
  borderElementSelector?: string;
  forceContain?: boolean;
}

export interface ISize {
  width?: number;
  height?: number;
}

export interface IPadding {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}

export interface IBanner {
  header?: IHeader;
  footer?: IFooter;
}

export interface IHeader extends IUri {
  title: string;
}

export interface IFooter extends IUri {}

export interface IUri {
  uri: string;
}
export interface IFile {
  type: FileTypes;
  chartData: IChart[];
}

export enum FileTypes {
  PDF = 'pdf',
  TEXT = 'text',
  IMG = 'img',
}

export enum Orientation {
  portrait = 'p',
  landscape = 'l',
}

export enum PageOrder {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
  Sixth = 6,
  Seventh = 7,
  Eighth = 8,
  Ninth = 9,
  Tenth = 10,
}

export enum ChartType {
  Activities = 1,
  Equipment = 2,
  Tasks = 3,
  Trend = 4,
  Doughnut = 5,
  Oee_Summary = 6,
  Oee_APQ = 7,
  Oee_Hours = 8,
  Widgets = 9,
  Oee_Trend = 10,
  Good_Count_Trend = 11,
  Work_Order_Count_Trend = 12,
  Labor_Hours_Trend = 13,
  Daily_Performance_Summary = 14,
  OEE_Heatmap = 15,
  Actual_vs_Planned_Production_Comparison = 16,
}

export enum EElementSelector {
  CANVAS = 'canvas',
  IS_PRINTABLE = '.is-printable',
}
