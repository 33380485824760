import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseInterface } from '../../../shared/model/interface/generic-api-response.model';
import { AccountSettingsInterface, CurrentUserInterface, LanguageInterface, SitesInterface } from './account.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly ACCOUNT = {
    ACCOUNT_URL: `${this.baseUrl}/users/account-settings`,
    LANGUAGE_URL: `${this.baseUrl}/languages`,
    SITES_URL: `${this.baseUrl}/sites`,
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getAccountSettings(): Observable<ResponseInterface<AccountSettingsInterface>> {
    return this.http.get<ResponseInterface<AccountSettingsInterface>>(this.ACCOUNT.ACCOUNT_URL);
  }

  public editAccountSettings(
    params: AccountSettingsInterface,
  ): Observable<ResponseInterface<AccountSettingsInterface>> {
    return this.http.patch<ResponseInterface<AccountSettingsInterface>>(this.ACCOUNT.ACCOUNT_URL, params);
  }

  public getLanguages(params?: HttpParams): Observable<ResponseInterface<LanguageInterface[]>> {
    return this.http.get<ResponseInterface<LanguageInterface[]>>(`${this.ACCOUNT.LANGUAGE_URL}`, { params });
  }

  public getSites(params: HttpParams): Observable<ResponseInterface<SitesInterface[]>> {
    return this.http.get<ResponseInterface<SitesInterface[]>>(`${this.ACCOUNT.SITES_URL}`, { params });
  }
}
