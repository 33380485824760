import * as Actions from './floor-plans.actions';
import { FloorPlanStateInterface } from './floor-plans.model';

export const floorPlansInitialState: FloorPlanStateInterface = {
  isFloorPlanDataLoading: false,
  isFloorPlanDataLoaded: false,
  isSiteDataLoading: false,
  isSiteDataLoaded: false,
  isLineDataLoading: false,
  isLineDataLoaded: false,
  isCreateFloorPlanStarted: false,
  isCreateFloorPlanCompleted: false,
  isUpdateFloorPlanStarted: false,
  isUpdateFloorPlanCompleted: false,
  isDeleteFloorPlanStarted: false,
  isDeleteFloorPlanCompleted: false,
  isBulkDeleteFloorPlansStarted: false,
  isBulkDeleteFloorPlansCompleted: false,
  siteData: [],
  lineData: [],
  floorPlanData: [],
};

export function floorPlansReducer(
  state: FloorPlanStateInterface = floorPlansInitialState,
  action: Actions.FloorPlanActions,
): FloorPlanStateInterface {
  switch (action.type) {
    case Actions.FLOOR_PLANS_FLOOR_PLAN_DATA_LOADING:
      return {
        ...state,
        ...{
          isFloorPlanDataLoading: true,
          isFloorPlanDataLoaded: false,
        },
      };
    case Actions.FLOOR_PLANS_FLOOR_PLAN_DATA_LOADED:
      return {
        ...state,
        ...{
          isFloorPlanDataLoading: false,
          isFloorPlanDataLoaded: true,
          floorPlanData: action.response.data,
        },
      };
    case Actions.FLOOR_PLANS_SITE_DATA_LOADING:
      return {
        ...state,
        ...{
          isSiteDataLoading: true,
          isSiteDataLoaded: false,
        },
      };
    case Actions.FLOOR_PLANS_SITE_DATA_LOADED:
      return {
        ...state,
        ...{
          isSiteDataLoading: false,
          isSiteDataLoaded: true,
          siteData: action.response.data,
        },
      };
    case Actions.FLOOR_PLANS_LINE_DATA_LOADING:
      return {
        ...state,
        ...{
          isLineDataLoading: true,
          isLineDataLoaded: false,
        },
      };
    case Actions.FLOOR_PLANS_LINE_DATA_LOADED:
      return {
        ...state,
        ...{
          isLineDataLoading: false,
          isLineDataLoaded: true,
          lineData: action.response.data,
        },
      };
    case Actions.FLOOR_PLANS_CREATE_FLOOR_PLAN_STARTED:
      return {
        ...state,
        ...{
          isCreateFloorPlanStarted: true,
          isCreateFloorPlanCompleted: false,
        },
      };
    case Actions.FLOOR_PLANS_CREATE_FLOOR_PLAN_COMPLETED:
      return {
        ...state,
        ...{
          isCreateFloorPlanStarted: false,
          isCreateFloorPlanCompleted: true,
        },
      };
    case Actions.FLOOR_PLANS_UPDATE_FLOOR_PLAN_STARTED:
      return {
        ...state,
        ...{
          isUpdateFloorPlanStarted: true,
          isUpdateFloorPlanCompleted: false,
        },
      };
    case Actions.FLOOR_PLANS_UPDATE_FLOOR_PLAN_COMPLETED:
      return {
        ...state,
        ...{
          isUpdateFloorPlanStarted: false,
          isUpdateFloorPlanCompleted: true,
        },
      };
    case Actions.FLOOR_PLANS_DELETE_FLOOR_PLAN_STARTED:
      return {
        ...state,
        ...{
          isDeleteFloorPlanStarted: true,
          isDeleteFloorPlanCompleted: false,
        },
      };
    case Actions.FLOOR_PLANS_DELETE_FLOOR_PLAN_COMPLETED:
      return {
        ...state,
        ...{
          isDeleteFloorPlanStarted: false,
          isDeleteFloorPlanCompleted: true,
        },
      };
    case Actions.FLOOR_PLANS_BULK_DELETE_FLOOR_PLANS_STARTED:
      return {
        ...state,
        ...{
          isBulkDeleteFloorPlansStarted: true,
          isBulkDeleteFloorPlansCompleted: false,
        },
      };
    case Actions.FLOOR_PLANS_BULK_DELETE_FLOOR_PLANS_COMPLETED:
      return {
        ...state,
        ...{
          isBulkDeleteFloorPlansStarted: false,
          isBulkDeleteFloorPlansCompleted: true,
        },
      };
    case Actions.FLOOR_PLANS_RESET_STORE_TO_DEFAULT:
      return {
        ...floorPlansInitialState,
      };
    default:
      return state;
  }
}
