import { Component, Input, OnDestroy } from '@angular/core';
import { PageHeaderInterface } from '../../service/page-header/page-header.model';
import { LayoutConstants } from '../../../layout/layout-constants';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { PageHeaderStateInterface } from '../../../store/page-header/page-header.model';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends LayoutConstants implements OnDestroy {
  @Input() pageHeader: PageHeaderInterface = {
    title: null,
    titleKey: null,
    icon: null,
    fullScreenButton: false,
    fullScreenTargetElementId: null,
    showPageConfiguration: false,
    showCountDownButton: false,
    showFilterBarVisibilityButton: false,
    showPrintFunctionalityButton: false,
    showModeSwitchButton: false,
    breadcrumbs: [],
  };
  private readonly subscriptions: Subscription[] = [];
  public mode: boolean = false;

  /**
   * @deprecated - use showPageConfigButton instead on {{component}}-routing.module.ts
   */
  public readonly pageConfigPages: string[] = [
    'work_orders',
    'products',
    'scheduler_scenario',
    'tasks',
    'users',
    'activity_logs',
    'production_review',
    'kpi_view',
    'labor_logs_report',
    'asset_logs_report',
    'shift_summary',
    'line_view',
    'asset_view',
    'alerts',
    'maintenance_performance',
  ];

  /**
   * @deprecated - use showFilterBarVisibilityButton instead on {{component}}-routing.module.ts
   */
  public readonly pagesWithFilterBarVisibilityButton: string[] = [
    'work_orders',
    'production_review',
    'line_view',
    'asset_view',
    'activity_timeline',
  ];

  /**
   * @deprecated - use showCountDownButton instead on {{component}}-routing.module.ts
   */
  public readonly pagesWithCountdownButton: string[] = [
    'work_orders',
    'production_review',
    'line_view',
    'asset_view',
    'activity_timeline',
  ];

  /**
   * @deprecated - use showPrintFunctionalityButton instead on {{component}}-routing.module.ts
   */
  public readonly pagesWithPrintFunctionality: string[] = [
    'root_cause_analysis_report',
    'work_order_performance_report',
    'deep_dive_analysis_report',
    'task_performance_report',
    'capacity_report_title',
    'oee_calendar_report',
    'my_report',
  ];

  public readonly pagesWithModeSwitchButton: string[] = ['my_report'];
  public readonly pagesWithDashboardModeButton: string[] = ['activity_timeline_report'];

  public showPageConfig: boolean = false;
  public showFilterBarVisibilityButton: boolean = false;
  public showCountDownButton: boolean = false;
  public showPrintFunctionalityButton: boolean = false;
  public showModeSwitchButton: boolean = false;
  public hideHeaderActions: boolean = false;
  public isHeaderVisible: boolean = true;
  public showDashboardModeButton: boolean = true;

  constructor(private readonly store: Store<oeeAppReducer.OeeAppState>) {
    super();
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('pageHeaderStore').subscribe((state: PageHeaderStateInterface) => {
        this.hideHeaderActions = state.hideHeaderActions;
        this.isHeaderVisible = state.isHeaderVisible;
        this.mode = state.isDashboardMode;
        this.showPageConfig =
          this.pageHeader.showPageConfiguration || _.indexOf(this.pageConfigPages, this.pageHeader.titleKey) !== -1;
        this.showCountDownButton =
          (this.pageHeader.showCountDownButton ||
            _.indexOf(this.pagesWithCountdownButton, this.pageHeader.titleKey) !== -1) &&
          this.mode;
        this.showFilterBarVisibilityButton =
          (this.pageHeader.showFilterBarVisibilityButton ||
            _.indexOf(this.pagesWithFilterBarVisibilityButton, this.pageHeader.titleKey) !== -1) &&
          !(this.mode && this.pageHeader.titleKey === 'production_review') &&
          !(!this.mode && this.pageHeader.titleKey === 'line_view');
        this.showPrintFunctionalityButton =
          this.pageHeader.showPrintFunctionalityButton ||
          _.indexOf(this.pagesWithPrintFunctionality, this.pageHeader.titleKey) !== -1;
        this.showModeSwitchButton =
          this.pageHeader.showModeSwitchButton ||
          _.indexOf(this.pagesWithModeSwitchButton, this.pageHeader.titleKey) !== -1;
        this.showDashboardModeButton =
          this.pageHeader.showDashboardModeButton ||
          _.indexOf(this.pagesWithDashboardModeButton, this.pageHeader.titleKey) !== -1;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });
  }
}
