import { BaseActivitiesInterface } from '../../../shared/model/interface/activities.model';

export interface UnplannedListSortSettingsInterface {
  [key: string]: {
    settings: {
      [key: string]: SortSettingInterface;
    };
  };
}

export interface SortSettingInterface {
  field: string;
  orderBy: OrderByEnum;
  sortIconClass: SortIconClassEnum;
}

export enum OrderByEnum {
  none = 'none',
  asc = 'asc',
  desc = 'desc',
}

export enum SortIconClassEnum {
  none = 'opacity-0 none-type',
  asc = 'opacity-100',
  desc = 'opacity-100 desc',
}

export interface UnplannedActivitiesInterface extends BaseActivitiesInterface {
  id: number | string;
  groupName?: string;
  phaseName?: string;
  lineName?: string;
}

export interface IGridTitleTranslationSettings {
  nameTitle?: string;
  detailTitle?: string;
  durationTitle?: string;
  searchTitle?: string;
}
