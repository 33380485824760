import * as Actions from './jobs.actions';
import { IJobsState } from './jobs.model';

export const jobsState: IJobsState = {
  jobsDataLoading: false,
  jobsDataLoaded: false,
  jobsData: null,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
};

export function jobsReducer(state: IJobsState = jobsState, action: Actions.JobsActions): IJobsState {
  switch (action.type) {
    case Actions.JOBS_LIST_DATA_LOADING:
      return {
        ...state,
        ...{
          jobsDataLoading: true,
          jobsDataLoaded: false,
        },
      };
    case Actions.JOBS_LIST_DATA_LOADED:
      return {
        ...state,
        ...{
          jobsDataLoading: false,
          jobsDataLoaded: true,
          jobsData: action.payload,
        },
      };
    case Actions.DOWNLOAD_JOB_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.DOWNLOAD_JOB_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.UPLOAD_JOB_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };
    case Actions.UPLOAD_JOB_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };
    default:
      return state;
  }
}
