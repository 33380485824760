<div class="radio-group-wrapper">
  <label *ngIf="groupLabel" class="radio-group-label">{{ groupLabel }}</label>
  <mat-radio-group [(ngModel)]="inputModel" (ngModelChange)="onNgModelChange()">
    <mat-radio-button
      style="--customMargin: {{customMargin ?? '0px'}}; --customScale: {{customScale ?? 1.0}};"
      [ngClass]="isAnyError ? 'has-error' : 'valid-input'"
      class="scw-mat-radio-button"
      *ngFor="let option of options"
      [class.vertical-alignment]="alignment === 'vertical'"
      [disabled]="option.disabled || false"
      [value]="option.value"
      [labelPosition]="labelPosition"
    >
      {{ option.text }}
    </mat-radio-button>
  </mat-radio-group>
  <ng-template class="error-message-required" [ngIf]="errorText">
    <mat-hint [class.error]="errorText">
      <em
        class="sub-text-icon fas"
        [class.fa-exclamation-circle]="errorText"
      ></em>
      <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
    </mat-hint>
  </ng-template>
</div>

