import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import * as oeeAppReducer from '../../oee.reducer';
import { Store } from '@ngrx/store';
import * as ObjectActions from './schedule-adherence.actions';
import * as AppActions from '../../app/actions';
import { of, switchMap } from 'rxjs';
import { ScheduleAdherenceService } from './schedule-adherence.service';
import { HelperService } from '../../../shared/service/helper.service';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import { IScheduleAdherenceData, IScheduleAdherenceResponseData } from './schedule-adherence.model';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ScheduleAdherenceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly scheduleAdherenceService: ScheduleAdherenceService,
  ) {}

  getScheduleAdherenceData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SCHEDULE_ADHERENCE_DATA_LOADING),
      switchMap((objectData: ObjectActions.ScheduleAdherenceDataLoading) => {
        return this.scheduleAdherenceService
          .getScheduleAdherenceData(...HelperService.argumentClone(objectData.filters))
          .pipe(
            switchMap((response: ResponseArrayInterface<IScheduleAdherenceResponseData>) => {
              const formattedData: IScheduleAdherenceData = this.scheduleAdherenceService.formatScheduleAdherenceData(
                response.data,
              );
              return of(new ObjectActions.ScheduleAdherenceDataLoaded(formattedData));
            }),
            catchError((error) => {
              return of(new ObjectActions.ScheduleAdherenceFetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new ObjectActions.ScheduleAdherenceFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
