import { Action } from '@ngrx/store';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface, IErrorMessageWitObjectId,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IJob, IJobsDownloadExcelFilter } from './jobs.model';

export const JOBS_LIST_DATA_LOADING = '[JOBS] JOBS DATA LOADING';
export const JOBS_LIST_DATA_LOADED = '[JOBS] JOBS DATA LOADED';
export const JOBS_FETCH_ERROR = '[JOBS] JOBS FETCH ERROR';
export const DOWNLOAD_JOB_EXCEL = '[JOBS] DOWNLOAD JOB EXCEL';
export const DOWNLOAD_JOB_EXCEL_COMPLETED = '[JOBS] DOWNLOAD JOB EXCEL COMPLETED';
export const ADD_JOB = '[JOBS] ADD JOB';
export const ADD_JOB_COMPLETED = '[JOBS] ADD JOB COMPLETED';
export const EDIT_JOB = '[JOBS] EDIT JOB';
export const EDIT_JOB_COMPLETED = '[JOBS] EDIT JOB COMPLETED';
export const DELETE_JOB = '[JOBS] DELETE JOB';
export const DELETE_JOB_COMPLETED = '[JOBS] DELETE JOB COMPLETED';
export const UPLOAD_JOB_EXCEL = '[JOBS] UPLOAD JOB EXCEL';
export const UPLOAD_JOB_EXCEL_COMPLETED = '[JOBS] UPLOAD JOB EXCEL COMPLETED';

export class JobsListDataLoading implements Action {
  readonly type = JOBS_LIST_DATA_LOADING;

  constructor(public params: GenericCrudRequestConstructionParameters) {}
}

export class JobsListDataLoaded implements Action {
  readonly type = JOBS_LIST_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<IJob>) {}
}

export class FetchError implements Action {
  readonly type = JOBS_FETCH_ERROR;
  constructor(public payload: Record<string, any>) {}
}

export class DownloadJobExcel implements Action {
  readonly type = DOWNLOAD_JOB_EXCEL;

  constructor(
    public withData: boolean = false,
    public filters: IJobsDownloadExcelFilter,
    public tableQuery: GenericCrudRequestConstructionParameters,
  ) {}
}

export class DownloadJobExcelCompleted implements Action {
  readonly type = DOWNLOAD_JOB_EXCEL_COMPLETED;
}

export class AddJob implements Action {
  readonly type = ADD_JOB;

  constructor(
    public payload: {
      data: Omit<IJob, 'id'>;
    },
  ) {}
}
export class AddJobCompleted implements Action {
  readonly type = ADD_JOB_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IJob>) {}
}
export class EditJob implements Action {
  readonly type = EDIT_JOB;

  constructor(
    public payload: {
      data: Omit<IJob, 'id'>;
      id: number;
    },
  ) {}
}
export class EditJobCompleted implements Action {
  readonly type = EDIT_JOB_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IJob>) {}
}
export class DeleteJob implements Action {
  readonly type = DELETE_JOB;

  constructor(public payload: number[]) {}
}
export class DeleteJobCompleted implements Action {
  readonly type = DELETE_JOB_COMPLETED;

  constructor(public payload: IErrorMessageWitObjectId[]) {}
}

export class UploadJobExcel implements Action {
  readonly type = UPLOAD_JOB_EXCEL;

  constructor(
    public payload: {
      jobs: IJob[];
    },
  ) {}
}

export class UploadJobExcelCompleted implements Action {
  readonly type = UPLOAD_JOB_EXCEL_COMPLETED;

  constructor(public payload: (IJob & { errorMessages?: string })[]) {}
}

export type JobsActions =
  | JobsListDataLoading
  | JobsListDataLoaded
  | DownloadJobExcel
  | DownloadJobExcelCompleted
  | AddJob
  | AddJobCompleted
  | EditJob
  | EditJobCompleted
  | DeleteJob
  | DeleteJobCompleted
  | UploadJobExcel
  | UploadJobExcelCompleted
  | FetchError;
