export interface IDeepDiveAnalysisData {
  oeeResult?: IFormattedOeeResult;
  weeklyResult: IFormattedOeeResult[];
  averageTarget: number | null;
  clientFormula: string;
  lineFormula: string;
  siteDecimalScaleLimit: number;
}

export type DeepDiveAnalysisResponse = Exclude<IDeepDiveAnalysisData, 'oeeResult' | 'weeklyResult'> & {
  oeeResult?: IOeeResponse;
  weeklyResult: IOeeResponse[];
};

export enum ChartValues {
  totalRunTimeDuration = 'totalRunTimeDuration',
  totalDownTimeDuration = 'totalDownTimeDuration',
  totalDownTimePlannedDuration = 'totalDownTimePlannedDuration',
  totalDownTimeUnplannedDuration = 'unplannedDownTimeDuration',
  totalIdleTimeDuration = 'totalIdleTimeDuration',
}

export interface ICommonOeeData {
  downTimeDurationxLineSpeed: number;
  goodQuantity: number;
  goodQuantityFromSensorTable: number;
  idleTimeDurationxLineSpeed: number;
  intervalEndDate: string;
  intervalName: string;
  intervalStartDate: string;
  oeeEndDate: string;
  oeeEndShiftDay: string;
  oeeFormula: string;
  oeeStartDate: string;
  oeeStartShiftDay: string;
  potentialQuantityBasedOnLineSpeedxActualDownTime: number;
  potentialQuantityBasedOnLineSpeedxActualIdleTime: number;
  potentialQuantityBasedOnLineSpeedxActualRunTime: number;
  potentialQuantityBasedOnLineSpeedxLineSettingScheduleHours: number;
  runTimeDurationxLineSpeed: number;
  totalDownTimeCount: number;
  totalIdleTimeCount: number;
  totalQuantityFromSensorTable: number;
  totalRunTimeCount: number;
  availabilityPerInterval: string;
  overallOEE: string;
  performancePerInterval: string;
  potentialQuantityBasedOnProductSpeedxActualRunTime: number;
  qualityPerInterval: string;
  totalGoodCount: number;
  totalGoodCountEffectiveDuration: number;
  totalInitialCount: number;
  totalInitialCountEffectiveDuration: number;
  totalScrapCount: number;
  totalDuration: number;
  productId: number;
  productName: string;
  productDescription: string;
}

export interface IOeeResponse extends ICommonOeeData {
  [ChartValues.totalRunTimeDuration]: number;
  [ChartValues.totalDownTimeDuration]: number;
  [ChartValues.totalIdleTimeDuration]: number;
  [ChartValues.totalDownTimeUnplannedDuration]: number;
}

export interface IFormattedOeeResult extends ICommonOeeData {
  [ChartValues.totalRunTimeDuration]: string;
  [ChartValues.totalDownTimeDuration]: string;
  [ChartValues.totalIdleTimeDuration]: string;
  [ChartValues.totalDownTimeUnplannedDuration]: string;
  [ChartValues.totalDownTimePlannedDuration]: string;
}

export interface ITableOeeResult {
  weekNo: string;
  staffedTime: string | number;
  availabilityPerInterval: string | number;
  overallOEE: string | number;
  performancePerInterval: string | number;
  potentialQuantityBasedOnProductSpeedxActualRunTime: string | number;
  qualityPerInterval: string | number;
  totalGoodCount: string;
  totalGoodCountEffectiveDuration: string | number;
  totalInitialCount: string;
  totalInitialCountEffectiveDuration: string | number;
  totalScrapCount: string;
  totalDuration: string | number;
  [ChartValues.totalRunTimeDuration]: string | number;
  [ChartValues.totalIdleTimeDuration]: string | number;
  [ChartValues.totalDownTimeUnplannedDuration]: string | number;
  [ChartValues.totalDownTimePlannedDuration]: string | number;
  [ChartValues.totalDownTimeDuration]: string | number;
}

export interface IDeepDiveFilters {
  start?: string;
  end?: string;
  sites?: number[];
  lines?: number[];
  shifts?: number[];
  products?: number[];
  activityType?: string[];
}

export interface IMonthlyOeeFilter {
  startDate?: string;
  endDate?: string;
  sites?: number[];
  lines?: number[];
  shifts?: number[];
  sqlMode: string;
  products?: number[];
}

export enum EChartValueChartType {
  BAR = 'bar',
  PIE = 'pie',
}

export enum EStackChartGroupBy {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  PRODUCT = 'product',
}

export enum EStackChartGroupByBased {
  QUANTITY = 'quantityBased',
  TIME = 'timeBased',
}

export enum EStackChartValue {
  OVERALL_OEE = 'overallOee',
  AVAILABILITY = 'availability',
  PERFORMANCE = 'performance',
  QUALITY = 'quality',
}

export interface IProgressBarInput {
  value: number;
  label?: string;
}

export interface IOEEProgressBars {
  overall: IProgressBarInput;
  availability: IProgressBarInput[];
  performance: IProgressBarInput[];
  quality: IProgressBarInput[];
}

export enum ProgressBarType {
  OVERALL_OEE,
  AVAILABILITY_TOP,
  AVAILABILITY_RUN,
  AVAILABILITY_DTPLANNED,
  AVAILABILITY_DTUNPLANNED,
  PERFORMANCE_TOP,
  PERFORMANCE_BOTTOM,
  QUALITY_TOP,
  QUALITY_BOTTOM,
}

export enum GaugeChartType {
  AVAILABILITY,
  PERFORMANCE,
  QUALITY,
}

export interface ButtonDisableInterface {
  monthButtonDisabled: boolean;
  weekButtonDisabled: boolean;
  dayButtonDisabled: boolean;
}

export interface TemporaryTableDataInterface {
  tableDataDay: ITableOeeResult[];
  tableDataWeek: ITableOeeResult[];
  tableDataMonth: ITableOeeResult[];
  tableDataProductGroup: ITableOeeResult[];
}
