import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import moment from 'moment';
import {
  EMPTY,
  Observable,
  catchError,
  combineLatestWith,
  filter,
  map,
  mergeMap,
  of,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs';
import * as CommentTypesActions from 'src/app/store/comment-types/comment-types.actions';
import { ECommentTypeCategory, ICommentType } from 'src/app/store/comment-types/comment-types.model';
import { selectCommentTypes } from 'src/app/store/comment-types/comment-types.selectors';
import { OeeAppState } from 'src/app/store/oee.reducer';
import { selectTagsState } from 'src/app/store/settings/tags/tags.selectors';
import { selectUserDateTimeFormat, selectUserTimezone } from 'src/app/store/user/user.selectors';
import {
  ICommentLogsRawData,
  ICommentLogsUser,
  IGetCommentLogRowResponse,
} from 'src/app/store/reports/comment-logs/comment-logs.model';
import { CommentLogsService } from 'src/app/store/reports/comment-logs/comment-logs.service';
import { LoadTags } from 'src/app/store/settings/tags/tags.actions';
import { TagsObjectTypes, TagsStateInterface } from 'src/app/store/settings/tags/tags.model';
import { ELoadStatus } from 'src/constants.model';
import { IActivityComment, ICommentTag, ITimestampWithUser } from '../comment-feed-service/comment-feed.model';
import { CommentFeedService } from '../comment-feed-service/comment-feed.service';
import {
  AllowedCommentObjectType,
  ICommentFeedState,
  TFormProcess,
  IInMemoryComment,
  IPersistentComment,
  TFormComment,
  TCommentResponseDto,
  IEditFormParams,
  ILoadCommentsParams,
} from './comment-feed-modal.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from 'src/app/shared/model/interface/crud-response-interface.model';
import { User } from 'src/app/store/user/model';

@Injectable()
export class CommentFeedStore extends ComponentStore<ICommentFeedState> {
  private static readonly defaultDateTimeFormat: string = 'll LT';
  private static readonly defaultTimezone: string = 'UTC';

  public readonly inMemoryMode$: Observable<boolean> = this.select((state: ICommentFeedState) => state.inMemoryMode);
  public readonly commentsLoadStatus$: Observable<ELoadStatus> = this.select(
    (state: ICommentFeedState) => state.commentsLoadStatus,
  );
  public readonly comments$: Observable<IActivityComment[]> = this.inMemoryMode$.pipe(
    switchMap((inMemoryMode: boolean) => (inMemoryMode ? this.inMemoryComments$ : this.persistentComments$)),
  );
  public readonly commentToDeleteIndex$: Observable<number> = this.select(
    (state: ICommentFeedState) => state.commentToDeleteIndex,
  ).pipe(filter((comment: number | undefined): comment is number => comment !== undefined));
  public readonly commentToEdit$: Observable<IActivityComment | null> = this.select(
    (state: ICommentFeedState) => state.commentToEdit,
  );
  public readonly formProcess$: Observable<TFormProcess> = this.select((state: ICommentFeedState) => state.formProcess);
  public readonly lastPersistedComment$: Observable<IActivityComment> = this.select(
    (state: ICommentFeedState) => state.lastPersistedComment,
  ).pipe(filter((comment: IActivityComment | undefined): comment is IActivityComment => comment !== undefined));
  public readonly lastSavedInMemoryComment$: Observable<IInMemoryComment> = this.select(
    (state: ICommentFeedState) => state.lastSavedInMemoryComment,
  ).pipe(filter((comment: IInMemoryComment | undefined): comment is IInMemoryComment => comment !== undefined));
  public readonly lastBulkInsertResponse$: Observable<unknown> = this.select(
    (state: ICommentFeedState) => state.lastBulkInsertResponse,
  ).pipe(filter((response: unknown) => response !== undefined));
  public readonly lastDeletedComment$: Observable<IActivityComment> = this.select(
    (state: ICommentFeedState) => state.lastDeletedComment,
  ).pipe(filter((comment: IActivityComment | undefined): comment is IActivityComment => comment !== undefined));
  public readonly lastEditedComment$: Observable<IActivityComment> = this.select(
    (state: ICommentFeedState) => state.lastEditedComment,
  ).pipe(filter((comment: IActivityComment | undefined): comment is IActivityComment => comment !== undefined));
  public readonly userDateTimeFormat$: Observable<string> = this.select(
    this.globalStore.select(selectUserDateTimeFormat).pipe(
      filter((value: string | undefined): value is string => value !== undefined),
      timeout(1000),
      catchError(() => of(CommentFeedStore.defaultDateTimeFormat)),
    ),
    (format: string) => format,
  );
  public readonly userDateTimezone$: Observable<string> = this.select(
    this.globalStore.select(selectUserTimezone).pipe(
      filter((value): value is string => value !== undefined),
      timeout(1000),
      take(1),
      catchError(() => of(CommentFeedStore.defaultTimezone)),
    ),
    (timezone: string) => timezone,
  );
  public readonly tags$: Observable<ICommentTag[]> = this.select(
    this.globalStore.select(selectTagsState),
    (state: TagsStateInterface) => state,
  ).pipe(
    filter((state: TagsStateInterface) => !state.tagsLoading && state.tagsLoaded),
    map((state: TagsStateInterface) => state.tagsData.data),
    tap(() => this.setTagsLoadStatus(ELoadStatus.Success)),
  );
  public readonly tagsLoadStatus$ = this.select((state: ICommentFeedState) => state.tagsLoadStatus);

  public readonly activityCommentTypeId$: Observable<number> = this.select(
    this.globalStore.select(selectCommentTypes),
    (commentTypes: readonly ICommentType[]) => commentTypes,
  ).pipe(
    map((commentTypes: readonly ICommentType[]) =>
      commentTypes.find((commentType: ICommentType) => commentType.category === ECommentTypeCategory.Activity),
    ),
    filter((commentType: ICommentType | undefined): commentType is ICommentType => commentType !== undefined),
    map((commentType: ICommentType) => commentType.id),
  );

  public readonly persistentComments$: Observable<IActivityComment[]> = this.select(
    (state: ICommentFeedState) => state.comments,
  );

  public readonly createComment = this.effect((params$: Observable<TFormComment>) =>
    params$.pipe(
      tap(() => this.setFormProcess('adding')),
      withLatestFrom(
        this.activityCommentTypeId$,
        this.globalStore
          .select(selectUserTimezone)
          .pipe(filter((tz: string | undefined): tz is string => tz !== undefined)),
      ),
      map(([params, commentTypeId, timezone]: [TFormComment, number, string]) =>
        params.objectIds.map<IPersistentComment | IInMemoryComment>((objectId: number | undefined) =>
          this.formCommentToDto(params, commentTypeId, objectId, timezone),
        ),
      ),
      withLatestFrom(this.inMemoryMode$),
      switchMap(([comments, inMemoryMode]: [readonly (IInMemoryComment | IPersistentComment)[], boolean]) => {
        if (inMemoryMode) {
          return of(comments[0] as IInMemoryComment).pipe(tap((com: IInMemoryComment) => this.addInMemoryComment(com)));
        }

        if (comments.length === 1) {
          return this.commentFeedService
            .createComment<TCommentResponseDto>(
              comments[0] as IPersistentComment,
              new HttpParams({ fromObject: { join: 'user' } }),
            )
            .pipe(
              withLatestFrom(this.tags$),
              tapResponse(
                ([response, tags]: [BaseOneResponseInterface<TCommentResponseDto>, readonly ICommentTag[]]) =>
                  this.insertComment(this.commentFromRawResponse(response.data, tags)),
                () => this.setFormProcess('idle'),
              ),
            );
        }

        return this.commentFeedService.createComments<ICommentLogsRawData>(comments as IPersistentComment[]).pipe(
          tapResponse(
            (response: GetManyResponseInterface<ICommentLogsRawData>) => this.setLastBulkInsertResponse(response),
            () => this.setFormProcess('idle'),
          ),
        );
      }),
    ),
  );

  public readonly deleteComment = this.effect(($: Observable<void>) =>
    $.pipe(
      tap(() => this.setFormProcess('deleting')),
      withLatestFrom(
        this.commentToDeleteIndex$,
        this.inMemoryMode$,
        this.persistentComments$.pipe(startWith(undefined)),
      ),
      switchMap(
        ([, index, inMemoryMode, persistentComments]: [
          void,
          number,
          boolean,
          readonly IActivityComment[] | undefined,
        ]) => {
          if (inMemoryMode) {
            return of(index).pipe(map((idx: number) => this.deleteInMemoryComment(idx)));
          }

          const commentId: number = persistentComments![index].id;
          return this.commentLogService.deleteCommentLogsRows([commentId]).pipe(
            tapResponse(
              () => this.removeComment(index),
              () => this.setFormProcess('idle'),
            ),
          );
        },
      ),
    ),
  );

  public readonly editComment = this.effect((params$: Observable<IEditFormParams>) =>
    params$.pipe(
      tap(() => this.setFormProcess('editing')),
      withLatestFrom(this.inMemoryMode$, this.persistentComments$.pipe(startWith(undefined))),
      switchMap(
        ([params, inMemoryMode, persistentComments]: [
          IEditFormParams,
          boolean,
          readonly IActivityComment[] | undefined,
        ]) => {
          if (inMemoryMode) {
            return of(params).pipe(
              map((param: IEditFormParams) => {
                return this.updateInMemoryComment({
                  index: param.index,
                  message: param.message,
                  tags: param.tags ?? [],
                });
              }),
            );
          }

          return this.commentLogService
            .editCommentLogData<TCommentResponseDto>(
              { commentMessage: params.message, tags: params.tags },
              persistentComments![params.index].id,
              new HttpParams({ fromObject: { join: ['updateUser', 'user'] } }),
            )
            .pipe(
              withLatestFrom(this.tags$),
              map(([response, tags]: [IGetCommentLogRowResponse<TCommentResponseDto>, readonly ICommentTag[]]) =>
                this.commentFromRawResponse(response.data, tags),
              ),
              tapResponse(
                (comment: IActivityComment) => this.updateComment(comment),
                () => this.setFormProcess('idle'),
              ),
            );
        },
      ),
    ),
  );

  public readonly loadTags = this.effect(($: Observable<void>) =>
    $.pipe(
      map(() => {
        this.globalStore.dispatch(new LoadTags({ filters: [{ field: 'objectType', ids: [TagsObjectTypes.Comment] }] }));
        this.setTagsLoadStatus(ELoadStatus.Loading);
      }),
    ),
  );

  public readonly loadCommentTypes = this.effect(($: Observable<void>) =>
    $.pipe(map(() => this.globalStore.dispatch(CommentTypesActions.loadIfNotLoaded()))),
  );

  public readonly loadComments = this.effect((params$: Observable<ILoadCommentsParams>) =>
    params$.pipe(
      switchMap((params: ILoadCommentsParams) => {
        if (params.objectIds.length !== 1) {
          return EMPTY;
        }

        this.setCommentsLoadStatus(ELoadStatus.Loading);
        return this.getComments({ objectId: params.objectIds[0], objectType: params.objectType }).pipe(
          tapResponse(
            (comments: IActivityComment[]) => this.setSuccess(comments),
            () => this.setCommentsLoadStatus(ELoadStatus.Failure),
          ),
        );
      }),
    ),
  );

  public readonly setCommentToDeleteIndex = this.updater((state: ICommentFeedState, commentToDeleteIndex: number) =>
    this.reduceState(state, { commentToDeleteIndex }),
  );

  public readonly setCommentToEdit = this.updater((state: ICommentFeedState, commentToEdit: IActivityComment) =>
    this.reduceState(state, { commentToEdit }),
  );

  public readonly setInMemoryComments = this.updater(
    (state: ICommentFeedState, inMemoryComments: IInMemoryComment[]) => {
      return this.reduceState(state, { inMemoryMode: true, commentsLoadStatus: ELoadStatus.Success, inMemoryComments });
    },
  );

  public readonly unsetCommentToDeleteIndex = this.updater((state: ICommentFeedState) =>
    this.reduceState(state, { commentToDeleteIndex: undefined }),
  );

  public readonly unsetCommentToEdit = this.updater((state: ICommentFeedState) =>
    this.reduceState(state, { commentToEdit: null }),
  );

  public readonly rawInMemoryComments$: Observable<IInMemoryComment[]> = this.select(
    (state: ICommentFeedState) => state.inMemoryComments,
  );

  private readonly inMemoryComments$: Observable<IActivityComment[]> = this.rawInMemoryComments$.pipe(
    combineLatestWith(
      this.select(this.globalStore.select(selectCommentTypes), (commentTypes: readonly ICommentType[]) => commentTypes),
      this.tags$,
      this.select(this.globalStore.select('user'), (user: User) => user),
    ),
    map(
      ([comments, commentTypes, tags, user]: [
        readonly IInMemoryComment[],
        readonly ICommentType[],
        readonly ICommentTag[],
        User,
      ]) =>
        comments.map((comment: IInMemoryComment, index: number) => ({
          id: index,
          commentDate: comment.commentDate,
          commentMessage: comment.commentMessage,
          commentType: commentTypes.find((type: ICommentType) => type.id === comment.commentTypeId)!,
          commentTags: comment.tags.map((tagId: number) => tags.find((tag: ICommentTag) => tag.id === tagId)!),
          created: {
            id: Number(user.userId),
            fullName: user.fullName!,
            timestamp: undefined,
          },
          updated: null,
        })),
    ),
  );

  private readonly addInMemoryComment = this.updater(
    (state: ICommentFeedState, lastSavedInMemoryComment: IInMemoryComment) => {
      return this.reduceState(state, {
        formProcess: 'idle',
        inMemoryComments: state.inMemoryComments.concat(lastSavedInMemoryComment),
        lastSavedInMemoryComment,
      });
    },
  );

  private readonly deleteInMemoryComment = this.updater((state: ICommentFeedState, index: number) => {
    const inMemoryComments: IInMemoryComment[] = structuredClone(state.inMemoryComments);
    inMemoryComments.splice(index, 1);
    return this.reduceState(state, { commentToDeleteIndex: undefined, formProcess: 'idle', inMemoryComments });
  });

  private readonly insertComment = this.updater((state: ICommentFeedState, lastPersistedComment: IActivityComment) =>
    this.reduceState(state, {
      formProcess: 'idle',
      comments: state.comments.concat(lastPersistedComment),
      lastPersistedComment,
    }),
  );

  private readonly removeComment = this.updater((state: ICommentFeedState, index: number) => {
    const comments: IActivityComment[] = structuredClone(state.comments);
    const [lastDeletedComment] = comments.splice(index, 1);
    return this.reduceState(state, {
      commentToDeleteIndex: undefined,
      formProcess: 'idle',
      comments,
      lastDeletedComment,
    });
  });

  private readonly setCommentsLoadStatus = this.updater((state: ICommentFeedState, commentsLoadStatus: ELoadStatus) =>
    this.reduceState(state, { commentsLoadStatus }),
  );

  private readonly setFormProcess = this.updater((state: ICommentFeedState, formProcess: TFormProcess) =>
    this.reduceState(state, { formProcess }),
  );

  private readonly setLastBulkInsertResponse = this.updater(
    (state: ICommentFeedState, lastBulkInsertResponse: unknown) => this.reduceState(state, { lastBulkInsertResponse }),
  );

  private readonly setSuccess = this.updater((state: ICommentFeedState, comments: IActivityComment[]) =>
    this.reduceState(state, { comments, commentsLoadStatus: ELoadStatus.Success }),
  );

  private readonly setTagsLoadStatus = this.updater((state: ICommentFeedState, tagsLoadStatus: ELoadStatus) =>
    this.reduceState(state, { tagsLoadStatus }),
  );

  private readonly updateComment = this.updater((state: ICommentFeedState, updatedComment: IActivityComment) => {
    const commentIndex: number = state.comments.findIndex((c: IActivityComment) => c.id === updatedComment.id);
    const comments: IActivityComment[] = structuredClone(state.comments);
    comments.splice(commentIndex, 1, updatedComment);
    return this.reduceState(state, {
      formProcess: 'idle',
      comments,
      commentToEdit: null,
      lastEditedComment: updatedComment,
    });
  });

  private readonly updateInMemoryComment = this.updater((state: ICommentFeedState, params: IEditFormParams) => {
    const inMemoryComment: IInMemoryComment = {
      ...state.inMemoryComments[params.index],
      commentMessage: params.message,
      tags: params.tags ?? [],
    };
    const inMemoryComments: IInMemoryComment[] = structuredClone(state.inMemoryComments);
    inMemoryComments.splice(params.index, 1, inMemoryComment);
    return this.reduceState(state, { formProcess: 'idle', inMemoryComments, commentToEdit: null });
  });

  constructor(
    private readonly commentFeedService: CommentFeedService,
    private readonly commentLogService: CommentLogsService,
    private readonly globalStore: Store<OeeAppState>,
  ) {
    super({
      comments: [],
      commentsLoadStatus: ELoadStatus.Initial,
      commentToDeleteIndex: undefined,
      commentToEdit: null,
      formProcess: 'idle',
      inMemoryComments: [],
      inMemoryMode: false,
      lastDeletedComment: undefined,
      lastEditedComment: undefined,
      lastPersistedComment: undefined,
      lastSavedInMemoryComment: undefined,
      lastBulkInsertResponse: undefined,
      tagsLoadStatus: ELoadStatus.Initial,
    });

    this.select(this.globalStore.select(selectTagsState), (state: TagsStateInterface) => state)
      .pipe(
        filter((state: TagsStateInterface) => state.loadFailed),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.setTagsLoadStatus(ELoadStatus.Failure));
  }

  private commentFromRawResponse(
    response: ICommentLogsRawData & { updateUser?: ICommentLogsUser; user: ICommentLogsUser },
    tags: readonly ICommentTag[],
  ): IActivityComment {
    const commentTags: ICommentTag[] | null = (response.tags || [])
      .map((tagId: number) => {
        const matchedTag = tags.find((tag: ICommentTag) => tag.id === tagId);
        return matchedTag ? {...matchedTag, showIcon: tagId === response.tagsFromAI} : null;
      })
      .filter((tag: ICommentTag | undefined) => tag !== null);
    return {
      ...response,
      commentTags,
      created: { id: response.user.id, fullName: response.user.fullName, timestamp: response.createdAt },
      updated: response.updateUser
        ? { id: response.updateUser.id, fullName: response.updateUser.fullName, timestamp: response.changedAt }
        : null,
    };
  }

  private formCommentToDto(
    comment: TFormComment,
    commentTypeId: number,
    objectId: number | undefined,
    timezone: string,
  ): IPersistentComment | IInMemoryComment {
    return {
      commentMessage: comment.commentMessage,
      commentTypeId,
      objectType: comment.objectType,
      tags: comment.commentTags?.map((tag: ICommentTag) => tag.id) ?? [],
      ...(objectId
        ? {
            objectId,
            commentDate: undefined,
            timestamp: comment.timestamp,
          }
        : {
            objectId: undefined,
            commentDate: moment.utc().tz(timezone).format('YYYY-MM-DD HH:mm:ss'),
          }),
    };
  }

  private getComments(params: {
    objectId: number;
    objectType: AllowedCommentObjectType;
  }): Observable<IActivityComment[]> {
    switch (params.objectType) {
      case 'lines':
        return this.commentFeedService
          .getLineActivityComments(params.objectId)
          .pipe(map((response: GetManyResponseInterface<IActivityComment>) => response.data));
      case 'activity_history':
        return this.commentFeedService
          .getActivityHistoryActivityComments(params.objectId)
          .pipe(map((response: GetManyResponseInterface<IActivityComment>) => response.data));
      case 'station_activity_history':
        return this.tags$.pipe(
          mergeMap((tags: readonly ICommentTag[]) =>
            this.commentLogService
              .getCommentLogsData<TCommentResponseDto>(
                new HttpParams({
                  fromObject: {
                    s: JSON.stringify({
                      'commentType.category': ECommentTypeCategory.Activity,
                      objectId: params.objectId,
                      objectType: params.objectType,
                    }),
                    join: ['commentType', 'updateUser', 'user'],
                    limit: 1000,
                  },
                }),
              )
              .pipe(
                map((response: GetManyResponseInterface<TCommentResponseDto>) =>
                  response.data.map((comment: TCommentResponseDto) => this.commentFromRawResponse(comment, tags)),
                ),
              ),
          ),
        );
    }
  }

  private reduceState(current: ICommentFeedState, newState: Partial<ICommentFeedState>): ICommentFeedState {
    if (newState.comments) {
      newState.comments = this.sortComments(newState.comments);
    }

    if (newState.inMemoryComments) {
      newState.inMemoryComments = this.sortComments(newState.inMemoryComments);
    }

    return { ...current, ...newState };
  }

  private sortComments<T extends { commentDate: string | null; created?: ITimestampWithUser }>(
    comments: readonly T[],
  ): T[] {
    const creationDates: Map<T, number> = new Map(
      comments.map((comment: T) => {
        const timestamp: string | undefined = comment.created?.timestamp ?? comment.commentDate;
        const date: Date = timestamp ? new Date(timestamp) : new Date();
        return [comment, date.getTime()];
      }),
    );
    return comments.slice().sort((a: T, b: T) => {
      const timeA: number = creationDates.get(b) ?? 0;
      const timeB: number = creationDates.get(a) ?? 0;
      return timeA - timeB;
    });
  }
}
