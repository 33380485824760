import * as ObjectActionsAndTypes from './work-order-schedule.actions';
import {
  RecalculateResponseDataInterface,
  SelectElementOptions,
  WorkOrderInterface,
  WorkOrderScheduleStateInterface,
} from './work-order-schedule.model';
import * as _ from 'lodash';
import { BulkEditFieldInterface } from '../../view/work-orders-new-design/work-orders-new-design.model';
import { minutesToHm, mysqlTimestampFormat } from '../../shared/helper/date';
import * as moment from 'moment';
import { HelperService } from '../../shared/service/helper.service';
import { TaskGroupsInterface } from '../work-orders-summary/work-orders-summary.model';
import { DecimalHelper } from '../../shared/helper/decimal/decimal-helper';

export const workOrderScheduleInitialState: WorkOrderScheduleStateInterface = {
  errors: [],
  workOrderScheduleList: [],
  workOrderStatusCounts: {
    notStartedCount: 0,
    inProgressCount: 0,
    completedCount: 0,
  },
  page: 1,
  pageCount: 1,
  itemCount: 1,
  itemTotal: 1,
  regularModeFilters: null,
  dashboardModeFilters: null,
  workOrderScheduleTableSettings: {},
  workOrderFormResources: {
    sites: [],
    lines: [],
    products: [],
    productionStages: [],
    jobs: [],
  },
  workOrder: null,
  getWorkOrderScheduleListLoading: false,
  getWorkOrderScheduleListLoaded: false,
  getWorkOrderForWorkOrderFormLoading: false,
  getWorkOrderForWorkOrderFormLoaded: false,
  getWorkOrderFormResourcesLoading: false,
  getWorkOrderFormResourcesLoaded: false,
  saveWorkOrderAddFormLoading: false,
  saveWorkOrderAddFormLoaded: false,
  tableSettings: [],
  customConfiguration: [{ name: 'dynamicDateFilter', value: ['woDueDateDateType'] }],
  getSitesLoading: false,
  getSitesLoaded: false,
  getLinesLoading: false,
  getLinesLoaded: false,
  getProductsLoading: false,
  getProductsLoaded: false,
  getProductionStagesLoaded: false,
  getProductionStagesLoading: false,
  sites: [],
  lines: null,
  products: [],
  productionStages: [],
  isWorkOrderDeleteLoading: false,
  isWorkOrderDeleteLoaded: false,
  isWorkOrderEditLoading: false,
  isWorkOrderEditLoaded: false,
  isWorkOrderRecalculateLoading: false,
  isWorkOrderRecalculateLoaded: false,
  fileDownloadStatus: false,
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
  excelUploadResult: [],
  jobs: [],
  getJobsLoaded: false,
  getJobsLoading: false,
  columnNames: null,
  taskGroups: [],
};

export function workOrderScheduleReducer(
  state: WorkOrderScheduleStateInterface = workOrderScheduleInitialState,
  action: ObjectActionsAndTypes.WorkOrderScheduleActions,
): WorkOrderScheduleStateInterface {
  let workOrderList$: WorkOrderInterface[];
  switch (action.type) {
    case ObjectActionsAndTypes.GET_WORK_ORDER_SCHEDULE_LIST:
      return {
        ...state,
        getWorkOrderScheduleListLoading: true,
        taskGroups: [],
      };
    case ObjectActionsAndTypes.GET_WORK_ORDER_SCHEDULE_LIST_COMPLETED:
      const data = HelperService.cloneDeep(action.payload.data);
      const taskGroups: TaskGroupsInterface[] = HelperService.cloneDeep(action.payload.taskGroups);

      data.forEach((workOrder: WorkOrderInterface) => {
        workOrder.oeeTotalDownDuration = minutesToHm(_.toInteger(workOrder.oeeTotalDownDuration));
        workOrder.oeeTotalRunDuration = minutesToHm(_.toInteger(workOrder.oeeTotalRunDuration));
        workOrder.oeeTotalIdleTimeDuration = minutesToHm(_.toInteger(workOrder.oeeTotalIdleTimeDuration));
        workOrder.scheduledPreDuration = minutesToHm(_.toInteger(Number(workOrder.scheduledPreDuration) * 60));
        workOrder.scheduledRunDuration = minutesToHm(_.toInteger(Number(workOrder.scheduledRunDuration) * 60));
        workOrder.scheduledPostDuration = minutesToHm(_.toInteger(Number(workOrder.scheduledPostDuration) * 60));
        workOrder.oeeOveralloee = workOrder.oeeOveralloee !== null ? `${workOrder.oeeOveralloee}%` : '';
        workOrder.oeeA = workOrder.oeeA !== null ? `${workOrder.oeeA}%` : '';
        workOrder.oeeP = workOrder.oeeP !== null ? `${workOrder.oeeP}%` : '';
        workOrder.oeeQ = workOrder.oeeQ !== null ? `${workOrder.oeeQ}%` : '';
        workOrder.inProgress = !workOrder.completed && Number(workOrder.inProgress);
        workOrder.notStarted = !workOrder.completed && Number(workOrder.notStarted);
        workOrder.workOrderActual = minutesToHm(Number(DecimalHelper.divide(workOrder.workOrderActual, '60')));
        workOrder.workOrderPlanned = minutesToHm(Number(DecimalHelper.divide(workOrder.workOrderPlanned, '60')));
        workOrder.workOrderVariance = minutesToHm(Number(DecimalHelper.divide(workOrder.workOrderVariance, '60')));
        workOrder.preRunPhaseDuration = minutesToHm(Number(DecimalHelper.divide(workOrder.preRunPhaseDuration, '60')));
        workOrder.runPhaseDuration = minutesToHm(Number(DecimalHelper.divide(workOrder.runPhaseDuration, '60')));
        workOrder.postRunPhaseDuration = minutesToHm(
          Number(DecimalHelper.divide(workOrder.postRunPhaseDuration, '60')),
        );
        taskGroups.forEach((taskGroup: TaskGroupsInterface) => {
          workOrder[taskGroup.name] = minutesToHm(Number(DecimalHelper.divide(workOrder[taskGroup.name], '60')));
        });
      });

      return {
        ...state,
        getWorkOrderScheduleListLoading: false,
        getWorkOrderScheduleListLoaded: true,
        workOrderScheduleList: data,
        workOrderStatusCounts: {
          notStartedCount: action.payload.notStartedCount,
          inProgressCount: action.payload.inProgressCount,
          completedCount: action.payload.completedCount,
        },
        columnNames: action.payload.columnNames,
        taskGroups: action.payload.taskGroups,
        page: action.payload.page,
        pageCount: action.payload.pageCount,
        itemCount: action.payload.count,
        itemTotal: action.payload.total,
      };
    case ObjectActionsAndTypes.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
        customConfiguration: [
          {
            name: 'dynamicDateFilter',
            value: [...(action.customSections.selectedDateType ? [action.customSections.selectedDateType] : ['woDueDateDateType'])],
          },
          {
            name: 'recalculateWorkOrders',
            value: [...(action.customSections.recalculateMode ? ['recalculate'] : [])],
          },
        ],
      };
    case ObjectActionsAndTypes.GET_WORK_ORDER:
      return {
        ...state,
        getWorkOrderForWorkOrderFormLoading: true,
      };
    case ObjectActionsAndTypes.GET_WORK_ORDER_COMPLETED:
      return {
        ...state,
        getWorkOrderForWorkOrderFormLoading: false,
        getWorkOrderForWorkOrderFormLoaded: true,
        workOrder: action.payload.data,
      };

    case ObjectActionsAndTypes.GET_SITES:
      return {
        ...state,
        getSitesLoading: true,
        getSitesLoaded: false,
      };

    case ObjectActionsAndTypes.GET_SITES_COMPLETED:
      const siteDropdownResource: SelectElementOptions[] = [];
      for (const item of action.payload.data) {
        siteDropdownResource.push({
          label: item.name,
          value: String(item.id),
          isExcelDateTimeFormat: item.isExcelDateTimeFormat,
          timezone: item.timezone,
        });
      }

      return {
        ...state,
        sites: siteDropdownResource,
        getSitesLoading: false,
        getSitesLoaded: true,
      };

    case ObjectActionsAndTypes.GET_LINES:
      return {
        ...state,
        getLinesLoading: true,
        getLinesLoaded: false,
      };

    case ObjectActionsAndTypes.GET_LINES_COMPLETED:
      return {
        ...state,
        lines: action.payload,
        getLinesLoading: false,
        getLinesLoaded: true,
      };

    case ObjectActionsAndTypes.GET_PRODUCTS:
      return {
        ...state,
        getProductsLoading: true,
        getProductsLoaded: false,
      };

    case ObjectActionsAndTypes.GET_PRODUCTS_COMPLETED:
      return {
        ...state,
        products: action.payload.data,
        getProductsLoading: false,
        getProductsLoaded: true,
      };

    case ObjectActionsAndTypes.GET_PRODUCTION_STAGES:
      return {
        ...state,
        getProductionStagesLoaded: false,
        getProductionStagesLoading: true,
      };

    case ObjectActionsAndTypes.GET_PRODUCTION_STAGES_COMPLETED:
      const productionStagesDropdownResource: SelectElementOptions[] = [];

      for (const item of action.payload.data) {
        productionStagesDropdownResource.push({
          label: item.lineType,
          value: String(item.id),
        });
      }

      return {
        ...state,
        productionStages: productionStagesDropdownResource,
        getProductionStagesLoaded: true,
        getProductionStagesLoading: false,
      };
    case ObjectActionsAndTypes.SAVE_WORK_ORDER_ADD_FORM:
      return {
        ...state,
        saveWorkOrderAddFormLoading: true,
      };
    case ObjectActionsAndTypes.SAVE_WORK_ORDER_ADD_FORM_COMPLETED:
      return {
        ...state,
        saveWorkOrderAddFormLoading: false,
        saveWorkOrderAddFormLoaded: true,
      };
    case ObjectActionsAndTypes.DELETE_WORK_ORDER:
      return {
        ...state,
        isWorkOrderDeleteLoading: true,
        isWorkOrderDeleteLoaded: false,
      };
    case ObjectActionsAndTypes.DELETE_WORK_ORDER_COMPLETED:
      const deletedWorkOrders = action.deletedWorkOrders;
      const removedWorkOrderArrayIndex = [];
      workOrderList$ = _.cloneDeep(state.workOrderScheduleList);
      const payloadData = _.get(action.payload, 'data', []);

      if (payloadData.length === 0) {
        payloadData.push({
          success: action.payload.success,
          message: action.payload.message,
        });
      }

      deletedWorkOrders.forEach((workOrderId: number, index: number) => {
        const stateIndex: number = _.findIndex(workOrderList$, { id: workOrderId });
        if (stateIndex !== -1) {
          if (payloadData[index].success) {
            removedWorkOrderArrayIndex.push(stateIndex);
          } else {
            _.set(workOrderList$[stateIndex], 'error', payloadData[index].message);
          }
        }
      });

      if (removedWorkOrderArrayIndex.length > 0) {
        _.pullAt(workOrderList$, removedWorkOrderArrayIndex);
      }

      return {
        ...state,
        isWorkOrderDeleteLoading: false,
        isWorkOrderDeleteLoaded: true,
        workOrderScheduleList: workOrderList$,
      };

    case ObjectActionsAndTypes.BULK_EDIT_WORK_ORDER:
    case ObjectActionsAndTypes.EDIT_WORK_ORDER:
      return {
        ...state,
        isWorkOrderEditLoading: true,
        isWorkOrderEditLoaded: false,
      };

    case ObjectActionsAndTypes.EDIT_WORK_ORDER_COMPLETED:
      return {
        ...state,
        isWorkOrderEditLoading: false,
        isWorkOrderEditLoaded: true,
      };

    case ObjectActionsAndTypes.BULK_EDIT_WORK_ORDER_COMPLETED:
      const editedWorkOrders = action.editedWorkOrders;
      workOrderList$ = _.cloneDeep(state.workOrderScheduleList);
      const editPayloadData = _.get(action.response, 'data', []);

      if (editPayloadData.length === 0) {
        editPayloadData.push({
          success: action.response.success,
          message: action.response.message,
        });
      }

      editedWorkOrders.forEach((editedWorkOrderItem: BulkEditFieldInterface, index: number) => {
        const stateIndex: number = _.findIndex(workOrderList$, { id: editedWorkOrderItem.id });
        const updatedRow: Partial<WorkOrderInterface> = {};

        Object.entries(editedWorkOrderItem).forEach(([key, value]) => {
          if (['productId', 'scheduledLine'].indexOf(key) !== -1 && value !== null) {
            _.set(updatedRow, value.textKey, value.text);
          }

          if (key === 'id') {
            _.set(updatedRow, key, value);
          } else {
            _.set(updatedRow, key, value === null ? null : value.value);
          }
        });

        if (stateIndex !== -1) {
          if (!editPayloadData[index].success) {
            _.set(workOrderList$[stateIndex], 'error', editPayloadData[index].message);
          } else {
            workOrderList$[stateIndex] = {
              ...workOrderList$[stateIndex],
              ...updatedRow,
              error: undefined,
            };
          }
        }
      });

      return {
        ...state,
        isWorkOrderEditLoading: false,
        isWorkOrderEditLoaded: true,
        workOrderScheduleList: workOrderList$,
      };

    case ObjectActionsAndTypes.RECALCULATE_WORK_ORDER:
      return {
        ...state,
        isWorkOrderRecalculateLoading: true,
        isWorkOrderRecalculateLoaded: false,
      };

    case ObjectActionsAndTypes.RECALCULATE_WORK_ORDER_COMPLETED:
      const recalculatedWorkOrders: number[] = action.recalculatedWorkOrders;
      workOrderList$ = _.cloneDeep(state.workOrderScheduleList);
      const recalculatePayloadData: RecalculateResponseDataInterface[] = _.get(action.response, 'data', []);

      if (recalculatePayloadData.length === 0) {
        recalculatePayloadData.push({
          success: action.response.success,
          message: action.response.message,
        });
      }

      recalculatedWorkOrders.forEach((recalculatedWorkOrderItem: number, index: number) => {
        const stateIndex: number = _.findIndex(workOrderList$, recalculatedWorkOrderItem);
        const updatedRow: Partial<WorkOrderInterface> = {};

        Object.entries(recalculatedWorkOrderItem).forEach(([key, value]) => {
          if (key === 'id') {
            _.set(updatedRow, key, value);
          } else {
            _.set(updatedRow, key, value === null ? null : value.value);
          }
        });

        if (stateIndex !== -1) {
          if (!recalculatePayloadData[index].success) {
            _.set(workOrderList$[stateIndex], 'error', recalculatePayloadData[index].message);
          } else {
            workOrderList$[stateIndex] = {
              ...workOrderList$[stateIndex],
              ...updatedRow,
              error: undefined,
            };
          }
        }
      });

      return {
        ...state,
        isWorkOrderRecalculateLoading: false,
        isWorkOrderRecalculateLoaded: true,
        workOrderScheduleList: workOrderList$,
      };

    case ObjectActionsAndTypes.SET_SELECTED_REGULAR_MODE_FILTERS:
      if (action.filters.filterStartDate) {
        action.filters.filterStartDate = moment(action.filters.filterStartDate).format(mysqlTimestampFormat);
      }

      if (action.filters.filterEndDate) {
        action.filters.filterEndDate = moment(action.filters.filterEndDate).format(mysqlTimestampFormat);
      }

      return {
        ...state,
        regularModeFilters: {
          ...state.regularModeFilters,
          ...action.filters,
        },
      };

    case ObjectActionsAndTypes.SET_SELECTED_DASHBOARD_MODE_FILTERS:
      if (action.filters.filterStartDate) {
        action.filters.filterStartDate = moment(action.filters.filterStartDate).format(mysqlTimestampFormat);
      }

      if (action.filters.filterEndDate) {
        action.filters.filterEndDate = moment(action.filters.filterEndDate).format(mysqlTimestampFormat);
      }

      return {
        ...state,
        dashboardModeFilters: {
          ...state.dashboardModeFilters,
          ...action.filters,
        },
      };

    case ObjectActionsAndTypes.DOWNLOAD_EXCEL:
      return {
        ...state,
        fileDownloadStatus: true,
      };

    case ObjectActionsAndTypes.DOWNLOAD_EXCEL_COMPLETED:
      return {
        ...state,
        fileDownloadStatus: false,
      };

    case ObjectActionsAndTypes.UPLOAD_EXCEL:
      return {
        ...state,
        isUploadExcelLoading: true,
        isUploadExcelLoaded: false,
      };

    case ObjectActionsAndTypes.UPLOAD_EXCEL_COMPLETED:
      return {
        ...state,
        ...{ excelUploadResult: action.payload },
        isUploadExcelLoading: false,
        isUploadExcelLoaded: true,
      };

    case ObjectActionsAndTypes.CLEAR_STATE:
      return { ...workOrderScheduleInitialState };

    case ObjectActionsAndTypes.CLEAR_WORK_ORDER_FORM_RESOURCES_STATE:
      return {
        ...state,
        ...{
          lines: workOrderScheduleInitialState.lines,
          products: workOrderScheduleInitialState.products,
        },
      };
    case ObjectActionsAndTypes.GET_JOBS:
      return {
        ...state,
        getJobsLoaded: false,
        getJobsLoading: true,
      };

    case ObjectActionsAndTypes.GET_JOBS_COMPLETED:
      const jobsDropdownResource: SelectElementOptions[] = [];

      for (const item of action.payload.data) {
        jobsDropdownResource.push({
          label: item.jobName,
          value: String(item.id),
        });
      }

      return {
        ...state,
        jobs: jobsDropdownResource,
        getJobsLoaded: true,
        getJobsLoading: false,
      };

    case ObjectActionsAndTypes.FETCH_ERROR:
      return {
        ...state,
        getWorkOrderScheduleListLoading: false,
        getWorkOrderForWorkOrderFormLoading: false,
        getWorkOrderFormResourcesLoading: false,
        saveWorkOrderAddFormLoading: false,
        getSitesLoading: false,
        getLinesLoading: false,
        getProductsLoading: false,
        getProductionStagesLoading: false,
        isWorkOrderDeleteLoading: false,
        isWorkOrderEditLoading: false,
        getJobsLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
