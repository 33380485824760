import { Action } from '@ngrx/store';
import {
  GetWorkOrderResponseInterface,
  SitesResponseInterface,
  ProductsResponseInterface,
  ProductionStagesResponseInterface,
  WorkOrderEditCreateResponseInterface,
  FormattedWorkOrdersResponseInterface,
  WorkOrderInterface,
  BulkEditResponseInterface,
  RecalculateResponseInterface, WorkOrderScheduleCustomSectionInterface,
} from './work-order-schedule.model';
import { TabRowInterface } from '../../shared/component/side-config-bar/side-config-bar.model';
import { WorkOrderDeleteResponseInterface } from '../../shared/service/work-order-schedule/work-order-schedule.model';
import {
  DynamicDateFilterParameterInterface,
  WorkOrdersTableQueryParams,
  CustomNewDesignWorkOrdersFilterOutputInterface,
  CustomWorkOrdersFilterOutputInterface,
  WorkOrderBulkSaveManyInterface,
  WorkOrderScheduleSaveOneDtoInterface,
  BulkEditFieldInterface,
} from '../../view/work-orders-new-design/work-orders-new-design.model';
import { LineCRUDResponseInterface } from '../../shared/service/filter/service.class';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface
} from '../../shared/model/interface/crud-response-interface.model';
import { IJob } from '../settings/jobs/jobs.model';

export const GET_WORK_ORDER_SCHEDULE_LIST = '[WORK_ORDER_SCHEDULE] Get work order schedule list';
export const GET_WORK_ORDER_SCHEDULE_LIST_COMPLETED = '[WORK_ORDER_SCHEDULE] Get work order schedule list completed';
export const SET_TABLE_SETTINGS = '[WORK_ORDER_SCHEDULE] Set table settings';
export const GET_WORK_ORDER = '[WORK_ORDER_SCHEDULE] Get work order';
export const GET_WORK_ORDER_COMPLETED = '[WORK_ORDER_SCHEDULE] Get work order completed';
export const GET_SITES = '[WORK_ORDER_SCHEDULE] Get sites';
export const GET_SITES_COMPLETED = '[WORK_ORDER_SCHEDULE] Get sites completed';
export const GET_LINES = '[WORK_ORDER_SCHEDULE] Get lines';
export const GET_LINES_COMPLETED = '[WORK_ORDER_SCHEDULE] Get lines completed';
export const GET_PRODUCTS = '[WORK_ORDER_SCHEDULE] Get products';
export const GET_PRODUCTS_COMPLETED = '[WORK_ORDER_SCHEDULE] Get products completed';
export const GET_PRODUCTION_STAGES = '[WORK_ORDER_SCHEDULE] Get production stages';
export const GET_PRODUCTION_STAGES_COMPLETED = '[WORK_ORDER_SCHEDULE] Get production stages completed';
export const SAVE_WORK_ORDER_ADD_FORM = '[WORK_ORDER_SCHEDULE] Save work order add form';
export const SAVE_WORK_ORDER_ADD_FORM_COMPLETED = '[WORK_ORDER_SCHEDULE] Save work order add form completed';
export const DELETE_WORK_ORDER = '[WORK_ORDER_SCHEDULE] Delete work order(s)';
export const DELETE_WORK_ORDER_COMPLETED = '[WORK_ORDER_SCHEDULE] Delete work order(s) completed';
export const EDIT_WORK_ORDER = '[WORK_ORDER_SCHEDULE] Edit work order';
export const EDIT_WORK_ORDER_COMPLETED = '[WORK_ORDER_SCHEDULE] Edit work order completed';
export const BULK_EDIT_WORK_ORDER = '[WORK_ORDER_SCHEDULE] Bulk Edit work orders';
export const BULK_EDIT_WORK_ORDER_COMPLETED = '[WORK_ORDER_SCHEDULE] Bulk Edit work order completed';
export const RECALCULATE_WORK_ORDER = '[WORK_ORDER_SCHEDULE] Recalculate work orders';
export const RECALCULATE_WORK_ORDER_COMPLETED = '[WORK_ORDER_SCHEDULE] Recalculate work order completed';
export const SET_SELECTED_REGULAR_MODE_FILTERS = '[WORK_ORDER_SCHEDULE] Set Selected Regular Mode Filters';
export const SET_SELECTED_DASHBOARD_MODE_FILTERS = '[WORK_ORDER_SCHEDULE] Set Selected Dashboard Mode Filters';
export const DOWNLOAD_EXCEL = '[WORK_ORDER_SCHEDULE] DOWNLOAD EXCEL';
export const DOWNLOAD_EXCEL_COMPLETED = '[WORK_ORDER_SCHEDULE] DOWNLOAD EXCEL COMPLETED';
export const UPLOAD_EXCEL = '[WORK_ORDER_SCHEDULE] UPLOAD EXCEL';
export const UPLOAD_EXCEL_COMPLETED = '[WORK_ORDER_SCHEDULE] UPLOAD EXCEL COMPLETED';
export const CLEAR_STATE = '[WORK_ORDER_SCHEDULE] Clear State';
export const CLEAR_WORK_ORDER_FORM_RESOURCES_STATE = '[WORK_ORDER_SCHEDULE] Clear Work Order Form Resources State';
export const GET_JOBS = '[WORK_ORDER_SCHEDULE] Get jobs';
export const GET_JOBS_COMPLETED = '[WORK_ORDER_SCHEDULE] Get jobs completed';
export const CREATE_NEW_JOB = '[WORK_ORDER_SCHEDULE] Create new job';
export const CREATE_NEW_JOB_COMPLETED = '[WORK_ORDER_SCHEDULE] Create new job completed';
export const FETCH_ERROR = '[WORK_ORDER_SCHEDULE] Fetch Error';

export class GetWorkOrderScheduleList implements Action {
  readonly type = GET_WORK_ORDER_SCHEDULE_LIST;
  constructor(
    public filters: CustomWorkOrdersFilterOutputInterface | CustomNewDesignWorkOrdersFilterOutputInterface,
    public query: WorkOrdersTableQueryParams = {
      page: 1,
      pageSize: 10,
    },
    public newDesign: boolean,
    public dynamicDateFilterParameters: DynamicDateFilterParameterInterface,
  ) {}
}

export class GetWorkOrderScheduleListCompleted implements Action {
  readonly type = GET_WORK_ORDER_SCHEDULE_LIST_COMPLETED;
  constructor(public payload: FormattedWorkOrdersResponseInterface) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;
  constructor(
    public payload: TabRowInterface[],
    public setAsDefault: boolean,
    public customSections: WorkOrderScheduleCustomSectionInterface) {}
}

export class GetWorkOrder implements Action {
  readonly type = GET_WORK_ORDER;
  constructor(public workOrderId: number) {}
}

export class GetWorkOrderCompleted implements Action {
  readonly type = GET_WORK_ORDER_COMPLETED;
  constructor(public payload: GetWorkOrderResponseInterface) {}
}

export class GetSites implements Action {
  readonly type = GET_SITES;
}

export class GetSitesCompleted implements Action {
  readonly type = GET_SITES_COMPLETED;
  constructor(public payload: SitesResponseInterface) {}
}

export class GetLines implements Action {
  readonly type = GET_LINES;
  constructor(public siteId?: number) {}
}

export class GetLinesCompleted implements Action {
  readonly type = GET_LINES_COMPLETED;
  constructor(public payload: LineCRUDResponseInterface) {}
}

export class GetProducts implements Action {
  readonly type = GET_PRODUCTS;
  constructor(public siteId: number, public searchText: string = null, public productId: number = null) {}
}

export class GetProductsCompleted implements Action {
  readonly type = GET_PRODUCTS_COMPLETED;
  constructor(public payload: ProductsResponseInterface) {}
}

export class GetProductionStages implements Action {
  readonly type = GET_PRODUCTION_STAGES;
}

export class GetProductionStagesCompleted implements Action {
  readonly type = GET_PRODUCTION_STAGES_COMPLETED;
  constructor(public payload: ProductionStagesResponseInterface) {}
}

export class SaveWorkOrderAddForm implements Action {
  readonly type = SAVE_WORK_ORDER_ADD_FORM;
  constructor(public workOrderAddForm: Partial<WorkOrderInterface>) {}
}

export class SaveWorkOrderAddFormCompleted implements Action {
  readonly type = SAVE_WORK_ORDER_ADD_FORM_COMPLETED;
  constructor(public payload: WorkOrderEditCreateResponseInterface) {}
}

export class DeleteWorkOrder implements Action {
  readonly type = DELETE_WORK_ORDER;
  constructor(public payload: number[]) {}
}

export class DeleteWorkOrderCompleted implements Action {
  readonly type = DELETE_WORK_ORDER_COMPLETED;
  constructor(public deletedWorkOrders: number[], public payload: WorkOrderDeleteResponseInterface) {}
}

export class BulkEditWorkOrder implements Action {
  readonly type = BULK_EDIT_WORK_ORDER;
  constructor(public payload: BulkEditFieldInterface[], public newDesign: boolean = false) {}
}

export class EditWorkOrder implements Action {
  readonly type = EDIT_WORK_ORDER;
  constructor(public payload: Partial<WorkOrderInterface>, public workOrderId: number) {}
}

export class EditWorkOrderCompleted implements Action {
  readonly type = EDIT_WORK_ORDER_COMPLETED;
  constructor(public response: WorkOrderEditCreateResponseInterface) {}
}

export class BulkEditWorkOrderCompleted implements Action {
  readonly type = BULK_EDIT_WORK_ORDER_COMPLETED;
  constructor(public editedWorkOrders: BulkEditFieldInterface[], public response: BulkEditResponseInterface) {}
}

export class SetSelectedRegularModeFilters implements Action {
  readonly type = SET_SELECTED_REGULAR_MODE_FILTERS;
  constructor(public filters: Partial<CustomWorkOrdersFilterOutputInterface>) {}
}

export class SetSelectedDashboardFilters implements Action {
  readonly type = SET_SELECTED_DASHBOARD_MODE_FILTERS;
  constructor(public filters: Partial<CustomWorkOrdersFilterOutputInterface>) {}
}

export class RecalculateWorkOrder implements Action {
  readonly type = RECALCULATE_WORK_ORDER;
  constructor(public payload: number[]) {}
}

export class RecalculateWorkOrderCompleted implements Action {
  readonly type = RECALCULATE_WORK_ORDER_COMPLETED;
  constructor(
    public recalculatedWorkOrders: number[],
    public response: RecalculateResponseInterface,
  ) {}
}

export class DownloadExcel implements Action {
  readonly type = DOWNLOAD_EXCEL;

  constructor(
    public siteId: number,
    public downloadOffsetValue: number = 1,
    public withData: boolean = false,
    public filters?: CustomWorkOrdersFilterOutputInterface | CustomNewDesignWorkOrdersFilterOutputInterface,
    public query?: WorkOrdersTableQueryParams,
    public dynamicDateFilterParameters?: DynamicDateFilterParameterInterface,
  ) {}
}

export class DownloadExcelCompleted implements Action {
  readonly type = DOWNLOAD_EXCEL_COMPLETED;
}

export class UploadExcel implements Action {
  readonly type = UPLOAD_EXCEL;

  constructor(public workOrders: WorkOrderBulkSaveManyInterface) {}
}

export class UploadExcelCompleted implements Action {
  readonly type = UPLOAD_EXCEL_COMPLETED;

  constructor(public payload: (WorkOrderScheduleSaveOneDtoInterface & { errorMessages?: string })[]) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export class ClearWorkOrderFormResourcesState implements Action {
  readonly type = CLEAR_WORK_ORDER_FORM_RESOURCES_STATE;
}

export class GetJobs implements Action {
  readonly type = GET_JOBS;

  constructor(public readonly siteId: number, public readonly searchText: string = null, public readonly jobId: number = null) {}
}

export class GetJobsCompleted implements Action {
  readonly type = GET_JOBS_COMPLETED;
  constructor(public payload: GetManyResponseInterface<IJob>) {}
}

export class CreateNewJob implements Action {
  readonly type = CREATE_NEW_JOB;

  constructor(public readonly job: Partial<IJob>) {}
}

export class CreateNewJobCompleted implements Action {
  readonly type = CREATE_NEW_JOB_COMPLETED;

  constructor(public readonly job: IJob) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: any) {}
}

export type WorkOrderScheduleActions =
  | GetWorkOrderScheduleList
  | GetWorkOrderScheduleListCompleted
  | SetTableSettings
  | GetWorkOrder
  | GetWorkOrderCompleted
  | GetSites
  | GetSitesCompleted
  | GetLines
  | GetLinesCompleted
  | GetProducts
  | GetProductsCompleted
  | GetProductionStages
  | GetProductionStagesCompleted
  | SaveWorkOrderAddForm
  | SaveWorkOrderAddFormCompleted
  | DeleteWorkOrder
  | DeleteWorkOrderCompleted
  | EditWorkOrder
  | EditWorkOrderCompleted
  | BulkEditWorkOrder
  | BulkEditWorkOrderCompleted
  | SetSelectedRegularModeFilters
  | SetSelectedDashboardFilters
  | DownloadExcel
  | DownloadExcelCompleted
  | UploadExcel
  | UploadExcelCompleted
  | RecalculateWorkOrder
  | RecalculateWorkOrderCompleted
  | ClearState
  | ClearWorkOrderFormResourcesState
  | GetJobs
  | GetJobsCompleted
  | CreateNewJob
  | CreateNewJobCompleted
  | FetchError;
