import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { BaseOneResponseInterface } from '../../../model/interface/crud-response-interface.model';
import { SiteViewLineDataInterface } from '../../../../store/global-view/site-view/site-view.model';

@Injectable({
  providedIn: 'root',
})
export class SiteViewService {
  private readonly routes = {
    siteViewData: `${this.baseUrl}/lines/site-view-data`,
    image: `${this.baseUrl}/floor-plans/image`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  public getSiteViewLineData(siteId: number, lineIds: number[]) {
    return this.http.post(
      this.routes.siteViewData,
      { siteId, lineIds },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getFloorPlanImage(imagePath: string) {
    return this.http.get<BaseOneResponseInterface<string>>(`${this.routes.image}/${imagePath}`);
  }
}
