import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  EDependentBehavior,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  AddProductSpeedInterface,
  AddProductSpeedResponseInterface,
  BulkEditProductSpeedInterface,
  BulkEditProductSpeedResponseInterface,
  EditProductSpeedInterface,
  EditProductSpeedResponseInterface,
  ProductSpeedBulkSaveManyInterface,
  ProductSpeedDeleteResponseInterface,
  ProductSpeedInterface,
  ProductSpeedsDownloadExcelFiltersInterface,
  ProductSpeedTableQueryParams,
} from './product-speeds.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import * as _ from 'lodash';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import { SiteService } from '../../../shared/service/filter/site.service';
import { takeUntil } from 'rxjs/operators';
import * as ProductSpeedsActions from './product-speeds.actions';
import { ProductsService } from '../products/products.service';
import * as AppActions from '../../app/actions';
import { HelperService } from '../../../shared/service/helper.service';
import { ProductInterface } from '../products/products.model';
import {
  LineCRUDInterface,
  ProductCRUDInterface,
  SiteCRUDInterface,
} from '../../../shared/component/filter/filter.class';
import { LineService } from '../../../shared/service/line/line.service';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';

@Injectable({
  providedIn: 'root',
})
export class ProductSpeedsService {
  private readonly PRODUCT_SPEEDS = {
    SITE_URL: `${this.baseUrl}/sites`,
    PRODUCT_SPEED_URL: `${this.baseUrl}/product-speeds`,
    PRODUCT_SPEED_BULK_SAVE_URL: `${this.baseUrl}/product-speeds/bulk/save`,
  };
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private userLanguage$: string = '';
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly routes = {
    productSpeeds: `${this.baseUrl}/product-speeds`,
    bulkEdit: `${this.baseUrl}/product-speeds/bulk/edit`,
    bulkDelete: `${this.baseUrl}/product-speeds/bulk/delete`,
  };

  constructor(
    public readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly productsService: ProductsService,
    private readonly helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.userLanguage$ = state.language;
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getProductSpeeds(params: HttpParams): Observable<GetManyResponseInterface<ProductSpeedInterface>> {
    return this.http.get<GetManyResponseInterface<ProductSpeedInterface>>(this.PRODUCT_SPEEDS.PRODUCT_SPEED_URL, {
      params,
    });
  }

  public downloadProductSpeedExcel(
    withData: boolean,
    filters: ProductSpeedsDownloadExcelFiltersInterface,
    httpParams: HttpParams,
    withErrorColumn: boolean = false,
    data?: ProductSpeedInterface[],
  ): void {
    this.store.dispatch(new AppActions.ShowLoader());

    forkJoin(this.getProductSpeedObservables(withData, filters, httpParams, data)).subscribe((responseList) => {
      const sites: SiteCRUDInterface[] = _.get(responseList, '0.data', []);
      const lines: LineCRUDInterface[] = _.get(responseList, '1.data', []).sort(
        this.helperService.dropdownOptionCompareFunction,
      );
      const products: ProductCRUDInterface[] = _.get(responseList, '2.data', []);
      const sheetTitle: string = this.translate.instant('pageTitles.productSpeeds');
      const excelName: string = `${sheetTitle} ${moment()
        .tz(this.timezone)
        .format(this.dateFormat$)}`;
      let excelData: ProductSpeedInterface[] = [];

      if (withData) {
        excelData = _.get(responseList, '3.data', []);

        for (const productSpeed of excelData) {
          const siteData: SiteCRUDInterface = _.find(sites, { id: productSpeed.siteId });
          productSpeed.speed = productSpeed.speed ? this.decimalHelper.removeTrailingZeros(productSpeed.speed) : null;

          productSpeed.site = {
            id: siteData.id,
            name: siteData.name,
            decimalScaleLimit: siteData.decimalScaleLimit,
          };

          productSpeed.line = {
            ...productSpeed.line,
            ...ExcelHelperService.generateDependentOptionProperties(
              productSpeed.line.id,
              productSpeed.line.title,
              siteData.id,
              siteData.name,
            ),
          };

          productSpeed.product = {
            ...productSpeed.product,
            ...ExcelHelperService.generateDependentOptionProperties(
              productSpeed.product.id,
              productSpeed.product.productId,
              siteData.id,
              siteData.name,
            ),
          };
        }

        if (data) {
          for (const productSpeed of data) {
            productSpeed.site = _.find(sites, { id: productSpeed.siteId });
            productSpeed.line = _.find(lines, { id: productSpeed.lineId });
            productSpeed.product = _.find(products, { id: productSpeed.productId });
            productSpeed.speed = productSpeed.speed ? this.decimalHelper.removeTrailingZeros(productSpeed.speed) : null;

            if (productSpeed.lineId) {
              productSpeed.line = {
                ...productSpeed.line,
                ...ExcelHelperService.generateDependentOptionProperties(
                  productSpeed.line.id,
                  productSpeed.line.title,
                  productSpeed.site?.id,
                  productSpeed.site?.name,
                ),
              };
            }

            if (productSpeed.productId) {
              productSpeed.product = {
                ...productSpeed.product,
                ...ExcelHelperService.generateDependentOptionProperties(
                  productSpeed.product.id,
                  productSpeed.product.productId,
                  productSpeed.site?.id,
                  productSpeed.site?.name,
                ),
              };
            }
          }

          excelData = data;
        }
      }

      const linesWithExcelId: LineCRUDInterface[] = ExcelHelperService.generateDependentOptions(
        { data: lines, key: 'siteId', label: 'title' },
        { data: sites, key: 'id', label: 'name' },
        EDependentBehavior.MAP,
      );

      const productsWithExcelId: ProductCRUDInterface[] = ExcelHelperService.generateDependentOptions(
        { data: products, key: 'siteId', label: 'productId' },
        { data: sites, key: 'id', label: 'name' },
        EDependentBehavior.MAP,
      );

      const excelOptions: CreateExcelInterface = this.getProductSpeedExcelColumns(
        sites,
        linesWithExcelId,
        productsWithExcelId,
        withErrorColumn,
      );

      if (withData) {
        excelOptions.data = excelData;
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          sheetTitle,
          withData,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
          excelRowFormatLimit: 5001,
        },
      ];

      this.excelHelper
        .createExcel(excelName, worksheets, this.timezone, this.dateFormat$, this.timeFormat$, false)
        .then(
          () => {
            this.store.dispatch(new ProductSpeedsActions.DownloadProductSpeedExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new ProductSpeedsActions.FetchError({}));
            this.store.dispatch(new AppActions.HideLoader());
          },
        );
    });
  }

  private getProductSpeedObservables(
    withData: boolean,
    filters: ProductSpeedsDownloadExcelFiltersInterface,
    httpParams: HttpParams,
    data?: ProductSpeedInterface[],
  ): [
    Observable<GetManyResponseInterface<SiteCRUDInterface>>,
    Observable<GetManyResponseInterface<LineCRUDInterface>>,
    Observable<GetManyResponseInterface<ProductInterface>>,
    Observable<GetManyResponseInterface<ProductSpeedInterface>>?,
  ] {
    const siteHttpParams: HttpParams = new HttpParams()
      .set('s', JSON.stringify({ id: { $in: filters.siteIds === -1 ? undefined : filters.siteIds } }))
      .set('limit', filters.limit);
    const lineHttpParams: HttpParams = new HttpParams()
      .set('s', JSON.stringify({ siteId: { $in: filters.siteIds === -1 ? undefined : filters.siteIds } }))
      .set('limit', filters.limit);
    const productHttpParams: HttpParams = new HttpParams()
      .set('s', JSON.stringify({ siteId: { $in: filters.siteIds === -1 ? undefined : filters.siteIds } }))
      .set('limit', 10000);

    const observables: [
      Observable<GetManyResponseInterface<SiteCRUDInterface>>,
      Observable<GetManyResponseInterface<LineCRUDInterface>>,
      Observable<GetManyResponseInterface<ProductInterface>>,
      Observable<GetManyResponseInterface<ProductSpeedInterface>>?,
    ] = [
      this.siteService.getSites(siteHttpParams),
      this.lineService.getLines(lineHttpParams),
      this.productsService.getProducts(productHttpParams),
    ];

    if (withData && !data) {
      observables.push(
        this.getProductSpeeds(httpParams.set('limit', filters.limit).set('page', filters.selectedDownloadOffset)),
      );
    }

    return observables;
  }

  private getProductSpeedExcelColumns(
    sites: SiteCRUDInterface[],
    lines: LineCRUDInterface[],
    products: ProductCRUDInterface[],
    withErrorColumn: boolean,
  ): CreateExcelInterface {
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('products.excel.siteId.header'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: sites,
            prop: 'site.name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('reports.checkInLogs.tableColumn.line'),
          key: 'lineId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: lines,
            prop: 'excelLabel',
            dataProperty: 'line.excelLabel',
            dataId: 'line.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('productSpeeds.excel.product.header'),
          key: 'productId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: products,
            prop: 'excelLabel',
            dataProperty: 'product.excelLabel',
            dataId: 'product.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('productSpeeds.excel.productSpeed.header'),
          key: 'speed',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
          isDecimalNumber: true,
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getProductSpeedsFromExcel(
    file: File,
  ): Promise<{
    siteData: { id: number; name: string }[];
    productSpeedData: { productSpeeds: ProductSpeedInterface[] };
  } | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const productSpeedSheet: Worksheet = workbook.getWorksheet(this.translate.instant('pageTitles.productSpeeds'));
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');

    if (!productSpeedSheet || !siteIdDataSheet) {
      return null;
    }

    const siteColumns = {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    };

    const sites: { id: number; name: string }[] = this.excelHelper.getExcelRowsFromWorkSheet<{
      id: number;
      name: string;
    }>(siteIdDataSheet, siteColumns);

    if (!sites.length) {
      return null;
    }

    const { columns } = this.getProductSpeedExcelColumns(null, null, null, false);
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);

    return {
      productSpeedData: {
        productSpeeds: this.excelHelper.getExcelRowsFromWorkSheet<ProductSpeedInterface>(
          productSpeedSheet,
          columnKeys,
          {
            dateFormat: this.dateFormat$,
            timeFormat: this.timeFormat$,
            timezone: this.timezone,
          },
        ),
      },
      siteData: sites,
    };
  }

  public uploadExcel(productSpeeds: ProductSpeedBulkSaveManyInterface): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.PRODUCT_SPEEDS.PRODUCT_SPEED_BULK_SAVE_URL, productSpeeds);
  }

  public deleteProductSpeeds(productSpeed: number[] | number): Observable<ProductSpeedDeleteResponseInterface> {
    if (Array.isArray(productSpeed) && productSpeed.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          productSpeeds: productSpeed,
        },
      };
      return this.http.delete<ProductSpeedDeleteResponseInterface>(`${this.routes.bulkDelete}`, httpOptions);
    }
    return this.http.delete<ProductSpeedDeleteResponseInterface>(`${this.routes.productSpeeds}/${productSpeed[0]}`);
  }

  public addProductSpeed(productSpeed: AddProductSpeedInterface): Observable<AddProductSpeedResponseInterface> {
    return this.http.post<AddProductSpeedResponseInterface>(`${this.routes.productSpeeds}`, productSpeed);
  }

  public editProductSpeed(
    productSpeed: EditProductSpeedInterface,
    productSpeedId: number,
  ): Observable<EditProductSpeedResponseInterface> {
    return this.http.patch<EditProductSpeedResponseInterface>(
      `${this.routes.productSpeeds}/${productSpeedId}`,
      productSpeed,
    );
  }

  public bulkEditProductSpeed(
    productSpeeds: BulkEditProductSpeedInterface[],
  ): Observable<BulkEditProductSpeedResponseInterface> {
    return this.http.patch<BulkEditProductSpeedResponseInterface>(this.routes.bulkEdit, { productSpeeds });
  }

  public prepareProductSpeedsHttpParams(query: ProductSpeedTableQueryParams): HttpParams {
    let params = new HttpParams();

    params = params
      .append('join', 'site||name,decimalScaleLimit')
      .append('join', 'line||title')
      .append('join', 'product||productId,description,unit')
      .append('page', String(query.page))
      .append('limit', String(query.pageSize || 1000))
      .append('sort', 'id,DESC');

    const filter: any = {};
    let search: any[] = [];

    if (query.search) {
      search = [
        { 'site.name': { $cont: query.search } },
        { 'line.title': { $cont: query.search } },
        { 'product.description': { $cont: query.search } },
        { 'product.productId': { $cont: query.search } },
        { speed: { $cont: query.search } },
      ];
    }

    if (query.siteIds !== -1 && query.siteIds !== undefined) {
      filter.siteId = { $in: query.siteIds };
    }

    if (query.lineId !== -1 && query.lineId !== undefined) {
      filter.lineId = { $in: query.lineId };
    }

    if (query.productId !== -1 && query.productId !== undefined) {
      filter.productId = { $in: query.productId };
    }

    if (query.search) {
      filter.$or = search;
    }

    if (!_.isNil(query.search) && query.search !== '') {
      params = params.append('searchText', String(query.search));
    }

    params = params.append('s', JSON.stringify(filter));

    if (query.orderDesc !== undefined) {
      const direction = query.orderDesc ? 'DESC' : 'ASC';
      params = params.set('sort', `${query.orderBy},${direction}`);
    }

    return params;
  }
}
