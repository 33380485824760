import { GenericCrudRequestConstructionParameters } from '../../model/interface/generic-api-request.model';
import { HttpParams } from '@angular/common/http';
import { FieldTypes } from '../../component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../component/filter/advanced-filter/advanced-filter.service';
import * as _ from 'lodash';
import { IGenericObject } from '../../model/interface/generic.model';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HTTPHelperService {
  constructor(private readonly advancedFilterService: AdvancedFilterService) {}

  public static getSuccessAndFailedFromBulkResponse<T>(response: T[]): { fail: T[]; success: T[] } {
    return response.reduce(
      (filtered: { fail: T[]; success: T[] }, data) => {
        filtered[_.get(data, 'errorMessages') ? 'fail' : 'success'].push(data);

        return filtered;
      },
      { fail: [], success: [] },
    );
  }

  public static createHttpParamsWithObject(
    parameters: IGenericObject<any>,
    arrayJoinSeparator: string = ',',
  ): HttpParams {
    let httpParams: HttpParams = new HttpParams();

    if (typeof parameters === 'object' && !_.isNil(parameters)) {
      for (const [key, value] of Object.entries(parameters)) {
        httpParams = httpParams.append(
          key,
          Array.isArray(value) ? value.join(arrayJoinSeparator) : (value as string | number | boolean),
        );
      }
    }

    return httpParams;
  }

  public insertGenericCrudRequestParameters(parameters: GenericCrudRequestConstructionParameters): HttpParams {
    let params: HttpParams = new HttpParams();

    if (parameters.page) {
      params = params.set('page', String(parameters.page));
    }

    if (parameters.perPage) {
      params = params.set('per_page', String(parameters.perPage));
    }

    if (parameters.limit) {
      params = params.set('limit', String(parameters.limit));
    }

    if (parameters.fields?.length) {
      params = params.set('fields', parameters.fields.join(','));
    }

    for (const sort of parameters.sort ?? []) {
      params = params.append('sort', `${sort.column},${sort.type === 'descending' ? 'DESC' : 'ASC'}`);
    }

    if (parameters.join?.length) {
      for (const join of parameters.join) {
        params = params.append('join', join);
      }
    }

    return this.prepareSearchParamsFilter(parameters, params);
  }

  private prepareSearchParamsFilter(
    parameters: GenericCrudRequestConstructionParameters,
    params: HttpParams,
  ): HttpParams {
    let searchCondition = [];

    if (parameters.additionalCustomSearch) {
      searchCondition = _.cloneDeep(parameters.additionalCustomSearch);
    }

    if (parameters.search?.searchText?.length) {
      searchCondition.push({
        $or: parameters.search.searchedFields.map((field: string) => {
          return {
            [field]: { $cont: parameters.search.searchText },
          };
        }),
      });
    }

    const filterCondition =
      parameters.filters?.map((filter) => {
        return { [filter.field]: { $in: filter.ids } };
      }) ?? [];

    const combinedConditions: { $and: { [key: string]: any }[] } = {
      $and: [...filterCondition, ...searchCondition],
    };

    if (parameters.advancedFilter) {
      const advancedFilter = parameters.advancedFilter.filters;

      for (const filter of advancedFilter) {
        if (filter.type === FieldTypes.predefined) {
          combinedConditions.$and.push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              parameters.advancedFilter.target,
              _.get(filter.value, `[0][${filter.searchBy}]`, ''),
            ) as { [key: string]: any },
          );
          continue;
        }

        combinedConditions.$and.push(
          this.advancedFilterService.generateQuery(
            filter.path,
            filter.type,
            filter.operator.name,
            filter.operator.type,
            parameters.advancedFilter.target,
            filter.value,
          ) as { [key: string]: any },
        );
      }
    }

    return combinedConditions.$and.length ? params.set('s', JSON.stringify(combinedConditions)) : params;
  }
}
