<div class="modal-header scw-modal-header">
  <h4 class="modal-title">
    {{
      (objectIds && objectIds.length > 1
        ? 'activityCommentModal.headers.bulkInsert'
        : mode === 'requiredComment'
        ? 'activityCommentModal.actions.addComment'
        : 'activityCommentModal.headers.commentFeed'
      ) | translate
    }}
  </h4>

  <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="closeModal()">
    <i aria-hidden class="fas fa-times"></i>
  </button>
</div>

<div class="modal-body d-flex flex-column">
  <h6 *ngIf="activityName" class="scw-header mb-3"><span class="fas fa-chart-line"></span> {{ activityName }}</h6>
  <h6 *ngIf="objectType === 'lines'" class="scw-header mb-3 text-secondary">
    {{ modalOpenedAt | momentDate : (userDateTimeFormat$ | async) }}
  </h6>

  <scw-mat-form [hidden]="readOnly" (scwMatFormSubmit)="submitCreationForm($event)">
    <div class="container p-0">
      <div class="row">
        <div class="col">
          <scw-mat-select
            [autoPosition]="false"
            [label]="(tagDropdownLabel$ | async) ?? ('activityCommentModal.label.commentTag' | translate)"
            position="bottom"
            [cItemTemplate]="tagTemplate"
            [data]="(tags$ | async) ?? []"
            [disabled]="
              (busy$ | async) || (commentToEdit$ | async) !== null || (tagsLoadStatus$ | async) !== LoadStatus.Success
            "
            [preserveItemStyle]="true"
            [singleSelection]="false"
            [(inputModel)]="addFormTags"
          ></scw-mat-select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <scw-mat-textarea
            label="{{ 'activityCommentModal.label.comment' | translate }}*"
            [block]="true"
            [disabled]="(busy$ | async) || (commentToEdit$ | async) !== null"
            [rows]="5"
            [rules]="commentMessageFormRules"
            [(inputModel)]="addFormComment"
          ></scw-mat-textarea>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-end">
          <scw-mat-button
            #addSubmitButton
            [hidden]="objectIds && objectIds.length > 1"
            [disabled]="(busy$ | async) || (commentToEdit$ | async) !== null"
            [isSubmitButton]="true"
          >
            <ng-container *ngIf="(formProcess$ | async) === 'adding'; else defaultText">
              <span class="spinner-border spinner-border-sm" aria-hidden></span>
              {{ 'activityCommentModal.info.adding' | translate }}
            </ng-container>
            <ng-template #defaultText>
              {{
                (mode === 'feed' ? 'activityCommentModal.actions.addComment' : 'activityCommentModal.actions.saveClose')
                  | translate
              }}
            </ng-template>
          </scw-mat-button>
        </div>
      </div>
    </div>
  </scw-mat-form>

  <ng-container *ngIf="objectIds?.length === 1">
    <scw-mat-modal-separator *ngIf="!readOnly" aria-role="separator"></scw-mat-modal-separator>

    <div id="comment-feed" class="d-flex flex-column">
      <h6 *ngIf="!readOnly" class="scw-header mb-3">
        <em class="fas fa-file-alt"></em>&nbsp;{{ 'activityCommentModal.subHeaders.activityCommentFeed' | translate }}
      </h6>
      <div class="mnh-scrollable overflow-auto mx-n3">
        <div class="container">
          <ng-container [ngSwitch]="commentsLoadStatus$ | async">
            <div *ngSwitchCase="LoadStatus.Loading" class="row">
              <div class="col">
                <p>{{ 'activityCommentModal.info.loading' | translate }}</p>
              </div>
            </div>

            <ng-container *ngSwitchCase="LoadStatus.Success">
              <ng-container *ngIf="comments$ | async as comments">
                <ng-container *ngIf="comments.length">
                  <ng-container
                    *ngFor="let comment of comments; let last = last; let index = index; trackBy: trackById"
                  >
                    <div class="row mb-3">
                      <div *ngIf="(commentToEdit$ | async)?.id !== comment.id; else editTemplate" class="col">
                        <div *ngIf="comment.commentTags?.length" class="row">
                          <div class="col">
                            <p class="mb-1 py-1">
                              <ng-container *ngFor="let tag of comment.commentTags">
                                <ng-template *ngTemplateOutlet="tagTemplateForComments; context: { data: tag }"> </ng-template>
                              </ng-container>
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col d-flex align-items-center">
                            <div class="flex-grow-1">
                              <p class="mb-0">
                                <strong class="mr-2">{{
                                  comment.created.fullName ?? ('activityCommentModal.error.unknownUser' | translate)
                                }}</strong>
                                <small
                                  *ngIf="comment.commentDate ?? comment.created.timestamp as timestamp"
                                  class="text-muted"
                                >
                                  {{
                                    timestamp
                                      | momentDate
                                        : (userDateTimeFormat$ | async) ??
                                            ('activityCommentModal.info.loadingDate' | translate)
                                  }}
                                </small>
                              </p>
                            </div>
                            <div *ngIf="mode === 'feed' && !readOnly" class="d-flex">
                              <scw-mat-button
                                class="ml-2"
                                type="standard"
                                size="xs"
                                [disabled]="
                                  (busy$ | async) ||
                                  (tagsLoadStatus$ | async) !== LoadStatus.Success ||
                                  (commentToEdit$ | async) !== null
                                "
                                [iconButton]="true"
                                (onClick)="commentFeedStore.setCommentToEdit(comment)"
                              >
                                <span class="fas fa-pen align-baseline"></span>
                              </scw-mat-button>
                              <scw-mat-button
                                class="ml-2"
                                type="standard"
                                size="xs"
                                [disabled]="(busy$ | async) || (commentToEdit$ | async) !== null"
                                [iconButton]="true"
                                (onClick)="commentFeedStore.setCommentToDeleteIndex(index)"
                              >
                                <span
                                  *ngIf="
                                    (formProcess$ | async) !== 'deleting' || (commentToDeleteIndex$ | async) !== index;
                                    else deletingSpinner
                                  "
                                  class="fas fa-trash align-baseline"
                                ></span>
                                <ng-template #deletingSpinner>
                                  <span class="spinner-border spinner-border-sm" aria-hidden></span>
                                </ng-template>
                              </scw-mat-button>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <p class="mt-2 mb-0">{{ comment.commentMessage }}</p>
                          </div>
                        </div>
                      </div>
                      <ng-template #editTemplate>
                        <scw-mat-form (scwMatFormSubmit)="submitEditForm($event, index)">
                          <div class="col">
                            <div class="row">
                              <div class="col d-flex align-items-end">
                                <scw-mat-select
                                  [autoPosition]="false"
                                  class="flex-grow-1"
                                  [label]="
                                    (tagDropdownLabel$ | async) ?? ('activityCommentModal.label.commentTag' | translate)
                                  "
                                  position="bottom"
                                  size="sm"
                                  [cItemTemplate]="tagTemplate"
                                  [data]="(tags$ | async) ?? []"
                                  [disabled]="(busy$ | async) || (tagsLoadStatus$ | async) !== LoadStatus.Success"
                                  [preserveItemStyle]="true"
                                  [singleSelection]="false"
                                  [(inputModel)]="editFormTags"
                                ></scw-mat-select>
                                <div class="h-field mb-3 d-flex align-items-center">
                                  <scw-mat-button
                                    class="ml-2"
                                    type="standard"
                                    size="xs"
                                    [disabled]="(busy$ | async) === true"
                                    [iconButton]="true"
                                    (onClick)="commentFeedStore.unsetCommentToEdit()"
                                  >
                                    <span class="fas fa-times align-baseline"></span>
                                  </scw-mat-button>
                                  <scw-mat-button
                                    class="ml-2"
                                    type="cta"
                                    size="xs"
                                    [disabled]="(busy$ | async) === true"
                                    [iconButton]="true"
                                    [isSubmitButton]="true"
                                  >
                                    <span
                                      *ngIf="(formProcess$ | async) !== 'editing'; else editingSpinner"
                                      class="fas fa-save align-baseline"
                                    ></span>
                                    <ng-template #editingSpinner>
                                      <span class="spinner-border spinner-border-sm" aria-hidden></span>
                                    </ng-template>
                                  </scw-mat-button>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <scw-mat-textarea
                                  label="{{ 'activityCommentModal.label.comment' | translate }}*"
                                  class="f-13"
                                  [block]="true"
                                  [disabled]="(busy$ | async) === true"
                                  [rows]="5"
                                  [rules]="commentMessageFormRules"
                                  [(inputModel)]="editFormComment"
                                ></scw-mat-textarea>
                              </div>
                            </div>
                          </div>
                        </scw-mat-form>
                      </ng-template>
                    </div>
                    <div aria-role="separator" *ngIf="!last" class="border-bottom mb-3"></div>
                  </ng-container>
                </ng-container>

                <div *ngIf="!comments.length" class="row">
                  <div class="col">
                    <p>{{ 'activityCommentModal.info.noComments' | translate }}</p>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div *ngSwitchCase="LoadStatus.Failure" class="row">
              <div class="col">
                <p>{{ 'activityCommentModal.info.loadingFailed' | translate }}</p>
              </div>
            </div>

            <div *ngSwitchDefault class="row">
              <div class="col">
                <p>{{ 'activityCommentModal.info.commentsWillAppearHere' | translate }}</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="modal-footer">
  <scw-mat-button type="standard" (onClick)="closeModal()">
    {{ (mode === 'feed' ? 'general.close' : 'general.cancel') | translate }}
  </scw-mat-button>
  <scw-mat-button
    *ngIf="objectIds && objectIds.length > 1"
    [disabled]="(busy$ | async) === true"
    (onClick)="addSubmitButton.onClickEmitterForScwMatForm()"
  >
    <ng-container *ngIf="(formProcess$ | async) === 'adding'; else defaultText">
      <span class="spinner-border spinner-border-sm" aria-hidden></span>
      {{ 'activityCommentModal.info.adding' | translate }}
    </ng-container>
    <ng-template #defaultText>{{ 'activityCommentModal.actions.insertComments' | translate }}</ng-template>
  </scw-mat-button>
</div>

<ng-template #tagTemplate let-tag="data">
  <span class="p-1 rounded f-12 mr-2 font-weight-bold"
        [style.backgroundColor]="tag.color"
        [style.color]="whiteColorTextList.includes(tag.color) ? 'white': 'black'">
    {{ 'systemTags.' + tag.tagType | translate }}
  </span>
</ng-template>

<ng-template #tagTemplateForComments let-tag="data">
  <ng-container *ngIf="tag.showIcon; else defaultTagType">
    <div class="row m-0 py-1">
    <span class="p-1 rounded f-12 mr-2 font-weight-bold"
          [style.backgroundColor]="tag.color"
          [style.color]="whiteColorTextList.includes(tag.color) ? 'white': 'black'">
    {{ 'systemTags.' + tag.tagType | translate }}
      <img class="gpt-image" [src]="whiteColorTextList.includes(tag.color) ? whiteGPTIcon : blackGPTIcon"/>
  </span>
    </div>
  </ng-container>
  <ng-template #defaultTagType>
    <div class="row m-0 py-1">
    <span class="p-1 rounded f-12 mr-2 font-weight-bold"
          [style.backgroundColor]="tag.color"
          [style.color]="whiteColorTextList.includes(tag.color) ? 'white': 'black'">
    {{ 'systemTags.' + tag.tagType | translate }}
  </span>
    </div>
  </ng-template>
</ng-template>

<ng-template #tagTemplateForComments let-tag="data">
  <ng-container *ngIf="tag.showIcon; else defaultTagType">
    <span class="p-1 rounded f-12 mr-2 font-weight-bold" [style.backgroundColor]="tag.color">
    {{ 'systemTags.' + tag.tagType | translate }}
      <img class="gpt-image" src="../assets/icon/openAI_icons/chatgpt_icon_white.svg"/>
  </span>
    <br/>
    <br/>
  </ng-container>
  <ng-template #defaultTagType>
    <span class="p-1 rounded f-12 mr-2 font-weight-bold" [style.backgroundColor]="tag.color">
    {{ 'systemTags.' + tag.tagType | translate }}
  </span>
    <br/>
    <br/>
  </ng-template>
</ng-template>

<ng-template #confirmDeleteModal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">{{ 'activityCommentModal.headers.deleteComment' | translate }}</h3>
    <button type="button" class="close" aria-label="Close" onfocus="blur()" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <h6 class="scw-header">
          <em class="fas fa-exclamation-circle"></em>&nbsp;
          {{ 'activityCommentModal.subHeaders.areYouSureDelete' | translate }}
        </h6>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <scw-mat-button type="standard" (onClick)="modal.dismiss()">{{ 'general.cancel' | translate }}</scw-mat-button>
    <scw-mat-button type="danger" (onClick)="modal.close()">{{ 'general.delete' | translate }}</scw-mat-button>
  </div>
</ng-template>
