import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IActivityLog } from 'src/app/store/reports/root-cause-analysis/root-cause-analysis.model';
import { CommentFeedButtonComponent } from '../../comment-feed/comment-feed-button/comment-feed-button.component';

@Component({
  selector: 'app-last-comment',
  standalone: true,
  templateUrl: './last-comment.component.html',
  styleUrls: ['./last-comment.component.scss'],
  imports: [CommentFeedButtonComponent, TranslateModule],
})
export class LastCommentComponent {
  @Input() public activityLog: IActivityLog | undefined;
  @Input() public lastComment: string | undefined;
  @Input() public totalComments: number | undefined;

  @Output() public onCommentModalClosedEvent = new EventEmitter<void>();

  public readonly seeAllMaxDigits: number = 1;
  public readonly seeAllMaxNumber: number = Math.pow(10, this.seeAllMaxDigits) - 1;

  public onCommentModalClosed(): void {
    this.onCommentModalClosedEvent.emit();
  }
}
