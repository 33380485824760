import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { forkJoin, Observable } from 'rxjs';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import * as ObjectActions from '../../../../store/settings/product-multiplier/product-multiplier.actions';
import {
  IProductMultiplierExcelData,
  IProductMultipliers,
  IProductMultipliersBulkSaveMany,
  LinesInterface,
  ProductMultiplierCreateDataInterface,
  ProductMultiplierCRUDResponseInterface,
  ProductMultiplierUpdateInterface,
  ProductsInterface,
  SensorTypeInterface,
  SitesInterface,
} from '../../../../store/settings/product-multiplier/product-multiplier.model';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../model/interface/crud-response-interface.model';
import { AllSettledResult } from '../../../model/interface/generic-api-response.model';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  DownloadExcelFiltersInterface,
  ExcelColumnWidthEnum,
  ExcelDateFormatInformationInterface,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../excel/excel-helper.service';
import { LineService } from '../../filter/line.service';
import { ProductService } from '../../filter/product.service';
import { SensorTypesService } from '../../filter/sensor-types.service';
import { SiteService } from '../../filter/site.service';
import { HelperService } from '../../helper.service';
import { LineCRUDInterface } from '../../../component/filter/filter.class';
import { LineCRUDResponseInterface } from '../../filter/service.class';
import { DecimalHelper } from '../../../helper/decimal/decimal-helper';

@Injectable({
  providedIn: 'root',
})
export class ProductMultiplierService {
  private readonly BASE_PRODUCT_MULTIPLIER_URL = `${this.baseUrl}/product-multipliers`;
  private readonly PRODUCT_MULTIPLIER = {
    GET: {
      GET_MANY_PRODUCT_MULTIPLIERS: this.BASE_PRODUCT_MULTIPLIER_URL,
    },
    PATCH: {
      UPDATE_PRODUCT_MULTIPLIER: this.BASE_PRODUCT_MULTIPLIER_URL,
    },
    POST: {
      CREATE_PRODUCT_MULTIPLIER: this.BASE_PRODUCT_MULTIPLIER_URL,
    },
    DELETE: {
      DELETE_PRODUCT_MULTIPLIER: this.BASE_PRODUCT_MULTIPLIER_URL,
    },
    BULK: {
      DELETE: `${this.BASE_PRODUCT_MULTIPLIER_URL}/bulk/delete`,
      EDIT: `${this.BASE_PRODUCT_MULTIPLIER_URL}/bulk/edit`,
      SAVE: `${this.BASE_PRODUCT_MULTIPLIER_URL}/bulk/save`,
    },
  };
  private dateFormatInformation: ExcelDateFormatInformationInterface;

  constructor(
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly http: HttpClient,
    private readonly lineService: LineService,
    private readonly productService: ProductService,
    private readonly sensorTypesService: SensorTypesService,
    private readonly siteService: SiteService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  public deleteProductMultiplier(id: number): Observable<AllSettledResult> {
    return this.http.delete<AllSettledResult>(`${this.PRODUCT_MULTIPLIER.DELETE.DELETE_PRODUCT_MULTIPLIER}/${id}`);
  }

  public deleteProductMultipliers(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.PRODUCT_MULTIPLIER.BULK.DELETE, {
      productMultipliers: ids,
    });
  }

  public updateProductMultiplier(
    id: number,
    body: ProductMultiplierUpdateInterface,
  ): Observable<ProductMultiplierCRUDResponseInterface> {
    return this.http.patch<ProductMultiplierCRUDResponseInterface>(
      `${this.PRODUCT_MULTIPLIER.PATCH.UPDATE_PRODUCT_MULTIPLIER}/${id}`,
      body,
    );
  }

  public updateProductMultipliers(
    payload: Partial<IProductMultipliers>[],
    params?: HttpParams,
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(
      this.PRODUCT_MULTIPLIER.BULK.EDIT,
      { productMultipliers: payload },
      { params },
    );
  }

  public createProductMultiplier(
    body: ProductMultiplierCreateDataInterface,
  ): Observable<ProductMultiplierCRUDResponseInterface> {
    return this.http.post<ProductMultiplierCRUDResponseInterface>(
      this.PRODUCT_MULTIPLIER.POST.CREATE_PRODUCT_MULTIPLIER,
      body,
    );
  }

  public getProductMultiplier(params?: HttpParams): Observable<GetManyResponseInterface<IProductMultipliers>> {
    return this.http.get<GetManyResponseInterface<IProductMultipliers>>(
      this.PRODUCT_MULTIPLIER.GET.GET_MANY_PRODUCT_MULTIPLIERS,
      {
        params,
      },
    );
  }

  private getProductMultiplierExcelColumns(
    lines: LinesInterface[],
    products: ProductsInterface[],
    sensorTypes: SensorTypeInterface[],
    withErrorColumn: boolean,
  ): CreateExcelInterface {
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('general.excel.column.product'),
          key: 'productId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: products,
            prop: 'product.productId',
            dataProperty: 'product.productId',
            dataId: 'product.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.line'),
          key: 'lineId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: lines,
            prop: 'line.title',
            dataProperty: 'line.title',
            dataId: 'line.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.sensorType'),
          key: 'sensorType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: sensorTypes,
            prop: 'sensorTypeTitle.title',
            dataProperty: 'sensorTypeTitle.title',
            dataId: 'sensorTypeTitle.type',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            errorStyle: 'error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.multiplicationFactor'),
          key: 'multiplicationFactor',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('general.excel.column.promptDecimalInput', {
              field: this.translate.instant('general.excel.column.multiplicationFactor'),
            }),
            prompt: this.translate.instant('general.excel.column.promptDecimalInput', {
              field: this.translate.instant('general.excel.column.multiplicationFactor'),
            }),
          },
          isRequired: true,
          isDecimalNumber: true,
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public downloadProductMultiplierExcel(
    withData: boolean,
    filters: DownloadExcelFiltersInterface,
    withErrorColumn: boolean = false,
    data?: IProductMultiplierExcelData[],
  ): void {
    const siteParams: HttpParams = new HttpParams()
      .set('s', JSON.stringify({ id: { $eq: filters.siteId } }))
      .set('fields', 'name')
      .set('limit', String(filters.limit));
    const lineParams: HttpParams = new HttpParams()
      .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }))
      .set('fields', 'title')
      .set('limit', String(filters.limit));
    const productParams: HttpParams = new HttpParams()
      .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }))
      .set('fields', 'productId')
      .set('limit', '10000');
    const sensorTypeParams: HttpParams = new HttpParams().set(
      's',
      JSON.stringify({ type: { $notin: ['card_reader', 'sub_sensor'] } }),
    );

    const observables: Observable<unknown>[] = [
      this.siteService.getSites(siteParams),
      this.lineService.getLines(lineParams),
      this.productService.getProducts(productParams),
      this.sensorTypesService.getSensorType(sensorTypeParams),
    ];

    if (withData && !data) {
      const httpParams: HttpParams = new HttpParams()
        .append('join', 'sensorTypeTitle')
        .append('join', 'line||title')
        .append('join', 'product||productId,siteId')
        .set('s', JSON.stringify({ 'product.siteId': { $eq: filters.siteId } }))
        .set('page', filters.selectedDownloadOffset)
        .set('limit', 1000);

      observables.push(this.getProductMultiplier(httpParams));
    }

    forkJoin(observables).subscribe(async (responseList) => {
      const site: SitesInterface = _.get(responseList, '0.data', []);
      const line: LinesInterface[] = _.get(responseList, '1.data', []).sort(
        this.helperService.dropdownOptionCompareFunction,
      );
      const product: ProductsInterface[] = _.get(responseList, '2.data', []);
      const uneditedSensorTypeData: SensorTypeInterface[] = _.get(responseList, '3.data', []);
      const sensorType: SensorTypeInterface[] = uneditedSensorTypeData.map((sensor: SensorTypeInterface) => {
        return { title: this.translate.instant(`sensorTypes.${sensor.title}`), type: sensor.type };
      });

      this.dateFormatInformation = this.excelHelper.getUserDateFormatInformation();
      const sheetTitle = this.translate.instant('pageTitles.product_multiplier');
      const excelName = `${sheetTitle} ${moment()
        .tz(this.dateFormatInformation.timezone)
        .format(this.dateFormatInformation.dateFormat$)}`;
      let excelData: IProductMultiplierExcelData[] = [];

      if (withData) {
        excelData = _.get(responseList, '4.data', []);
        excelData = data
          ? this.editIncorrectExcelDataToAppropriateFormat(sensorType, product, line, site, data)
          : excelData.map((excelRow: IProductMultiplierExcelData) => {
              return {
                ...excelRow,
                multiplicationFactor: excelRow.multiplicationFactor
                  ? this.decimalHelper.removeTrailingZeros(excelRow.multiplicationFactor)
                  : null,
              };
            });
      }

      if (!withErrorColumn) {
        excelData = this.translateSensorTypeTitle(excelData);
      }

      const excelOptions: CreateExcelInterface = this.getProductMultiplierExcelColumns(
        line,
        product,
        sensorType,
        withErrorColumn,
      );

      if (withData) {
        excelOptions.data = excelData;
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          sheetTitle,
          withData,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
        },
      ];
      this.excelHelper
        .createExcel(
          excelName,
          worksheets,
          this.dateFormatInformation.timezone,
          this.dateFormatInformation.dateFormat$,
          this.dateFormatInformation.timeFormat$,
          false,
          1,
          true,
        )
        .then(
          () => {
            this.store.dispatch(new ObjectActions.ProductMultiplierExcelDownloaded());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError([]));
          },
        );
    });
  }

  private editIncorrectExcelDataToAppropriateFormat(
    sensorTypes: SensorTypeInterface[],
    products: ProductsInterface[],
    lines: LinesInterface[],
    site: SitesInterface,
    excelData?: IProductMultiplierExcelData[],
  ): IProductMultiplierExcelData[] {
    for (const productMultiplier of excelData) {
      productMultiplier.sensorTypeTitle = productMultiplier.sensorType
        ? sensorTypes.find((sensorType: SensorTypeInterface) => sensorType.type === productMultiplier.sensorType)
        : null;
      productMultiplier.product = productMultiplier.productId
        ? products.find((product: ProductsInterface) => product.id === productMultiplier.productId)
        : null;
      productMultiplier.line = productMultiplier.lineId
        ? lines.find((line: LinesInterface) => line.id === productMultiplier.lineId)
        : null;
      if (productMultiplier.product) {
        productMultiplier.product.site = productMultiplier.product.site ? site : null;
      }
      productMultiplier.multiplicationFactor = productMultiplier.multiplicationFactor
        ? this.decimalHelper.removeTrailingZeros(productMultiplier.multiplicationFactor)
        : null;
    }

    return excelData;
  }

  private translateSensorTypeTitle(excelData: IProductMultiplierExcelData[]): IProductMultiplierExcelData[] {
    return excelData.map((data: IProductMultiplierExcelData) => {
      const sensorType = {
        title: this.translate.instant(`sensorTypes.${data.sensorTypeTitle.title}`),
        type: data.sensorTypeTitle.type,
      };
      return {
        ...data,
        sensorTypeTitle: data.sensorType ? sensorType : null,
      };
    });
  }

  async getProductMultiplierFromExcel(
    file: File,
  ): Promise<null | {
    productMultiplierData: { productMultipliers: IProductMultipliers[] };
    siteId: number;
  }> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const productMultiplierSheet: Worksheet = workbook.getWorksheet(
      this.translate.instant('pageTitles.product_multiplier'),
    );
    const lineIdDataSheet: Worksheet = workbook.getWorksheet('lineIdDataSheet');
    const productIdDataSheet: Worksheet = workbook.getWorksheet('productIdDataSheet');
    const sensorTypeDataSheet: Worksheet = workbook.getWorksheet('sensorTypeDataSheet');

    if (!lineIdDataSheet || !productIdDataSheet || !sensorTypeDataSheet) {
      return null;
    }

    const selectedSiteId = await this.findUploadedExcelDataSiteId(lineIdDataSheet);
    const { columns } = this.getProductMultiplierExcelColumns(null, null, null, false);
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);
    this.dateFormatInformation = this.excelHelper.getUserDateFormatInformation();

    return {
      productMultiplierData: {
        productMultipliers: this.excelHelper.getExcelRowsFromWorkSheet<IProductMultipliers>(
          productMultiplierSheet,
          columnKeys,
          {
            dateFormat: this.dateFormatInformation.dateFormat$,
            timeFormat: this.dateFormatInformation.timeFormat$,
            timezone: this.dateFormatInformation.timezone,
          },
        ),
      },
      siteId: selectedSiteId,
    };
  }

  public uploadProductMultiplierExcel(
    productMultipliers: IProductMultipliersBulkSaveMany,
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(`${this.PRODUCT_MULTIPLIER.BULK.SAVE}`, productMultipliers);
  }

  private async findUploadedExcelDataSiteId(lineIdDataSheet: Worksheet): Promise<number> {
    const linesColumn = {
      id: {
        key: 'id',
        type: ValueType.Number,
        dataValidationType: CellTypes.CUSTOM,
      },
      title: {
        key: 'title',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    };

    const lines: LinesInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<{
      id: number;
      title: string;
    }>(lineIdDataSheet, linesColumn);

    if (!lines.length) {
      return null;
    }

    const lineParams: HttpParams = new HttpParams().set('s', JSON.stringify({ id: { $eq: lines[0].id } }));

    const lineDetails: LineCRUDResponseInterface = await this.lineService.getLines(lineParams).toPromise();
    const line: LineCRUDInterface = _.get(lineDetails, 'data.0', []);

    return line.siteId;
  }
}
