import * as ObjectActions from './jobs.actions';
import * as AppActions from '../../app/actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import { catchError, switchMap } from 'rxjs/operators';
import { JobsService } from './jobs.service';
import { HTTPHelperService } from '../../../shared/service/http/http.helper.service';
import { of } from 'rxjs';
import {
  BaseCrudResponse,
  BulkResponseDataInterface,
  IErrorMessageWitObjectId,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel.helper.service';
import { IJob } from './jobs.model';
import { emptyAction } from '../../../../constants';
@Injectable()
export class JobsEffects {
  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly actions$: Actions,
    private readonly jobsService: JobsService,
    private readonly httpHelperService: HTTPHelperService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
  ) {}


  getJobsData = createEffect(() => this.actions$.pipe(
    ofType<ObjectActions.JobsListDataLoading>(ObjectActions.JOBS_LIST_DATA_LOADING),
    switchMap((payload) => {
      return this.jobsService.getJobs(this.httpHelperService.insertGenericCrudRequestParameters(payload.params)).pipe(
        switchMap((response) => {
          return of(new ObjectActions.JobsListDataLoaded(response));
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  addJob = createEffect(() => this.actions$.pipe(
    ofType<ObjectActions.AddJob>(ObjectActions.ADD_JOB),
    switchMap((payload) => {
      return this.jobsService.createJob(payload.payload.data).pipe(
        switchMap((response) => {
          return of(new ObjectActions.AddJobCompleted(response));
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  editJob = createEffect(() => this.actions$.pipe(
    ofType<ObjectActions.EditJob>(ObjectActions.EDIT_JOB),
    switchMap((payload) => {
      return this.jobsService.editJob(payload.payload.data, payload.payload.id).pipe(
        switchMap((response) => {
          return of(new ObjectActions.EditJobCompleted(response));
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  deleteAlerts = createEffect(() => this.actions$.pipe(
    ofType<ObjectActions.DeleteJob>(ObjectActions.DELETE_JOB),
    switchMap((objectData: ObjectActions.DeleteJob) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return this.jobsService.deleteJobs(objectData.payload).pipe(
        switchMap((response: BaseCrudResponse | BulkResponseDataInterface) => {
          const responseMessages = 'data' in response && Array.isArray(response.data) ? response.data : [response];
          this.errorMessageService.getTranslatedErrorMessage(responseMessages);
          const mergedArray: IErrorMessageWitObjectId[] = ExcelHelperService.mergeBulkResponseWithRequestData(
            { ...response, data: responseMessages },
            objectData.payload.map((item) => {
              return { id: item };
            }),
            true,
          );

          return of(new ObjectActions.DeleteJobCompleted(mergedArray), new AppActions.HideLoader());
        }),
        catchError((error) => {
          return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((error) => {
      return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
    }),
  ));


  downloadJobExcel = createEffect(() => this.actions$.pipe(
    ofType<ObjectActions.DownloadJobExcel>(ObjectActions.DOWNLOAD_JOB_EXCEL),
    switchMap((objectData: ObjectActions.DownloadJobExcel) => {
      this.jobsService.downloadExcel(
        objectData.withData,
        objectData.filters,
        this.httpHelperService.insertGenericCrudRequestParameters(objectData.tableQuery),
      );
      return emptyAction;
    }),
    catchError((error) => {
      return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
    }),
  ));


  uploadExcel = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.UPLOAD_JOB_EXCEL),
    switchMap((objectData: ObjectActions.UploadJobExcel) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return this.jobsService.uploadExcel(objectData.payload).pipe(
        switchMap((response: BulkResponseDataInterface) => {
          this.errorMessageService.getTranslatedErrorMessage(response.data);
          const mergedArray = ExcelHelperService.mergeBulkResponseWithRequestData<IJob>(
            response,
            objectData.payload.jobs,
          );
          return of(new ObjectActions.UploadJobExcelCompleted(mergedArray), new AppActions.HideLoader());
        }),
        catchError((error) => {
          return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
        }),
      );
    }),
  ));
}
