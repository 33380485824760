import { User } from './model';
import * as Actions from './actions';
import { NotificationInterface } from '../../shared/service/notification/notification.service';
import * as _ from 'lodash';

// TODO client's number code is added to the store temporarily
const initialState: User = {
  age: null,
  clientCode: '',
  clientNumber: '',
  dataInterval: 60,
  clientOeeFormula: null,
  dateFormat: '',
  dateTimeFormat: '',
  timeFormat: '',
  timeFormatHourAndMinute: '',
  locale: '',
  decimalSeparator: '.',
  errors: [],
  language: null,
  lineId: null,
  lineName: null,
  name: null,
  siteId: null,
  defaultSite: null,
  siteName: null,
  surname: null,
  thousandSeparator: ',',
  timezone: null,
  updateCurrentUserLoaded: false,
  updateCurrentUserLoading: false,
  userId: null,
  userLevelId: null,
  username: null,
  isUserLoaded: false,
  isUserLoading: false,
  isDigitalFormsActive: false,
  isLaborTrackerActive: false,
  isAssetTrackerActive: false,
  isStationTrackerActive: false,
  isTokenRefreshed: false,
  isFeedTokenLoading: false,
  isFeedTokenLoaded: false,
  notificationsLoaded: false,
  notificationsLoading: false,
  notificationToken: null,
  notifications: { results: [], next: undefined, duration: null, unseen: 0, unread: 0 },
  authorizedSites: null,
  authorizedLines: null,
  fullName: null,
  expectedSpeed: null,
  unitId: null,
  decimalScaleLimit: null,
  siteDecimalScaleLimit: null,
  isNonVerifiedUserPassActive: true,
  logbookAccessUrl: null,
  logbookAccess: null,
  weekStartDay: null,
};

export function userReducer(state: User = initialState, action: Actions.UserActions): User {
  switch (action.type) {
    case Actions.UPDATE_CURRENT_USER:
      return {
        ...state,
        updateCurrentUserLoading: true,
      };

    case Actions.UPDATE_CURRENT_USER_LOADED:
      return {
        ...state,
        updateCurrentUserLoaded: true,
        updateCurrentUserLoading: false,
      };

    case Actions.CLEAR_USER_LOAD_STATUS:
      return {
        ...state,
        updateCurrentUserLoaded: false,
        updateCurrentUserLoading: false,
      };

    case Actions.CURRENT_USER_LOADING:
      return { ...state, isUserLoading: true };

    case Actions.CURRENT_USER_LOADED:
      return { ...state, isUserLoading: false, isUserLoaded: true };

    case Actions.FETCH_ERROR:
      return { ...state, errors: action.payload };

    case Actions.SET_ACCESS_TOKEN_LOADED:
      return { ...state, isTokenRefreshed: true };

    case Actions.GET_USER_FEED_TOKEN:
      return { ...state, isFeedTokenLoading: true, isFeedTokenLoaded: false };

    case Actions.GET_USER_FEED_TOKEN_LOADED:
      return { ...state, isFeedTokenLoading: false, isFeedTokenLoaded: true, notificationToken: action.userFeed };

    case Actions.GET_NOTIFICATION_LIST:
      return { ...state, notificationsLoading: true, notificationsLoaded: false };

    case Actions.GET_NOTIFICATION_LIST_LOADED:
      let results: NotificationInterface[] = _.cloneDeep(state.notifications.results);
      results = _.uniqBy(results.concat(action.notifications.results), 'id');
      return {
        ...state,
        notifications: {
          ...action.notifications,
          results,
          next: state.notifications.next === null || state.notifications.next === '' ? null : action.notifications.next,
        },
        notificationsLoading: false,
        notificationsLoaded: true,
      };

    case Actions.GET_NOTIFICATION_LIST_WITH_LIMIT_ZERO_LOADED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          unseen: action.notifications.unseen,
        },
        notificationsLoading: false,
        notificationsLoaded: true,
      };

    case Actions.SET_IS_NON_VERIFIED_USER_PASS_ACTIVE:
      return {
        ...state,
        ...{ isNonVerifiedUserPassActive: action.payload },
      };

    case Actions.CURRENT_USER_DATA_LOADED:
      return {
        ...state,
        ...{ ...action.user },
      };

    default:
      return state;
  }
}
