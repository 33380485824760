import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as MicrostopsAnalysisActions from './microstops-analysis.actions';
import * as AppActions from '../../../store/app/actions';
import { catchError, switchMap, take } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { MicrostopsAnalysisService } from './microstops-analysis.service';
import {
  IMicrostopsDeviceMessagesElasticResponse,
  IMicrostopsAnalysisData,
  IMicrostopsActivityHistoriesElasticResponse,
} from './microstops-analysis.model';
import * as _ from 'lodash';
import { HelperService } from '../../../shared/service/helper.service';
import * as moment from 'moment';
import { ILineData } from '../root-cause-analysis/root-cause-analysis.model';
import { User } from '../../user/model';

@Injectable()
export class MicrostopsAnalysisEffects {
  private startDate: moment.Moment;
  private endDate: moment.Moment;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly microstopsAnalysisService: MicrostopsAnalysisService,
  ) {}

  getSensorList = createEffect(() =>
    this.actions$.pipe(
      ofType(MicrostopsAnalysisActions.GET_MICROSTOPS_ANALYSIS_DATA),
      switchMap((objectData: MicrostopsAnalysisActions.GetMicrostopsAnalysisData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        this.startDate = HelperService.cloneDeep(moment(objectData.payload.dateRange.startDate));
        this.endDate = HelperService.cloneDeep(moment(objectData.payload.dateRange.endDate));
        const timezone$: string = this.getTimezone();

        return forkJoin([
          this.microstopsAnalysisService.getDeviceMessages(objectData.payload, timezone$),
          this.microstopsAnalysisService.getActivityHistories(objectData.payload, timezone$),
          this.microstopsAnalysisService.getOngoingActivity(objectData.payload),
        ]).pipe(
          switchMap((response: unknown) => {
            const microstopsAnalysisData: IMicrostopsAnalysisData = {
              sensorData: MicrostopsAnalysisService.formatDeviceMessageData(
                _.get(response, '0.data') as IMicrostopsDeviceMessagesElasticResponse,
                timezone$,
                this.startDate,
                this.endDate,
              ),
              activityData: MicrostopsAnalysisService.formatActivityData(
                _.get(response, '1.data') as IMicrostopsActivityHistoriesElasticResponse,
                _.get(response, '2.data') as ILineData[],
                timezone$,
                this.startDate,
                this.endDate,
              ),
            };

            return of(
              new MicrostopsAnalysisActions.GetMicrostopsAnalysisDataCompleted(microstopsAnalysisData),
              new AppActions.HideLoader(),
            );
          }),
          catchError((err) => {
            return of(new MicrostopsAnalysisActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new MicrostopsAnalysisActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  private getTimezone(): string {
    let timezone$: string = '';

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user: User) => {
        timezone$ = user.timezone;
      });

    return timezone$;
  }
}
