import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { ICustomConfigurationDbEntry } from '../../../shared/component/page-configuration/page-configuration.model';

export interface ScheduleAdherenceStateInterface {
  scheduleAdherenceDataLoading: boolean;
  scheduleAdherenceDataLoaded: boolean;
  currentData: IScheduleAdherenceData | null;
  filters: IScheduleAdherenceFilters;
  tableSettings: TabRowInterface[];
  customConfiguration: ICustomConfigurationDbEntry[];
}

export interface IScheduleAdherenceFilters {
  intervalStartDate: string;
  intervalEndDate: string;
  siteId: number;
  lineIds: number[];
  includeAllActivities: number;
}

export interface IScheduleAdherenceResponseData {
  client: string;
  siteId: number;
  actualLineId: number;
  actualLineName: string;
  scheduledLineId: number;
  scheduledLineName: string;
  workOrderId: number;
  woNumber: string;
  completed: boolean;
  woDueDate: string | null;
  actualMinStart: string | null;
  actualMaxEnd: string | null;
  scheduledRunDate: string | null;
  scheduledEndDate: string | null;
  scheduledPreDuration: number | null;
  scheduledPostDuration: number | null;
  scheduledRunDuration: number | null;
  actualNoPhaseDuration: number;
  actualPreRunPhaseDuration: number;
  actualPostRunPhaseDuration: number;
  actualRunPhaseDuration: number;
  calcScheduledEndDate: string | null;
  varianceOfPreRunDuration: number | null;
  varianceOfPostRunDuration: number | null;
  varianceOfRunDuration: number | null;
  isOnTimeCompletion: boolean;
  runType: string;
  overdue: boolean;
  behindScheduleWorkOrder: boolean;
  compliancePercentage: number | null;
  preRunPhaseName: string | null;
  runPhaseName: string;
  postRunPhaseName: string;
}

export interface IScheduleAdherenceData {
  kpiCardsData: IScheduleAdherenceKpiCardsData;
  woDurationsChartData: IWoDurationsChartNode[];
  rawData: IScheduleAdherenceResponseData[];
  tableData: IScheduleAdherenceTableRow[];
}

export interface IScheduleAdherenceKpiCardsData {
  overdueWorkOrders: number;
  behindScheduleWorkOrders: number;
  scheduleCompliance: number;
  scheduleAdherence: number;
  scheduleAttainment: number;
  workOrderOnTimeCompletion: number;
  plannedAndStartedWorkOrders: number;
  unplannedAndStartedWorkOrders: number;
  plannedAndNotStartedWorkOrders: number;
}

export interface IWoDurationsChartNode {
  title: string;
  phases: IPhases;
}

export interface IPhases {
  preRun: IPhaseData;
  run: IPhaseData;
  postRun: IPhaseData;
  noPhase: IPhaseData;
}

export interface IPhaseData {
  title: string;
  scheduledDuration: number;
  actualDuration: number;
}

export interface IWoNumbersChartNode {
  time: string;
  started: IWoNumbers;
  notStarted: IWoNumbers;
}

export interface IWoNumbers {
  planned?: number;
  unplanned?: number;
}

export interface IScheduleAdherenceTableRow {
  actualLine: string;
  scheduledLine: string;
  workOrder: string;
  scheduledPreRun: string | null;
  actualPreRun: string;
  varianceOfPreRun: string | null;
  scheduledRun: string | null;
  actualRun: string;
  varianceOfRun: string | null;
  scheduledPostRun: string;
  actualPostRun: string;
  varianceOfPostRun: string | null;
  scheduledStartDate: string | null;
  actualStartDate: string | null;
  scheduledEndDate: string | null;
  actualEndDate: string | null;
  woDueDate: string | null;
  varianceStatus: IDurationVarianceResults;
  isOverdue: boolean;
}

export interface IDurationVarianceResults {
  varianceOfPreRun: DurationVarianceType;
  varianceOfRun: DurationVarianceType;
  varianceOfPostRun: DurationVarianceType;
}

export enum DurationVarianceType {
  NEGATIVE = 0,
  POSITIVE = 1,
  ZERO = 2,
}

export enum WorkOrderRunType {
  PLANNED_AND_STARTED = 'planned_and_started',
  PLANNED_AND_NOT_STARTED = 'planned_and_not_started',
  UNPLANNED_AND_STARTED = 'unplanned_and_started',
}

export type TGroupedData = {
  [key in WorkOrderRunType]?: number[];
};
