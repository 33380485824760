import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, filter, map, Subscription, merge, take, combineLatest } from 'rxjs';
import { ScwMatButtonModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { ScwMatFormModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-form/scw-mat-form.module';
import { ScwModalSize } from 'src/app/shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { ScwMatSelectModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.module';
import { ScwMatTextareaModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-textarea/scw-mat-textarea.module';
import { ScwMatModalSeparatorModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal-separator/scw-mat-modal-separator.module';
import { ComponentUtilities } from 'src/app/shared/helper/component-utilities';
import { GenericHelperService } from 'src/app/shared/service/generic.helper.service';
import { ToastHelperService } from 'src/app/shared/service/toast/toast.helper.service';
import { TagType } from 'src/app/store/settings/tags/tags.model';
import { ELoadStatus } from 'src/constants.model';
import { IActivityComment, ICommentTag } from '../comment-feed-service/comment-feed.model';
import { MomentDatePipe } from '../../pipes/moment-date.pipe';
import {
  AllowedCommentObjectType,
  TFormProcess,
  IInMemoryComment,
  TModalMode,
  ETagBackgroundColors
} from './comment-feed-modal.model';
import { CommentFeedStore } from './comment-feed-modal.store';
import { OnDestroyDecorator } from '../../../shared/decorator/on-destroy-decorator';
import { ScwMatTextAreaRule } from 'src/app/shared/component/scw-mat-ui/scw-mat-textarea/scw-mat-textarea.model';
import * as moment from 'moment';
import { mysqlTimestampFormat } from '../../../shared/helper/date';

@OnDestroyDecorator
@Component({
  selector: 'app-comment-feed-modal',
  standalone: true,
  imports: [
    CommonModule,
    MomentDatePipe,
    ScwMatButtonModule,
    ScwMatFormModule,
    ScwMatModalSeparatorModule,
    ScwMatSelectModule,
    ScwMatTextareaModule,
    TranslateModule,
  ],
  templateUrl: './comment-feed-modal.component.html',
  styleUrls: ['./comment-feed-modal.component.scss'],
  providers: [CommentFeedStore],
})
export class CommentFeedModalComponent implements OnInit, OnDestroy {
  @Input() public readonly activityName: string | undefined;
  @Input() public readonly mode: TModalMode = 'feed';
  @Input() public readonly objectIds: readonly (number | undefined)[] | undefined;
  @Input() public readonly objectType: AllowedCommentObjectType | undefined;

  @Input() public readonly readOnly: boolean = false;
  @Input() private readonly defaultTagTypes: readonly TagType[] | undefined;

  @Input() private readonly inMemoryComments: IInMemoryComment[] = [];
  @Output() private readonly inMemoryCommentsChange = new EventEmitter<IInMemoryComment[]>();

  @ViewChild('confirmDeleteModal') private readonly confirmDeleteModal: TemplateRef<unknown>;

  public readonly inMemoryMode$: Observable<boolean> = this.commentFeedStore.inMemoryMode$;
  public readonly rawInMemoryComments$: Observable<IInMemoryComment[]> = this.commentFeedStore.rawInMemoryComments$;
  public readonly comments$: Observable<IActivityComment[]> = this.commentFeedStore.comments$;
  public readonly commentsLoadStatus$: Observable<ELoadStatus> = this.commentFeedStore.commentsLoadStatus$;
  public readonly commentToDeleteIndex$: Observable<number> = this.commentFeedStore.commentToDeleteIndex$;
  public readonly commentToEdit$: Observable<IActivityComment | null> = this.commentFeedStore.commentToEdit$;
  public readonly formProcess$: Observable<TFormProcess> = this.commentFeedStore.formProcess$;
  public readonly userDateTimeFormat$: Observable<string> = this.commentFeedStore.userDateTimeFormat$;
  public readonly userDateTimezone$: Observable<string> = this.commentFeedStore.userDateTimezone$;
  public readonly tags$: Observable<ICommentTag[]> = this.commentFeedStore.tags$;
  public readonly tagsLoadStatus$: Observable<ELoadStatus> = this.commentFeedStore.tagsLoadStatus$;
  public readonly whiteColorTextList: string[] = Object.values(ETagBackgroundColors);
  public readonly whiteGPTIcon: string = '../assets/icon/openAI_icons/chatgpt_icon_white.svg';
  public readonly blackGPTIcon: string = '../assets/icon/openAI_icons/chatgpt_icon_black.svg';
  public readonly busy$: Observable<boolean> = this.formProcess$.pipe(
    map((process: TFormProcess) => process !== 'idle'),
  );
  public readonly tagDropdownLabel$: Observable<string> = this.tagsLoadStatus$.pipe(
    map((status: ELoadStatus) => {
      const statusText: string | undefined = this.getNonSuccessStatusText(status);
      return this.translate.instant('activityCommentModal.label.commentTag') + (statusText ? ` (${statusText})` : '');
    }),
  );

  public readonly LoadStatus = ELoadStatus;
  public readonly trackById = ComponentUtilities.genericTrackByIdFunction;
  public readonly commentMessageFormRules: ScwMatTextAreaRule[] = [
    { required: true },
    {
      custom: true,
      validator: (value: string): boolean => this.sanitizeCommentMessage(value).length > 0,
      message: this.translate.instant('scwMatForm.validation.required'),
    },
  ];

  public addFormComment: string;
  public addFormTags: readonly ICommentTag[] | null;
  public editFormComment: string;
  public editFormTags: readonly ICommentTag[] | null;
  public modalOpenedAt: string | undefined;

  private readonly lastBulkInsertResponse$: Observable<unknown> = this.commentFeedStore.lastBulkInsertResponse$;
  private readonly lastDeletedComment$: Observable<IActivityComment | undefined> =
    this.commentFeedStore.lastDeletedComment$;
  private readonly lastEditedComment$: Observable<IActivityComment | undefined> =
    this.commentFeedStore.lastEditedComment$;
  private readonly lastPersistedComment$: Observable<IActivityComment> = this.commentFeedStore.lastPersistedComment$;
  private readonly lastSavedInMemoryComment$: Observable<IInMemoryComment> =
    this.commentFeedStore.lastSavedInMemoryComment$;

  private confirmDeleteModalRef: NgbModalRef | undefined;
  private subscriptions: Subscription[] = [];

  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly commentFeedStore: CommentFeedStore,
    private readonly modalService: NgbModal,
    private readonly toast: ToastHelperService,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.loadData();

    this.subscriptions.push(
      this.commentToDeleteIndex$.subscribe(() => {
        this.confirmDeleteModalRef = this.modalService.open(this.confirmDeleteModal, {
          windowClass: ScwModalSize.small,
        });
        this.confirmDeleteModalRef.result
          .then(() => this.commentFeedStore.deleteComment())
          .catch(() => this.commentFeedStore.unsetCommentToDeleteIndex());
      }),
      this.commentToEdit$
        .pipe(filter((comment: IActivityComment | null): comment is IActivityComment => comment !== null))
        .subscribe((comment: IActivityComment) => {
          this.editFormComment = comment.commentMessage;
          this.editFormTags = comment.commentTags ? [...comment.commentTags] : [];
        }),
      merge(
        this.lastBulkInsertResponse$,
        this.lastEditedComment$,
        this.lastDeletedComment$,
        this.lastPersistedComment$,
      ).subscribe(() => {
        this.toast.showGenericChangesSavedSuccessfullyToastMessage();
      }),
      merge(this.lastBulkInsertResponse$, this.lastPersistedComment$, this.lastSavedInMemoryComment$).subscribe(() => {
        this.resetAddForm();

        if (this.mode === 'requiredComment' || (this.objectIds && this.objectIds.length > 1)) {
          this.activeModal.close();
        }
      }),
      this.userDateTimezone$.subscribe((timezone: string) => {
        this.modalOpenedAt = moment().tz(timezone).format(mysqlTimestampFormat);
      }),
      this.tags$.subscribe((tags: readonly ICommentTag[]) => {
        this.addFormTags =
          this.defaultTagTypes
            ?.map((type: TagType) => tags.find((tag: ICommentTag) => tag.tagType === type))
            .filter((tag: ICommentTag | undefined): tag is ICommentTag => tag !== undefined) ?? null;
      }),
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription?.unsubscribe();
    }
  }

  public closeModal(): void {
    combineLatest([this.inMemoryMode$, this.rawInMemoryComments$])
      .pipe(take(1))
      .subscribe(([inMemoryMode, comments]: [boolean, IInMemoryComment[]]) => {
        if (inMemoryMode) {
          this.inMemoryCommentsChange.emit(comments);
        }
      });

    this.activeModal.dismiss();
  }

  public submitCreationForm(valid: boolean): void {
    if (!valid || !this.objectIds || !this.objectType) {
      return;
    }

    this.commentFeedStore.createComment({
      commentMessage: this.sanitizeCommentMessage(this.addFormComment),
      objectIds: this.objectIds,
      objectType: this.objectType,
      commentTags: this.addFormTags,
      timestamp: this.modalOpenedAt,
    });
  }

  public submitEditForm(valid: boolean, index: number): void {
    if (!valid) {
      return;
    }

    this.commentFeedStore.editComment({
      index,
      message: this.sanitizeCommentMessage(this.editFormComment),
      tags: this.editFormTags?.map((tag: ICommentTag) => tag.id) ?? null,
    });
  }

  private getNonSuccessStatusText(status: ELoadStatus): string | undefined {
    switch (status) {
      case ELoadStatus.Loading:
        return this.translate.instant('activityCommentModal.label.loading');
      case ELoadStatus.Failure:
        return this.translate.instant('activityCommentModal.label.failed');
      case ELoadStatus.Initial:
        return this.translate.instant('activityCommentModal.label.pending');
    }
    return undefined;
  }

  private loadData(): void {
    if (!this.objectIds || !this.objectType) {
      return;
    }

    this.commentFeedStore.loadTags();
    this.commentFeedStore.loadCommentTypes();

    if (GenericHelperService.allElementsDefined(this.objectIds)) {
      this.commentFeedStore.loadComments({ objectIds: this.objectIds, objectType: this.objectType });
    } else {
      this.commentFeedStore.setInMemoryComments(this.inMemoryComments);
    }
  }

  private resetAddForm(): void {
    this.addFormTags = null;
    this.addFormComment = '';
  }

  private sanitizeCommentMessage(comment: string): string {
    return comment.trim();
  }
}
