import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import * as ObjectActions from './station-activity-history.actions';
import * as oeeAppReducer from '../oee.reducer';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { of } from 'rxjs';
import { StationActivityHistoryService } from '../../shared/service/station-activity-history/station-activity-history.service';
import {
  IActivityAndLine,
  IStationActivityHistoryCrudResponse,
  IStationDropdownResponse,
  NoActivityStation,
} from './station-activity-history.model';
import * as AppActions from '../app/actions';
import { HttpParams } from '@angular/common/http';
import { HelperService } from '../../shared/service/helper.service';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';

@Injectable()
export class StationActivityHistoryEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: StationActivityHistoryService,
    private readonly helperService: HelperService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  public stationDropdownItemsLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.STATION_DROPDOWN_ITEMS_LOADING),
      switchMap((objectData: ObjectActions.StationDropdownItemsLoading) => {
        const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
          join: ['ongoingCheckIns', 'ongoingStationActivityHistory'],
          filters: [{ field: 'lineId', ids: [objectData.lineId] }],
          additionalCustomSearch: [{ isTrackerEnabled: { $eq: true } }],
          limit: 500,
        });
        return this.service.loadStationDropdownItems(params).pipe(
          switchMap((response: GetManyResponseInterface<IStationDropdownResponse>) => {
            response.data.forEach((data: IStationDropdownResponse) =>
              this.entityTranslatorService.translate(data.ongoingStationActivityHistory),
            );

            const actions: Action[] = [
              new ObjectActions.StationDropdownItemsLoaded(response)
            ];

            if (objectData.hideLoader) {
              actions.push(new AppActions.HideLoader());
            }
            return of(...actions);
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public getOngoingStationActivityHistoryLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADING),
      exhaustMap((objectData: ObjectActions.GetOngoingStationActivityHistoryLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
          join: ['station', 'task', 'task.equipment', 'workOrder', 'workOrder.productVersionInfo', 'workOrder.product'],
          additionalCustomSearch: [{ stationId: { $eq: objectData.stationId } }, { endedAt: { $isnull: true } }],
        });

        return this.service.getManyStationActivityHistories(params).pipe(
          switchMap((response: GetManyResponseInterface<IStationActivityHistoryCrudResponse>) => {
            response.data.forEach((stationActivityHistory: IStationActivityHistoryCrudResponse) => this.entityTranslatorService.translate(stationActivityHistory));
            return of(new ObjectActions.GetOngoingStationActivityHistoryLoaded(response));
          }),
          catchError(() => {
            return of(
              new ObjectActions.FetchError(ObjectActions.ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADING),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public updateOngoingStationActivityHistoryLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.UPDATE_ONGOING_STATION_ACTIVITY_HISTORY_LOADING),
      switchMap((objectData: ObjectActions.UpdateOngoingStationActivityHistoryLoading) => {
        return this.service.updateOngoingStationActivityHistory(objectData.stationId, objectData.requestData).pipe(
          switchMap((response: BaseOneResponseInterface<IStationActivityHistoryCrudResponse>) => {
            return of(
              new ObjectActions.UpdateOngoingStationActivityHistoryLoaded(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public stationActivityHistoryActivityChangeLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.STATION_ACTIVITY_HISTORY_ACTIVITY_CHANGE_LOADING),
      switchMap((objectData: ObjectActions.StationActivityHistoryActivityChangeLoading) => {
        return this.service.stationActivityHistoryActivityChange(objectData.stationId, objectData.requestData).pipe(
          switchMap((response: BaseOneResponseInterface<IStationActivityHistoryCrudResponse>) => {
            return of(new ObjectActions.StationActivityHistoryActivityChangeLoaded(response));
          }),
          catchError(() => {
            return of(new ObjectActions.ChangeStationActivityError(), new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public getActivityAndLine = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.GET_ACTIVITY_AND_LINE_LOADING),
      switchMap((objectData: ObjectActions.GetActivityAndLineLoading) => {
        return this.service
          .getActivityAndLine(
            objectData.ongoingStationActivityHistory.lineId,
            objectData.ongoingStationActivityHistory.activityId,
          )
          .pipe(
            switchMap((response: IActivityAndLine) => {
              this.entityTranslatorService.translate(response.activity);

              return of(
                new ObjectActions.GetActivityAndLineLoaded(response, objectData.ongoingStationActivityHistory),
                new AppActions.HideLoader(),
              );
            }),
            catchError(() => {
              return of(new AppActions.HideLoader());
            }),
          );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public getNoActivityStation = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.GET_NO_ACTIVITY_STATION_LOADING),
      switchMap((objectData: ObjectActions.GetNoActivityStationLoading) => {
        const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
          fields: ['id', 'name', 'line.id', 'line.title', 'line.siteId'],
          join: ['line'],
          additionalCustomSearch: [{ isTrackerEnabled: { $eq: true } }],
        });

        return this.service.getOneStation(objectData.stationId, params).pipe(
          switchMap((response: BaseOneResponseInterface<NoActivityStation>) => {
            return of(
              new ObjectActions.GetNoActivityStationLoaded(response),
              new ObjectActions.UpdateMissingActivityMode(false),
              new AppActions.HideLoader(),
            );
          }),
          catchError(() => {
            return of(
              new ObjectActions.FetchError(ObjectActions.ActionTypes.GET_NO_ACTIVITY_STATION_LOADING),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  public ongoingStationActivityHistoriesLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORIES_LOADING),
      switchMap((objectData: ObjectActions.GetOngoingStationActivityHistoriesLoading) => {
        const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
          additionalCustomSearch: objectData.additionalCustomSearch,
        });

        return this.service.getManyStationActivityHistories(params).pipe(
          switchMap((response: GetManyResponseInterface<IStationActivityHistoryCrudResponse>) => {
            return of(new ObjectActions.GetOngoingStationActivityHistoriesLoaded(response));
          }),
          catchError(() => {
            return of(
              new ObjectActions.FetchError(ObjectActions.ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADING),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );
}
