import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SiteViewActions from './site-view.actions';
import * as AppActions from '../../app/actions';
import * as oeeAppReducer from '../../oee.reducer';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SiteViewService } from '../../../shared/service/global-view/site-view/site-view.service';
import { Store } from '@ngrx/store';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

@Injectable()
export class SiteViewEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: SiteViewService,
  ) {}

  getSiteViewLineData = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteViewActions.SITE_VIEW_LINE_DATA_LOADING),
      switchMap((objectData: SiteViewActions.LineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getSiteViewLineData(objectData.siteId, objectData.lineIds).pipe(
          switchMap((response: any) => {
            return of(new SiteViewActions.LineDataLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new SiteViewActions.FetchDataError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SiteViewActions.FetchDataError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getFloorPlanImage = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteViewActions.SITE_VIEW_GET_FLOOR_PLAN_IMAGE_LOADING),
      switchMap((objectData: SiteViewActions.GetFloorPlanImageLoading) => {
        return this.service.getFloorPlanImage(objectData.imagePath).pipe(
          switchMap((response: BaseOneResponseInterface<string>) => {
            return of(new SiteViewActions.GetFloorPlanImageLoaded(response, objectData.imagePath));
          }),
          catchError((error) => {
            return of(new SiteViewActions.FetchDataError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new SiteViewActions.FetchDataError(error));
      }),
    ),
  );
}
