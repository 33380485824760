import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import {
  IActivityAndLine,
  IStationActivityHistoryActivityChangeRequest,
  IStationActivityHistoryCrudResponse,
  IStationDropdownResponse,
  IUpdateOngoingStationActivityHistoryRequest,
  NoActivityStation,
} from './station-activity-history.model';

export enum ActionTypes {
  STATION_DROPDOWN_ITEMS_LOADING = '[STATION_ACTIVITY_HISTORY] STATION DROPDOWN ITEMS LOADING',
  STATION_DROPDOWN_ITEMS_LOADED = '[STATION_ACTIVITY_HISTORY] STATION DROPDOWN ITEMS LOADED',
  GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADING = '[STATION_ACTIVITY_HISTORY] GET ONGOING STATION ACTIVITY HISTORY LOADING',
  GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADED = '[STATION_ACTIVITY_HISTORY] GET ONGOING STATION ACTIVITY HISTORY LOADED',
  UPDATE_ONGOING_STATION_ACTIVITY_HISTORY_LOADING = '[STATION_ACTIVITY_HISTORY] UPDATE ONGOING STATION ACTIVITY HISTORY LOADING',
  UPDATE_ONGOING_STATION_ACTIVITY_HISTORY_LOADED = '[STATION_ACTIVITY_HISTORY] UPDATE ONGOING STATION ACTIVITY HISTORY LOADED',
  STATION_ACTIVITY_HISTORY_ACTIVITY_CHANGE_LOADING = '[STATION_ACTIVITY_HISTORY] STATION ACTIVITY HISTORY ACTIVITY CHANGE LOADING',
  STATION_ACTIVITY_HISTORY_ACTIVITY_CHANGE_LOADED = '[STATION_ACTIVITY_HISTORY] STATION ACTIVITY HISTORY ACTIVITY CHANGE LOADED',
  GET_ACTIVITY_AND_LINE_LOADING = '[STATION_ACTIVITY_HISTORY] GET ACTIVITY AND LINE LOADING',
  GET_ACTIVITY_AND_LINE_LOADED = '[STATION_ACTIVITY_HISTORY] GET ACTIVITY AND LINE LOADED',
  GET_NO_ACTIVITY_STATION_LOADING = '[STATION_ACTIVITY_HISTORY] GET NO ACTIVITY STATION LOADING',
  GET_NO_ACTIVITY_STATION_LOADED = '[STATION_ACTIVITY_HISTORY] GET NO ACTIVITY STATION LOADED',
  GET_ONGOING_STATION_ACTIVITY_HISTORIES_LOADING = '[STATION_ACTIVITY_HISTORY] GET ONGOING STATION ACTIVITY HISTORIES LOADING',
  GET_ONGOING_STATION_ACTIVITY_HISTORIES_LOADED = '[STATION_ACTIVITY_HISTORY] GET ONGOING STATION ACTIVITY HISTORIES LOADED',
  UPDATE_MISSING_ACTIVITY_MODE = '[STATION_ACTIVITY_HISTORY] UPDATE MISSING ACTIVITY MODE',
  RESET_STORE = '[STATION_ACTIVITY_HISTORY] RESET STORE',
  CHANGE_STATION_ACTIVITY_ERROR = '[STATION_ACTIVITY_HISTORY] CHANGE STATION ACTIVITY ERROR',
  FETCH_ERROR = '[STATION_ACTIVITY_HISTORY] FETCH ERROR',
}

export class StationDropdownItemsLoading implements Action {
  public readonly type = ActionTypes.STATION_DROPDOWN_ITEMS_LOADING;

  constructor(public lineId: number, public hideLoader: boolean = true) {}
}

export class StationDropdownItemsLoaded implements Action {
  public readonly type = ActionTypes.STATION_DROPDOWN_ITEMS_LOADED;

  constructor(public response: GetManyResponseInterface<IStationDropdownResponse>) {}
}

export class GetOngoingStationActivityHistoryLoading implements Action {
  public readonly type = ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADING;

  constructor(public stationId: number) {}
}

export class GetOngoingStationActivityHistoryLoaded implements Action {
  public readonly type = ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORY_LOADED;

  constructor(public response: GetManyResponseInterface<IStationActivityHistoryCrudResponse>) {}
}

export class UpdateOngoingStationActivityHistoryLoading implements Action {
  public readonly type = ActionTypes.UPDATE_ONGOING_STATION_ACTIVITY_HISTORY_LOADING;

  constructor(public stationId: number, public requestData: IUpdateOngoingStationActivityHistoryRequest) {}
}

export class UpdateOngoingStationActivityHistoryLoaded implements Action {
  public readonly type = ActionTypes.UPDATE_ONGOING_STATION_ACTIVITY_HISTORY_LOADED;

  constructor(public response: BaseOneResponseInterface<IStationActivityHistoryCrudResponse>) {}
}

export class StationActivityHistoryActivityChangeLoading implements Action {
  public readonly type = ActionTypes.STATION_ACTIVITY_HISTORY_ACTIVITY_CHANGE_LOADING;

  constructor(public stationId: number, public requestData: IStationActivityHistoryActivityChangeRequest) {}
}

export class StationActivityHistoryActivityChangeLoaded implements Action {
  public readonly type = ActionTypes.STATION_ACTIVITY_HISTORY_ACTIVITY_CHANGE_LOADED;

  constructor(public response: BaseOneResponseInterface<IStationActivityHistoryCrudResponse>) {}
}

export class GetActivityAndLineLoading implements Action {
  public readonly type = ActionTypes.GET_ACTIVITY_AND_LINE_LOADING;

  constructor(public ongoingStationActivityHistory: IStationActivityHistoryCrudResponse) {}
}

export class GetActivityAndLineLoaded implements Action {
  public readonly type = ActionTypes.GET_ACTIVITY_AND_LINE_LOADED;

  constructor(
    public activityAndLine: IActivityAndLine,
    public ongoingStationActivityHistory: IStationActivityHistoryCrudResponse,
  ) {}
}

export class GetNoActivityStationLoading implements Action {
  public readonly type = ActionTypes.GET_NO_ACTIVITY_STATION_LOADING;

  constructor(public stationId: number) {}
}

export class GetNoActivityStationLoaded implements Action {
  public readonly type = ActionTypes.GET_NO_ACTIVITY_STATION_LOADED;

  constructor(public response: BaseOneResponseInterface<NoActivityStation>) {}
}

export class GetOngoingStationActivityHistoriesLoading implements Action {
  public readonly type = ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORIES_LOADING;

  constructor(public additionalCustomSearch: any[] = []) {}
}

export class GetOngoingStationActivityHistoriesLoaded implements Action {
  public readonly type = ActionTypes.GET_ONGOING_STATION_ACTIVITY_HISTORIES_LOADED;

  constructor(
    public response: GetManyResponseInterface<
      Exclude<IStationActivityHistoryCrudResponse, ['task', 'workOrder', 'station']>
    >,
  ) {}
}

export class UpdateMissingActivityMode implements Action {
  public readonly type = ActionTypes.UPDATE_MISSING_ACTIVITY_MODE;

  constructor(public isMissingActivityModeEnabled: boolean) {}
}

export class ResetStore implements Action {
  public readonly type = ActionTypes.RESET_STORE;
}

export class ChangeStationActivityError implements Action {
  readonly type = ActionTypes.CHANGE_STATION_ACTIVITY_ERROR;
}

export class FetchError implements Action {
  public readonly type = ActionTypes.FETCH_ERROR;

  constructor(public errorAction: ActionTypes) {}
}

export type StationActivityHistoryActions =
  | StationDropdownItemsLoading
  | StationDropdownItemsLoaded
  | GetOngoingStationActivityHistoryLoading
  | GetOngoingStationActivityHistoryLoaded
  | UpdateOngoingStationActivityHistoryLoading
  | UpdateOngoingStationActivityHistoryLoaded
  | StationActivityHistoryActivityChangeLoading
  | StationActivityHistoryActivityChangeLoaded
  | GetActivityAndLineLoading
  | GetActivityAndLineLoaded
  | GetNoActivityStationLoading
  | GetNoActivityStationLoaded
  | GetOngoingStationActivityHistoriesLoading
  | GetOngoingStationActivityHistoriesLoaded
  | ResetStore
  | ChangeStationActivityError
  | FetchError;
