import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from 'src/app/shared/model/interface/crud-response-interface.model';
import { ICommentLogsRawData } from 'src/app/store/reports/comment-logs/comment-logs.model';
import { IActivityComment } from './comment-feed.model';
import { omit } from 'lodash';

@Injectable({ providedIn: 'root' })
export class CommentFeedService {
  constructor(@Inject('API_BASE_URL') private readonly api: string, private readonly http: HttpClient) {}

  public createComment<T extends Partial<ICommentLogsRawData> = Partial<ICommentLogsRawData>>(
    payload: Pick<ICommentLogsRawData, 'commentMessage' | 'commentTypeId' | 'objectId' | 'objectType' | 'tags'>,
    params?: HttpParams,
  ): Observable<BaseOneResponseInterface<T>> {
    if (payload.objectType === 'lines') {
      return this.http.post<BaseOneResponseInterface<T>>(`${this.api}/lines/add-comment`, payload, {
        params,
      });
    }

    return this.http.post<BaseOneResponseInterface<T>>(`${this.api}/comments`, omit(payload, 'timestamp'), {
      params,
    });
  }

  public createComments<T extends Partial<ICommentLogsRawData> = Partial<ICommentLogsRawData>>(
    comments: readonly Pick<
      ICommentLogsRawData,
      'commentMessage' | 'commentTypeId' | 'objectId' | 'objectType' | 'tags'
    >[],
    params?: HttpParams,
  ): Observable<GetManyResponseInterface<T>> {
    return this.http.post<GetManyResponseInterface<T>>(`${this.api}/comments/bulk/create`, { comments }, { params });
  }

  public getLineActivityComments(lineId: number): Observable<GetManyResponseInterface<IActivityComment>> {
    return this.http.get<GetManyResponseInterface<IActivityComment>>(`${this.api}/lines/${lineId}/comments`);
  }

  public getActivityHistoryActivityComments(
    activityHistoryId: number,
  ): Observable<GetManyResponseInterface<IActivityComment>> {
    return this.http.get<GetManyResponseInterface<IActivityComment>>(
      `${this.api}/activity-histories/${activityHistoryId}/comments`,
    );
  }
}
