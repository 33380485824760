<mat-progress-bar
  mode="determinate"
  color="primary"
  [value]="dataProgress$"
  [hidden]="!isRawDataLoading$ || activityEquipmentTaskChartType === activityEquipmentTaskChartTypeEnum.BAR"
></mat-progress-bar>
<app-card cardClass="root-cause-analysis-card">
  <div class="row">
    <div class="col-12">
      <div class="button-group float-right fa-flip-horizontal">
        <scw-mat-button-group
          [buttons]="chartButtonGroup"
          [inputModel]="activityEquipmentTaskChartType"
          (inputModelChange)="onChartSwitchButtonClick($event)"
        ></scw-mat-button-group>
      </div>
      <ng-template
        [ngIf]="
          !helperService.isTouchDevice && activityEquipmentTaskChartType === activityEquipmentTaskChartTypeEnum.BAR
        "
      >
        <div class="button-group float-left">
          <scw-mat-button-group
            [buttons]="chartLayoutButtonGroup"
            [inputModel]="activitiesEquipmentChartLayout"
            [hidden]="isNoEquipmentOnly"
            (inputModelChange)="decideBarChartLayout($event)"
          ></scw-mat-button-group>
        </div>
      </ng-template>
    </div>
    <div
      class="col-12 text-danger"
      *ngIf="showManyDataWarning && activityEquipmentTaskChartType === activityEquipmentTaskChartTypeEnum.PIE"
    >
      <div class="text-left">
        <em class="fas fa-exclamation-circle p-r-5"></em>
        <label class="f-12">{{ 'rootCauseAnalysis.warning.narrowFilter' | translate }}</label>
      </div>
    </div>
    <div class="col-12">
      <ng-template [ngIf]="rawData$.length === 0 && chartData$ && chartData$.length === 0">
        {{ 'datatable.noData' | translate }}
      </ng-template>
    </div>
  </div>
  <div [hidden]="activityEquipmentTaskChartType !== activityEquipmentTaskChartTypeEnum.BAR">
    <app-tasks-charts
      [customColors]="customColors$"
      [chartData]="chartData$"
      [isDataLoading]="isChartDataLoading$"
      [isBusinessDate]="isBusinessDate"
      [chartConfiguration]="barChartConfiguration"
      [dateRange]="dateRange"
      (noEquipmentUsedEvent)="setNoEquipmentUsed($event)"
    ></app-tasks-charts>
  </div>
  <ng-template [ngIf]="activityEquipmentTaskChartType === activityEquipmentTaskChartTypeEnum.PIE">
    <div class="row" [class.d-none]="this.rawData$.length === 0">
      <div class="col-12">
        <div
          echarts
          id="sunburst-chart"
          class="demo-chart"
          [options]="sunburstChartOption"
          (chartInit)="setSunburstChartRef($event)"
        ></div>
        <div
          class="text-center f-12 f-w-600 p-t-20"
          *ngIf="isNoEquipmentAssignedExists">
          {{
            'rootCauseAnalysis.charts.equipment.noEquipmentAssigned.info'
              | translate
                : { noEquipmentAssigned: 'rootCauseAnalysis.charts.equipment.noEquipmentAssigned.label' | translate }
          }}
        </div>
      </div>
    </div>
  </ng-template>
</app-card>
<mat-progress-bar
  mode="determinate"
  color="primary"
  [value]="dataProgress$"
  [hidden]="!isRawDataLoading$"
></mat-progress-bar>
<app-card>
  <div class="row">
    <div class="col-lg-12 text-danger" [hidden]="!showManyDataWarning">
      <div class="text-left">
        <em class="fas fa-exclamation-circle p-r-5"></em>
        <label class="f-12">{{ 'rootCauseAnalysis.warning.narrowFilter' | translate }}</label>
      </div>
    </div>
    <div class="col-lg-2" [class.col-lg-3]="helperService.isTouchDevice">
      <scw-mat-select
        [clearAll]="false"
        [data]="stackChartDropdownOptions"
        [(inputModel)]="stackChartDropdownModel"
        (inputModelChange)="onGroupByDropdownChange($event)"
        [autoPosition]="false"
      ></scw-mat-select>
    </div>
    <div class="col-4">
      <ng-template [ngIf]="stackChartDropdownModel[0]?.id === eStackChartDropdownOption.TIME">
        <scw-mat-button-group
          [buttons]="stackChartTimeButtonGroup"
          [(inputModel)]="stackChartTimeButtonGroupModel"
          (inputModelChange)="prepareStackChartData($event)"
        ></scw-mat-button-group>
      </ng-template>
      <ng-template [ngIf]="stackChartDropdownModel[0]?.id === eStackChartDropdownOption.LOCATION">
        <scw-mat-button-group
          [buttons]="stackChartLocationButtonGroup"
          [(inputModel)]="stackChartLocationButtonGroupModel"
          (inputModelChange)="prepareStackChartData($event)"
        ></scw-mat-button-group>
      </ng-template>
    </div>
    <div class="col d-flex justify-content-end top-results" *ngIf="isGroupByProductOrLocation">
      <span class="top-results-text">{{
        'rootCauseAnalysis.charts.showTopOf.partOne' | translate: { totalCount: stackChartTotalNumberOfBars }
      }}</span>
      <scw-mat-input
        [placeholder]="stackChartTotalNumberOfBarsText"
        type="number"
        (onKeyup)="positiveNumberInputCheck(1, stackChartTotalNumberOfBars)"
        [(inputModel)]="stackChartBarLimitInputModel"
      ></scw-mat-input>
      <span class="top-results-text">{{
        'rootCauseAnalysis.charts.showTopOf.partTwo' | translate: { totalCount: stackChartTotalNumberOfBars }
      }}</span>
    </div>
  </div>
  <ng-template [ngIf]="rawData$.length === 0">
    {{ 'datatable.noData' | translate }}
  </ng-template>
  <div class="row" [class.d-none]="this.rawData$.length === 0">
    <div class="col-12">
      <div
        echarts
        id="stack-bar-chart"
        class="demo-chart"
        [options]="stackChartOption"
        (chartInit)="setStackBarChartRef($event)"
      ></div>
    </div>
  </div>
</app-card>
