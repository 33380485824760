import * as ObjectActions from './floor-plans.actions';
import * as oeeAppReducer from '../../../oee.reducer';
import * as AppActions from '../../../app/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import {
  ResponseArrayInterface,
  ResponseInterface,
} from '../../../../shared/model/interface/generic-api-response.model';
import { from, of } from 'rxjs';
import { FloorPlanInterface, LineInterface } from './floor-plans.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { FloorPlansService } from './floor-plans.service';
import { CreateFloorPlanCompleted } from './floor-plans.actions';

@Injectable()
export class FloorPlansEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly floorPlansService: FloorPlansService,
  ) {}

  getFloorPlans = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLANS_FLOOR_PLAN_DATA_LOADING),
      switchMap((object: ObjectActions.FloorPlanDataLoading) => {
        const andConditions = [];
        let httpParams: HttpParams = new HttpParams()
          .append('join', 'site||name')
          .append('page', String(object.tableQuery.page))
          .append('limit', String(object.tableQuery.pageSize || 1000));

        if (object.tableQuery.orderBy && object.tableQuery.orderDesc !== undefined) {
          httpParams = httpParams.set(
            'sort',
            `${object.tableQuery.orderBy},${object.tableQuery.orderDesc ? 'DESC' : 'ASC'}`,
          );
        }

        if (object.tableQuery.search) {
          andConditions.push({ name: { $cont: object.tableQuery.search } });
        }

        if (object.tableQuery.siteIds && object.tableQuery.siteIds.length > 0) {
          andConditions.push({ siteId: { $in: object.tableQuery.siteIds } });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        return this.floorPlansService.getFloorPlans(httpParams).pipe(
          map((response: ResponseArrayInterface<FloorPlanInterface>) => {
            response.data = response.data.map((floorPlan: FloorPlanInterface) => {
              return floorPlan;
            });
            return response;
          }),
          switchMap((response: ResponseArrayInterface<FloorPlanInterface>) => {
            return of(new ObjectActions.FloorPlanDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  createFloorPlan = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLANS_CREATE_FLOOR_PLAN_STARTED),
      switchMap((objectData: ObjectActions.CreateFloorPlan) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.floorPlansService.createFloorPlan(objectData.payload).pipe(
          switchMap((response: BaseOneResponseInterface<FloorPlanInterface>) => {
            return of(new ObjectActions.CreateFloorPlanCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  updateFloorPlan = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLANS_UPDATE_FLOOR_PLAN_STARTED),
      switchMap((objectData: ObjectActions.UpdateFloorPlan) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.floorPlansService.updateFloorPlan(objectData.floorPlanId, objectData.payload).pipe(
          switchMap((response: BaseOneResponseInterface<FloorPlanInterface>) => {
            return of(new ObjectActions.UpdateFloorPlanCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteFloorPlan = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLANS_DELETE_FLOOR_PLAN_STARTED),
      switchMap((objectData: ObjectActions.DeleteFloorPlan) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(
          this.floorPlansService.deleteFloorPlan(objectData.floorPlanId).pipe(
            switchMap((response: BulkResponseDataInterface) => {
              return of(new ObjectActions.DeleteFloorPlanCompleted(response), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
            }),
          ),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteFloorPlans = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLANS_BULK_DELETE_FLOOR_PLANS_STARTED),
      switchMap((objectData: ObjectActions.BulkDeleteFloorPlans) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(
          this.floorPlansService.bulkDeleteFloorPlans(objectData.floorPlanIds).pipe(
            switchMap((response: BulkResponseDataInterface) => {
              return of(new ObjectActions.BulkDeleteFloorPlansCompleted(response), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
            }),
          ),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLANS_LINE_DATA_LOADING),
      switchMap((objectData: ObjectActions.LineDataLoading) => {
        return this.floorPlansService.getLines(objectData.siteId).pipe(
          switchMap((response: ResponseInterface<LineInterface[]>) => {
            return of(new ObjectActions.LineDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
    ),
  );

  getFloorPlanImage = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLANS_GET_FLOOR_PLAN_IMAGE_LOADING),
      switchMap((objectData: ObjectActions.GetFloorPlanImageLoading) => {
        return this.floorPlansService.getFloorPlanImage(objectData.imagePath).pipe(
          switchMap((response: BaseOneResponseInterface<string>) => {
            return of(new ObjectActions.GetFloorPlanImageLoaded(response, objectData.imagePath));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );

  uploadFloorPlanImage = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FLOOR_PLANS_UPLOAD_FLOOR_PLAN_IMAGE_LOADING),
      switchMap((objectData: ObjectActions.UploadFloorPlanImageLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.floorPlansService.uploadFloorPlanImage(objectData.id, objectData.base64ImageContent).pipe(
          switchMap(() => {
            return of(new ObjectActions.UploadFloorPlanImageLoaded());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
