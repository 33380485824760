import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as AppActions from '../../app/actions';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseCrudResponse,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  AddProductInterface,
  AddProductResponseInterface,
  BulkEditProductInterface,
  BulkEditProductResponseInterface,
  CustomerInterface,
  EditProductInterface,
  EditProductResponseInterface,
  ProductBulkSaveManyInterface,
  ProductInterface,
  ProductsDownloadExcelFiltersInterface,
  ProductsLookupsInterface,
  ProductTableQueryParams,
} from './products.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import * as _ from 'lodash';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import * as ObjectActions from './products.actions';
import { SiteService } from '../../../shared/service/filter/site.service';
import { takeUntil } from 'rxjs/operators';
import { FieldTypes, IFilterOutput } from '../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { CustomersService } from '../customers/customers.service';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { ECellTypes, EExcelColumnWidth } from '../../../shared/service/excel/excel.enum';
import {IProductFamily} from "../product-family/product-family.model";
import {ProductFamilyService} from "../product-family/product-family.service";

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private readonly PRODUCTS = {
    PRODUCT_URL: `${this.baseUrl}/products`,
    PRODUCT_BULK_SAVE_URL: `${this.baseUrl}/products/bulk/save`,
  };
  private readonly PRODUCTFAMILY = {
    PRODUCTFAMILY_URL: `${this.baseUrl}/product-family`,
    BULK_EDIT_URL: `${this.baseUrl}/customers/bulk/edit`,
    BULK_DELETE_URL: `${this.baseUrl}/customers/bulk/delete`,
  };
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly routes = {
    products: `${this.baseUrl}/products`,
    bulkEdit: `${this.baseUrl}/products/bulk/edit`,
    bulkDelete: `${this.baseUrl}/products/bulk/delete`,
  };
  private userLanguage$: string = '';

  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly siteService: SiteService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly customersService: CustomersService,
    private readonly decimalHelper: DecimalHelper,
    private readonly productFamilyService: ProductFamilyService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.userLanguage$ = state.language;
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getProducts(params: HttpParams): Observable<GetManyResponseInterface<ProductInterface>> {
    return this.http.get<GetManyResponseInterface<ProductInterface>>(this.PRODUCTS.PRODUCT_URL, { params });
  }

  public downloadProductExcel(
    withData: boolean,
    filters: ProductsDownloadExcelFiltersInterface,
    httpParams: HttpParams,
    withErrorColumn: boolean = false,
    data?: ProductInterface[],
  ): void {
    let siteParams: HttpParams = new HttpParams();

    if (filters.siteIds !== -1) {
      siteParams = siteParams.set('s', JSON.stringify({ id: { $in: filters.siteIds } }));
    }

    let queryParams = new HttpParams()
      .set('limit', String(filters.limit))
      .set('s', JSON.stringify({ siteId: { $in: filters.siteIds === -1 ? undefined : filters.siteIds } }));

    const observables: [
      Observable<GetManyResponseInterface<CustomerInterface>>,
      Observable<GetManyResponseInterface<SiteCRUDInterface>>,
      Observable<GetManyResponseInterface<IProductFamily>>?,
      Observable<GetManyResponseInterface<ProductInterface>>?,
    ] = [
      this.customersService.getCustomers(queryParams),
      this.siteService.getSites(siteParams),
      this.productFamilyService.getProductFamily(queryParams),
    ];

    if (withData && !data) {
      observables.push(
        this.getProducts(httpParams.set('page', filters.selectedDownloadOffset).set('limit', filters.limit)),
      );
    }

    forkJoin(observables).subscribe((responseList) => {
      let customers: CustomerInterface[] = _.get(responseList, '0.data', []).map((customer: CustomerInterface) => {
        return {
          id: customer.id,
          name: `${customer.customerId} - ${customer.name}`,
        };
      });
      const sites: SiteCRUDInterface[] = _.get(responseList, '1.data', []);
      const productFamily: IProductFamily[] = _.get(responseList, '2.data', []);
      const sheetTitle: string = this.translate.instant('excel.items.products');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
      let excelData: ProductInterface[] = [];

      if (withData) {
        excelData = _.get(responseList, '3.data', []).map((product: ProductInterface) => {
          return {
            ...product,
            productSpeed: product.productSpeed ? this.decimalHelper.removeTrailingZeros(product.productSpeed) : null,
            customer: product.customer
              ? {
                  ...product.customer,
                  name: `${product.customer.customerId} - ${product.customer.name}`,
                }
              : null,
            minimumWaitingDuration: product.minimumWaitingDuration
              ? this.decimalHelper.removeTrailingZeros(product.minimumWaitingDuration)
              : null,
            maximumWaitingDuration: product.maximumWaitingDuration
              ? this.decimalHelper.removeTrailingZeros(product.maximumWaitingDuration)
              : null,
          };
        });

        if (data) {
          for (const product of data) {
            product.site = _.find(sites, { id: product.siteId });
            product.customer = _.find(customers, { id: product.customerId });
            product.productSpeed = product.productSpeed
              ? this.decimalHelper.removeTrailingZeros(product.productSpeed)
              : null;
            product.minimumWaitingDuration = product.minimumWaitingDuration
              ? this.decimalHelper.removeTrailingZeros(product.minimumWaitingDuration)
              : null;
            product.maximumWaitingDuration = product.maximumWaitingDuration
              ? this.decimalHelper.removeTrailingZeros(product.maximumWaitingDuration)
              : null;
          }
          excelData = data;
        }

        customers = ProductsService.updateCustomerListWithData(excelData, customers);
        ProductsService.setLookUpData(excelData, filters);
      }

      const excelOptions: CreateExcelInterface = this.getProductExcelColumns(
        sites,
        customers,
        productFamily,
        {
          materialType: filters.materialType,
          planningType: filters.planningType,
          unitType: filters.unitType,
        },
        withErrorColumn,
      );

      if (withData) {
        excelOptions.data = excelData;
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          sheetTitle,
          withData,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
          excelRowFormatLimit: 5001,
        },
      ];

      this.excelHelper
        .createExcel(excelName, worksheets, this.timezone, this.dateFormat$, this.timeFormat$, false)
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadProductExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
            this.store.dispatch(new AppActions.HideLoader());
          },
        );
    });
  }

  private getProductExcelColumns(
    sites: { id: number; name: string }[],
    customers: CustomerInterface[],
    productFamily: IProductFamily[],
    lookups: ProductsLookupsInterface,
    withErrorColumn: boolean,
  ): CreateExcelInterface {
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('products.excel.siteId.header'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: sites,
            prop: 'name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('products.excel.productId.header'),
          key: 'productId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
          isRequired: true,
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('products.excel.description.header'),
          key: 'description',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('products.excel.packageSize.header'),
          key: 'packageSize',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.standardSpeed'),
          key: 'productSpeed',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('products.excel.unit.header'),
          key: 'unit',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: _.get(lookups, 'unitType', []),
            prop: 'name',
            dataProperty: 'units.name',
            dataId: 'units.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('products.excel.materialType.header'),
          key: 'materialType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: _.get(lookups, 'materialType', []),
            prop: 'name',
            dataProperty: 'materials.name',
            dataId: 'materials.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('products.excel.planningType.header'),
          key: 'planningType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: _.get(lookups, 'planningType', []),
            prop: 'name',
            dataProperty: 'plannings.name',
            dataId: 'plannings.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('products.excel.productFamily.header'),
          key: 'productFamilyId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: productFamily,
            prop: 'name',
            dataProperty: 'productFamily.name',
            dataId: 'productFamily.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('products.excel.planningGroup.header'),
          key: 'planningGroup',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('products.excel.customerId.header'),
          key: 'customerId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: customers,
            prop: 'name',
            dataProperty: 'customer.name',
            dataId: 'customer.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('products.excel.column.minimumWaitingDuration'),
          key: 'minimumWaitingDuration',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: false,
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('products.excel.column.maximumWaitingDuration'),
          key: 'maximumWaitingDuration',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: false,
          isDecimalNumber: true,
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getProductsFromExcel(
    file: File,
  ): Promise<{ siteData: { id: number; name: string }[]; productData: { products: ProductInterface[] } } | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const productSheet: Worksheet = workbook.getWorksheet(this.translate.instant('excel.items.products'));
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const materialTypeDataSheet: Worksheet = workbook.getWorksheet('materialTypeDataSheet');
    const planningTypeDataSheet: Worksheet = workbook.getWorksheet('planningTypeDataSheet');
    const customerIdDataSheet: Worksheet = workbook.getWorksheet('customerIdDataSheet');

    if (!productSheet || !siteIdDataSheet || !materialTypeDataSheet || !planningTypeDataSheet || !customerIdDataSheet) {
      return null;
    }

    const siteColumns = {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    };

    const sites: { id: number; name: string }[] = this.excelHelper.getExcelRowsFromWorkSheet<{
      id: number;
      name: string;
    }>(siteIdDataSheet, siteColumns);

    if (!sites.length) {
      return null;
    }

    const { columns } = this.getProductExcelColumns(null, [], null, null, false);
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);

    return {
      productData: {
        products: this.excelHelper.getExcelRowsFromWorkSheet<ProductInterface>(productSheet, columnKeys, {
          dateFormat: this.dateFormat$,
          timeFormat: this.timeFormat$,
          timezone: this.timezone,
        }),
      },
      siteData: sites,
    };
  }

  public uploadExcel(products: ProductBulkSaveManyInterface): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.PRODUCTS.PRODUCT_BULK_SAVE_URL, products);
  }

  private static setLookUpData(excelData: ProductInterface[], filters: ProductsDownloadExcelFiltersInterface): void {
    for (const product of excelData) {
      product.materials = filters.materialType.find((materialType) => materialType.id === product.materialType);
      product.plannings = filters.planningType.find((planningType) => planningType.id === product.planningType);
      product.units = filters.unitType.find((unit) => unit.id === product.unit);
    }
  }

  private static updateCustomerListWithData(
    excelData: ProductInterface[],
    customers: CustomerInterface[],
  ): CustomerInterface[] {
    const customersOfProducts: Set<CustomerInterface> = excelData.reduce(
      (filteredData: Set<CustomerInterface>, product) => {
        if (product.customer !== null) {
          filteredData.add(product.customer);
        }

        return filteredData;
      },
      new Set<CustomerInterface>(),
    );

    return _.unionBy(customers, Array.from(customersOfProducts), 'id');
  }

  public deleteProducts(product: number[] | number): Observable<BulkResponseDataInterface | BaseCrudResponse> {
    if (Array.isArray(product) && product.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          products: product,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.routes.bulkDelete}`, httpOptions);
    }
    return this.http.delete<BaseCrudResponse>(`${this.routes.products}/${product[0]}`);
  }

  public addProduct(product: AddProductInterface): Observable<AddProductResponseInterface> {
    return this.http.post<AddProductResponseInterface>(`${this.routes.products}`, product);
  }

  public editProduct(product: EditProductInterface, productId: number): Observable<EditProductResponseInterface> {
    return this.http.patch<EditProductResponseInterface>(`${this.routes.products}/${productId}`, product);
  }

  public bulkEditProduct(products: BulkEditProductInterface[]): Observable<BulkEditProductResponseInterface> {
    return this.http.patch<BulkEditProductResponseInterface>(`${this.routes.bulkEdit}`, { products });
  }

  public getProductSearchParams(tableQuery: ProductTableQueryParams): string {
    const searchParams: { [key: string]: any } = {
      $and: [
        tableQuery.siteIds !== -1
          ? {
              siteId: {
                $in: tableQuery.siteIds,
              },
            }
          : {},
        {
          $or: [
            {
              productId: {
                $cont: tableQuery.search,
              },
            },
            {
              description: {
                $cont: tableQuery.search,
              },
            },
          ],
        },
      ],
    };

    if (tableQuery.advancedFilter) {
      const advancedFilter: IFilterOutput[] = tableQuery.advancedFilter.filters;

      for (const filter of advancedFilter) {
        if (filter.type === FieldTypes.predefined) {
          searchParams['$and'].push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              tableQuery.advancedFilter.target,
              _.get(filter.value, `[0][${filter.searchBy}]`, ''),
            ),
          );
          continue;
        }

        searchParams['$and'].push(
          this.advancedFilterService.generateQuery(
            filter.path,
            filter.type,
            filter.operator.name,
            filter.operator.type,
            tableQuery.advancedFilter.target,
            filter.value,
          ),
        );
      }
    }

    return JSON.stringify(searchParams);
  }

  public prepareProductsHttpParams(tableQuery: ProductTableQueryParams): HttpParams {
    let params = new HttpParams();
    params = params
      .append('join', 'site||name,decimalScaleLimit')
      .append('join', 'productBom')
      .append('join', 'productResources')
      .append('join', 'productVersion')
      .append('join', 'customer')
      .append('join', 'unitType||name')
      .append('join', 'productFamily')
      .append('join', 'customer.customerSegment||segmentId,name')
      .append('page', String(tableQuery.page))
      .append('limit', String(tableQuery.pageSize || 1000))
      .append('sort', 'id,DESC');

    if (tableQuery.search !== undefined || (tableQuery.siteIds !== -1 && tableQuery.siteIds !== undefined)) {
      params = params.append('s', this.getProductSearchParams(tableQuery));
    }

    if (tableQuery.orderDesc !== undefined) {
      const direction = tableQuery.orderDesc ? 'DESC' : 'ASC';
      params = params.set('sort', `${tableQuery.orderBy},${direction}`);
    }

    return params;
  }
}
