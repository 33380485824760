import { CommentObjectType } from 'src/app/store/shift-summary/shift-summary.model';
import { ELoadStatus } from 'src/constants.model';
import { IActivityComment } from '../comment-feed-service/comment-feed.model';
import { ICommentLogsRawData, ICommentLogsUser } from 'src/app/store/reports/comment-logs/comment-logs.model';

export type AllowedCommentObjectType =
  | `${CommentObjectType.activityHistoryComment}`
  | `${CommentObjectType.lineComment}`
  | `${CommentObjectType.stationActivityHistoryComment}`;

export interface ICommentFeedState {
  comments: IActivityComment[];
  commentsLoadStatus: ELoadStatus;
  commentToDeleteIndex: number | undefined;
  commentToEdit: IActivityComment | null;
  formProcess: TFormProcess;
  inMemoryComments: IInMemoryComment[];
  inMemoryMode: boolean;
  lastDeletedComment: IActivityComment | undefined;
  lastEditedComment: IActivityComment | undefined;
  lastPersistedComment: IActivityComment | undefined;
  lastSavedInMemoryComment: IInMemoryComment | undefined;
  lastBulkInsertResponse: unknown | undefined;
  tagsLoadStatus: ELoadStatus;
}

interface ICommentDto {
  commentMessage: string;
  commentTypeId: number;
  objectType: AllowedCommentObjectType;
  tags: number[];
}

export interface IInMemoryComment extends ICommentDto {
  commentDate: string;
  objectId: undefined;
}

export interface IPersistentComment extends ICommentDto {
  commentDate: undefined;
  objectId: number;
  timestamp: string;
}

export interface IEditFormParams {
  index: number;
  message: string;
  tags: number[] | null;
}

export interface ILoadCommentsParams {
  objectIds: readonly number[];
  objectType: AllowedCommentObjectType;
}

export type TCommentResponseDto = ICommentLogsRawData & { updateUser?: ICommentLogsUser; user: ICommentLogsUser };

export type TFormComment = Pick<IActivityComment, 'commentMessage' | 'commentTags'> & {
  objectIds: readonly (number | undefined)[];
  objectType: AllowedCommentObjectType;
  timestamp: string;
};

export type TFormProcess = 'idle' | 'adding' | 'deleting' | 'editing';

export type TModalMode = 'requiredComment' | 'feed';

export enum ETagBackgroundColors {
  orange = '#FF9347',
  red = '#DD332B',
  blue = '#448AFF'
}
