import { Action } from '@ngrx/store';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import {
  ICommentLogsDeleteResponse,
  IGetCommentLogRowResponse,
  ICommentLogsRawData,
  ICommentLogsFilterCard,
  ICommentLogsQueryParams,
  ICommentLogsTableDataResponse,
  ICommentCountWithLastCommentMessage,
  ICommentCountWithLastCommentMessageParams,
} from './comment-logs.model';

export const COMMENT_LOGS_DATA_LOADING = '[COMMENT LOGS] COMMENT LOGS DATA LOADING';
export const COMMENT_LOGS_DATA_LOADED = '[COMMENT LOGS] COMMENT LOGS DATA LOADED';
export const COMMENT_LOGS_DELETE_ROWS = '[COMMENT LOGS] COMMENT LOGS DELETE ROWS';
export const COMMENT_LOGS_DELETE_ROWS_COMPLETED = '[COMMENT LOGS] COMMENT LOGS DELETE ROWS COMPLETED';
export const COMMENT_LOGS_GET_ROW = '[COMMENT LOGS] COMMENT LOGS GET ROW';
export const COMMENT_LOGS_GET_ROW_COMPLETED = '[COMMENT LOGS] COMMENT LOGS GET ROW COMPLETED';
export const COMMENT_LOGS_EDIT_ROW = '[COMMENT LOGS] COMMENT LOGS EDIT ROW';
export const COMMENT_LOGS_EDIT_ROW_COMPLETED = '[COMMENT LOGS] COMMENT LOGS EDIT ROW COMPLETED';
export const COMMENT_LOGS_SET_SELECTED_FILTERS = '[COMMENT LOGS] COMMENT LOGS SET SELECTED FILTERS';
export const COMMENT_LOGS_COUNT_WITH_LAST_MESSAGE_DATA_LOADING = '[COMMENT LOGS] COMMENT LOGS COUNT WITH LAST MESSAGE DATA LOADING';
export const COMMENT_LOGS_COUNT_WITH_LAST_MESSAGE_DATA_LOADED = '[COMMENT LOGS] COMMENT LOGS COUNT WITH LAST MESSAGE DATA LOADED';
export const FETCH_ERROR = '[COMMENT LOGS] FETCH ERROR';

export class CommentLogsDataLoading implements Action {
  readonly type = COMMENT_LOGS_DATA_LOADING;

  constructor(public params: ICommentLogsQueryParams) {}
}

export class CommentLogsDataLoaded implements Action {
  readonly type = COMMENT_LOGS_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<ICommentLogsTableDataResponse>) {}
}

export class CommentLogsDeleteRows implements Action {
  readonly type = COMMENT_LOGS_DELETE_ROWS;
  constructor(public payload: number[]) {}
}

export class CommentLogsDeleteRowsCompleted implements Action {
  readonly type = COMMENT_LOGS_DELETE_ROWS_COMPLETED;
  constructor(public deletedCommentLogs: number[], public payload: ICommentLogsDeleteResponse) {}
}

export class CommentLogsGetRow implements Action {
  readonly type = COMMENT_LOGS_GET_ROW;
  constructor(public commentId: number) {}
}

export class CommentLogsGetRowCompleted implements Action {
  readonly type = COMMENT_LOGS_GET_ROW_COMPLETED;
  constructor(public payload: IGetCommentLogRowResponse) {}
}

export class CommentLogsEditRow implements Action {
  readonly type = COMMENT_LOGS_EDIT_ROW;
  constructor(public payload: Partial<ICommentLogsRawData>, public commentId: number) {}
}

export class CommentLogsEditRowCompleted implements Action {
  readonly type = COMMENT_LOGS_EDIT_ROW_COMPLETED;
  constructor(public payload: IGetCommentLogRowResponse) {}
}

export class CommentLogsSetSelectedFilters implements Action {
  readonly type = COMMENT_LOGS_SET_SELECTED_FILTERS;
  constructor(public filters: ICommentLogsFilterCard) {}
}

export class CommentLogsCountWithLastMessageDataLoading implements Action {
  readonly type = COMMENT_LOGS_COUNT_WITH_LAST_MESSAGE_DATA_LOADING;

  constructor(public params: ICommentCountWithLastCommentMessageParams) {}
}

export class CommentLogsCountWithLastMessageDataLoaded implements Action {
  readonly type = COMMENT_LOGS_COUNT_WITH_LAST_MESSAGE_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<ICommentCountWithLastCommentMessage>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type CommentLogsActions =
  | CommentLogsDataLoading
  | CommentLogsDataLoaded
  | CommentLogsDeleteRows
  | CommentLogsDeleteRowsCompleted
  | CommentLogsGetRow
  | CommentLogsGetRowCompleted
  | CommentLogsEditRow
  | CommentLogsEditRowCompleted
  | CommentLogsSetSelectedFilters
  | CommentLogsCountWithLastMessageDataLoading
  | CommentLogsCountWithLastMessageDataLoaded
  | FetchError;
