import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './activity-logs-table.actions';
import { of } from 'rxjs';
import * as AppActions from '../app/actions';
import { ActivityLogsService } from '../reports/activity-logs/activity-logs.service';
import { emptyAction } from '../../../constants';
import { HelperService } from '../../shared/service/helper.service';
import { IExcelColumnDefinition } from 'src/app/shared/service/excel/excel.helper';
import { GenericHelperService } from 'src/app/shared/service/generic.helper.service';

@Injectable()
export class ActivityLogsTableEffects {
  constructor(private readonly actions$: Actions, public activityLogService: ActivityLogsService) {}

  downloadActivityLogsTableExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ACTIVITY_LOGS_TABLE_DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.ActivityLogsTableDownloadExcel) => {
        const excelColumnHeaders: IExcelColumnDefinition[][] = objectData.worksheetsColumnDefinitions.map(
          (worksheetsColumnDefinition: IExcelColumnDefinition[]) =>
            GenericHelperService.moveItemAsLastInArray(
              worksheetsColumnDefinition,
              worksheetsColumnDefinition.find((columnDefinition) => columnDefinition.key === 'lastCommentMessage'),
            ),
        );
        this.activityLogService.downloadExcel(
          ...HelperService.argumentClone(objectData.params, excelColumnHeaders, false, objectData.data),
        );
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchDataError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
