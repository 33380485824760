import { NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ScwMatButtonModule } from 'src/app/shared/component/scw-mat-ui/scw-mat-button/scw-mat-button.module';
import { ScwModalSize } from 'src/app/shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { CommentFeedModalComponent } from '../comment-feed-modal/comment-feed-modal.component';
import { AllowedCommentObjectType, IInMemoryComment } from '../comment-feed-modal/comment-feed-modal.model';

@Component({
  selector: 'app-comment-feed-button',
  standalone: true,
  imports: [NgIf, ScwMatButtonModule],
  templateUrl: './comment-feed-button.component.html',
  styleUrls: ['./comment-feed-button.component.scss'],
})
export class CommentFeedButtonComponent implements OnDestroy, OnInit {
  @Input() activityName: string | undefined;
  @Input() iconButton: boolean = true;
  @Input() maxWidth: string | undefined;
  @Input() objectIds: readonly (number | undefined)[] | undefined;
  @Input() objectType: AllowedCommentObjectType | undefined;
  @Input() size: 'md' | 'sm' | 'xs' = 'md';
  @Input() text: string | undefined;

  @Output() public onModalCloseEvent = new EventEmitter<void>();

  @Input() inMemoryComments: readonly IInMemoryComment[] = [];
  @Output() inMemoryCommentsChange = new EventEmitter<IInMemoryComment[]>();

  private modal: NgbModalRef | undefined;

  constructor(private readonly hostElement: ElementRef<HTMLElement>, private readonly modalService: NgbModal) {}

  public ngOnInit(): void {
    this.applyMaxWidth();
  }

  public ngOnDestroy(): void {
    this.modal?.close();
  }

  public openModal(): void {
    this.modal = this.modalService.open(CommentFeedModalComponent, { windowClass: ScwModalSize.small });
    this.modal.componentInstance.activityName = this.activityName;
    this.modal.componentInstance.objectIds = this.objectIds;
    this.modal.componentInstance.objectType = this.objectType;
    this.modal.componentInstance.inMemoryComments = this.inMemoryComments;
    this.modal.componentInstance.inMemoryCommentsChange.subscribe((event: IInMemoryComment[]) =>
      this.inMemoryCommentsChange.emit(event),
    );

    this.modal.result.then(() => {}, () => this.onModalCloseEvent.emit());
  }

  private applyMaxWidth(): void {
    if (!this.maxWidth) {
      return;
    }

    this.hostElement.nativeElement.style.setProperty('--button-max-width', this.maxWidth);
  }
}
