import { Injectable } from '@angular/core';
import {
  GetManyResponseInterface,
  BaseOneResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as AppActions from '../app/actions';
import * as oeeAppReducer from '../oee.reducer';
import * as ObjectActions from './actions';
import {
  IDistributionResponse,
  IManualCount,
  ICommentsResponse,
} from '../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { ManualCountService } from './service';
import { BulkResponse, ResponseInterface } from '../../shared/model/interface/generic-api-response.model';

@Injectable()
export class ManualCountEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly service: ManualCountService,
    private store: Store<oeeAppReducer.OeeAppState>,
  ) {}


  saveManualCountComments = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.SAVE_MANUAL_COUNT_COMMENTS),
    switchMap((objectData: ObjectActions.SaveManualCountComments) => {
      return this.service.saveComments(objectData.comments).pipe(
        switchMap((response: GetManyResponseInterface<ICommentsResponse>) => {
          return of(new ObjectActions.ManualCountCommentsSaved(response));
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err));
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.FetchDataError(err));
    }),
  ));


  setHourlyCount = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.SET_HOURLY_COUNT),
    switchMap((objectData: ObjectActions.SetHourlyCount) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.setHourlyCount(objectData.manualCount)).pipe(
        switchMap((response: BaseOneResponseInterface<IManualCount>) => {
          return of(
            new ObjectActions.SetHourlyCountCompleted(response.data, objectData.duringOngoingDistribution),
            new AppActions.HideLoader(),
          );
        }),
        catchError((err) => {
          return of(new ObjectActions.SetHourlyCountFailed(err, objectData.duringOngoingDistribution), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.SetHourlyCountFailed(err), new AppActions.HideLoader());
    }),
  ));


  setBulkHourlyCount = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.SET_BULK_HOURLY_COUNT),
    switchMap((objectData: ObjectActions.SetBulkHourlyCount) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.setBulkHourlyCount(objectData.manualCounts)).pipe(
        switchMap((response: ResponseInterface<BulkResponse[]>) => {
          return of(new ObjectActions.SetBulkHourlyCountCompleted(response.data, objectData.duringOngoingDistribution));
        }),
        catchError((err) => {
          return of(new ObjectActions.SetBulkHourlyCountFailed(err, objectData.duringOngoingDistribution));
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.SetHourlyCountFailed(err));
    }),
  ));


  createManualCount = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.CREATE_MANUAL_COUNT),
    switchMap((objectData: ObjectActions.CreateManualCount) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.createManualCount(objectData.data)).pipe(
        switchMap((response: BaseOneResponseInterface<IManualCount>) => {
          if (objectData.isCalledFromSetHourlyCount) {
            return of(new ObjectActions.SetHourlyCountCompleted(response.data));
          }

          return of(new ObjectActions.CreateManualCountCompleted(response.data));
        }),
        catchError((err) => {
          if (objectData.isCalledFromSetHourlyCount) {
            return of(new ObjectActions.SetHourlyCountFailed(err));
          }

          return of(new ObjectActions.CreateManualCountFailed(err));
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.CreateManualCountFailed(err));
    }),
  ));


  getDistribution = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.MANUAL_COUNT_GET_DISTRIBUTION_LOADING),
    switchMap((objectData: ObjectActions.ManualCountGetDistributionLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());

      const httpParams: HttpParams = new HttpParams()
        .append('goodCount', objectData.params.goodCount)
        .append('scrapCount', objectData.params.scrapCount)
        .append('workOrderId', objectData.params.workOrderId);

      return from(this.service.getDistribution(httpParams)).pipe(
        switchMap((response: GetManyResponseInterface<IDistributionResponse>) => {
          return of(new ObjectActions.ManualCountGetDistributionLoaded(response.data), new AppActions.HideLoader());
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
    }),
  ));


  setDistribution = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.MANUAL_COUNT_SET_DISTRIBUTION_LOADING),
    switchMap((objectData: ObjectActions.ManualCountSetDistributionLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return from(this.service.setDistribution(objectData.params)).pipe(
        switchMap(() => {
          const actions: Action[] = [
            new ObjectActions.ManualCountSetDistributionLoaded(),
            ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
          ];
          return of(...actions);
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
    }),
  ));
}
