import { HomeActions, HomeActionTypes } from './home.actions';
import { HomeStateInterface, RequestTypeEnum, UpdateCurrentActivityResponseModel } from './home.model';
import { IHomeMetricSetItem } from '../settings/home-page-display-settings/home-page-display-settings.model';
import { PhaseDurationTypeEnum } from '../../view/home/home-metrics/common-phase-duration-metric/common-phase-duration-metric.model';
import * as _ from 'lodash';
import { HelperService } from '../../shared/service/helper.service';

export const initialState: HomeStateInterface = {
  line: undefined,
  user: undefined,
  errors: [],
  generatedActivityButtons: [],
  homeInformationLoading: false,
  homeInformationLoaded: false,
  activityTreeGraphData: [],
  cicoAccess: false,
  cicoPending: false,
  saveWorkOrderFeedbackLoading: false,
  saveWorkOrderFeedbackLoaded: false,
  getWorkOrderFeedbackLoading: false,
  getWorkOrderFeedbackLoaded: false,
  updateWorkOrderFeedbackLoading: false,
  updateWorkOrderFeedbackLoaded: false,
  workOrderCloseFeedback: {
    id: null,
    workOrderId: null,
    crewPerformPoint: null,
    goodThings: '',
    needToBeImprove: '',
    variancesExplanation: '',
  },
  activityTreeGraphDataLoading: false,
  activityTreeGraphDataLoaded: false,
  changeActivityLoading: false,
  changeActivityCompleted: false,
  updateCurrentActivityLoading: false,
  checkWorkOrderLoading: false,
  updateWorkOrderLoading: false,
  getWorkOrderLoading: false,
  workOrderStatusLoading: false,
  getVersionDetailLoading: false,
  getProductPerformanceLoading: false,
  productSearchLoading: false,
  getWorkOrderNumbersLoading: false,
  searchUsersByFilterLoading: false,
  searchAssetsByFilterLoading: false,
  homeMetricSetItemsLoading: false,
  homeMetricSetItemsLoaded: false,
  homeMetricSetItems: [],
  phaseDurationLoading: false,
  phaseDurationLoaded: false,
  phaseDurations: null,
  phaseComments: {
    preRun: null,
    postRun: null,
    run: null,
  },
  phaseCommentsLoading: false,
  phaseCommentsLoaded: false,
  phaseCommentSubmitLoading: false,
  phaseCommentSubmitLoaded: false,
  isDigitalFormsActive: false,
  isAutomatedPhaseCommentsActive: false,
  phaseCommentsEnteredStatus: null,
  previousPhase: null,
  getLineInfoThatSelectedWorkOrderLoading: false,
  getLineInfoThatSelectedWorkOrderLoaded: false,
  getLineInfoThatSelectedWorkOrderData: null,
  splitWorkOrderOnLinePredictedWorkOrderNumberLoading: false,
  splitWorkOrderOnLinePredictedWorkOrderNumberLoaded: false,
  splitWorkOrderOnLinePredictedWorkOrderNumberData: null,
  requestType: RequestTypeEnum.DEFAULT,
};

export function homeReducer(state = initialState, action: HomeActions): HomeStateInterface {
  switch (action.type) {
    case HomeActionTypes.ChangeActivity:
      return {
        ...state,
        changeActivityLoading: true,
        changeActivityCompleted: false,
      };
    case HomeActionTypes.SetChangeActivityLoading:
      return {
        ...state,
        changeActivityLoading: action.loadingState,
      };
    case HomeActionTypes.ChangeActivityError:
      return {
        ...state,
        changeActivityLoading: false,
        changeActivityCompleted: false,
      };
    case HomeActionTypes.ChangeActivityCompleted:
      return {
        ...state,
        changeActivityLoading: false,
        changeActivityCompleted: true,
      };
    case HomeActionTypes.StartLoadHomeInformation:
      return {
        ...state,
        homeInformationLoading: true,
      };
    case HomeActionTypes.GetQuantityFromSensor:
      return {
        ...state,
      };
    case HomeActionTypes.ActivityTreeGraphDataLoaded:
      return {
        ...state,
        activityTreeGraphDataLoading: false,
        activityTreeGraphDataLoaded: true,
        activityTreeGraphData: action.payload['data'],
      };
    case HomeActionTypes.SaveWorkOrderFeedback:
      return {
        ...state,
        saveWorkOrderFeedbackLoading: true,
        saveWorkOrderFeedbackLoaded: false,
      };
    case HomeActionTypes.SaveWorkOrderFeedbackLoaded:
      return {
        ...state,
        saveWorkOrderFeedbackLoading: false,
        saveWorkOrderFeedbackLoaded: true,
      };
    case HomeActionTypes.ClearWorkOrderFeedbackForm:
      return {
        ...state,
        saveWorkOrderFeedbackLoading: false,
        saveWorkOrderFeedbackLoaded: false,
      };
    case HomeActionTypes.GetWorkOrderFeedbackLoading:
      return {
        ...state,
        getWorkOrderFeedbackLoading: true,
        getWorkOrderFeedbackLoaded: false,
      };
    case HomeActionTypes.GetWorkOrderFeedbackLoaded:
      const workOrderCloseFeedback = {
        id: null,
        workOrderId: null,
        crewPerformPoint: null,
        goodThings: '',
        needToBeImprove: '',
        variancesExplanation: '',
      };

      if (action.workOrderCloseFeedbackData) {
        workOrderCloseFeedback.id = action.workOrderCloseFeedbackData.id;
        workOrderCloseFeedback.workOrderId = action.workOrderCloseFeedbackData.workOrderId;
        workOrderCloseFeedback.crewPerformPoint = action.workOrderCloseFeedbackData.crewPerformPoint;
        workOrderCloseFeedback.goodThings = action.workOrderCloseFeedbackData.goodThings;
        workOrderCloseFeedback.needToBeImprove = action.workOrderCloseFeedbackData.needToBeImprove;
        workOrderCloseFeedback.variancesExplanation = action.workOrderCloseFeedbackData.variancesExplanation;
      }
      return {
        ...state,
        ...{
          workOrderCloseFeedback,
        },
        getWorkOrderFeedbackLoading: false,
        getWorkOrderFeedbackLoaded: true,
      };
    case HomeActionTypes.UpdateWorkOrderFeedbackLoading:
      return {
        ...state,
        updateWorkOrderFeedbackLoading: true,
        updateWorkOrderFeedbackLoaded: false,
      };
    case HomeActionTypes.UpdateWorkOrderFeedbackCompleted:
      return {
        ...state,
        updateWorkOrderFeedbackLoading: false,
        updateWorkOrderFeedbackLoaded: true,
      };
    case HomeActionTypes.SetHomeActivityButtons:
      return {
        ...state,
        generatedActivityButtons: action.payload,
      };
    case HomeActionTypes.HomeInformationLoaded:
      return {
        ...state,
        homeInformationLoading: false,
        homeInformationLoaded: true,
        ...action.payload.data,
      };
    case HomeActionTypes.UpdateCurrentActivity:
      return {
        ...state,
        requestType: action.requestType,
        updateCurrentActivityLoading: true,
      };
    case HomeActionTypes.UpdateCurrentActivityCompleted:
      const line = HelperService.cloneDeep(state.line);
      const data: UpdateCurrentActivityResponseModel = HelperService.cloneDeep(action.payload.data);

      if (data.selectedPhaseId !== undefined) {
        line.activityCard.selectedPhase = data.selectedPhaseId;
      }

      if (data.currentTask !== null) {
        line.activityCard.ignorePhase = data.currentTask.ignorePhase === 1;
      }

      return {
        ...state,
        ...{
          line,
        },
        updateCurrentActivityLoading: false,
      };
    case HomeActionTypes.CheckWorkOrder:
      return {
        ...state,
        checkWorkOrderLoading: true,
      };
    case HomeActionTypes.CheckWorkOrderCompleted:
      return {
        ...state,
        checkWorkOrderLoading: false,
      };
    case HomeActionTypes.UpdateWorkOrder:
      return {
        ...state,
        updateWorkOrderLoading: true,
      };
    case HomeActionTypes.UpdateWorkOrderCompleted:
      return {
        ...state,
        updateWorkOrderLoading: false,
      };
    case HomeActionTypes.GetWorkOrder:
      return {
        ...state,
        getWorkOrderLoading: true,
      };
    case HomeActionTypes.GetWorkOrderCompleted:
      return {
        ...state,
        getWorkOrderLoading: false,
      };
    case HomeActionTypes.WorkOrderStatus:
      return {
        ...state,
        workOrderStatusLoading: true,
      };
    case HomeActionTypes.WorkOrderStatusCompleted:
      return {
        ...state,
        workOrderStatusLoading: false,
      };
    case HomeActionTypes.GetVersionDetail:
      return {
        ...state,
        getVersionDetailLoading: true,
      };
    case HomeActionTypes.GetVersionDetailCompleted:
      return {
        ...state,
        getVersionDetailLoading: false,
      };
    case HomeActionTypes.GetProductPerformance:
      return {
        ...state,
        getProductPerformanceLoading: true,
      };
    case HomeActionTypes.GetProductPerformanceCompleted:
      return {
        ...state,
        getProductPerformanceLoading: false,
      };
    case HomeActionTypes.ProductSearch:
      return {
        ...state,
        productSearchLoading: true,
      };
    case HomeActionTypes.ProductSearchCompleted:
      return {
        ...state,
        productSearchLoading: false,
      };
    case HomeActionTypes.GetWorkOrderNumbers:
      return {
        ...state,
        getWorkOrderNumbersLoading: true,
      };
    case HomeActionTypes.GetWorkOrderNumbersCompleted:
      return {
        ...state,
        getWorkOrderNumbersLoading: false,
      };
    case HomeActionTypes.SearchUsersByFilter:
      return {
        ...state,
        searchUsersByFilterLoading: true,
      };
    case HomeActionTypes.SearchUsersByFilterCompleted:
      return {
        ...state,
        searchUsersByFilterLoading: false,
      };
    case HomeActionTypes.SearchAssetsByFilter:
      return {
        ...state,
        searchAssetsByFilterLoading: true,
      };
    case HomeActionTypes.SearchAssetsByFilterCompleted:
      return {
        ...state,
        searchAssetsByFilterLoading: false,
      };
    case HomeActionTypes.FetchError:
      const loadedActions = {
        [HomeActionTypes.UpdateCurrentActivityCompleted]: { updateCurrentActivityLoading: false },
      };

      const actionType = action.actionType || 'noAction';

      return _.assign(
        {
          ...state,
          errors: action.payload,
        },
        loadedActions[actionType] || {},
      );
    case HomeActionTypes.HomeMetricSetItemsSetDefaultState:
      return {
        ...state,
        homeMetricSetItemsLoading: false,
        homeMetricSetItemsLoaded: false,
      };
    case HomeActionTypes.HomeMetricSetItemsLoading:
      return {
        ...state,
        homeMetricSetItemsLoading: true,
        homeMetricSetItemsLoaded: false,
      };
    case HomeActionTypes.HomeMetricSetItemsLoaded:
      let metrics: IHomeMetricSetItem[] = [];

      if (action.payload.length) {
        metrics = action.payload[0].homeMetricSet[0].homeMetricSetItems;
      }

      return {
        ...state,
        ...{
          homeMetricSetItems: metrics,
        },
        homeMetricSetItemsLoading: false,
        homeMetricSetItemsLoaded: true,
      };
    case HomeActionTypes.PhaseDurationsLoading:
      return {
        ...state,
        phaseDurationLoading: true,
        phaseDurationLoaded: false,
      };
    case HomeActionTypes.PhaseDurationsLoaded:
      return {
        ...state,
        ...{
          phaseDurations: action.payload,
        },
        phaseDurationLoading: false,
        phaseDurationLoaded: true,
      };
    case HomeActionTypes.PhaseDurationIntervalTick:
      const phaseDurations = HelperService.cloneDeep(state.phaseDurations);

      if (phaseDurations?.[action.phaseTickType]) {
        phaseDurations[action.phaseTickType] = +phaseDurations[action.phaseTickType] + 60;
        phaseDurations[PhaseDurationTypeEnum.totalDuration] = +phaseDurations[PhaseDurationTypeEnum.totalDuration] + 60;
      }

      return {
        ...state,
        ...{
          phaseDurations,
        },
      };
    case HomeActionTypes.ResetPhaseDurationOnSiteLineSelection:
      return {
        ...state,
        ...{
          phaseDurationLoading: false,
          phaseDurationLoaded: false,
          phaseDurations: null,
        },
      };
    case HomeActionTypes.PhaseCommentsLoading:
      return {
        ...state,
        phaseCommentsLoading: true,
        phaseCommentsLoaded: false,
      };
    case HomeActionTypes.UpdatePhaseComment:
    case HomeActionTypes.DeletePhaseComment:
    case HomeActionTypes.CreatePhaseComment:
      return {
        ...state,
        phaseCommentSubmitLoading: true,
        phaseCommentSubmitLoaded: false,
      };
    case HomeActionTypes.PhaseCommentsLoaded:
      return {
        ...state,
        phaseComments: {
          ...action.response,
        },
        phaseCommentsLoading: false,
        phaseCommentsLoaded: true,
      };
    case HomeActionTypes.UpdatePhaseCommentCompleted:
    case HomeActionTypes.CreatePhaseCommentCompleted:
      return {
        ...state,
        phaseComments: {
          ...state.phaseComments,
          ...action.response,
        },
        phaseCommentSubmitLoading: false,
        phaseCommentSubmitLoaded: true,
      };
    case HomeActionTypes.DeletePhaseCommentCompleted:
      const newPhaseComments = { ...state.phaseComments };
      for (const key of Object.keys(newPhaseComments)) {
        if (newPhaseComments[key] !== null && newPhaseComments[key].id === action.deletedId) {
          newPhaseComments[key] = null;
          break;
        }
      }

      return {
        ...state,
        phaseComments: newPhaseComments,
        phaseCommentSubmitLoading: false,
        phaseCommentSubmitLoaded: true,
      };
    case HomeActionTypes.UpdateIsAutomatedPhaseCommentsActive:
      return {
        ...state,
        isAutomatedPhaseCommentsActive: action.isAutomatedPhaseCommentsActive,
      };
    case HomeActionTypes.UpdatePhaseCommentsEnteredStatus:
      return {
        ...state,
        phaseCommentsEnteredStatus: action.phaseCommentsEnteredStatus,
      };
    case HomeActionTypes.UpdatePreviousPhase:
      return {
        ...state,
        previousPhase: action.previousPhase,
      };
    case HomeActionTypes.GetLineInfoThatSelectedWorkOrder:
      return {
        ...state,
        getLineInfoThatSelectedWorkOrderLoading: true,
        getLineInfoThatSelectedWorkOrderLoaded: false,
      };
    case HomeActionTypes.GetLineInfoThatSelectedWorkOrderCompleted:
      return {
        ...state,
        getLineInfoThatSelectedWorkOrderData: action.payload,
        getLineInfoThatSelectedWorkOrderLoading: false,
        getLineInfoThatSelectedWorkOrderLoaded: true,
      };
    case HomeActionTypes.SetRequestType:
      return {
        ...state,
        requestType: action.requestType,
      };
    case HomeActionTypes.SplitWorkOrderOnLinePredictedWorkOrderNumberLoad:
      return {
        ...state,
        splitWorkOrderOnLinePredictedWorkOrderNumberLoading: true,
        splitWorkOrderOnLinePredictedWorkOrderNumberLoaded: false,
      };
    case HomeActionTypes.SplitWorkOrderOnLinePredictedWorkOrderNumberLoaded:
      return {
        ...state,
        splitWorkOrderOnLinePredictedWorkOrderNumberData: action.payload,
        splitWorkOrderOnLinePredictedWorkOrderNumberLoading: false,
        splitWorkOrderOnLinePredictedWorkOrderNumberLoaded: true,
      };
    default:
      return state;
  }
}
