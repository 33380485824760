import { Injectable, Type } from '@angular/core';
import { FilterableObjectTypes } from '../../component/filter/filterable-objects.class';
import { ApiServer, DependencyType, DropdownSettingsInterface } from '../../component/filter/dropdown/dropdown.model';
import { DefaultSelectionValuesInterface, RowConfigurationInterface } from '../../component/filter/filter.class';
import { DropdownComponent } from '../../component/filter/dropdown/dropdown.component';
import { TranslateService } from '@ngx-translate/core';
import { AdvancedFilterClass, TargetEndpoints } from '../../component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterComponent } from '../../component/filter/advanced-filter/advanced-filter.component';
import {
  GenericCrudRequestConstructionParameterFilters,
  GenericCrudRequestConstructionParameters,
} from '../../model/interface/generic-api-request.model';
import { filter, take } from 'rxjs/operators';
import { IAdvancedFilterStore } from '../../../store/advanced-filter/advanced-filter.model';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { AdvancedFilterService } from '../../component/filter/advanced-filter/advanced-filter.service';
import { IFilterMap, IFilterMapped } from '../../component/file-generator/file-generator.model';
import { DateRangeFilterInterface } from '../../../view/reports/check-in-logs/check-in-logs.model';
import { StartLoadFilterShifts } from '../../../store/filter/shift/shift.actions';
import { Subject, takeWhile, skipWhile } from 'rxjs';
import { EFilterDropdownElements } from '../../../store/filter/filter.model';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '../../../store/user/model';

@Injectable({ providedIn: 'root' })
export class FilterHelperService {
  public isDefaultSelectionPushed: boolean = false;

  private defaultSite$: string;
  private userStateValuesUpdated: boolean;
  private selectedFilterCardData: { dateRange: DateRangeFilterInterface; } = {
    dateRange: null
  };
  private siteIds: number[] | -1;
  private lineIds: number[] | -1;


  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly router: Router,
  ) {
    this.updateUserStateValues();

    this.router.events.subscribe((params: unknown): void => {
      if (params instanceof NavigationEnd) {
        this.selectedFilterCardData = { dateRange: null };
      }
    });
  }

  static formatDropdownFilterOptionOutput<T>(output: T[] | T): -1 | T[] | T {
    return (output?.[0] ?? -1) === -1 ? -1 : output;
  }

  public getGenericDropdownFilter(
    entity: {
      type: Type<FilterableObjectTypes>;
      name: string;
      elementId?: EFilterDropdownElements;
    },
    cls: string = 'col-md-3 m-t-5 m-b-5',
    overrideOptions: Partial<DropdownSettingsInterface> = {},
    depends?: DependencyType,
  ): RowConfigurationInterface {
    return {
      cls,
      elementId: entity.elementId || `${entity.name}Dropdown`,
      type: DropdownComponent,
      object: entity.type,
      outputOptions: {
        filterObjectId: entity.name,
        filterObjectProp: 'id',
        returnFilterObjectAllProp: false,
      },
      options: {
        isRequired: false,
        singleSelection: false,
        badgeShowLimit: 1,
        text: this.translate.instant(`filterCard.${entity.name}.dropdownPlaceHolder`),
        ...overrideOptions,
      },
      ...(depends ? { depends } : {}),
    };
  }

  public static getGenericAdvancedFilter(
    advancedFilterObject: Type<AdvancedFilterClass>,
    cls: string = 'col-md-4 m-t-5 m-b-5',
    elementId: string = 'advancedFilter',
  ): RowConfigurationInterface {
    return {
      advancedFilterObject,
      cls,
      elementId,
      type: AdvancedFilterComponent,
      outputOptions: {
        filterObjectId: 'advancedFilter',
      },
      options: {
        isRequired: false,
        autoApply: true,
      },
    };
  }

  public pageFilterChangeOperations(
    event: any,
    pageData: {
      pageDataCrudParameters: GenericCrudRequestConstructionParameters;
      pageName: string;
      isFirstTimeOpening: boolean[];
      selectedItems: unknown[];
    },
    additionalOperations: Function,
    target: TargetEndpoints = TargetEndpoints.NestJSCrud,
  ): void {
    this.store
      .select('advancedFilterStore')
      .pipe(
        filter((state: IAdvancedFilterStore) => !state.isDefaultFiltersLoading && state.isDefaultFiltersLoaded),
        take(1),
      )
      .subscribe((state: IAdvancedFilterStore) => {
        pageData.pageDataCrudParameters.page = 1;

        if (pageData.isFirstTimeOpening[0]) {
          pageData.pageDataCrudParameters.advancedFilter = {
            target,
            filters: [],
            page: pageData.pageName,
          };

          if (!_.isEmpty(_.get(state.defaultFilters, pageData.pageName, null))) {
            pageData.pageDataCrudParameters.advancedFilterPage = pageData.pageName;
            pageData.pageDataCrudParameters.advancedFilter.filters = this.advancedFilterService.prepareForOutput(
              state.defaultFilters[pageData.pageName],
            );
          }

          pageData.isFirstTimeOpening[0] = false;
        } else if (event.advancedFilter !== null) {
          pageData.pageDataCrudParameters.advancedFilter = _.get(event, 'advancedFilter', null);
          pageData.pageDataCrudParameters.advancedFilterPage = pageData.pageName;
        }

        pageData.selectedItems = [];
        pageData.pageDataCrudParameters.filters = [
          ...(Array.isArray(event.site) ? [{ field: 'siteId', ids: event.site }] : []),
          ...(Array.isArray(event.line) ? [{ field: 'lineId', ids: event.line }] : []),
        ];

        additionalOperations();
      });
  }

  public getMappedFilteredValues(requestModel: IFilterMap): IFilterMapped[] {
    if (!requestModel) {
      return null;
    }

    const mappedValues: IFilterMapped[] = [];
    this.store
      .select(requestModel.storeName)
      .pipe(take(1))
      .subscribe((state: any) => {
        if (!state) {
          return;
        }

        let filter = [];
        const reqIds: number[] | number = requestModel.filteredDataIds as number | number[];

        if (reqIds === -1 || reqIds === undefined) {
          filter = state.data;
        } else {
          filter = state.data.filter((state: string | number) =>
            (reqIds as number[]).includes(state[requestModel.filterPrefix]),
          );
        }
        filter.map((prop) => {
          mappedValues.push({
            value: prop.hasOwnProperty(requestModel.expectedProperty) ? prop[requestModel.expectedProperty] : null,
            id: prop.hasOwnProperty('id') ? prop['id'] : null,
          });
        });
      });
    return mappedValues;
  }

  public static getFiltersFromFilterCardEvent($event): GenericCrudRequestConstructionParameterFilters[] {
    return _.toPairs($event).reduce(
      (dropdownFilters: GenericCrudRequestConstructionParameterFilters[], [key, value]) => {
        if (!_.isNil(key) && !_.isNil(value) && !_.isNil(_.fromPairs([key, value])) && Array.isArray(value)) {
          const filter: GenericCrudRequestConstructionParameterFilters = {
            field: key,
            ids: value,
          };
          dropdownFilters.push(filter);
        }

        return dropdownFilters;
      },
      [],
    );
  }

  public filterChangeOperationsWithoutAdvancedFilter(
    event: unknown,
    pageData: {
      pageDataCrudParameters: GenericCrudRequestConstructionParameters;
      pageName: string;
      selectedItems: unknown[];
    },
  ): void {
    pageData.pageDataCrudParameters.page = 1;
    pageData.selectedItems = [];
    pageData.pageDataCrudParameters.filters = FilterHelperService.getFiltersFromFilterCardEvent(event);
  }

  public setDefaultSiteAndPushSubject(
    subject: Subject<DefaultSelectionValuesInterface>,
    defaultSelectionValues: DefaultSelectionValuesInterface = {},
  ): void {
    this.isDefaultSelectionPushed = false;

    if (defaultSelectionValues.hasOwnProperty(EFilterDropdownElements.siteSingleSelectDropdown)) {
      subject.next(defaultSelectionValues);
      this.isDefaultSelectionPushed = true;

      return;
    }

    this.updateUserStateValues();
    this.store
      .select('siteFilter')
      .pipe(
        skipWhile(() => !this.userStateValuesUpdated),
        takeWhile(() => !this.isDefaultSelectionPushed),
      )
      .subscribe((state) => {
        if (state.isLoaded && !state.isLoading) {
          if (state.data.length >= 1) {
            defaultSelectionValues[EFilterDropdownElements.siteSingleSelectDropdown] = {
              key: 'id',
              values: [this.defaultSite$ ? Number(this.defaultSite$) : state.data[0].id],
            };

            subject.next(defaultSelectionValues);
          }

          this.isDefaultSelectionPushed = true;
          this.userStateValuesUpdated = false;
        }
      });
  }

  public getShifts(dateRange: DateRangeFilterInterface, siteIds: number[] | -1, lineIds?: number[] | -1): void {
    const isDateRangeChanged: boolean =
      dateRange &&
      this.selectedFilterCardData.dateRange &&
      this.selectedFilterCardData.dateRange.startDate &&
      this.selectedFilterCardData.dateRange.endDate &&
      (!this.selectedFilterCardData.dateRange.startDate.isSame(dateRange.startDate) ||
        !this.selectedFilterCardData.dateRange.endDate.isSame(dateRange.endDate));

    const isSiteChanged: boolean = siteIds && this.siteIds !== siteIds;
    this.siteIds = siteIds;

    const isLineChanged: boolean = lineIds && this.lineIds !== lineIds;
    this.lineIds = lineIds;

    if ((isDateRangeChanged || isSiteChanged || isLineChanged || this.selectedFilterCardData.dateRange === null) && dateRange !== null) {
      this.selectedFilterCardData.dateRange = dateRange;

      this.store.dispatch(
        new StartLoadFilterShifts(ApiServer.NestJS, this.selectedFilterCardData.dateRange, siteIds !== -1 ? siteIds : null, lineIds !== -1 ? lineIds : null, true),
      );
    }
  }

  private updateUserStateValues(): void {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        this.defaultSite$ = state.defaultSite;
        this.userStateValuesUpdated = true;
      });
  }
}
