import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as FloorPlanActions from './floor-plan.actions'
import { catchError, switchMap } from 'rxjs/operators';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { HttpParams } from '@angular/common/http';
import { from, of } from 'rxjs';
import { FloorPlanService } from '../../../shared/service/filter/floor-plan.service';
import { FloorPlanCRUDInterface } from '../../../shared/component/filter/filter.class';

@Injectable()
export class FilterFloorPlanEffects {
  constructor(
    private actions$: Actions<FloorPlanActions.FilterFloorPlanActions>,
    public floorPlanService: FloorPlanService
  ) {
  }


  loadFilterFloorPlans$ = createEffect(() => this.actions$.pipe(
    ofType(FloorPlanActions.FilterFloorPlanActionTypes.FilterFloorPlansLoading),
    switchMap((objectData: FloorPlanActions.FilterFloorPlansLoading) => {
      const params: HttpParams = generateHttpOption(objectData.options);
      return from(this.floorPlanService.getData(params)).pipe(
        switchMap((response: FloorPlanCRUDInterface[]) => {
          return of(new FloorPlanActions.FilterFloorPlansLoaded(response))
        }),
        catchError(() => {
          return of(new FloorPlanActions.FilterFloorPlansLoaded([]))
        }),
      );
    }),
  ));
}
