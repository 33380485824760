import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScwMatButtonSize, ScwMatButtonType } from './scw-mat-button.model';
import { ITreeNodeMetaColorsData } from '../../../../store/settings/tree-nodes/tree-nodes.model';

@Component({
  selector: 'scw-mat-button',
  templateUrl: './scw-mat-button.component.html',
  styleUrls: ['./scw-mat-button.component.scss'],
})
export class ScwMatButtonComponent implements OnInit {
  @Input() type: ScwMatButtonType = 'cta';
  @Input() size: ScwMatButtonSize = 'md';
  @Input() isSubmitButton: boolean = false;
  @Input() block: boolean = false;
  @Input() iconButton: boolean = false;
  @Input() fab: boolean = false;
  @Input() disabled: boolean = false;
  @Input() badgeText: string = '';
  @Input() className:
    | string[]
    | {
        [klass: string]: any;
      };
  @Input() form: string;
  @Input() colors: ITreeNodeMetaColorsData | undefined;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly hostElement: ElementRef) {}

  public onClickEmitterForScwMatForm(): void {}

  public onClickEmitter(): void {
    this.onClick.emit();
    this.onClickEmitterForScwMatForm();
  }

  public ngOnInit(): void {
    if (this.colors) {
      this.hostElement.nativeElement.style.setProperty('--standard-background-color', this.colors.background);
      this.hostElement.nativeElement.style.setProperty('--standard-hover-color', this.colors.hover);
    }
  }
}
