import { Action } from '@ngrx/store';
import {
  ILinesReportInterface,
  IWidgetDataInterface,
  SiteLineFilterDataInterface,
  TableColumnInterface,
  WidgetDataInterface,
} from './line-view-enhanced.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';

export const START_LINE_VIEW_ENHANCED_FILTER_DATA_LOADING = '[LINE_VIEW_ENHANCED] START LINE VIEW FILTER DATA LOADING';
export const LINE_VIEW_ENHANCED_FILTER_DATA_LOADED = '[LINE_VIEW_ENHANCED] LINE VIEW FILTER DATA LOADED';
export const LINE_VIEW_ENHANCED_FILTER_DATA_GENERATED = '[LINE_VIEW_ENHANCED] FILTER DATA GENERATED';
export const LINE_VIEW_ENHANCED_SET_SELECTED_SITE_FILTERS = '[LINE_VIEW_ENHANCED] SET SELECTED SITE FILTERS';
export const LINE_VIEW_ENHANCED_SET_SELECTED_LINE_FILTERS = '[LINE_VIEW_ENHANCED] SET SELECTED LINE FILTERS';
export const LINE_VIEW_ENHANCED_SET_SELECTED_TABLE_COLUMN_SETTINGS =
  '[LINE_VIEW_ENHANCED] SET SELECTED TABLE COLUMN SETTINGS';
export const SET_SELECTED_WIDGET_SETTINGS = '[LINE_VIEW_ENHANCED] SET SELECTED WIDGET SETTINGS';
export const GET_LINE_VIEW_ENHANCED_DATA_STARTED = '[LINE_VIEW_ENHANCED] GET LINE VIEW DATA STARTED';
export const LINE_VIEW_ENHANCED_DATA_LOADED = '[LINE_VIEW_ENHANCED] LINE VIEW DATA LOADED';
export const LINE_VIEW_ENHANCED_START_FILTERS_AND_SETTINGS_SAVING =
  '[LINE_VIEW_ENHANCED] START FILTERS AND SETTINGS SAVING';
export const LINE_VIEW_ENHANCED_FILTERS_AND_SETTINGS_SAVED = '[LINE_VIEW_ENHANCED]  FILTERS AND SETTINGS SAVED';
export const LINE_VIEW_ENHANCED_FETCH_DATA_ERROR = '[LINE_VIEW_ENHANCED] FETCH DATA ERROR';
export const LINE_VIEW_ENHANCED_CANCEL_LOADING = '[LINE_VIEW_ENHANCED] CANCEL LOADING';
export const SET_TABLE_SETTINGS = '[LINE_VIEW_ENHANCED] SET TABLE SETTINGS';
export const LINE_VIEW_ENHANCED_SET_FILTER_SETTINGS = '[LINE_VIEW_ENHANCED] SET FILTER SETTINGS';
export const LINE_COUNT_OF_SITES_LOADING = '[LINE_VIEW_ENHANCED] LINE COUNT OF SITES LOADING';
export const LINE_COUNT_OF_SITES_LOADED = '[LINE_VIEW_ENHANCED] LINE COUNT OF SITES LOADED';

export class StartLineViewEnhancedFilterDataLoading implements Action {
  readonly type = START_LINE_VIEW_ENHANCED_FILTER_DATA_LOADING;

  constructor() {}
}

export class LineViewEnhancedFilterDataLoaded implements Action {
  readonly type = LINE_VIEW_ENHANCED_FILTER_DATA_LOADED;

  constructor(public payload: { data: IWidgetDataInterface }) {}
}

export class SetFilterDataGenerated implements Action {
  readonly type = LINE_VIEW_ENHANCED_FILTER_DATA_GENERATED;

  constructor(public payload: SiteLineFilterDataInterface) {}
}

export class SetSelectedSiteFilters implements Action {
  readonly type = LINE_VIEW_ENHANCED_SET_SELECTED_SITE_FILTERS;

  constructor(public payload: number[]) {}
}

export class SetSelectedLineFilters implements Action {
  readonly type = LINE_VIEW_ENHANCED_SET_SELECTED_LINE_FILTERS;

  constructor(public payload: number[]) {}
}

export class SetSelectedTableColumnSettings implements Action {
  readonly type = LINE_VIEW_ENHANCED_SET_SELECTED_TABLE_COLUMN_SETTINGS;

  constructor(public payload: TableColumnInterface[]) {}
}

export class SetSelectedWidgetSettings implements Action {
  readonly type = SET_SELECTED_WIDGET_SETTINGS;

  constructor(public payload: WidgetDataInterface[]) {}
}

export class GetLineViewEnhancedDataStarted implements Action {
  readonly type = GET_LINE_VIEW_ENHANCED_DATA_STARTED;

  constructor(public mode: string) {}
}

export class LineViewEnhancedDataLoaded implements Action {
  readonly type = LINE_VIEW_ENHANCED_DATA_LOADED;

  constructor(public payload: ILinesReportInterface, public mode: string) {}
}

export class StartFiltersAndSettingsSaving implements Action {
  readonly type = LINE_VIEW_ENHANCED_START_FILTERS_AND_SETTINGS_SAVING;

  constructor() {}
}

export class FiltersAndSettingsSaved implements Action {
  readonly type = LINE_VIEW_ENHANCED_FILTERS_AND_SETTINGS_SAVED;

  constructor() {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;
  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class SetFilterSettings implements Action {
  readonly type = LINE_VIEW_ENHANCED_SET_FILTER_SETTINGS;

  constructor(public siteIds: number[], public lineIds: number[], public setAsDefault: boolean) {}
}

export class LineCountOfSitesLoading implements Action {
  readonly type = LINE_COUNT_OF_SITES_LOADING;

  constructor(public selectedSites: number[]) {}
}

export class LineCountOfSitesLoaded implements Action {
  readonly type = LINE_COUNT_OF_SITES_LOADED;

  constructor(public payload: number) {}
}

export class FetchDataError implements Action {
  readonly type = LINE_VIEW_ENHANCED_FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export class CancelLoading implements Action {
  readonly type = LINE_VIEW_ENHANCED_CANCEL_LOADING;
}

export type LineViewEnhancedEnhancedActions =
  | StartLineViewEnhancedFilterDataLoading
  | LineViewEnhancedFilterDataLoaded
  | SetFilterDataGenerated
  | SetSelectedSiteFilters
  | SetSelectedLineFilters
  | GetLineViewEnhancedDataStarted
  | LineViewEnhancedDataLoaded
  | SetSelectedTableColumnSettings
  | SetSelectedWidgetSettings
  | StartFiltersAndSettingsSaving
  | FiltersAndSettingsSaved
  | SetTableSettings
  | SetFilterSettings
  | LineCountOfSitesLoading
  | LineCountOfSitesLoaded
  | FetchDataError
  | CancelLoading;
