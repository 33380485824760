import { Moment } from 'moment';
import { HttpParams } from '@angular/common/http';
import { FilterConditionTypes } from '../../../shared/component/filter/filter.class';
import { IObjectWithEntityTranslations } from '../../../shared/service/entity-translator/entity-translator.model';

export interface DatePickerInterFace {
  startDate: Moment;
  endDate: Moment;
}

export interface ITaskPerformanceFilter {
  dateRange: DatePickerInterFace;
  siteId: number[];
  lineIds: number[];
  activityIds: number[];
  equipmentIds: number[];
  taskTitles: string[];
  shiftIds: number[];
  analysisMode: ETaskPerformanceAnalysisMode;
}

export interface ITaskPerformanceState {
  isTaskPerformanceDataLoading: boolean;
  isTaskPerformanceDataLoaded: boolean;
  fileDownloadStatus: boolean;
  taskPerformanceData: ITaskPerformanceData[];
  shifts: ITaskPerformanceShift[];
}

export interface ITaskPerformanceEquipment {
  id: number;
  equipmentName: string;
}

export interface ITaskPerformanceActivity {
  id: number;
  name: string;
  activitySequenceId: string | null;
}

export interface ITaskPerformanceTask {
  id: number;
  title: string;
  originalTitle: string;
  lcl: string;
  ucl: string;
  equipment?: ITaskPerformanceEquipment;
}

export interface ITaskPerformanceWorkOrder {
  id: number;
  woNumber: string;
  product: ITaskPerformanceProduct;
}

export interface ITaskPerformanceLine extends IObjectWithEntityTranslations{
  id: number;
  timer: string;
  selectedPhaseId: number;
  title?: string;
  currentTask?: ITaskPerformanceTask;
  currentWorkOrder?: ITaskPerformanceWorkOrder;
  currentActivity?: ITaskPerformanceActivity;
}

export interface ITaskPerformanceActivityHistory extends IObjectWithEntityTranslations {
  id: number;
  start: string;
  end: string;
  shiftDay: string;
  phaseId: number;
  task: ITaskPerformanceTask;
  line: ITaskPerformanceLine;
  activity: ITaskPerformanceActivity;
  activitySequenceId: string | null;
  workOrder: ITaskPerformanceWorkOrder;
}

export interface ITaskPerformanceProduct {
  id: number;
  productId: string;
  description: string;
}

export interface ITaskPerformanceShift {
  shiftName: string;
  id: number;
  weekDayStart: number;
  weekDayEnd: number;
  shiftId: number;
  formattedStartDate: string;
  formattedEndDate: string;
  shiftInfo: string;
  siteId: number;
  lineId: number;
}

export interface ITaskPerformanceSite {
  id: number;
  preRunPhaseName: string;
  postRunPhaseName: string;
  runPhaseName: string;
}

export interface IRequestBody {
  fields?: string[];
  search?: object;
  join?: object;
  page?: number;
  offset?: number;
  useReplicaForGetIfAvailable?: boolean;
}

export interface IRequestParamMap {
  property: string;
  condition: FilterConditionTypes;
  entityProperty: string;
}

export interface ITaskPerformanceRequestParams {
  siteId: number;
  siteParams: HttpParams;
  lineParams: IRequestBody;
  activityHistoryParams: IRequestBody;
  shiftParams: HttpParams;
}

export interface ITaskPerformanceResponse {
  lines: ITaskPerformanceLine[];
  activityHistories: ITaskPerformanceActivityHistory[];
  shifts: ITaskPerformanceShift[];
  site: ITaskPerformanceSite;
}

export interface ITaskPerformanceDataAndCurrentShifts {
  taskPerformanceData: ITaskPerformanceData[];
  shifts: ITaskPerformanceShift[];
}

export interface ITaskPerformanceData extends IObjectWithEntityTranslations{
  taskId: number;
  taskName: string;
  lineId: number;
  lineTitle: string;
  activityId: number;
  activityName: string;
  productId: string;
  productDescription: string;
  phaseId: number;
  phaseName: string;
  woNumber: string;
  shiftDay: string;
  startDate: string;
  endDate: string;
  duration: number;
  ucl: string;
  lcl: string;
  activitySequenceId: string | null;
}

export enum ETaskPerformanceAnalysisMode {
  WORK_ORDER_BASED = 'workOrderBased',
  PHASE_BASED = 'phaseBased',
  RAW_DATA = 'rawData',
  INSTANCE_BASED = 'instanceBased',
}
