import { MemoizedSelector, createSelector } from '@ngrx/store';
import { OeeAppState } from '../oee.reducer';
import { User } from './model';

export const selectUserDateTimeFormat = createSelector(
  (state: OeeAppState) => state.user,
  (user) => user.dateTimeFormat,
);

export const selectUserTimezone: MemoizedSelector<OeeAppState, string | undefined> = createSelector(
  (state: OeeAppState) => state.user,
  (user: User) => user.timezone,
);
