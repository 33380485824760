import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import { KpiCardMetrics } from '../../../view/reports/labor-performance/labor-performance.model';
import { ScwMatRadioGroupOption } from '../scw-mat-ui/scw-mat-radio-group/scw-mat-radio-group.model';

export interface PageConfigurationInterface {
  table: TableConfigurationInterface[];
  widget: WidgetConfigurationInterface[];
  chart: ChartConfigurationInterface[];
  custom: ICustomSectionConfiguration[];
}

export interface TableConfigurationInterface {
  name: string;
  selected: boolean;
  title: string;
  disabled: boolean;
  sortable?: boolean;
  isTableRow?: boolean;
  linkedTo?: string;
  opposedTo?: string[];
  type?: PageConfigurationTypes;
}

export interface WidgetConfigurationInterface {
  name: string | KpiCardMetrics;
  selected: boolean;
  title: string;
  disabled: boolean;
  icon?: string;
  cardColor?: string;
  cardValue?: string;
  displayPercentage?: boolean;
  componentName?: string;
  type?: PageConfigurationTypes;
  tooltipText?: string;
}

export interface ChartConfigurationInterface {
  name: string;
  disabled: boolean;
  title: string;
  selected: boolean;
  componentName?: string;
  type?: PageConfigurationTypes;
}

export interface IPageConfigurationGroup<T> {
  id: T;
  name: string;
  values: ITableHeader[];
  icon?: string;
  description?: string;
  isVisible?: boolean;
  isSortable?: boolean;
}

export enum SectionTypes {
  Table = 'table',
  Widget = 'widget',
  Custom = 'custom',
}

export interface ICustomSectionRow {
  name: string;
  selected: boolean;
  title: string;
  disabled: boolean;
}

export interface ICustomSectionConfiguration {
  name: string;
  bordered: boolean;
  header: string;
  headerIconClass: string;
  label?: string;
  rows: ICustomSectionRow[];
  sortable: boolean;
  hasRadioButtonBehaviour?: boolean;
  disableDefaultSort?: boolean;
  show?: boolean;
  isRadio?: true;
  radioGroup?: ScwMatRadioGroupOption[];
  defaultRadioButtonValue?: any;
}

export interface ICustomConfigurationDbEntry {
  name: string;
  value: string[];
}

export interface ICustomSectionOrderConfiguration {
  type: PageConfigurationTypes;
  index?: number;
}

export type PageConfigurationDataType =
  | TableConfigurationInterface
  | WidgetConfigurationInterface
  | ChartConfigurationInterface;
