import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CheckInDateRangeInterface,
  CheckInGetOneInterface,
  CheckInsResponseInterface,
  IGetDistinctCheckInObjects,
  IOngoingCheckIn,
  GetDistinctCheckInObjectsInterfaceData,
  ICheckInUpdateMany,
  ICheckOutMany,
  IBulkCheckIn,
  ICheckOut,
} from '../../../store/check-in/check-in.model';
import { mysqlTimestampFormat } from '../../helper/date';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { CheckInDestinationTypeEnum, CheckInOngoingUpdateInterface } from '../../../view/home/cico/cico.model';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class CICOService {
  private readonly BASE_CHECK_INS_URL: string = '/check-ins';
  private readonly BULK: string = '/bulk';
  private readonly CHECK_IN: string = '/check-in';
  private readonly CHECK_IN_BULK: string = '/bulk/check-in';
  private readonly CHECK_OUT: string = '/check-out';
  private readonly UPDATE_ONGOING_CHECK_IN: string = '/ongoing-check-in';

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  private readonly routes = {
    lineCheckInData: '/lines/:id/check-ins',
  };

  public checkIn(requestData: IOngoingCheckIn): Observable<BaseOneResponseInterface<CheckInGetOneInterface>> {
    return this.http.post<BaseOneResponseInterface<CheckInGetOneInterface>>(
      `${this.api}${this.BASE_CHECK_INS_URL}${this.CHECK_IN}`,
      requestData,
    );
  }

  public checkInBulk(requestData: IBulkCheckIn): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(
      `${this.api}${this.BASE_CHECK_INS_URL}${this.CHECK_IN_BULK}`,
      requestData,
    );
  }

  public checkOut(id: number, checkOutData: ICheckOut): Observable<BaseOneResponseInterface<CheckInGetOneInterface>> {
    return this.http.patch<BaseOneResponseInterface<CheckInGetOneInterface>>(
      `${this.api}${this.BASE_CHECK_INS_URL}/${id}${this.CHECK_OUT}`,
      checkOutData,
    );
  }

  public getCheckInHomeData(lineId: number, stationId?: number): Observable<CheckInsResponseInterface> {
    const url: string = this.routes.lineCheckInData.replace(':id', String(lineId));
    const queryParams: {
      $and: object[];
    } = {
      $and: [],
    };
    const checkInItemLimit: number = 6;
    const orFilter = [];
    let httpParams: HttpParams = new HttpParams().set('limit', String(checkInItemLimit));

    orFilter.push({ checkOutTime: { $isnull: true } });
    queryParams.$and.push({ $or: orFilter });

    if (stationId) {
      queryParams.$and = [
        ...queryParams.$and,
        { destinationTypeId: { $eq: CheckInDestinationTypeEnum.DESTINATION_TYPE_LINE_STATION } },
        { destinationObjectId: { $eq: stationId } },
      ];
    }

    httpParams = httpParams.set('checkInSearch', JSON.stringify(queryParams));
    return this.http.get<CheckInsResponseInterface>(`${this.api}${url}`, { params: httpParams });
  }

  public getCheckInLogData(
    lineId: number,
    timezone: string,
    isLast24HoursSelected: boolean = false,
    stationId?: number,
  ): Observable<CheckInsResponseInterface> {
    const url: string = this.routes.lineCheckInData.replace(':id', String(lineId));
    const queryParams: {
      $and: object[];
    } = {
      $and: [],
    };
    const checkInItemLimit: number = 500;
    const orFilter = [];
    let httpParams: HttpParams = new HttpParams();
    orFilter.push({ checkOutTime: { $isnull: true } });

    if (isLast24HoursSelected) {
      const endDate = moment().tz(timezone);
      const startDate = endDate.clone().subtract(1440, 'minutes');
      const dateRange: CheckInDateRangeInterface = {
        startDate: startDate.format(mysqlTimestampFormat),
        endDate: endDate.format(mysqlTimestampFormat),
      };
      orFilter.push(
        {
          checkOutTime: {
            $between: [dateRange.startDate, dateRange.endDate],
          },
        },
        {
          checkInTime: {
            $between: [dateRange.startDate, dateRange.endDate],
          },
        },
      );
    }

    queryParams.$and.push({ $or: orFilter });

    if (stationId) {
      queryParams.$and.push(
        { destinationTypeId: { $eq: CheckInDestinationTypeEnum.DESTINATION_TYPE_LINE_STATION } },
        { destinationObjectId: { $eq: stationId } },
      );
    }

    httpParams = httpParams.set('checkInSearch', JSON.stringify(queryParams)).set('limit', String(checkInItemLimit));
    return this.http.get<CheckInsResponseInterface>(`${this.api}${url}`, { params: httpParams });
  }

  public updateOngoingCheckIn(
    id: number,
    body: CheckInOngoingUpdateInterface,
  ): Observable<BaseOneResponseInterface<CheckInGetOneInterface & { isAvailable: boolean }>> {
    return this.http.patch<BaseOneResponseInterface<CheckInGetOneInterface & { isAvailable: boolean }>>(
      `${this.api}${this.BASE_CHECK_INS_URL}/${id}${this.UPDATE_ONGOING_CHECK_IN}`,
      body,
    );
  }

  public getDistinctCheckInObjects(
    data: IGetDistinctCheckInObjects,
  ): Observable<GetManyResponseInterface<GetDistinctCheckInObjectsInterfaceData>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('sourceTypeId', String(data.sourceTypeId));

    return this.http.get<GetManyResponseInterface<GetDistinctCheckInObjectsInterfaceData>>(
      `${this.api}${this.BASE_CHECK_INS_URL}/distinct-check-in-objects`,
      { params: httpParams },
    );
  }

  public updateBulkOngoingCheckIns(body: ICheckInUpdateMany): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(
      `${this.api}${this.BASE_CHECK_INS_URL}${this.BULK}${this.UPDATE_ONGOING_CHECK_IN}`,
      body,
    );
  }

  public checkOutBulkOngoingCheckIns(body: ICheckOutMany): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(
      `${this.api}${this.BASE_CHECK_INS_URL}${this.BULK}${this.CHECK_OUT}`,
      body,
    );
  }
}
