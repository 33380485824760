<div class="badge" *ngIf="badgeText !== ''">{{ badgeText }}</div>
<button
  mat-flat-button
  type="button"
  class="scw-mat-button"
  [class.block]="block"
  [disabled]="disabled"
  [class.mat-cta]="type === 'cta'"
  [class.mat-standard]="type === 'standard'"
  [class.mat-danger]="type === 'danger'"
  [class.mat-ghost]="type === 'ghost'"
  [class.icon-button]="iconButton"
  [class.button-xs]="size === 'xs'"
  [class.button-sm]="size === 'sm'"
  [class.button-md]="size === 'md'"
  [class.button-lg]="size === 'lg'"
  [class.button-xl]="size === 'xl'"
  [class.button-xxl]="size === 'xxl'"
  [class.fab]="fab"
  [style.color]="colors?.text"
  [ngClass]="className"
  (click)="onClickEmitter()"
  [attr.form]="form"
>
  <ng-content></ng-content>
</button>
