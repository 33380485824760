import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IJob, IJobExcelData, IJobsDownloadExcelFilter } from './jobs.model';
import { ExcelHelperService } from '../../../shared/service/excel/excel.helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import { ECellTypes, EExcelColumnWidth, EExcelSheetType } from '../../../shared/service/excel/excel.enum';
import { ICreateExcel, ICreateExcelSheet } from '../../../shared/service/excel/excel.helper';
import { SiteService } from '../../../shared/service/filter/site.service';
import { STATIC_MAX_LIMIT_OF_CRUD } from '../../../../constants';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import * as moment from 'moment-timezone';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import * as ObjectActions from './jobs.actions';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  private readonly URLs = {
    JOBS_URL: '/jobs',
  };
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly translate: TranslateService,
    private readonly siteService: SiteService,
    private store: Store<OeeAppState>,
  ) {
    const destroySubject: Subject<boolean> = new Subject<boolean>();
    this.store
      .select('user')
      .pipe(takeUntil(destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          destroySubject.next(true);
          destroySubject.complete();
        }
      });
  }
  getJobs(params: HttpParams) {
    return this.http.get<GetManyResponseInterface<IJob>>(this.baseUrl + this.URLs.JOBS_URL, {
      params,
    });
  }

  createJob(data: any) {
    return this.http.post<BaseOneResponseInterface<IJob>>(this.baseUrl + this.URLs.JOBS_URL, data);
  }

  editJob(data: any, id: number) {
    return this.http.patch<BaseOneResponseInterface<IJob>>(`${this.baseUrl + this.URLs.JOBS_URL}/${id}`, data);
  }

  deleteJobs(ids: number[]) {
    if (Array.isArray(ids) && ids.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          jobs: ids,
        },
      };

      return this.http.delete<BulkResponseDataInterface>(
        `${this.baseUrl + this.URLs.JOBS_URL}/bulk/delete`,
        httpOptions,
      );
    }

    return this.http.delete<BaseCrudResponse>(`${this.baseUrl + this.URLs.JOBS_URL}/${ids[0]}`);
  }

  async getJobsFromExcel(file: File): Promise<IJobExcelData> {
    const workbook: Workbook = await ExcelHelperService.getExcelWorkBookFromFile(file);
    const jobSheet: Worksheet = workbook.getWorksheet(this.translate.instant('excel.items.jobs'));
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');

    if (!jobSheet || !siteIdDataSheet) {
      return null;
    }

    const siteColumns = {
      id: {
        key: 'id',
        type: ValueType.Number,
        dataValidationType: ECellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: ECellTypes.CUSTOM,
      },
    };

    const sites: { id: number; name: string }[] = this.excelHelper.getExcelRowsFromWorkSheet<{
      id: number;
      name: string;
    }>(siteIdDataSheet, siteColumns);

    if (!sites.length) {
      return null;
    }

    const { columns } = this.getJobsExcelColumns(null, false);
    const columnKeys = ExcelHelperService.getSheetColumnKeys(columns);

    return {
      jobData: {
        jobs: this.excelHelper.getExcelRowsFromWorkSheet<IJob>(jobSheet, columnKeys),
      },
      siteData: sites,
    };
  }

  private getJobsExcelColumns(sites: { id: number; name: string }[], withErrorColumn: boolean) {
    const excelColumns: ICreateExcel = {
      columns: [
        {
          header: this.translate.instant('equipmentLists.excel.siteId.header'), // TODO translate
          key: 'siteId',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: sites,
            prop: 'name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('jobs.excel.jobName.header'),
          key: 'jobName',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
          isRequired: true,
        },
        {
          header: 'id',
          key: 'id',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('jobs.excel.description.header'),
          key: 'description',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public uploadExcel(payload: { jobs: IJob[] }) {
    return this.http.post<BulkResponseDataInterface>(`${this.baseUrl + this.URLs.JOBS_URL}/bulk/save`, payload);
  }

  public downloadExcel(
    withData: boolean,
    filters: IJobsDownloadExcelFilter,
    httpParamsForData: HttpParams | undefined,
    withErrorColumn: boolean = false,
    data?: IJob[],
  ) {
    const httpParams: HttpParams = new HttpParams();

    const observables: {
      sites: Observable<GetManyResponseInterface<SiteCRUDInterface>>;
      jobs?: Observable<GetManyResponseInterface<IJob>>;
    } = {
      sites: this.siteService.getSites(
        httpParams
          .set('limit', STATIC_MAX_LIMIT_OF_CRUD)
          .set('s', JSON.stringify({ id: filters.siteId && filters.siteId !== -1 ? { $eq: filters.siteId } : {} })),
      ),
    };

    if (withData && !data) {
      observables.jobs = this.getJobs(
        (httpParamsForData ?? httpParams)
          .set('per_page', filters.limit)
          .set('limit', filters.limit)
          .set('page', filters.selectedDownloadOffset),
      );
    }

    forkJoin<
      {
        sites: Observable<GetManyResponseInterface<SiteCRUDInterface>>;
        jobs?: Observable<GetManyResponseInterface<IJob>>;
      }
    >(observables).subscribe((result) => {
      const sheetTitle = this.translate.instant('excel.items.jobs');
      const excelName = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;
      let excelData: IJob[] = [];

      if (withData) {
        excelData = result.jobs?.data ?? [];

        if (data) {
          excelData = data.map((item) => {
            item.site = result.sites.data.find((site) => site.id === item.siteId);

            return item;
          });
        }
      }

      const excelOptions: ICreateExcel = this.getJobsExcelColumns(result.sites.data, withErrorColumn);

      if (withData) {
        excelOptions.data = excelData.map((item) => {
          return {
            ...item,
          };
        });
      }

      const worksheets: ICreateExcelSheet[] = [
        {
          sheetTitle,
          withData,
          sheetType: EExcelSheetType.TABLE,
          params: excelOptions,
          excelRowFormatLimit: 5001,
        },
      ];

      this.excelHelper.createExcel(excelName, worksheets, this.timezone, this.dateFormat$, this.timeFormat$).then(
        () => {
          this.store.dispatch(new ObjectActions.DownloadJobExcelCompleted());
        },
        () => {
          this.store.dispatch(new ObjectActions.FetchError({}));
        },
      );
    });
  }
}
