import { WidgetConfigurationInterface } from './app/shared/component/page-configuration/page-configuration.model';

export enum ELoadStatus {
  Initial,
  Loading,
  Success,
  Failure,
}

export interface IPage {
  title: string;
  icon: string;
  relatedModule?: string;
}

export interface ITableHeader {
  value: string;
  name: string;
  type?: PageConfigurationTypes;
  class?: string;
  width?: string;
  disabled?: boolean;
  selected?: boolean;
  sortable?: boolean;
  linkedTo?: string;
  opposedTo?: string[];
  colspan?: number;
  parentId?: string;
}

export enum PageConfigurationTypes {
  WIDGET = 'widget',
  TABLE = 'table',
  CHART = 'chart',
  HEADER_GROUP = 'headerGroup',
  CUSTOM = 'custom',
  BUTTON_GROUP = 'buttonGroup',
}

export enum PageRefreshIntervals {
  work_orders = 120,
  production_review = 60,
  kpi_view = 180,
  line_view = 60,
  asset_view = 60,
  daily_production_performance = 600,
  global_view = 900,
  activity_view = 60,
  site_view = 120,
  activity_timeline_report = 300,
  line_view_enhanced = 60,
}

export interface BulkResponseFailSuccessInterface<T> {
  fail: T[];
  success: T[];
}

export interface MergedArrayDataInterface {
  id: number;
  errorMessages?: string;
}

export interface IDataTableHeaders {
  headers: ITableHeader[];
  widgets: WidgetConfigurationInterface[];
  headerGroups?: ITableHeader[];
}

type TNonBreakingSpace = '\u00a0';

export type TDecimalSeparator = '.' | ',';

export type TThousandSeparator = '.' | ',' | ' ' | TNonBreakingSpace;

export interface ISeparators {
  decimalSeparator: TDecimalSeparator;
  thousandSeparator: TThousandSeparator;
}
