import {
  ILinesReportLineExtendedInterface,
  IObjectConfiguration,
  WidgetDataInterface,
} from './line-view-enhanced.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import * as Actions from './line-view-enhanced.actions';

export interface LineViewEnhancedInterface {
  downTime: number;
  idleTime: number;
  runTime: number;
  orderBy: string;
  runTimeAndDownTimeOee: string;
  totalNumberOfUsers: number | null;
  totalNumberOfAssets: number | null;
  orderDesc: boolean;
  rows: ILinesReportLineExtendedInterface[];
  sitesFilter: number[];
  linesFilter: number[];
  sitesFilterIsLoading: boolean;
  linesFilterIsLoading: boolean;
  tableColumnFilterIsLoading: boolean;
  sitesFilterIsLoaded: boolean;
  linesFilterIsLoaded: boolean;
  tableColumnFilterIsLoaded: boolean;
  selectedSiteFilters: number[];
  selectedLineFilters: number[];
  filterDataGenerated: boolean;
  siteFilterDataGenerated: boolean;
  lineFilterDataGenerated: boolean;
  fileDownloadStatus: boolean;
  tableData: any[];
  tableColumns: IObjectConfiguration[];
  widgets: IObjectConfiguration[];
  tableDataIsLoading: boolean;
  tableDataIsLoaded: boolean;
  isLineListVisible: boolean;
  isCardDataLoading: boolean;
  isCardDataLoaded: boolean;
  isWidgetsLoaded: boolean;
  isWidgetsGenerated: boolean;
  isFiltersAndSettingSaving: boolean;
  calculatedOeeDate: string;
  generatedWidgets: WidgetDataInterface[];
  tableSettings: TabRowInterface[];
  lineCountOfSite: number;
  lineCountStatus: boolean;
}

export const lineViewEnhancedInitialState: LineViewEnhancedInterface = {
  downTime: 0,
  idleTime: 0,
  runTime: 0,
  orderBy: '',
  runTimeAndDownTimeOee: '0',
  totalNumberOfUsers: 0,
  totalNumberOfAssets: 0,
  orderDesc: false,
  rows: [],
  sitesFilter: [],
  linesFilter: [],
  sitesFilterIsLoading: false,
  linesFilterIsLoading: false,
  tableColumnFilterIsLoading: false,
  sitesFilterIsLoaded: false,
  linesFilterIsLoaded: false,
  tableColumnFilterIsLoaded: false,
  selectedSiteFilters: [],
  selectedLineFilters: [],
  filterDataGenerated: false,
  siteFilterDataGenerated: false,
  lineFilterDataGenerated: false,
  fileDownloadStatus: false,
  tableData: [],
  tableColumns: [],
  widgets: [],
  tableDataIsLoading: false,
  tableDataIsLoaded: false,
  isLineListVisible: false,
  isCardDataLoading: false,
  isCardDataLoaded: false,
  isWidgetsLoaded: false,
  isWidgetsGenerated: false,
  isFiltersAndSettingSaving: false,
  calculatedOeeDate: null,
  generatedWidgets: [],
  tableSettings: [],
  lineCountOfSite: 0,
  lineCountStatus: false,
};

export function lineViewEnhancedReducer(
  state: LineViewEnhancedInterface = lineViewEnhancedInitialState,
  action: Actions.LineViewEnhancedEnhancedActions,
): LineViewEnhancedInterface {
  switch (action.type) {
    case Actions.START_LINE_VIEW_ENHANCED_FILTER_DATA_LOADING:
      return {
        ...state,
        sitesFilterIsLoading: true,
        linesFilterIsLoading: true,
        filterDataGenerated: false,
        isLineListVisible: false,
      };
    case Actions.LINE_VIEW_ENHANCED_FILTER_DATA_LOADED:
      return {
        ...state,
        ...{
          sitesFilter: action.payload.data.sites,
          linesFilter: action.payload.data.lines,
          tableColumns: action.payload.data.table,
          widgets: action.payload.data.widgets,
        },
        sitesFilterIsLoading: false,
        linesFilterIsLoading: false,
        tableColumnFilterIsLoading: false,
        sitesFilterIsLoaded: true,
        linesFilterIsLoaded: true,
        tableColumnFilterIsLoaded: true,
        isWidgetsLoaded: true,
        isWidgetsGenerated: false,
      };
    case Actions.LINE_VIEW_ENHANCED_FILTER_DATA_GENERATED:
      return {
        ...state,
        filterDataGenerated: true,
      };
    case Actions.LINE_VIEW_ENHANCED_SET_SELECTED_SITE_FILTERS:
      return {
        ...state,
        sitesFilterIsLoading: false,
        sitesFilterIsLoaded: false,
        selectedSiteFilters: action.payload,
        sitesFilter: action.payload,
        siteFilterDataGenerated: true,
      };
    case Actions.LINE_VIEW_ENHANCED_SET_SELECTED_LINE_FILTERS:
      return {
        ...state,
        linesFilterIsLoading: false,
        linesFilterIsLoaded: false,
        selectedLineFilters: action.payload,
        linesFilter: action.payload,
        lineFilterDataGenerated: true,
      };
    case Actions.LINE_VIEW_ENHANCED_SET_SELECTED_TABLE_COLUMN_SETTINGS:
      return {
        ...state,
        tableColumns: action.payload,
      };
    case Actions.SET_SELECTED_WIDGET_SETTINGS:
      const widgets = action.payload.map((widget) => {
        return { name: widget.name, selected: widget.selected };
      });
      return {
        ...state,
        widgets,
        generatedWidgets: action.payload,
        isWidgetsGenerated: true,
      };
    case Actions.GET_LINE_VIEW_ENHANCED_DATA_STARTED:
      return {
        ...state,
        tableDataIsLoading: true,
        isCardDataLoading: true,
        isCardDataLoaded: false,
        tableDataIsLoaded: false,
      };
    case Actions.LINE_VIEW_ENHANCED_DATA_LOADED:
      let responseData: Partial<LineViewEnhancedInterface> = {
        isCardDataLoaded: true,
        isCardDataLoading: false,
        tableDataIsLoading: false,
        downTime: action.payload.downTime,
        idleTime: action.payload.idleTime,
        runTime: action.payload.runTime,
        runTimeAndDownTimeOee: action.payload.todayOEE,
        calculatedOeeDate: action.payload.calculatedOeeDate,
        totalNumberOfUsers: action.payload.totalNumberOfUsers,
        totalNumberOfAssets: action.payload.totalNumberOfAssets,
      };

      if (action.mode === 'all') {
        responseData = Object.assign(responseData, {
          tableDataIsLoading: false,
          tableDataIsLoaded: true,
          isLineListVisible: true,
          isCardDataLoading: false,
          isCardDataLoaded: true,
          downTime: action.payload.downTime,
          idleTime: action.payload.idleTime,
          rows: action.payload.lines,
          runTime: action.payload.runTime,
          runTimeAndDownTimeOee: action.payload.todayOEE,
          tableData: action.payload.lines,
          calculatedOeeDate: action.payload.calculatedOeeDate,
          totalNumberOfUsers: action.payload.totalNumberOfUsers,
          totalNumberOfAssets: action.payload.totalNumberOfAssets,
        });
      }
      return {
        ...state,
        ...responseData,
      };
    case Actions.LINE_VIEW_ENHANCED_START_FILTERS_AND_SETTINGS_SAVING:
      return {
        ...state,
        isFiltersAndSettingSaving: true,
      };
    case Actions.LINE_VIEW_ENHANCED_FILTERS_AND_SETTINGS_SAVED:
      return {
        ...state,
        isFiltersAndSettingSaving: false,
      };
    case Actions.LINE_VIEW_ENHANCED_FETCH_DATA_ERROR:
      return {
        ...state,
        tableDataIsLoading: false,
        isCardDataLoading: false,
        isCardDataLoaded: true,
        tableDataIsLoaded: true,
        downTime: 0,
        idleTime: 0,
        runTime: 0,
        totalNumberOfUsers: 0,
        totalNumberOfAssets: 0,
        runTimeAndDownTimeOee: '',
        rows: [],
        lineCountOfSite: 0,
        lineCountStatus: false,
      };
    case Actions.LINE_VIEW_ENHANCED_CANCEL_LOADING:
      return {
        ...state,
        tableDataIsLoading: false,
        isCardDataLoading: false,
        isCardDataLoaded: true,
        tableDataIsLoaded: false,
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.LINE_VIEW_ENHANCED_SET_FILTER_SETTINGS:
      return {
        ...state,
        sitesFilter: action.siteIds,
        linesFilter: action.lineIds,
      };
    case Actions.LINE_COUNT_OF_SITES_LOADING:
      return {
        ...state,
        lineCountOfSite: 0,
        lineCountStatus: true,
      };
    case Actions.LINE_COUNT_OF_SITES_LOADED:
      return {
        ...state,
        lineCountOfSite: action.payload,
        lineCountStatus: false,
      };
    default:
      return state;
  }
}
