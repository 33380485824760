import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as ObjectActions from './daily-performance-summary.actions';
import { switchMap, catchError } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { DailyPerformanceSummaryService } from '../../../shared/service/reports/daily-performance-summary/daily-performance-summary.service';
import {
  DailyPerformanceActivityDataInterface,
  DailyPerformanceSummaryFilterInterface,
  DailyPerformanceSummaryInterface,
  DailyPerformanceSummaryWorkOrderInterface,
  IDailyPerformanceWorkOrderRequest,
} from './daily-performance-summary.model';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import {
  IActivityHistory,
  ILine,
  IProductsLaborsAssetsHourOfActivityHistory,
  IWorkOrder,
} from '../../../shared/service/reports/daily-performance-summary/daily-performance-summary.model';
import moment from 'moment';
import * as _ from 'lodash'
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';

@Injectable()
export class DailyPerformanceSummaryEffects {
  constructor(
    private readonly actions$: Actions,
    public service: DailyPerformanceSummaryService,
    private readonly entityTranslation: EntityTranslatorService,
  ) {}
  getUniqueOperatorCount(checkinTable: IProductsLaborsAssetsHourOfActivityHistory[]): number {
    const uniqueOperators = [];

    for (let i = 0; i < checkinTable.length; i = i + 1) {
      if (uniqueOperators.indexOf(`${checkinTable[i].sourceObjectId}|${checkinTable[i].sourceTypeId}`) === -1) {
        uniqueOperators.push(`${checkinTable[i].sourceObjectId}|${checkinTable[i].sourceTypeId}`);
      }
    }

    return uniqueOperators.length;
  }

  calculateLaborDuration(records: IProductsLaborsAssetsHourOfActivityHistory[]): number {
    let totalDuration = 0;

    if (!records) {
      return totalDuration;
    }

    for (let i = 0; i < records.length; i = i + 1) {
      totalDuration += moment(records[i].endDate).diff(moment(records[i].startDate), 'seconds');
    }
    return totalDuration;
  }

  getDailyPerformanceSummaryData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DAILY_PERFORMANCE_SUMMARY_DATA_LOADING),
      switchMap((objectData: ObjectActions.DailyPerformanceSummaryDataLoading) => {
        const body: DailyPerformanceSummaryFilterInterface = objectData.params;
        const bodyForWorkOrder: IDailyPerformanceWorkOrderRequest = {
          id: objectData.params.workOrderId,
        };

        return forkJoin(this.service.getDailyPerformanceObservables(body, bodyForWorkOrder)).pipe(
          switchMap(
            (
              response: [
                ResponseArrayInterface<ILine>,
                ResponseArrayInterface<IActivityHistory>,
                ResponseArrayInterface<IWorkOrder>,
              ],
            ) => {
              const formattedLineData: IActivityHistory[] = this.service.formatLineData(response[0].data);

              const results: DailyPerformanceSummaryInterface = {
                lineName: null,
                productName: null,
                packagingBatch: null,
                packagingGmid: null,
                productionOrder: null,
                plannedDowntime: [],
                productionTime: [],
                downtimeTime: [],
                setupNumberOfEmployees: 0,
                setupTotalMachineHours: 0,
                setupTotalLaborHours: 0,
                runNumberOfEmployees: 0,
                runTotalMachineHours: 0,
                runTotalLaborHours: 0,
                totalQuantityProduced: 0,
                totalUnplannedDowntime: 0,
                totalIdleTime: 0,
                duration: 0,
              };
              const activities = _.merge(formattedLineData,response[1].data);

              for (const resultRow of activities) {
                this.entityTranslation.translate(resultRow);

                results.lineName = resultRow.line.title;
                results.packagingBatch = resultRow.workOrder.woNumber;
                results.lineName = resultRow.line.title;

                let numberOfOperators = 0;
                if (resultRow.productsLaborsAssetsHour !== undefined) {
                  numberOfOperators = this.getUniqueOperatorCount(resultRow.productsLaborsAssetsHour);
                }

                const activityRow: DailyPerformanceActivityDataInterface = {
                  numberOfOperators,
                  activity: resultRow.activity.name,
                  shift: resultRow.shift?.name,
                  startDate: resultRow.start,
                  endDate: resultRow.end,
                  machineSetupHours: null,
                  setupLaborHours: null,
                  machineRunHours: null,
                  runLaborHours: null,
                  downTimeData: '',
                  initials: '',
                  date: '',
                  quantityProduced: 0,
                  totalHours: null,
                  code: '',
                  description: resultRow.description,
                };
                let activityDuration = moment(resultRow.end).diff(moment(resultRow.start), 'seconds');
                if (resultRow.end === null) {
                  activityDuration = moment().diff(moment(resultRow.start), 'seconds');
                }

                results.duration += activityDuration;

                switch (resultRow.activity.activityType) {
                  case 'downTimePlanned':
                    activityRow.machineSetupHours = activityDuration;
                    activityRow.setupLaborHours = this.calculateLaborDuration(resultRow.productsLaborsAssetsHour);
                    results.setupTotalMachineHours += activityRow.machineSetupHours;
                    results.setupTotalLaborHours += activityRow.setupLaborHours;
                    results.plannedDowntime.push(activityRow);
                    break;
                  case 'runTime':
                    activityRow.machineRunHours = activityDuration;
                    activityRow.runLaborHours = this.calculateLaborDuration(resultRow.productsLaborsAssetsHour);
                    results.runTotalMachineHours += activityRow.machineRunHours;
                    results.runTotalLaborHours += activityRow.runLaborHours;
                    results.productionTime.push(activityRow);
                    break;
                  case 'downTime':
                    activityRow.totalHours = activityDuration;
                    results.totalUnplannedDowntime += activityRow.totalHours;
                    results.downtimeTime.push(activityRow);
                    break;
                  case 'idleTime':
                    activityRow.totalHours = activityDuration;
                    results.totalIdleTime += activityRow.totalHours;
                    results.downtimeTime.push(activityRow);
                    break;
                }
              }

              const woResults: DailyPerformanceSummaryWorkOrderInterface = {
                id: null,
                woNumber: null,
                description: null,
                quantityOrdered: null,
                goodQuantity: null,
                completed: null,
                lineName: null,
                productId: null,
                product: {
                  id: null,
                  productId: null,
                  description: null,
                },
              };
              response[2].data.forEach((workOrder: IWorkOrder) => {
                this.entityTranslation.translate(workOrder);
              });
              if (response[2].data.length > 0) {
                const workOrder = response[2].data[0];
                woResults.woNumber = workOrder.woNumber;
                woResults.productId = workOrder.productName;
                woResults.product = workOrder.product;
                woResults.quantityOrdered = workOrder.quantityOrdered;
                woResults.goodQuantity = workOrder.goodCount;
                woResults.lineName = workOrder.actualLineDetail?.title;
              }

              return of(
                new ObjectActions.DailyPerformanceSummaryDataLoaded(results),
                new ObjectActions.DailyPerformanceSummaryWorkOrderLoaded(woResults),
              );
            },
          ),
          catchError((err: any) => {
            return of(new ObjectActions.FetchDataError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err));
      }),
    ),
  );
}
