import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  EActivitiesEquipmentChartLayout,
  EActivityEquipmentTaskChart,
  EStackChartDropdown,
  EStackChartGroupBy,
  EStackChartGroupByName,
  IChartLoader,
  IFilterOptions,
  IOptionGroupAndDuration,
  IStackChartAxisLabelData,
  IStackChartDropdownOptions,
  TActivityTypeOptionGroupDurations,
  TGroupedData,
  TGroupedDataOptionGroup,
  TOptionGroupAndDurations,
} from './charts.model';
import * as echarts from 'echarts';
import * as _ from 'lodash';
import {
  IActivityLog,
  IRootCauseAnalysisChartNode,
  RootCauseAnalysisFilterInterface,
  RootCauseAnalysisStateInterface,
} from '../../../../store/reports/root-cause-analysis/root-cause-analysis.model';
import { activityColors } from '../../../../shared/helper/app-helper';
import moment from 'moment';
import { ScwMatButtonGroupButtons } from '../../../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { filter, take } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import * as RootCauseAnalysisActions from '../../../../store/reports/root-cause-analysis/root-cause-analysis.actions';
import { OeeAppState } from '../../../../store/oee.reducer';
import { HelperService } from '../../../../shared/service/helper.service';
import {
  getCurrentDateTimeAsMoment,
  momentMonthFormat,
  momentWeekFormat,
  mysqlTimestampFormat,
  transformDurationType,
} from '../../../../shared/helper/date';
import { TranslateService } from '@ngx-translate/core';
import { ActivityTypes } from '../../../../shared/model/enum/activity-types';
import { User } from '../../../../store/user/model';
import {
  EAxisNameLocation,
  IBarChartConfiguration,
  IChartEventParams,
  IDateRangeOptions,
  IGenericChartData,
  ROOT_CAUSE_ANALYSIS_TASKS_CHART_MAX_BAR_WITHOUT_OTHER,
} from '../../../../shared/component/tasks-charts/tasks-charts.model';
import { ChartType } from '../../../../shared/component/file-generator/file-generator.model';
import { ofType } from '@ngrx/effects';
import { DecimalHelper } from 'src/app/shared/helper/decimal/decimal-helper';
import { ColorService } from 'src/app/shared/service/color/color.service';
import { FilterSiteState } from 'src/app/store/filter/site/site.reducer';
import { SiteCRUDInterface } from 'src/app/shared/component/filter/filter.class';
import { CustomColors } from '../../../../shared/service/color/color.model';
import { IActivityTypeDurations } from '../comparison-charts/comparison-charts.model';
import { RootCauseAnalysisService } from '../../../../store/reports/root-cause-analysis/root-cause-analysis.service';

@Component({
  selector: 'app-root-cause-analysis-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChartsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isBusinessDate: boolean = false;
  @Input() showManyDataWarning: boolean = false;
  @Input() filter: Partial<RootCauseAnalysisFilterInterface> = {
    siteIds: [],
    isBusinessDate: true,
    dateRange: {
      startDate: moment().startOf('week'),
      endDate: moment(),
    },
  };
  @Output() pdfChartObject = new EventEmitter<number[]>();
  public customColors$: CustomColors;

  public dataProgress$: number = 0;
  public rawData$: IActivityLog[] = [];
  public chartData$: IRootCauseAnalysisChartNode[] = [];
  private readonly subscriptions: Subscription[] = [];
  private readonly activityTypeTexts: string[] = [
    ActivityTypes.DOWN_TIME_PLANNED,
    ActivityTypes.DOWN_TIME,
    ActivityTypes.IDLE_TIME,
  ];
  private readonly groupByPrefix: string = this.translate.instant('filterCard.groupBy.dropdownPlaceHolder');
  private readonly shortHour: string = this.translate.instant('general.shortHour');
  private readonly noEquipmentAssignedLabel: string = this.translate.instant(
    'rootCauseAnalysis.charts.equipment.noEquipmentAssigned.label',
  );
  private readonly columnSix: string = 'col-6';
  private readonly columnTwelve: string = 'col-12';
  private readonly maxStackChartBarWidth: number = 150;
  private readonly leftPaddingHalfRow: string = '28%';
  private readonly leftPaddingFullRow: string = '14%';
  private readonly leftPaddingFullRowWidened: string = '20%';
  private readonly barChartYAxisLabelLengthShort: number = 25;
  private readonly barChartYAxisLabelLengthLong: number = 35;
  private sunburstChartRef: echarts.ECharts;
  private stackChartRef: echarts.ECharts;
  private activityGroups: IActivityLog[][];
  private activityTypeGroups: IActivityLog[][];
  private userDateFormat$: string;
  private timezone$: string;
  private formattedData: IActivityLog[];
  public isNoEquipmentOnly: boolean = false;
  private optionGroupAndDurations: TOptionGroupAndDurations = {};
  private activityTypeOptionGroupDurations: TActivityTypeOptionGroupDurations = {};
  private stackChartXAxisLabelData: IStackChartAxisLabelData[];
  private stackChartLimitTimeout: NodeJS.Timeout;
  private readonly stackChartLimitSearchDelay: number = 600;
  public sunburstChartOption: echarts.EChartsOption;
  public stackChartOption: echarts.EChartsOption;
  public activityEquipmentTaskChartType: EActivityEquipmentTaskChart = EActivityEquipmentTaskChart.BAR;
  public activitiesEquipmentChartLayout: EActivitiesEquipmentChartLayout = EActivitiesEquipmentChartLayout.COLUMN;
  public activityEquipmentTaskChartTypeEnum = EActivityEquipmentTaskChart;
  public eStackChartDropdownOption = EStackChartDropdown;
  public stackChartDropdownOptions: IStackChartDropdownOptions[] = this.getDropdownOptionTranslation();
  public stackChartDropdownModel: IStackChartDropdownOptions[] = [this.stackChartDropdownOptions[0]];
  public selectedGroupByOption: EStackChartGroupBy = EStackChartGroupBy.WEEK;
  public stackChartTimeButtonGroupModel: EStackChartGroupBy = EStackChartGroupBy.WEEK;
  public stackChartLocationButtonGroupModel: EStackChartGroupBy = EStackChartGroupBy.LINE;
  public dateRange: IDateRangeOptions;
  public isNoEquipmentAssignedExists: boolean = false;
  public isRawDataLoading$: boolean = true;
  public isChartDataLoading$: boolean = true;
  public isEquipmentUsedInTasks: boolean = true;
  public chartButtonGroup: ScwMatButtonGroupButtons[] = [
    {
      iconClass: 'fas fa-chart-pie initial',
      iconPosition: 'right',
      value: EActivityEquipmentTaskChart.PIE,
    },
    {
      iconClass: 'fas fa-chart-bar fa-rotate-270 initial',
      iconPosition: 'left',
      value: EActivityEquipmentTaskChart.BAR,
    },
  ];
  public chartLayoutButtonGroup: ScwMatButtonGroupButtons[] = [
    {
      iconClass: 'fas fa-pause initial',
      iconPosition: 'right',
      value: EActivitiesEquipmentChartLayout.COLUMN,
    },
    {
      iconClass: 'fas fa-pause fa-rotate-90 initial',
      iconPosition: 'left',
      value: EActivitiesEquipmentChartLayout.ROW,
    },
  ];
  public stackChartTimeButtonGroup: ScwMatButtonGroupButtons[] = [
    {
      value: EStackChartGroupBy.DAY,
      text: this.translate.instant('rootCauseAnalysis.charts.groupBy.buttonGroup.day'),
    },
    {
      value: EStackChartGroupBy.WEEK,
      text: this.translate.instant('rootCauseAnalysis.charts.groupBy.buttonGroup.week'),
    },
    {
      value: EStackChartGroupBy.MONTH,
      text: this.translate.instant('rootCauseAnalysis.charts.groupBy.buttonGroup.month'),
    },
  ];
  public stackChartLocationButtonGroup: ScwMatButtonGroupButtons[] = [
    {
      value: EStackChartGroupBy.LINE,
      text: this.translate.instant('rootCauseAnalysis.charts.groupBy.buttonGroup.lines'),
    },
    {
      value: EStackChartGroupBy.DEPARTMENT,
      text: this.translate.instant('rootCauseAnalysis.charts.groupBy.buttonGroup.departments'),
    },
  ];
  public barChartConfiguration: IBarChartConfiguration = {
    leftPercentageActivitiesChart: this.leftPaddingHalfRow,
    leftPercentageEquipmentChart: this.leftPaddingHalfRow,
    leftPercentageTasksChart: this.leftPaddingFullRow,
    tasksChartMaxBarsWithoutOther: ROOT_CAUSE_ANALYSIS_TASKS_CHART_MAX_BAR_WITHOUT_OTHER,
    shouldResizeTasksChart: true,
    activitiesChartDivClasses: this.columnSix,
    equipmentChartDivClasses: this.columnSix,
    tasksChartDivClasses: this.columnTwelve,
    shouldDisplayInfoMessages: true,
    barLabelPositionThreshold: 0.18,
    noEquipmentAssignedLabelHasStar: true,
    taskChartBarWidth: 40,
    showTaskTargets: true,
    showActivitiesChartYAxisName: false,
    showEquipmentChartYAxisName: false,
    yAxisStyle: {
      nameFontSize: 20,
      nameLocation: EAxisNameLocation.END,
      namePadding: [0, 0, 0, 0],
      labelMaxLength: 25,
      showLabels: true,
    },
    xAxisStyle: {
      nameLocation: EAxisNameLocation.MIDDLE,
    },
  };
  public stackChartBarLimit: number;
  public stackChartTotalNumberOfBars: number;
  public stackChartTotalNumberOfBarsText: string;
  public isGroupByProductOrLocation: boolean;
  public stackChartBarLimitInputModel: string;
  private selectedActivityName: string;
  private selectedEquipmentName: string;
  private selectedTaskNames: string[] = [];

  private static missingDataTranslation: string;

  constructor(
    private readonly store: Store<OeeAppState>,
    public readonly helperService: HelperService,
    private readonly translate: TranslateService,
    private readonly actions: ActionsSubject,
    private readonly decimalHelper: DecimalHelper,
    private readonly colorService: ColorService,
    private readonly rootCauseAnalysisService: RootCauseAnalysisService,
  ) {
    ChartsComponent.missingDataTranslation = this.translate.instant('general.missingDataText');
  }

  public ngOnInit(): void {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        if (state.isUserLoaded && !state.isUserLoading) {
          this.userDateFormat$ = state.dateFormat;
          this.timezone$ = state.timezone;
        }
      });
    this.store
      .select('rootCauseAnalysisStore')
      .pipe(take(1))
      .subscribe((state: RootCauseAnalysisStateInterface) => {
        this.rawData$ = state.rootCauseAnalysisData;
        this.chartData$ = state.regularChartData;
      });

    this.subscriptions.push(
      combineLatest([
        this.store.select('user').pipe(filter((value) => value.isUserLoaded === true)),
        this.store.select('siteFilter').pipe(filter((value: FilterSiteState) => value.isLoaded)),
        this.actions.pipe(ofType(RootCauseAnalysisActions.ROOT_CAUSE_ANALYSIS_CHART_DATA_LOADED)),
      ]).subscribe(
        ([user, sites, response]: [User, { data: SiteCRUDInterface[] }, { data: IRootCauseAnalysisChartNode[] }]) => {
          const sitesColors: Record<number, CustomColors | undefined> = _.fromPairs(
            HelperService.cloneDeep(sites.data).map((site) => [site.id, site.configuration?.colors]),
          );
          const clientColors: CustomColors | undefined = user.clientInfo?.configuration?.colors;
          this.customColors$ = this.colorService.pickCustomColors(
            this.filter.siteIds !== -1 ? this.filter.siteIds : [],
            sitesColors,
            clientColors,
          );
          this.chartData$ = HelperService.cloneDeep(response.data).map((node) => {
            if (node.activityType === 'downTimePlanned') {
              node.activityColor = this.customColors$.activity.plannedDownTime.card;
            }
            return node;
          });
          this.checkForTasksWithoutEquipment();
          this.decideBarChartLayout(this.activitiesEquipmentChartLayout);
        },
      ),
      combineLatest([
        this.store.select('rootCauseAnalysisStore'),
        this.actions.pipe(ofType(RootCauseAnalysisActions.ROOT_CAUSE_ANALYSIS_CHART_DATA_LOADED)),
      ]).subscribe(([state, response]: [RootCauseAnalysisStateInterface, { data: IRootCauseAnalysisChartNode[] }]) => {
        this.selectedActivityName = state.selectedActivityName;
        this.selectedEquipmentName = state.selectedEquipmentName;
        this.selectedTaskNames = state.selectedTaskNames;
        this.isRawDataLoading$ = state.isRootCauseAnalysisDataLoading;
        this.isChartDataLoading$ = state.isChartDataLoading;
        this.chartButtonGroup[0].iconClass = this.isRawDataLoading$
          ? 'fas fa-circle-notch fa-spin'
          : 'fas fa-chart-pie initial';
        this.dataProgress$ = state.dataProgress;

        if (state.isRootCauseAnalysisDataLoading || !state.isRootCauseAnalysisDataLoaded) {
          this.setChartLoadingState(true);
        }

        if (this.chartData$.length) {
          this.decideBarChartLayout(this.activitiesEquipmentChartLayout);
        }

        if (!state.isRootCauseAnalysisDataLoading && (state.isRootCauseAnalysisDataLoaded || this.rawData$.length)) {
          this.rawData$ = state.rootCauseAnalysisData;
          this.dateRange = {
            start: this.filter.dateRange?.startDate,
            end: this.filter.dateRange?.endDate,
          };

          if (this.rawData$.length === 0 || !this.filter) {
            this.sunburstChartOption = null;
            this.stackChartOption = null;
            this.decideBarChartLayout(this.activitiesEquipmentChartLayout);
            return;
          }

          this.formattedData = ChartsComponent.formatRawData(
            this.filter.dateRange.startDate,
            this.filter.dateRange.endDate,
            this.rawData$,
            this.timezone$,
            this.filter.isBusinessDate,
          );

          this.formattedData = this.rootCauseAnalysisService.filterFormattedDataWithSelectedChartData(
            this.formattedData,
            this.selectedActivityName,
            this.selectedEquipmentName,
            this.selectedTaskNames,
          );

          this.activityGroups = _.groupBy(this.formattedData, 'activityId');
          this.prepareSunburstChartOption();
          this.prepareStackChartOption();
          this.setChartLoadingState(false);
          this.setupPdfCharts();
        }
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('showManyDataWarning')) {
      this.setupPdfCharts();
    }
  }

  private setupPdfCharts(): void {
    let deletedChart = [];

    if (this.activityEquipmentTaskChartType === EActivityEquipmentTaskChart.PIE && this.isNoEquipmentOnly) {
      deletedChart = [];
      deletedChart.push(ChartType.Activities, ChartType.Equipment, ChartType.Tasks);
    } else if (this.isNoEquipmentOnly) {
      deletedChart = [];
      deletedChart.push(ChartType.Equipment);
    } else if (this.activityEquipmentTaskChartType === EActivityEquipmentTaskChart.PIE) {
      deletedChart = [];
      deletedChart.push(ChartType.Activities, ChartType.Equipment, ChartType.Tasks);
    } else if (!this.rawData$.length) {
      deletedChart = [];
      deletedChart.push(ChartType.Activities, ChartType.Equipment, ChartType.Tasks, ChartType.Trend);
    }

    this.pdfChartObject.emit(deletedChart);
  }

  public onChartSwitchButtonClick(type: EActivityEquipmentTaskChart): void {
    this.activityEquipmentTaskChartType = type;
    this.setupPdfCharts();
  }

  public setStackBarChartRef(params: echarts.ECharts): void {
    this.stackChartRef = params;
  }

  public setSunburstChartRef(params: echarts.ECharts): void {
    this.sunburstChartRef = params;
  }

  private prepareSunburstChartOption(): void {
    this.sunburstChartOption = {
      series: {
        type: 'sunburst',
        data: null,
        radius: [0, '100%'],
        label: {
          rotate: 'radial',
        },
        select: {
          label: {
            rotate: 'tangential',
          },
        },
        selectedMode: 'single',
        emphasis: {
          label: {
            rotate: 0,
          },
        },
      },
    };

    this.prepareSunburstChartData();
  }

  private prepareSunburstChartData(): void {
    const sunburstActivitiesData: IGenericChartData[] = [];

    Object.values(this.activityGroups).forEach((activityGroup: IActivityLog[]) => {
      const durationInHours: number = transformDurationType(this.getTotalDuration(activityGroup), 'seconds', 'hours');
      const sunburstEquipmentData: IGenericChartData[] = [];
      const pieceColor: string =
        (_.get(activityGroup, '0.activityTypeText') === 'downTimePlanned'
          ? this.customColors$?.activity.plannedDownTime.card
          : null) ?? activityColors(_.get(activityGroup, '0.activityTypeText'));

      Object.values(_.groupBy(activityGroup, 'equipmentId')).forEach((equipmentGroup: IActivityLog[]) => {
        const durationInHours: number = transformDurationType(
          this.getTotalDuration(equipmentGroup),
          'seconds',
          'hours',
        );
        const sunburstTasksData: IGenericChartData[] = [];

        Object.values(_.groupBy(equipmentGroup, 'taskName')).forEach((taskGroup: IActivityLog[]) => {
          const durationInHours: number = transformDurationType(this.getTotalDuration(taskGroup), 'seconds', 'hours');

          sunburstTasksData.push({
            name: _.get(taskGroup, '0.taskName'),
            value: durationInHours,
            itemStyle: { color: _.get(taskGroup, '0.activityColor') ?? pieceColor },
          });
        });

        sunburstEquipmentData.push({
          name: _.get(equipmentGroup, '0.equipmentName') ?? `${this.noEquipmentAssignedLabel}*`,
          value: durationInHours,
          itemStyle: { color: pieceColor },
          children: _.clone(sunburstTasksData),
        });
      });

      sunburstActivitiesData.push({
        name: _.get(activityGroup, '0.activityName'),
        value: durationInHours,
        itemStyle: { color: pieceColor },
        children: _.clone(sunburstEquipmentData),
      });
    });

    Object.assign(this.sunburstChartOption.series, { data: sunburstActivitiesData });
  }

  private prepareStackChartOption(): void {
    this.stackChartOption = {
      grid: { containLabel: true },
      xAxis: {
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: 45,
        },
      },
      yAxis: {
        type: 'value',
        name: this.translate.instant('rootCauseAnalysis.charts.hours'),
        axisLabel: {
          formatter: (value) => {
            return this.decimalHelper.toFixedValue(value.toString(), 0) as string;
          },
        },
      },
      series: [],
    };

    this.formattedData = this.rootCauseAnalysisService.filterFormattedDataWithSelectedChartData(
      this.formattedData,
      this.selectedActivityName,
      this.selectedEquipmentName,
      this.selectedTaskNames,
    );

    this.activityTypeGroups = _.groupBy(this.formattedData, 'activityTypeText');
    this.prepareStackChartData(this.selectedGroupByOption);
  }

  public prepareStackChartData(groupByOption: EStackChartGroupBy): void {
    if (this.rawData$.length === 0) {
      return;
    }

    this.selectedGroupByOption = groupByOption;
    const [groupedData, xAxisLabelData] = this.groupDataByOption(groupByOption);
    this.stackChartXAxisLabelData = xAxisLabelData;
    this.activityTypeOptionGroupDurations = {};
    this.isGroupByProductOrLocation =
      groupByOption !== EStackChartGroupBy.DAY &&
      groupByOption !== EStackChartGroupBy.WEEK &&
      groupByOption !== EStackChartGroupBy.MONTH;
    this.optionGroupAndDurations = {};

    this.activityTypeTexts.forEach((activityTypeText: string, index: number) => {
      let activityTypeOptionGroupDuration: string[] = [];

      if (this.isGroupByProductOrLocation) {
        xAxisLabelData.forEach((labelData: IStackChartAxisLabelData) => {
          const durationInHours: number = transformDurationType(
            this.getTotalDuration(_.get(groupedData, activityTypeText, [])[labelData.id]),
            'seconds',
            'hours',
          );

          this.activityTypeOptionGroupDurations[activityTypeText] = {
            ...this.activityTypeOptionGroupDurations[activityTypeText],
            [labelData.id]: durationInHours ? durationInHours : undefined,
          };
          this.optionGroupAndDurations = {
            ...this.optionGroupAndDurations,
            [labelData.id]: {
              id: labelData.id,
              value: labelData.value,
              duration:
                (this.optionGroupAndDurations[labelData.id]?.duration ?? 0) + (durationInHours ? durationInHours : 0),
            },
          };
        });
      } else {
        activityTypeOptionGroupDuration = _.get(groupedData, activityTypeText, []);
      }

      this.stackChartOption.series[index] = {
        name: this.translate.instant(`general.activityType.${activityTypeText}`),
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: (item: IChartEventParams) => {
            return `${this.decimalHelper.toFixedValue(String(item.value), 2)} ${this.shortHour}`;
          },
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color:
            (activityTypeText === 'downTimePlanned' ? this.customColors$?.activity.plannedDownTime.card : null) ??
            activityColors(activityTypeText),
        },
        data: !this.isGroupByProductOrLocation ? _.clone(activityTypeOptionGroupDuration) : [],
        barMaxWidth: this.maxStackChartBarWidth,
      };
    });

    if (this.isGroupByProductOrLocation) {
      this.optionGroupAndDurations = _.orderBy(
        this.optionGroupAndDurations,
        ['duration', (optionGroupAndDuration: IOptionGroupAndDuration) => optionGroupAndDuration.value.toLowerCase()],
        ['desc', 'asc'],
      );

      for (const optionGroupData of Object.values(this.optionGroupAndDurations)) {
        this.activityTypeTexts.forEach((activityTypeText: string, index: number) => {
          this.stackChartOption.series[index].data.push(
            this.activityTypeOptionGroupDurations[activityTypeText][optionGroupData.id],
          );
        });
      }

      this.stackChartTotalNumberOfBars = Object.values(this.optionGroupAndDurations).length;
      this.stackChartTotalNumberOfBarsText = String(this.stackChartTotalNumberOfBars);
    }

    Object.assign(this.stackChartOption.xAxis, {
      data: (this.isGroupByProductOrLocation ? Object.values(this.optionGroupAndDurations) : xAxisLabelData).map(
        (label: IOptionGroupAndDuration | IStackChartAxisLabelData) => label.value,
      ),
    });

    this.stackChartRef?.setOption(this.stackChartOption);
    Object.assign(this.stackChartOption, {
      tooltip: {
        formatter: (params: IChartEventParams) => {
          return `<b>${params.seriesName}</b></br>${params.name} - ${this.decimalHelper.toFixedValue(
            String(params.value),
          )} ${this.shortHour}`;
        },
      },
    });

    this.stackChartBarLimitInputModel = '';
  }

  private groupDataByOption(groupByOption: EStackChartGroupBy): [TGroupedData, IStackChartAxisLabelData[]] | undefined {
    switch (groupByOption) {
      case EStackChartGroupBy.PRODUCT:
      case EStackChartGroupBy.LINE:
      case EStackChartGroupBy.DEPARTMENT:
      case EStackChartGroupBy.ROOT_CAUSE_GROUP:
        return this.groupByLocationOrProduct(groupByOption);
      case EStackChartGroupBy.DAY:
      case EStackChartGroupBy.WEEK:
      case EStackChartGroupBy.MONTH:
        return this.groupByTime(groupByOption);
      default:
        return undefined;
    }
  }

  private groupByLocationOrProduct(groupByOption: EStackChartGroupBy): [TGroupedData, IStackChartAxisLabelData[]] {
    let groupedData: TGroupedData = {};
    let xAxisLabelData: IStackChartAxisLabelData[] = [];

    for (const [key, activityTypeGroup] of Object.entries(this.activityTypeGroups)) {
      const optionGroups: TGroupedDataOptionGroup = _.groupBy(activityTypeGroup, groupByOption);
      groupedData = { ...groupedData, [key]: optionGroups };
      xAxisLabelData = xAxisLabelData.concat(
        Object.keys(optionGroups).map((optionId: string) => {
          return {
            id: optionId,
            value: _.get(optionGroups[optionId], `0.${[EStackChartGroupByName[groupByOption]]}`),
          };
        }),
      );
    }

    return [groupedData, _.uniqBy(xAxisLabelData, 'id')];
  }

  private groupByTime(groupByOption: EStackChartGroupBy): [TGroupedData, IStackChartAxisLabelData[]] {
    const groupByOptionModified: moment.DurationInputArg2 = groupByOption as moment.DurationInputArg2;
    const [minStartDate, maxEndDate] = ChartsComponent.getMinStartDateMaxEndDate(
      this.filter.isBusinessDate,
      this.formattedData,
    );
    const xAxisDates: moment.Moment[] = ChartsComponent.divideDatesToDaysWeeksOrMonths(
      minStartDate,
      maxEndDate,
      groupByOptionModified,
    );
    const groupedData: TGroupedData = this.calculateActivityTypeDurations(xAxisDates, groupByOptionModified);
    let formatType: string = momentWeekFormat;

    if (groupByOption === EStackChartGroupBy.DAY) {
      formatType = this.userDateFormat$;
    } else if (groupByOption === EStackChartGroupBy.MONTH) {
      formatType = momentMonthFormat;
    }

    return [
      groupedData,
      xAxisDates.map((xAxisDate: moment.Moment) => {
        return {
          value:
            groupByOption === EStackChartGroupBy.DAY
              ? this.helperService.removeYear(xAxisDate, xAxisDate.format(formatType))
              : xAxisDate.format(formatType),
        };
      }),
    ];
  }

  private calculateActivityTypeDurations(
    xAxisDates: moment.Moment[],
    groupByOptionModified: moment.DurationInputArg2,
  ): TGroupedData {
    let groupedData: TGroupedData = {};

    for (const [key, activityTypeGroup] of Object.entries(this.activityTypeGroups)) {
      const durations: number[] = Array(xAxisDates.length).fill(0);

      activityTypeGroup.forEach((logData: IActivityLog) => {
        ChartsComponent.calculateDurationForEachDate(
          logData,
          xAxisDates,
          groupByOptionModified,
          durations,
          this.filter.isBusinessDate,
        );
      });

      groupedData = {
        ...groupedData,
        [key]: durations.map((duration: number) => (duration === 0 ? undefined : duration)),
      };
    }

    return groupedData;
  }

  public static calculateDurationForEachDate(
    logData: IActivityLog,
    xAxisDates: moment.Moment[],
    groupByOptionModified: moment.DurationInputArg2,
    durations: number[],
    isBusinessDate: boolean,
    activityTypeDurations?: IActivityTypeDurations,
  ): void {
    const shiftDay: moment.Moment = moment(logData.shiftDay);
    const activityStart: moment.Moment = moment(logData.start);
    const activityEnd: moment.Moment = moment(logData.end);

    xAxisDates.forEach((xAxisDate: moment.Moment, index: number) => {
      if (
        (!isBusinessDate && xAxisDate.isSame(activityStart, groupByOptionModified)) ||
        (isBusinessDate && xAxisDate.isSame(shiftDay, groupByOptionModified))
      ) {
        if (xAxisDate.isSame(activityEnd, groupByOptionModified) || isBusinessDate) {
          const duration: number = moment.duration(activityEnd.diff(activityStart)).asHours();
          durations[index] += duration;

          if (activityTypeDurations) {
            activityTypeDurations[logData.activityTypeText][index] += duration;
          }

          return;
        }

        const endOfDay: moment.Moment = activityStart
          .clone()
          .add(1, 'day')
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0);

        const firstDayDuration: number = moment.duration(endOfDay.diff(activityStart)).asHours();
        const secondDayDuration: number = moment.duration(activityEnd.diff(endOfDay)).asHours();
        durations[index] += firstDayDuration;
        durations[index + 1] += secondDayDuration;

        if (activityTypeDurations) {
          activityTypeDurations[logData.activityTypeText][index] += firstDayDuration;
          activityTypeDurations[logData.activityTypeText][index + 1] += secondDayDuration;
        }
      }
    });
  }

  public static divideDatesToDaysWeeksOrMonths(
    startDate: moment.Moment,
    endDate: moment.Moment,
    divisionType: moment.DurationInputArg2,
  ): moment.Moment[] {
    const startDateClone = startDate.clone();
    const dates = [];

    while (startDateClone.isSameOrBefore(endDate, divisionType)) {
      dates.push(startDateClone.clone());
      startDateClone.add(1, divisionType);
    }

    return dates;
  }

  public static getMinStartDateMaxEndDate(
    isBusinessDate: boolean = false,
    formattedData: IActivityLog[],
  ): moment.Moment[] {
    const minStartDate: moment.Moment = moment.min(
      formattedData.map((activityLog: IActivityLog) =>
        moment(isBusinessDate && activityLog.shiftDay ? activityLog.shiftDay : activityLog.start),
      ),
    );
    const maxEndDate: moment.Moment = moment.max(
      formattedData.map((activityLog: IActivityLog) =>
        moment(isBusinessDate && activityLog.shiftDay ? activityLog.shiftDay : activityLog.end),
      ),
    );

    return [minStartDate, maxEndDate];
  }

  public static formatRawData(
    startDate: moment.Moment,
    endDate: moment.Moment,
    rawData: IActivityLog[],
    timezone: string,
    isBusinessDate: boolean,
  ): IActivityLog[] {
    return rawData.map((logData: IActivityLog) => {
      const oldEndDate: string =
        logData.end && logData.end !== 'Ongoing'
          ? logData.end
          : getCurrentDateTimeAsMoment(timezone).format(mysqlTimestampFormat);
      const newStartDate: string =
        moment(logData.start).isBefore(startDate) && !isBusinessDate
          ? startDate.format(mysqlTimestampFormat)
          : logData.start;
      const newEndDate: string =
        moment(oldEndDate).isAfter(endDate) && !isBusinessDate ? endDate.format(mysqlTimestampFormat) : oldEndDate;

      const formattedTask = ChartsComponent.getFormattedTaskName({
        taskId: logData.taskId,
        activityType: logData.activityTypeText,
        taskName: logData.taskName,
        activityColor: logData.activityColor,
      });

      return {
        ...logData,
        start: newStartDate,
        end: newEndDate,
        taskId: logData.taskId ?? 0,
        taskName: formattedTask.taskName,
        activityColor: formattedTask.activityColor,
        productId: logData.productId ?? 'N/A',
        rootCauseGroupId: logData.rootCauseGroupId ?? 0,
        rootCauseGroupName: logData.rootCauseGroupName ?? 'N/A',
        duration: transformDurationType(moment(newEndDate).diff(moment(newStartDate)), 'millisecond', 'second'),
      };
    });
  }

  private getTotalDuration(logGroup: IActivityLog[]): number | undefined {
    if (!logGroup) {
      return undefined;
    }

    let totalDuration: number = 0;

    logGroup.forEach((activityLog: IActivityLog) => (totalDuration += Number(activityLog.duration)));

    return totalDuration;
  }

  public onGroupByDropdownChange(event: IStackChartDropdownOptions[]): void {
    switch (_.get(event, '0.id')) {
      case EStackChartDropdown.TIME:
        this.stackChartTimeButtonGroupModel = EStackChartGroupBy.WEEK;
        this.selectedGroupByOption = EStackChartGroupBy.WEEK;
        this.prepareStackChartData(EStackChartGroupBy.WEEK);
        break;
      case EStackChartDropdown.LOCATION:
        this.stackChartLocationButtonGroupModel = EStackChartGroupBy.LINE;
        this.selectedGroupByOption = EStackChartGroupBy.LINE;
        this.prepareStackChartData(EStackChartGroupBy.LINE);
        break;
      case EStackChartDropdown.PRODUCT:
        this.selectedGroupByOption = EStackChartGroupBy.PRODUCT;
        this.prepareStackChartData(EStackChartGroupBy.PRODUCT);
        break;
      case EStackChartDropdown.ROOT_CAUSE_GROUP:
        this.selectedGroupByOption = EStackChartGroupBy.ROOT_CAUSE_GROUP;
        this.prepareStackChartData(EStackChartGroupBy.ROOT_CAUSE_GROUP);
        break;
      default:
    }
  }

  private getDropdownOptionTranslation(): IStackChartDropdownOptions[] {
    const keys: EStackChartDropdown[] = [
      EStackChartDropdown.TIME,
      EStackChartDropdown.LOCATION,
      EStackChartDropdown.PRODUCT,
      EStackChartDropdown.ROOT_CAUSE_GROUP,
    ];
    const prefix: string = `${this.translate.instant(this.groupByPrefix)}: `;

    return keys.map((value: EStackChartDropdown) => {
      return {
        id: value,
        name: prefix + this.translate.instant(`rootCauseAnalysis.charts.groupBy.${value}`),
      };
    });
  }

  private setChartLoadingState(isLoading: boolean): void {
    if (isLoading) {
      const params: IChartLoader = { type: 'default', options: { text: '' } };

      this.sunburstChartRef?.showLoading(params.type, params.options);
      this.stackChartRef?.showLoading(params.type, params.options);

      return;
    }

    this.sunburstChartRef?.hideLoading();
    this.stackChartRef?.hideLoading();
  }

  public positiveNumberInputCheck(min: number, max: number): void {
    let stackChartBarLimitInputModel: number = Number(this.stackChartBarLimitInputModel);

    if (stackChartBarLimitInputModel > max) {
      stackChartBarLimitInputModel = max;
    } else if (this.stackChartBarLimitInputModel && stackChartBarLimitInputModel < min) {
      stackChartBarLimitInputModel = 1;
    }

    if (!Number.isInteger(stackChartBarLimitInputModel)) {
      stackChartBarLimitInputModel = Math.floor(stackChartBarLimitInputModel);
    }

    this.stackChartBarLimitInputModel = !stackChartBarLimitInputModel ? null : String(stackChartBarLimitInputModel);
    this.stackChartBarLimit = !stackChartBarLimitInputModel
      ? this.stackChartTotalNumberOfBars
      : stackChartBarLimitInputModel;

    clearTimeout(this.stackChartLimitTimeout);

    this.stackChartLimitTimeout = setTimeout(() => {
      this.filterTopResultsData();
    }, this.stackChartLimitSearchDelay);
  }

  public filterTopResultsData(): void {
    this.activityTypeTexts.forEach((activityTypeText: string, index: number) => {
      this.stackChartOption.series[index].data = [];
    });

    for (const optionGroupData of Object.values(this.optionGroupAndDurations).slice(0, this.stackChartBarLimit)) {
      this.activityTypeTexts.forEach((activityTypeText: string, index: number) => {
        this.stackChartOption.series[index].data.push(
          this.activityTypeOptionGroupDurations[activityTypeText][optionGroupData.id],
        );
      });
    }

    Object.assign(this.stackChartOption.xAxis, {
      data: (this.isGroupByProductOrLocation
        ? Object.values(this.optionGroupAndDurations).slice(0, this.stackChartBarLimit)
        : this.stackChartXAxisLabelData
      ).map((label: IOptionGroupAndDuration | IStackChartAxisLabelData) => label.value),
    });

    this.stackChartRef?.setOption(this.stackChartOption);
  }

  private checkForTasksWithoutEquipment(): void {
    const noEquipmentUsed: boolean = this.chartData$
      ? this.chartData$.every((node: IRootCauseAnalysisChartNode) => !node.equipmentId)
      : this.rawData$.every((logData: IActivityLog) => !logData.equipmentId);
    const allTasksHaveEquipment: boolean = this.chartData$
      ? this.chartData$.every((node: IRootCauseAnalysisChartNode) => node.equipmentId)
      : this.rawData$.every((logData: IActivityLog) => logData.equipmentId);

    this.isNoEquipmentAssignedExists = noEquipmentUsed || !allTasksHaveEquipment;
    this.isEquipmentUsedInTasks = !noEquipmentUsed;
    this.isNoEquipmentOnly = noEquipmentUsed;
  }

  public setNoEquipmentUsed(isNoEquipmentOnly: boolean): void {
    this.isNoEquipmentOnly = isNoEquipmentOnly;

    this.decideBarChartLayout(this.activitiesEquipmentChartLayout);
    this.setupPdfCharts();
  }

  private getLeftPaddingFullRow(
    layoutType: EActivitiesEquipmentChartLayout = this.activitiesEquipmentChartLayout,
  ): string {
    return this.helperService.isTouchDevice
      ? this.leftPaddingHalfRow
      : layoutType === EActivitiesEquipmentChartLayout.ROW || this.isNoEquipmentOnly
      ? this.leftPaddingFullRowWidened
      : this.leftPaddingFullRow;
  }

  public decideBarChartLayout(type: EActivitiesEquipmentChartLayout, shouldChangeLayoutType: boolean = true): void {
    if (shouldChangeLayoutType) {
      this.activitiesEquipmentChartLayout = type;
    }

    const leftPaddingFullRow: string = this.getLeftPaddingFullRow(type);
    const isRowLayout: boolean = type === EActivitiesEquipmentChartLayout.ROW;
    const labelLength: number =
      (isRowLayout || this.isNoEquipmentOnly) && !this.helperService.isTouchDevice
        ? this.barChartYAxisLabelLengthLong
        : this.barChartYAxisLabelLengthShort;

    if (isRowLayout) {
      this.barChartConfiguration = {
        ...this.barChartConfiguration,
        activitiesChartDivClasses: this.columnTwelve,
        leftPercentageActivitiesChart: leftPaddingFullRow,
        equipmentChartDivClasses: this.columnTwelve,
        leftPercentageEquipmentChart: leftPaddingFullRow,
      };
    } else {
      this.barChartConfiguration = {
        ...this.barChartConfiguration,
        activitiesChartDivClasses:
          !this.isEquipmentUsedInTasks || this.isNoEquipmentOnly || this.helperService.isTouchDevice
            ? this.columnTwelve
            : this.columnSix,
        leftPercentageActivitiesChart: this.isNoEquipmentOnly ? leftPaddingFullRow : this.leftPaddingHalfRow,
        equipmentChartDivClasses: this.helperService.isTouchDevice ? this.columnTwelve : this.columnSix,
        leftPercentageEquipmentChart: this.leftPaddingHalfRow,
      };
    }

    this.barChartConfiguration = {
      ...this.barChartConfiguration,
      leftPercentageTasksChart:
        this.isNoEquipmentOnly && !this.helperService.isTouchDevice
          ? this.leftPaddingFullRowWidened
          : leftPaddingFullRow,
      yAxisStyle: {
        ...this.barChartConfiguration.yAxisStyle,
        labelMaxLength: labelLength,
      },
    };
  }

  private static getFormattedTaskName(data: { taskId: number; activityType: string; taskName: string, activityColor: string }) {
    const isMissingData = !Boolean(data.taskId || data.activityType === ActivityTypes.RUN_TIME);

    return {
      taskName: isMissingData ? ChartsComponent.missingDataTranslation : data.taskName,
      activityColor: isMissingData ? activityColors(data.activityType, undefined, true) : data.activityColor,
      isMissingData,
    };
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }
}
