import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { isObservable, Subject, Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { HelperService } from '../../../service/helper.service';
import { IAdvancedFilterStore } from '../../../../store/advanced-filter/advanced-filter.model';
import * as AppActions from '../../../../store/app/actions';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { User } from '../../../../store/user/model';
import { DateRangeOpensTypes } from '../../scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.model';
import { ScwMatFormComponent } from '../../scw-mat-ui/scw-mat-form/scw-mat-form.component';
import { FilterCardComponent } from '../filter-card/filter-card.component';
import { OutputOptionsInterface } from '../filter.class';
import {
  AdvancedFilterObjects,
  FieldTypes,
  IAddFilterOptions,
  IAdvancedFilterOutput,
  IField,
  IFieldSelect,
  IFieldToFieldCompareOperators,
  IFilter,
  IFilterSetAsDefaultData,
  IHiddenField,
  InputTypes,
  IOperator,
  IOperatorSelect,
  IOperatorsFor,
  IPredefinedFieldSelect,
  IPredefinedSelect,
  Operator,
  OperatorTypes,
  QueryTypes,
  SelectAllTypes,
  SqlOperators,
  SubmitTypes,
  TargetEndpoints,
} from './advanced-filter.model';
import { AdvancedFilterService } from './advanced-filter.service';
import * as AdvancedFilterActions from '../../../../store/advanced-filter/advanced-filter.actions';
import {advancedFilterFilterCardSubject, DEFAULT_DECIMAL_INPUT_RULE } from '../../../../../constants';
import { TDecimalSeparator } from 'src/constants.model';

@Component({
  selector: 'advanced-filter',
  templateUrl: './advanced-filter.component.html',
  styleUrls: ['./advanced-filter.component.scss'],
})
export class AdvancedFilterComponent implements OnInit, OnDestroy {
  @ViewChild(ScwMatFormComponent, { static: false }) scwMatForm: ScwMatFormComponent;

  public readonly outputSubject: Subject<{ [advancedFilter: string]: IAdvancedFilterOutput }>;
  public readonly outputOptions: OutputOptionsInterface;

  public readonly OperatorTypes: typeof OperatorTypes = OperatorTypes;
  public readonly InputTypes: typeof InputTypes = InputTypes;
  public readonly SqlOperators: typeof SqlOperators = SqlOperators;
  public readonly QueryTypes: typeof QueryTypes = QueryTypes;
  public readonly DateRangeOpens: typeof DateRangeOpensTypes = DateRangeOpensTypes;

  public advancedFilterModalRef: NgbModalRef;
  public confirmationModalRef: NgbModalRef;
  private modalRefs: NgbModalRef[] = [];

  public filters: IFilter[] = [];
  public initialFilters: IFilter[] = [];
  public visibleFilters: IFilter[];
  public appliedFilterCount: number = 0;

  public isAddButtonDisabled: boolean = false;
  public isDeleteButtonDisabled: boolean = false;
  public isApplyButtonDisabled: boolean = false;
  public isSetDefaultButtonDisabled: boolean = false;

  public isChangesApplied: boolean = true;

  public selectAllType: SelectAllTypes = SelectAllTypes.select;

  private userId$: string;
  private filterCard$: FilterCardComponent;

  private decimalSeparator: TDecimalSeparator = ',';

  private readonly subscriptions: Subscription[] = [];

  @Input() filterObject: AdvancedFilterObjects;
  @Input() defaultValues: IFilter[] = [];
  @Input() isDisabled: boolean = false;
  @Input() isSetDefaultButtonHide: boolean = false;
  @Input() eventHandler: Subject<any>;

  private targetEndpoint: TargetEndpoints;

  public readonly operatorsFor: IOperatorsFor = {
    number: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.$gt, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.$lt, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.$gte, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.$lte, type: OperatorTypes.string },
      { name: Operator.isBlank, sql: SqlOperators.$isnull, type: OperatorTypes.boolean },
      { name: Operator.isNotBlank, sql: SqlOperators.$notnull, type: OperatorTypes.boolean },
    ],
    decimal: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.$gt, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.$lt, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.$gte, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.$lte, type: OperatorTypes.string },
      { name: Operator.isBlank, sql: SqlOperators.$isnull, type: OperatorTypes.boolean },
      { name: Operator.isNotBlank, sql: SqlOperators.$notnull, type: OperatorTypes.boolean },
    ],
    date: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.$gt, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.$lt, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.$gte, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.$lte, type: OperatorTypes.string },
      { name: Operator.isBlank, sql: SqlOperators.$isnull, type: OperatorTypes.boolean },
      { name: Operator.isNotBlank, sql: SqlOperators.$notnull, type: OperatorTypes.boolean },
      { name: Operator.between, sql: SqlOperators.$between, type: OperatorTypes.range },
    ],
    datetime: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.$gt, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.$lt, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.$gte, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.$lte, type: OperatorTypes.string },
      { name: Operator.isBlank, sql: SqlOperators.$isnull, type: OperatorTypes.boolean },
      { name: Operator.isNotBlank, sql: SqlOperators.$notnull, type: OperatorTypes.boolean },
      { name: Operator.between, sql: SqlOperators.$between, type: OperatorTypes.range },
    ],
    string: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.contains, sql: SqlOperators.$cont, type: OperatorTypes.string },
      { name: Operator.doesNotContain, sql: SqlOperators.$excl, type: OperatorTypes.string },
      { name: Operator.isBlank, sql: SqlOperators.$isnull, type: OperatorTypes.boolean },
      { name: Operator.isNotBlank, sql: SqlOperators.$notnull, type: OperatorTypes.boolean },
    ],
    boolean: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
    ],
    predefined: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.contains, sql: SqlOperators.$cont, type: OperatorTypes.string },
      { name: Operator.doesNotContain, sql: SqlOperators.$excl, type: OperatorTypes.string },
      { name: Operator.isBlank, sql: SqlOperators.$isnull, type: OperatorTypes.boolean },
      { name: Operator.isNotBlank, sql: SqlOperators.$notnull, type: OperatorTypes.boolean },
    ],
    duration: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.$gt, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.$lt, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.$gte, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.$lte, type: OperatorTypes.string },
      { name: Operator.isBlank, sql: SqlOperators.$isnull, type: OperatorTypes.boolean },
      { name: Operator.isNotBlank, sql: SqlOperators.$notnull, type: OperatorTypes.boolean },
    ],
    dateFormat: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
    ],
  };

  public readonly operatorsForFieldToFieldCompare: IFieldToFieldCompareOperators = {
    number: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.$gt, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.$lt, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.$gte, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.$lte, type: OperatorTypes.string },
    ],
    decimal: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.$gt, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.$lt, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.$gte, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.$lte, type: OperatorTypes.string },
    ],
    date: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.$gt, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.$lt, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.$gte, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.$lte, type: OperatorTypes.string },
    ],
    datetime: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.$gt, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.$lt, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.$gte, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.$lte, type: OperatorTypes.string },
    ],
    duration: [
      { name: Operator.equals, sql: SqlOperators.$eq, type: OperatorTypes.string },
      { name: Operator.notEqual, sql: SqlOperators.$ne, type: OperatorTypes.string },
      { name: Operator.greaterThan, sql: SqlOperators.$gt, type: OperatorTypes.string },
      { name: Operator.lessThan, sql: SqlOperators.$lt, type: OperatorTypes.string },
      { name: Operator.greaterThanOrEqual, sql: SqlOperators.$gte, type: OperatorTypes.string },
      { name: Operator.lessThanOrEqual, sql: SqlOperators.$lte, type: OperatorTypes.string },
    ],
  };

  public fields: (IFieldSelect | IPredefinedFieldSelect)[] = [];
  private initialFields: (IFieldSelect | IPredefinedFieldSelect)[] = [];

  private readonly hiddenFields: IHiddenField[] = [];

  public readonly inputRules = {
    number: [
      {
        minValue: 0,
        message: this.translate.instant('scwMatForm.validation.minValue', { minValue: 0 }),
      },
      {
        maxValue: 1000000,
        message: this.translate.instant('scwMatForm.validation.maxValue', { maxValue: 1000000 }),
      },
    ],
    decimal: [DEFAULT_DECIMAL_INPUT_RULE],
    duration: [
      {
        pattern: new RegExp('^(?:\\d+):(?:[012345]\\d)$'),
        message: this.translate.instant('scwMatForm.validation.duration'),
      },
    ],
    text: [
      {
        custom: true,
        validator: (value: string) => {
          return !_.isEmpty(String(value).trim());
        },
        message: this.translate.instant('scwMatForm.validation.whiteSpace'),
      },
    ],
  };

  public submitType: SubmitTypes;

  constructor(
    public readonly translate: TranslateService,
    public readonly advancedFilterService: AdvancedFilterService,
    public readonly helperService: HelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly toast: ToastrService,
    private readonly advancedFilterModal: NgbModal,
    private readonly confirmationModal: NgbModal,
  ) {}
  public ngOnInit(): void {
    this.store.dispatch(new AdvancedFilterActions.LoadDefaultFilters());

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((user: User) => {
        this.userId$ = user.userId;
        this.decimalSeparator = user.decimalSeparator;
      });

    this.subscriptions.push(
      advancedFilterFilterCardSubject.subscribe((filterCard: any) => {
        this.filterCard$ = filterCard;
      }),
    );

    this.store
      .select('advancedFilterStore')
      .pipe(
        skipWhile((state: IAdvancedFilterStore) => state.isDefaultFiltersLoading && !state.isDefaultFiltersLoaded),
        take(1),
      )
      .subscribe((state: IAdvancedFilterStore) => {
        if (this.defaultValues.length !== 0) {
          this.filters = _.cloneDeep(this.defaultValues);
          this.initialFilters = _.cloneDeep(this.filters);
          this.isChangesApplied = true;
          this.setAppliedFilterCount();
          this.onFormChange();
          return;
        }

        if (_.isEmpty(state.defaultFilters) || _.isEmpty(state.defaultFilters[this.filterObject.pageName])) {
          const doesHiddenFilterExist: boolean =
            this.filters.length && this.filters.some((filter: IFilter) => filter.isHidden);

          if (!doesHiddenFilterExist) {
            this.addFilter();
          }

          this.initialFilters = _.cloneDeep(this.filters);
          this.isChangesApplied = true;
          return;
        }

        this.filters = _.cloneDeep(state.defaultFilters[this.filterObject.pageName]);

        for (const index of Object.keys(this.filters)) {
          this.assignFieldToFieldCompareValues(Number(index));
        }

        this.translateObjectKeys();
        this.translateSelectedOperators();
        this.setOperatorsForType();

        this.initialFilters = _.cloneDeep(this.filters);
        this.isChangesApplied = true;
        this.setAppliedFilterCount();

        this.onFormChange();
      });

    this.fields = this.filterObject.fields.map((field: IField, index: number) => {
      const baseField: IFieldSelect = {
        id: index,
        field: field.key,
        label: this.translate.instant(field.key),
        path: field.path,
        type: field.type,
        queryType: field.queryType,
        excludeOperators: field.excludeOperators,
        showSearchBox: {
          showSearchBox: field.showSearchBox?.showSearchBox ?? false,
          searchBehaviour: field.showSearchBox?.searchBehaviour ?? 'live',
        },
        ...(field.isDbPath ? { isDbPath: field.isDbPath } : {}),
      };

      if (isObservable(field.predefinedValues)) {
        return {
          ...baseField,
          predefinedValues: [],
          predefinedValuesObservable: field.predefinedValues,
          observableIdProperty: field.observableIdProperty,
          observableNameProperty: field.observableNameProperty,
          observableKeyProperty: field.observableKeyProperty,
          searchBy: field.searchBy,
        };
      }

      if (Array.isArray(field.predefinedValues)) {
        return {
          ...baseField,
          predefinedValues: field.predefinedValues,
          searchBy: field.searchBy,
        };
      }

      return {
        ...baseField,
      };
    });
    this.targetEndpoint = this.filterObject.targetEndpoint;

    this.populatePredefinedFieldsWithObservables();
    this.setOperatorsForType();

    this.isChangesApplied = true;
    this.initialFilters = _.cloneDeep(this.filters);
    this.initialFields = _.cloneDeep(this.fields);

    this.onFormChange();
  }

  private populatePredefinedFieldsWithObservables(): void {
    this.fields
      .filter((field: IFieldSelect | IPredefinedFieldSelect) =>
        isObservable(_.get(field, 'predefinedValuesObservable', null)),
      )
      .forEach((field: IPredefinedFieldSelect) => {
        field.predefinedValuesObservable.pipe(take(2)).subscribe((state: any) => {
          field.predefinedValues = HelperService.cloneDeep(state.data).map((predefinedValue: any) => {
            return {
              id: predefinedValue[field.observableIdProperty],
              name: this.translate.instant(predefinedValue[field.observableKeyProperty]),
              key: predefinedValue[field.observableKeyProperty],
            };
          });

          this.translateObservableKeys(field);

          this.initialFields = _.cloneDeep(this.fields);
        });

        delete field.predefinedValuesObservable;
      });

    this.isChangesApplied = true;
    this.initialFilters = _.cloneDeep(this.filters);

    this.onFormChange();
  }

  public showAdvancedFilterModal(templateRef: TemplateRef<any>): void {
    if (this.isDisabled) return;

    this.advancedFilterModalRef = this.advancedFilterModal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-lg scw-modal-all-scrollable',
    });

    this.modalRefs.push(this.advancedFilterModalRef);
  }

  public showConfirmationModal(templateRef: TemplateRef<any>): void {
    this.confirmationModalRef = this.confirmationModal.open(templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-sm',
    });

    this.modalRefs.push(this.confirmationModalRef);
  }

  public closeAllModals(): void {
    this.fields = _.cloneDeep(this.initialFields);
    this.modalRefs.forEach((modal: NgbModalRef) => modal.close());
    this.modalRefs = [];
  }

  public addFilter(options: IAddFilterOptions = null): void {
    this.filters = this.filters.concat({
      isActive: options?.isActive ?? true,
      operators: [],
      selectedOperator: [],
      selectedField: [],
      selectedFieldType: InputTypes.text,
      value: null,
      isFieldToFieldComparisonEnabled: false,
      isCompareMethodChangeButtonVisible: false,
      sameGroupFieldDropdownItems: [],
    });

    this.onFormChange();
  }

  public onCompareMethodChange(index: number): void {
    const clickedFilter: IFilter = this.filters[index];

    if (clickedFilter.selectedOperator?.length) {
      const operators = clickedFilter.isFieldToFieldComparisonEnabled
        ? this.operatorsFor
        : this.operatorsForFieldToFieldCompare;

      if (
        !_.find(operators[clickedFilter.selectedField[0].type], { name: clickedFilter.selectedOperator[0].operator })
      ) {
        clickedFilter.selectedOperator = [];
      }
    }

    clickedFilter.value = null;
    clickedFilter.isFieldToFieldComparisonEnabled = !clickedFilter.isFieldToFieldComparisonEnabled;
    this.setOperatorsForType();
  }
  public deleteFilters(): void {
    this.filters = this.filters.filter((filter: IFilter) => !filter.isActive || filter.isHidden);
    this.addEmptyFilterIfThereAreNoVisibleFilters();

    this.onFormChange();
  }

  private addEmptyFilterIfThereAreNoVisibleFilters() {
    const areAllFiltersHidden: boolean = this.filters.every((filter: IFilter) => filter.isHidden);

    if (areAllFiltersHidden) {
      this.addFilter({ isActive: false });
    }
  }

  private static determineInputType(filter: IFilter): InputTypes {
    if (_.isEmpty(filter.selectedField)) {
      return InputTypes.text;
    }

    switch (_.head(filter.selectedField).type) {
      case FieldTypes.string:
        return InputTypes.text;

      case FieldTypes.predefined:
        return InputTypes.select;

      case FieldTypes.number:
        return InputTypes.number;

      case FieldTypes.decimal:
        return InputTypes.decimal;

      case FieldTypes.date:
        return InputTypes.date;

      case FieldTypes.dateTime:
        return InputTypes.dateTime;

      case FieldTypes.boolean:
        return InputTypes.checkbox;

      case FieldTypes.duration:
        return InputTypes.duration;

      case FieldTypes.dateFormat:
        return InputTypes.dateFormat;

      default:
        return InputTypes.text;
    }
  }

  private setSelectAllType(): void {
    if (this.filters.filter((filter: IFilter) => !filter.isHidden).every((filter: IFilter) => filter.isActive)) {
      this.selectAllType = SelectAllTypes.deSelect;
    } else {
      this.selectAllType = SelectAllTypes.select;
    }
  }

  private setAddButtonState(): void {
    const invalidFilters: IFilter[] = this.filters.filter(
      (filter: IFilter) => !AdvancedFilterComponent.isFilterValid(filter),
    );

    this.isAddButtonDisabled = invalidFilters.length >= 3;
  }

  private setDeleteButtonState(isSomeFiltersActive: boolean): void {
    this.isDeleteButtonDisabled = !isSomeFiltersActive;
  }

  private setFieldsValueInputType(): void {
    this.filters.forEach((filter: IFilter) => {
      filter.selectedFieldType = AdvancedFilterComponent.determineInputType(filter);
    });
  }

  private translateSelectedOperators(): void {
    this.filters.forEach((filter: IFilter) => {
      if (_.isEmpty(filter.selectedOperator)) return;

      filter.selectedOperator[0].translatedOperator = this.translate.instant(
        `filterCard.advancedFilter.operators.${filter.selectedOperator[0].operator}`,
      );
    });
  }

  private setOperatorsForType(): void {
    this.filters.forEach((filter: IFilter) => {
      if (_.isEmpty(filter.selectedField)) return;

      const fieldType: FieldTypes = filter.selectedField[0].type;
      const operators = filter.isFieldToFieldComparisonEnabled
        ? this.operatorsForFieldToFieldCompare
        : this.operatorsFor;
      filter.operators = operators[fieldType]
        .filter((operator: IOperator) => {
          const excludedOperators: SqlOperators[] = _.get(filter, 'selectedField[0].excludeOperators', []);

          return !excludedOperators.includes(operator.sql);
        })
        .map(
          (operator: IOperator, index: number): IOperatorSelect => {
            return {
              id: index,
              operator: operator.name,
              translatedOperator: this.translate.instant(`filterCard.advancedFilter.operators.${operator.name}`),
              type: operator.type,
              sql: operator.sql,
              showSearchBox: {
                showSearchBox: filter.selectedField[0].showSearchBox?.showSearchBox ?? false,
                searchBehaviour: filter.selectedField[0].showSearchBox?.searchBehaviour ?? 'live',
              },
            };
          },
        );
    });
  }

  private translatePredefinedValues(): void {
    if (!this.filters.some((filter: IFilter) => _.get(_.head(filter.selectedField), 'predefinedValues', null))) {
      return;
    }

    for (const filter of this.filters) {
      if (_.isEmpty(filter.selectedField) || _.isEmpty(_.head(filter.selectedField).predefinedValues)) {
        continue;
      }

      _.head(filter.selectedField).predefinedValues.forEach((predefinedValue: IPredefinedSelect) => {
        if (predefinedValue.key) {
          predefinedValue.name = this.translate.instant(predefinedValue.key);
        }
      });

      if (!_.isEmpty(filter.value) && Array.isArray(filter.value) && !_.isEmpty(filter.value[0].key)) {
        filter.value[0].name = this.translate.instant(filter.value[0].key);
      }
    }
  }

  public selectAll(): void {
    this.filters.forEach((filter: IFilter) => {
      filter.isActive = true;
    });

    this.onFormChange();
  }

  public deSelectAll(): void {
    this.filters.forEach((filter: IFilter) => {
      filter.isActive = false;
    });

    this.onFormChange();
  }

  private checkOperatorMatch(): void {
    this.filters.forEach((filter: IFilter) => {
      if (_.isEmpty(filter.selectedField) || _.isEmpty(filter.selectedOperator)) return;
      const selectedField: IFieldSelect = _.head(filter.selectedField);
      const selectedOperator: IOperatorSelect = _.head(filter.selectedOperator);

      const isValidOperator: boolean = this.operatorsFor[selectedField.type]
        .map((operator: IOperator) => operator.name)
        .includes(selectedOperator.operator);

      if (!isValidOperator) {
        filter.selectedOperator = null;
      }
    });
  }

  private static validateSingleDate(filter: IFilter): boolean {
    return (
      moment(_.get(filter, 'value.startDate', null)).isValid() && moment(_.get(filter, 'value.endDate', null)).isValid()
    );
  }

  private static isFilterValid(filter: IFilter): boolean {
    if (filter.isHidden) {
      return true;
    }

    if (_.isEmpty(filter.selectedField) || _.isEmpty(filter.selectedOperator)) {
      return false;
    }

    if (filter.selectedOperator[0].type === OperatorTypes.boolean) {
      return true;
    }

    switch (filter.selectedFieldType) {
      case InputTypes.dateTime:
      case InputTypes.date:
        return filter.isFieldToFieldComparisonEnabled
          ? filter.value
          : AdvancedFilterComponent.validateSingleDate(filter);

      default:
        return !_.isEmpty(filter.value);
    }
  }

  private checkAreSelectedFiltersValid(): void {
    const activeFilters: IFilter[] = this.filters.filter((filter: IFilter) => filter.isActive);

    if (_.isEmpty(activeFilters)) {
      this.isApplyButtonDisabled = false;
      this.isSetDefaultButtonDisabled = false;
      return;
    }

    const isAllValid: boolean = !activeFilters.some((filter: IFilter) => {
      return !AdvancedFilterComponent.isFilterValid(filter);
    });

    if (!isAllValid) {
      this.isApplyButtonDisabled = true;
      this.isSetDefaultButtonDisabled = true;
    } else {
      this.isApplyButtonDisabled = false;
      this.isSetDefaultButtonDisabled = false;
    }
  }

  public checkChanges(): void {
    const difference: IFilter[] = _.differenceWith(this.filters, this.initialFilters, _.isEqual);
    this.isChangesApplied = _.isEmpty(difference);
  }

  private checkOperatorQueryTypeMatch(): void {
    this.filters.forEach((filter: IFilter) => {
      if (_.isEmpty(filter.selectedField)) return;

      if (_.head(filter.selectedField).queryType === QueryTypes.withinRequestQueryString) {
        filter.selectedOperator = [filter.operators[0]];
      }
    });
  }

  private restoreHiddenFieldsIfNotUsed(): void {
    this.hiddenFields.forEach((hiddenField: IHiddenField, index: number) => {
      const isFieldUsed: boolean = this.filters.some((filter: IFilter) => {
        return !_.isEmpty(filter.selectedField) && filter.selectedField[0].path === hiddenField.field.path;
      });

      if (isFieldUsed) {
        return;
      }

      this.fields.splice(hiddenField.atIndex, 0, hiddenField.field);

      // Trigger Angular change detection
      this.fields = _.cloneDeep(this.fields);
      this.hiddenFields.splice(index, 1);
    });

    this.initialFields = _.cloneDeep(this.fields);
  }

  private hideFieldsShouldUsedOnce(): void {
    this.filters.forEach((filter: IFilter) => {
      if (_.isEmpty(filter.selectedField)) {
        return;
      }
      if (
        filter.selectedField[0].queryType === QueryTypes.withinRequestQueryString ||
        filter.selectedField[0].path === 'activityTypeText' ||
        filter.isHidden
      ) {
        const selectedField: IFieldSelect | IPredefinedFieldSelect = this.fields.find(
          (field: IFieldSelect) => field.path === filter.selectedField[0].path,
        );

        if (!selectedField) {
          return;
        }

        this.hiddenFields.push({
          atIndex: this.fields.indexOf(selectedField),
          field: selectedField,
        });

        this.fields = this.fields.filter((field: IFieldSelect) => {
          return field.path !== filter.selectedField[0].path;
        });
      }
    });

    this.initialFields = _.cloneDeep(this.fields);
  }

  private setHiddenFields(): void {
    this.hideFieldsShouldUsedOnce();
    this.restoreHiddenFieldsIfNotUsed();
  }

  public onFieldChange(filterIndex: number): void {
    this.filters[filterIndex].selectedOperator = [];
    this.filters[filterIndex].value = '';
    this.assignFieldToFieldCompareValues(filterIndex);
    // Trigger Angular change detection
    this.filters = _.cloneDeep(this.filters);
    this.onFormChange();
  }

  private assignFieldToFieldCompareValues(filterIndex: number): void {
    if (!this.filters[filterIndex].selectedField.length) {
      this.filters[filterIndex].isCompareMethodChangeButtonVisible = false;
      this.filters[filterIndex].isFieldToFieldComparisonEnabled = false;
      return;
    }

    const path = this.filters[filterIndex].selectedField[0].path;
    const field: IField = _.find(this.filterObject.fields, { path });
    if (!field.groupId) {
      this.filters[filterIndex].isCompareMethodChangeButtonVisible = false;
      this.filters[filterIndex].sameGroupFieldDropdownItems = [];
      return;
    }
    this.filters[filterIndex].sameGroupFieldDropdownItems = this.filterObject.fields
      .filter((data: IField) => data.path !== field.path && data.groupId === field.groupId)
      .map((field: IField) => {
        return {
          id: field.path,
          name: this.translate.instant(field.key),
        };
      });

    this.filters[filterIndex].isCompareMethodChangeButtonVisible = true;
  }

  public onOperatorChange(filter: IFilter): void {
    if (filter.selectedFieldType === InputTypes.date || filter.selectedFieldType === InputTypes.dateTime) {
      filter.value = null;
    }

    this.onFormChange();
  }

  public onFormChange(): void {
    const isSomeFiltersActive: boolean = this.filters.some((filter: IFilter) => filter.isActive && !filter.isHidden);

    this.isChangesApplied = false;

    this.setSelectAllType();
    this.setAddButtonState();
    this.setDeleteButtonState(isSomeFiltersActive);
    this.setFieldsValueInputType();
    this.setOperatorsForType();
    this.checkOperatorMatch();
    this.checkAreSelectedFiltersValid();
    this.translatePredefinedValues();
    this.checkChanges();
    this.checkOperatorValueMatch();
    this.checkOperatorQueryTypeMatch();
    this.setHiddenFields();
    this.formatValueForDateInputs();
    this.visibleFilters = this.filters.filter((filter: IFilter) => !filter.isHidden);
  }

  private formatValueForDateInputs(): void {
    this.filters = this.filters.map((filter: IFilter) => {
      return [InputTypes.dateTime, InputTypes.date].includes(filter.selectedFieldType) &&
        typeof filter.value === 'string' &&
        filter.value !== ''
        ? { ...filter, value: { startDate: moment(filter.value), endDate: moment(filter.value) } }
        : filter;
    });
  }

  public onSubmit(isValid: boolean): void {
    if (!isValid) return;

    if (this.filterCard$.invalidUpdate) {
      this.toast.error(this.filterCard$.updateMessage, this.translate.instant('general.error'), {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-right',
      });

      return;
    }

    if (this.submitType === SubmitTypes.apply) {
      this.onApply();
    } else if (this.submitType === SubmitTypes.setAsDefault) {
      this.onSetAsDefault();
    }
  }

  public inputInvalid($event: boolean): void {
    this.isApplyButtonDisabled = !$event;
    this.isSetDefaultButtonDisabled = !$event;
    this.filters.forEach((filter: IFilter) => {
      if (
        filter?.isActive === true &&
        filter?.value === '' &&
        !['isBlank', 'isNotBlank'].includes(filter?.selectedOperator[0]?.operator)
      ) {
        this.isApplyButtonDisabled = true;
        this.isSetDefaultButtonDisabled = true;
      }
    });
  }

  public clickSetAsDefaultButton(): void {
    this.submitType = SubmitTypes.setAsDefault;
  }

  public clickApplyButton(): void {
    this.submitType = SubmitTypes.apply;
  }

  public async onSetAsDefault(): Promise<void> {
    this.store.dispatch(new AppActions.ShowLoader());

    const setAsDefaultFilterData: IFilterSetAsDefaultData[] = HelperService.cloneDeep(this.filters).map((filter: IFilter) => {
      const { isCompareMethodChangeButtonVisible, sameGroupFieldDropdownItems, ...setAsDefaultFilterData } = filter;
      return setAsDefaultFilterData;
    });

    await this.advancedFilterService
      .setAsDefault(this.userId$, this.filterObject.pageName, setAsDefaultFilterData)
      .toPromise();
    this.store.dispatch(new AdvancedFilterActions.LoadDefaultFilters());
    this.onApply();
    this.store.dispatch(new AppActions.HideLoader());
  }

  public setAppliedFilterCount(): void {
    this.appliedFilterCount = this.filters.filter(
      (filter: IFilter) => filter.isActive && filter.selectedField.length && !filter.isHidden,
    ).length;
  }

  private checkOperatorValueMatch(): void {
    this.filters.forEach((filter: IFilter) => {
      if (_.isEmpty(filter.selectedOperator)) return;

      if (filter.selectedOperator[0].type === OperatorTypes.boolean) {
        filter.value = '';
      }
    });
  }

  public onDontSave(): void {
    this.filters = _.cloneDeep(this.initialFilters);
    this.onFormChange();
    this.closeAllModals();
  }

  public onApply(): void {
    this.outputSubject.next({
      [this.outputOptions.filterObjectId]: {
        filters: this.advancedFilterService.prepareForOutput(this.filters),
        rawFilters: this.filters,
        target: this.targetEndpoint,
        page: this.filterObject.pageName,
      },
    });
    this.setAppliedFilterCount();
    this.initialFilters = _.cloneDeep(this.filters);
    this.isChangesApplied = true;
    this.closeAllModals();
    this.advancedFilterService.updateTable();
  }

  public ngOnDestroy(): void {
    (this.subscriptions ?? [])?.forEach((subscription: Subscription) => subscription?.unsubscribe());
    this.closeAllModals();
    this.advancedFilterService.unsetFilterCard();
  }

  public translateObjectKeys(): void {
    this.filters = this.filters.map((filter: IFilter) => {
      if (_.isEmpty(filter.selectedField)) return filter;

      return {
        ...filter,
        selectedField: [{ ...filter.selectedField[0], label: this.translate.instant(filter.selectedField[0].field) }],
      };
    });
  }

  public translateObservableKeys(field: IPredefinedFieldSelect): void {
    this.filters = this.filters.map((filter: IFilter) => {
      if (_.isEmpty(filter.selectedField)) return filter;

      if (field.path !== filter.selectedField[0].path) return filter;

      return {
        ...filter,
        selectedField: [
          {
            ...filter.selectedField[0],
            predefinedValues: field.predefinedValues,
            label: this.translate.instant(filter.selectedField[0].field),
          },
        ],
      };
    });
  }
}
