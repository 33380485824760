import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { HelperService } from '../../../shared/service/helper.service';
import * as oeeAppReducer from '../../oee.reducer';
import {
  DurationVarianceType,
  IScheduleAdherenceData,
  IScheduleAdherenceFilters,
  IScheduleAdherenceResponseData,
  IScheduleAdherenceTableRow,
  IWoDurationsChartNode,
  WorkOrderRunType,
} from './schedule-adherence.model';
import { Observable } from 'rxjs';
import { ResponseInterface } from '../../../shared/model/interface/generic-api-response.model';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { transformDurationType } from '../../../shared/helper/date';

@Injectable({
  providedIn: 'root',
})
export class ScheduleAdherenceService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
    private readonly helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  private readonly routes = {
    schedulerAdherence: `${this.api}/work-orders/schedule-adherence`,
  };

  public getScheduleAdherenceData(
    params: IScheduleAdherenceFilters,
  ): Observable<ResponseInterface<IScheduleAdherenceResponseData[]>> {
    const httpParams: HttpParams = new HttpParams()
      .append('intervalStartDate', params.intervalStartDate)
      .append('intervalEndDate', params.intervalEndDate)
      .append('siteId', params.siteId)
      .append('lineIds', params.lineIds.toString())
      .append('includeAllActivities', params.includeAllActivities);

    return this.http.get<ResponseInterface<IScheduleAdherenceResponseData[]>>(`${this.routes.schedulerAdherence}`, {
      params: httpParams,
    });
  }

  public formatScheduleAdherenceData(responseData: IScheduleAdherenceResponseData[]): IScheduleAdherenceData {
    let overdueWorkOrdersCount: number = 0;
    let behindWoScheduleWorkOrdersCount: number = 0;
    let plannedAndStartedWorkOrdersCount: number = 0;
    let plannedAndNotStartedWorkOrdersCount: number = 0;
    let unplannedAndStartedWorkOrdersCount: number = 0;

    let scheduleComplianceCount: number = 0;
    let scheduleComplianceTotal: number = 0;

    let onTimeCompletionCounts: number = 0;

    const woDurationsChartNodes: IWoDurationsChartNode[] = [];
    const tableData: IScheduleAdherenceTableRow[] = [];

    responseData.map((data: IScheduleAdherenceResponseData) => {
      if (data.overdue) {
        overdueWorkOrdersCount++;
      }

      if (data.behindScheduleWorkOrder) {
        behindWoScheduleWorkOrdersCount++;
      }

      switch (data.runType) {
        case WorkOrderRunType.PLANNED_AND_STARTED:
          plannedAndStartedWorkOrdersCount++;
          break;
        case WorkOrderRunType.PLANNED_AND_NOT_STARTED:
          plannedAndNotStartedWorkOrdersCount++;
          break;
        case WorkOrderRunType.UNPLANNED_AND_STARTED:
          unplannedAndStartedWorkOrdersCount++;
          break;
      }

      if (data.isOnTimeCompletion) {
        onTimeCompletionCounts++;
      }

      if (data.compliancePercentage) {
        scheduleComplianceTotal += data.compliancePercentage;
        scheduleComplianceCount++;
      }

      woDurationsChartNodes.push({
        title: data.woNumber,
        phases: {
          noPhase: {
            title: 'noPhase',
            actualDuration: this.durationSecondsToHour(data.actualNoPhaseDuration),
            scheduledDuration: 0,
          },
          preRun: {
            title: 'preRun',
            actualDuration: this.durationSecondsToHour(data.actualPreRunPhaseDuration),
            scheduledDuration: this.durationSecondsToHour(data.scheduledPreDuration),
          },
          run: {
            title: 'run',
            actualDuration: this.durationSecondsToHour(data.actualRunPhaseDuration),
            scheduledDuration: this.durationSecondsToHour(data.scheduledRunDuration),
          },
          postRun: {
            title: 'postRun',
            actualDuration: this.durationSecondsToHour(data.actualPostRunPhaseDuration),
            scheduledDuration: this.durationSecondsToHour(data.scheduledPostDuration),
          },
        },
      });

      tableData.push({
        actualLine: data.actualLineName ?? '',
        scheduledLine: data.scheduledLineName ?? '',
        workOrder: data.woNumber,
        scheduledPreRun: this.durationSecondsToHour(data.scheduledPreDuration).toString(),
        actualPreRun: this.durationSecondsToHour(data.actualPreRunPhaseDuration).toString(),
        varianceOfPreRun: this.durationSecondsToHour(data.varianceOfPreRunDuration).toString(),
        scheduledRun: this.durationSecondsToHour(data.scheduledRunDuration).toString(),
        actualRun: this.durationSecondsToHour(data.actualRunPhaseDuration).toString(),
        varianceOfRun: this.durationSecondsToHour(data.varianceOfRunDuration).toString(),
        scheduledPostRun: this.durationSecondsToHour(data.scheduledPostDuration).toString(),
        actualPostRun: this.durationSecondsToHour(data.actualPostRunPhaseDuration).toString(),
        varianceOfPostRun: this.durationSecondsToHour(data.varianceOfPostRunDuration).toString(),
        scheduledStartDate: data.scheduledRunDate,
        scheduledEndDate: data.scheduledEndDate,
        actualStartDate: data.actualMinStart,
        actualEndDate: data.actualMaxEnd,
        woDueDate: data.woDueDate,
        varianceStatus: {
          varianceOfPreRun: this.getVarianceResult(data.varianceOfPreRunDuration),
          varianceOfRun: this.getVarianceResult(data.varianceOfRunDuration),
          varianceOfPostRun: this.getVarianceResult(data.varianceOfPostRunDuration),
        },
        isOverdue: data.overdue,
      });
    });

    let scheduleAdherence: number = -1;
    let scheduleAttainment: number = -1;
    let scheduleCompliance: number = -1;
    const plannedWorkOrderCounts: number = plannedAndStartedWorkOrdersCount + plannedAndNotStartedWorkOrdersCount;
    const startedWorkOrderCounts: number = plannedAndStartedWorkOrdersCount + unplannedAndStartedWorkOrdersCount;

    if (plannedWorkOrderCounts !== 0) {
      scheduleAttainment = startedWorkOrderCounts / plannedWorkOrderCounts;
      scheduleAdherence = plannedAndStartedWorkOrdersCount / plannedWorkOrderCounts;
    }

    const workOrderOnTimeCompletionRatio: number = responseData.length !== 0 ? (onTimeCompletionCounts / responseData.length) * 100 : -1;

    if (scheduleComplianceCount !== 0) {
      scheduleCompliance = (scheduleComplianceTotal / scheduleComplianceCount);
    }

    return {
      kpiCardsData: {
        overdueWorkOrders: overdueWorkOrdersCount,
        behindScheduleWorkOrders: behindWoScheduleWorkOrdersCount,
        scheduleAdherence: scheduleAdherence,
        scheduleAttainment: scheduleAttainment,
        scheduleCompliance: scheduleCompliance,
        workOrderOnTimeCompletion: workOrderOnTimeCompletionRatio,
        plannedAndStartedWorkOrders: plannedAndStartedWorkOrdersCount,
        unplannedAndStartedWorkOrders: unplannedAndStartedWorkOrdersCount,
        plannedAndNotStartedWorkOrders: plannedAndNotStartedWorkOrdersCount,
      },
      woDurationsChartData: woDurationsChartNodes,
      tableData: tableData,
      rawData: responseData,
    };
  }

  private getVarianceResult(duration: number): DurationVarianceType {
    const value = this.durationSecondsToHour(duration);

    if (value > 0) {
      return DurationVarianceType.POSITIVE;
    } else if (value < 0) {
      return DurationVarianceType.NEGATIVE;
    }

    return DurationVarianceType.ZERO;
  }

  private durationSecondsToHour(duration: number): number {
    return transformDurationType(duration, 'seconds', 'hours');
  }
}
