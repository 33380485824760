import { Action } from '@ngrx/store';
import { IScheduleAdherenceData, IScheduleAdherenceFilters } from './schedule-adherence.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { ICustomConfigurationDbEntry } from '../../../shared/component/page-configuration/page-configuration.model';

export const SCHEDULE_ADHERENCE_DATA_LOADING = '[SCHEDULE_ADHERENCE] SCHEDULE ADHERENCE DATA LOADING';
export const SCHEDULE_ADHERENCE_DATA_LOADED = '[SCHEDULE_ADHERENCE] SCHEDULE ADHERENCE DATA LOADED';
export const SCHEDULE_ADHERENCE_FETCH_ERROR = '[SCHEDULE_ADHERENCE] SCHEDULE ADHERENCE FETCH ERROR';
export const SET_SELECTED_FILTERS = '[SCHEDULE_ADHERENCE] SET SELECTED FILTERS';
export const SET_TABLE_SETTINGS = '[SCHEDULE_ADHERENCE] SET TABLE SETTINGS';

export class ScheduleAdherenceDataLoading implements Action {
  readonly type = SCHEDULE_ADHERENCE_DATA_LOADING;

  constructor(public filters: IScheduleAdherenceFilters) {}
}

export class ScheduleAdherenceDataLoaded implements Action {
  readonly type = SCHEDULE_ADHERENCE_DATA_LOADED;

  constructor(public payload: IScheduleAdherenceData) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;

  constructor(public filters: IScheduleAdherenceFilters) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(
    public payload: TabRowInterface[],
    public setAsDefault: boolean,
    public customSections: ICustomConfigurationDbEntry[],
  ) {}
}

export class ScheduleAdherenceFetchError implements Action {
  readonly type = SCHEDULE_ADHERENCE_FETCH_ERROR;

  constructor(public payload: object) {}
}

export type ScheduleAdherenceActions =
  | ScheduleAdherenceDataLoading
  | ScheduleAdherenceDataLoaded
  | SetSelectedFilters
  | SetTableSettings
  | ScheduleAdherenceFetchError;
